import { authenticationPolicyStore } from '@/store';
import AuthorizedNetworkBaseModel from './AuthorizedNetworkBaseModel';

export default class IpDisplayModel extends AuthorizedNetworkBaseModel {
    public constructor(ip: string) {
        super(ip, /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/);
    }

    public validate(): void {
        const isValid = this.regex.test(this.value);

        authenticationPolicyStore.storeApi.commit(authenticationPolicyStore.mutations.updateEditedAuthorizedNetworksIpValidation, {
            id: this.id,
            isValid: isValid,
            errorMessageId: isValid ? void 0 : 'page_policies_ip_invalid',
        });
    }
}
