import { LanguageStoreState } from './state';
import LanguageModel from '@/api/language-api-client/models/LanguageModel';

export default {
    isProcessing(state: LanguageStoreState): boolean {
        return state.isProcessing;
    },

    languages(state: LanguageStoreState): LanguageModel[] {
        return state.languages;
    },
};
