import OidcIdentityProviderOutputModel from '@/api/oidc-idp-client/models/OidcIdentityProviderOutputModel';
import { OidcIdentityProviderStoreState } from './state';

export default {
    setOidcIdentityProvider(state: OidcIdentityProviderStoreState, model: OidcIdentityProviderOutputModel): void {
        state.oidcIdentityProvider = model;
    },

    setProcessingState(state: OidcIdentityProviderStoreState, isProcessing: boolean): void {
        state.isProcessing = isProcessing;
    },

    setUnauthorizedToViewOidcIdp(state: OidcIdentityProviderStoreState): void {
        state.isUnauthorizedToViewOidcIdp = true;
    },
};
