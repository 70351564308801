import util from '@/util';

export default class GroupSearchInputModel {
    public constructor() {
        this.page = 1;
    }

    public page: number;
    public groupName?: string;
    public member?: string;

    public get isStateChanged() {
        return this.page !== 1 || !util.isNullOrEmpty(this.groupName) || !util.isNullOrEmpty(this.member);
    }
}
