export default {
  "common_learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lær mere"])},
  "common_ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "common_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annullér"])},
  "common_keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behold"])},
  "common_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiér"])},
  "common_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieret"])},
  "common_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
  "common_downloaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloadet"])},
  "common_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
  "common_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet"])},
  "common_verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificér"])},
  "common_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificeret"])},
  "common_not_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke verificeret"])},
  "common_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succes"])},
  "common_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mislykkedes"])},
  "common_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "common_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Næste"])},
  "common_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj"])},
  "common_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdatér"])},
  "common_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handlinger"])},
  "common_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem"])},
  "common_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn"])},
  "common_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprettelsesdato"])},
  "common_completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuldført dato"])},
  "common_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luk"])},
  "notif_error_server_unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunne ikke kontakte serveren. Prøv igen om lidt."])},
  "notif_warn_unauthorized_to_perform_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du er ikke autoriseret til at udføre denne handling."])},
  "notif_warn_unauthorized_to_view_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du er ikke autoriseret til at se denne information."])},
  "notif_modal_session_expired_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session udløbet"])},
  "notif_modal_session_expired_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din nuværende session er udløbet. Af sikkerhedsmæssige årsager skal du logge på igen."])},
  "alert_no_verified_domains_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du skal tilføje og bekræfte mindst ét domæne for at kunne fortsætte."])},
  "pagination_first_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå til første side"])},
  "pagination_last_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå til sidste side"])},
  "pagination_previous_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forrige side"])},
  "pagination_next_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Næste side"])},
  "pagination_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["af"])},
  "common_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "common_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detaljer"])},
  "common_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beskrivelse"])},
  "common_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
  "common_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprettet"])},
  "common_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuldførte"])},
  "common_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbage"])},
  "common_sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorter"])},
  "common_apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anvend"])},
  "common_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigér"])},
  "common_yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
  "common_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nej"])},
  "common_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nulstil"])},
  "leave_page_confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har ændringer, der ikke er gemt"])},
  "leave_page_confirmation_modal_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker på, at du vil forlade denne side? Alle ikke-gemte ændringer vil gå tabt."])},
  "common_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis"])},
  "common_show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis"])},
  "common_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])}
}