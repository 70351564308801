import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('ApiError')
export default class ApiError {
    public constructor() {
        this.code = '';
    }

    @JsonProperty('error_code', String)
    public code: string;

    @JsonProperty('details', String, true)
    public details?: string;
}
