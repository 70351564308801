import { JsonObject, JsonProperty } from 'json2typescript';
import UserModel from './UserModel';

@JsonObject('UsersModel')
export default class UsersModel {
    public constructor() {
        this.totalUsers = 0;
        this.totalPages = 0;
    }

    @JsonProperty('total_users', Number)
    public totalUsers: number;

    @JsonProperty('total_pages', Number)
    public totalPages: number;

    @JsonProperty('users', [UserModel])
    public users: UserModel[] = [];
}
