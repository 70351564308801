import { AuthStoreState } from './state';
import AuthUserStoreModel from './models/AuthUserStoreModel';
import TenantContextStoreModel from './models/TenantContextStoreModel';
import i18n from '@/i18n';

export default {
    setUser(state: AuthStoreState, user: AuthUserStoreModel): void {
        state.user = user;

        // Change the UI language
        i18n.switchLanguage(user.languageCode.split('-')[0]);
    },

    setTenant(state: AuthStoreState, tenant: TenantContextStoreModel): void {
        state.tenant = tenant;
    },

    setInitializationState(state: AuthStoreState, isInitialized: boolean): void {
        state.isInitialized = isInitialized;
    },

    setAuthenticationState(state: AuthStoreState, isAuthenticated: boolean): void {
        state.isAuthenticated = isAuthenticated;
    },
};
