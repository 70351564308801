import { AuthStoreState } from './state';
import AuthUserStoreModel from './models/AuthUserStoreModel';
import TenantStoreModel from './models/TenantStoreModel';

export default {
    authenticatedUser(state: AuthStoreState): AuthUserStoreModel {
        return state.user;
    },

    accessTokenValidTo(state: AuthStoreState): number {
        return state.user.accessTokenValidTo;
    },

    currentTenant(state: AuthStoreState): TenantStoreModel | undefined {
        if (!state.tenant?.tenants || state.tenant?.tenants.length === 0) {
            return void 0;
        }

        return state.tenant.tenants.find((tenant) => tenant.id === state.tenant.tenantId);
    },

    otherTenants(state: AuthStoreState): TenantStoreModel[] {
        if (!state.tenant?.tenants || state.tenant?.tenants.length === 0) {
            return [];
        }

        return state.tenant.tenants.filter((tenant) => tenant.id !== state.tenant.tenantId);
    },

    isAuthenticated(state: AuthStoreState): boolean {
        return state.isAuthenticated;
    },

    isAppInitializing(state: AuthStoreState): boolean {
        return !state.isInitialized && !state.isAuthenticated;
    },
};
