import util from '@/util';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('GroupModel')
export default class GroupModel {
    public constructor() {
        this.id = '';
        this.groupName = '';
        this.createdDate = '';
        this.updatedDate = '';
        this.members = 0;
    }

    @JsonProperty('id', String)
    public id: string;

    @JsonProperty('group_name', String)
    public groupName: string;

    @JsonProperty('group_description', String, true)
    public groupDescription?: string;

    @JsonProperty('created_date', String)
    public createdDate: string;

    @JsonProperty('updated_date', String)
    public updatedDate: string;

    @JsonProperty('members', Number)
    public members: number;

    @JsonProperty('external_id', String, true)
    public externalId?: string;

    @JsonProperty('membership_updated_date', String, true)
    public membershipUpdatedDate?: string;

    public get displayCreatedDateTime(): string {
        return util.formatDateTime(this.createdDate);
    }

    public get displayMembershipUpdatedDateTime(): string {
        return this.membershipUpdatedDate ? util.formatDateTime(this.membershipUpdatedDate) : '';
    }

    public get displayGroupName(): string {
        return this.groupName.length > 50 ? `${this.groupName.slice(0, 50)} (...)` : this.groupName;
    }
}
