
import { defineComponent, computed, ref, watch, onBeforeMount, onBeforeUnmount } from 'vue';
import { authStore, uiInteractionStore } from './store';
import AppInitSplash from './components/layout/AppInitSplash.vue';
import NotificationsOverlay from './components/layout/NotificationOverlay/NotificationsOverlay.vue';
import AppNavbar from './components/layout/AppNavbar.vue';
// import AppFooter from './components/layout/AppFooter.vue';
import router from './router';
import routes from './router/routes';
import SessionExpiredModal from '@/components/layout/SessionExpiredModal.vue';
import LeavePageConfirmationModal from '@/components/layout/LeavePageConfirmationModal.vue';
import { RouteLocationNormalizedLoaded } from 'vue-router';

export default defineComponent({
    components: {
        AppInitSplash,
        NotificationsOverlay,
        AppNavbar,
        // AppFooter,
        SessionExpiredModal,
        LeavePageConfirmationModal,
    },

    setup() {
        // local state
        const containerCss = ref(getContainerCss(router.currentRoute.value.path));

        // element refs
        const appNavbar = ref<typeof AppNavbar | null>(null);

        // computed
        const isInitializing = computed(() => authStore.storeApi.get(authStore.getters.isAppInitializing));

        const isSessionExpired = computed(() => !authStore.storeApi.get(authStore.getters.isAuthenticated));

        const isPageNotFound = computed(() => router.currentRoute.value.name === routes.notFound.name);

        const isStateChanged = computed(() => {
            return uiInteractionStore.storeApi.get(uiInteractionStore.getters.isStateChanged);
        });
        const showLeavePageConfirmationModal = computed(() => {
            return uiInteractionStore.storeApi.get(uiInteractionStore.getters.showLeavePageConfirmationModal);
        });

        // functions
        function onAppContentClicked(): void {
            if (!appNavbar.value) {
                return;
            }

            appNavbar.value.hideSignInInformationMenu();
            appNavbar.value.hideHamburgerMenu();
        }

        // lifecycle

        onBeforeMount(async () => {
            window.addEventListener('beforeunload', beforeUnloadEventListener);
        });

        onBeforeUnmount(async () => {
            window.removeEventListener('beforeunload', beforeUnloadEventListener);
        });

        // prevent browser refresh if the authorized networks state was modified without saving
        function beforeUnloadEventListener(event: BeforeUnloadEvent): void {
            if (isStateChanged.value) {
                event.preventDefault();
                // Chrome requires returnValue to be set.
                event.returnValue = '';
            }
        }

        router.beforeEach((to, from, next) => {
            if (isStateChanged.value) {
                uiInteractionStore.storeApi.commit(uiInteractionStore.mutations.setConfirmPageLeaveCallback, () => next());
            } else {
                next();
            }
        });

        watch(
            () => router.currentRoute.value,
            (value: RouteLocationNormalizedLoaded) => {
                containerCss.value = getContainerCss(value.path);
            }
        );

        function getContainerCss(currentRoutePath: string): string {
            const fullWidthRoutes = ['/user', '/groups/details/', '/policies/'];
            for (let i = 0; i < fullWidthRoutes.length; i++) {
                if (currentRoutePath.indexOf(fullWidthRoutes[i]) > -1) {
                    return 'full-width';
                }
            }

            return 'restricted-width';
        }

        return {
            // element refs
            appNavbar,
            containerCss,

            // functions
            onAppContentClicked,

            // computed
            isInitializing,
            isSessionExpired,
            isPageNotFound,
            isStateChanged,
            showLeavePageConfirmationModal,
        };
    },
});
