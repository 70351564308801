import { JsonObject, JsonProperty } from 'json2typescript';
import DomainSourceModel from './DomainSourceModel';

@JsonObject('UserExportInputModel')
export default class UserExportInputModel {
    public constructor() {
        this.domains = new Array<DomainSourceModel>();
        this.fieldsToExport = new Array<string>();
    }

    @JsonProperty('domains', [DomainSourceModel])
    public domains: DomainSourceModel[];

    @JsonProperty('fields_to_export', [String])
    public fieldsToExport: string[];
}
