import { JobStoreState } from './state';
import AggregatedJobModel from '@/api/job-api-client/models/AggregatedJobModel';
import ExportFieldModel from '@/api/job-api-client/models/ExportFieldModel';

export default {
    isProcessing(state: JobStoreState): boolean {
        return state.isProcessing;
    },

    isUnauthorizedToViewJobs(state: JobStoreState): boolean {
        return state.isUnauthorizedToViewJobs;
    },

    jobs(state: JobStoreState): AggregatedJobModel[] {
        return state.jobs;
    },

    userExportFields(state: JobStoreState): ExportFieldModel[] {
        return state.userExportFields;
    },

    auditExportFields(state: JobStoreState): ExportFieldModel[] | undefined {
        return state.auditExportFields;
    },
};
