export default {
  "common_learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])},
  "common_ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "common_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "common_keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conserver"])},
  "common_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier"])},
  "common_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copié"])},
  "common_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])},
  "common_downloaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargé"])},
  "common_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
  "common_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
  "common_verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier"])},
  "common_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifié"])},
  "common_not_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not verified"])},
  "common_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réussite"])},
  "common_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
  "common_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "common_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
  "common_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
  "common_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour"])},
  "common_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "common_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
  "common_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
  "common_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création"])},
  "common_completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed date"])},
  "common_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
  "notif_error_server_unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not contact the web server. Please try again in a few moments."])},
  "notif_warn_unauthorized_to_perform_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not authorized to perform this action."])},
  "notif_warn_unauthorized_to_view_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not authorized to view this data."])},
  "notif_modal_session_expired_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session expirée"])},
  "notif_modal_session_expired_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your current session has expired. For security reasons, you need to sign in again."])},
  "alert_no_verified_domains_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to add and verify at least one domain in order to continue."])},
  "pagination_first_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller à la première page"])},
  "pagination_last_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller à la dernière page"])},
  "pagination_previous_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page précédente"])},
  "pagination_next_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page suivante"])},
  "pagination_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
  "common_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
  "common_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])},
  "common_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "common_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
  "common_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créé"])},
  "common_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminé"])},
  "common_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
  "common_sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trier"])},
  "common_apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliquer"])},
  "common_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
  "common_yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
  "common_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
  "common_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "leave_page_confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez des changements non sauvegardés"])},
  "leave_page_confirmation_modal_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to leave this page? Any unsaved changes will be lost."])},
  "common_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher"])},
  "common_show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
  "common_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])}
}