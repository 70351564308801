import mutations from './mutations';
import { BasicStoreAccessors, RootState } from '../types';
import { MyDomainStoreState } from './state';
import mydomainApiClient from '@/api/mydomain-api-client';
import ApiClientError from '@/api/error';
import { ActionContext } from 'vuex';
import { ERROR_MYDOMAIN_NOT_EXISTS } from '@/api/mydomain-api-client/error-codes';
import { StatusCodes } from 'http-status-codes';
import getters from './getters';
import TenantClientSignInUriModel from '@/api/mydomain-api-client/models/TenantClientSignInUriModel';

let storeApi: BasicStoreAccessors<MyDomainStoreState>;

export function initActions(api: BasicStoreAccessors<MyDomainStoreState>): void {
    storeApi = api;
}

export default {
    async getMyDomainAsync(): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            const myDomainModel = await mydomainApiClient.getAsync();

            if (myDomainModel) {
                storeApi.commit(mutations.setMyDomain, myDomainModel);
            }
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewMyDomain);
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
            storeApi.commit(mutations.setDataLoaded);
        }
    },

    async addMyDomainAsync(context: ActionContext<MyDomainStoreState, RootState>, myDomain: string): Promise<string> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            const myDomainModel = await mydomainApiClient.addAsync(myDomain);

            storeApi.commit(mutations.setMyDomain, myDomainModel);
            storeApi.commit(mutations.setVismaMetadataXml, undefined);

            return myDomainModel.myDomainUri;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
        }
    },

    async updateMyDomainAsync(context: ActionContext<MyDomainStoreState, RootState>, myDomain: string): Promise<string> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            const myDomainModel = await mydomainApiClient.updateAsync(myDomain);

            storeApi.commit(mutations.setMyDomain, myDomainModel);
            storeApi.commit(mutations.setVismaMetadataXml, undefined);

            return myDomainModel.myDomainUri;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
        }
    },

    async deleteMyDomainAsync(): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            await mydomainApiClient.deleteAsync();

            storeApi.commit(mutations.deleteMyDomain);
            storeApi.commit(mutations.setVismaMetadataXml, undefined);
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.statusCode === StatusCodes.NOT_FOUND && apiError.errorCode === ERROR_MYDOMAIN_NOT_EXISTS) {
                storeApi.commit(mutations.deleteMyDomain);
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
        }
    },

    async fetchVismaMetadataXmlAsync(): Promise<string | undefined> {
        try {
            let myDomain = storeApi.get(getters.myDomain);
            if (!myDomain) {
                const myDomainModel = await mydomainApiClient.getAsync();
                myDomain = myDomainModel!.myDomain;
            }

            const vismaMetadataXml = await mydomainApiClient.fetchVismaMetadataXmlAsync(myDomain);

            if (vismaMetadataXml) {
                storeApi.commit(mutations.setVismaMetadataXml, vismaMetadataXml);

                // set visma metadata url to be used further in UI if the xml exists
                const vismaMetadataXmlUrl = mydomainApiClient.getvismaMetadataXmlUrl(myDomain);
                storeApi.commit(mutations.setVismaMetadataXmlUrl, vismaMetadataXmlUrl);

                return vismaMetadataXml;
            }
        } finally {
            // nothing to do here
        }
    },

    async getTenantClientsWithSignInUriAsync(): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            const tenantClients = await mydomainApiClient.getTenantClientsWithSignInUriAsync();
            if (tenantClients) {
                storeApi.commit(mutations.setTenantClientsWithSignInUri, tenantClients);
            }
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewMyDomain);
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
            storeApi.commit(mutations.setDataLoaded);
        }
    },

    async getAssignedTenantClientOnTenantAsync(): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            const tenantClient = await mydomainApiClient.getAssignedTenantClientOnTenantAsync();
            if (tenantClient) {
                storeApi.commit(mutations.setAssignedTenantClientWithSignInUri, tenantClient);
            }
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewMyDomain);
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
            storeApi.commit(mutations.setDataLoaded);
        }
    },

    async updateAssignedTenantClientOnTenantAsync(context: ActionContext<MyDomainStoreState, RootState>, tenantClient: TenantClientSignInUriModel): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);
            await mydomainApiClient.updateAssignedTenantClientOnTenantAsync(tenantClient.clientIdentity);
            storeApi.commit(mutations.setAssignedTenantClientWithSignInUri, tenantClient);
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
        }
    },

    async deleteAssignedTenantClientOnTenantAsync(): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);
            await mydomainApiClient.deleteAssignedTenantClientOnTenantAsync();
            storeApi.commit(mutations.setAssignedTenantClientWithSignInUri, void 0);
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
        }
    },

    async getVismaHomeSignInUriAsync(): Promise<void> {
        try {
            const vismaHomeSignInUri = await mydomainApiClient.getVismaHomeSignInUriAsync();
            storeApi.commit(mutations.setVismaHomeSignInUri, vismaHomeSignInUri);
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewMyDomain);
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
            storeApi.commit(mutations.setDataLoaded);
        }
    },
};
