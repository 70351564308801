/* eslint-disable @typescript-eslint/no-explicit-any */
import { Composer, createI18n, useI18n, UseI18nOptions } from 'vue-i18n';
import requireContext from 'require-context.macro';

export type SupportedLanguages = 'en' | 'da' | 'nl' | 'fi' | 'fr' | 'de' | 'it' | 'lv' | 'no' | 'es' | 'sv';

function loadLocaleMessages(): Record<SupportedLanguages, any | undefined> {
    const locales = requireContext('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = new Map<string, any>();

    locales.keys().forEach((key) => {
        // key is in the format `./en/some_file.json`
        const locale = key.split('/')[1];

        if (messages.has(locale)) {
            messages.set(locale, { ...messages.get(locale), ...locales(key).default });
        } else {
            messages.set(locale, locales(key).default);
        }
    });

    const convertedLocales: Record<SupportedLanguages, any | undefined> = {
        en: undefined,
        da: undefined,
        nl: undefined,
        fi: undefined,
        fr: undefined,
        de: undefined,
        it: undefined,
        lv: undefined,
        no: undefined,
        es: undefined,
        sv: undefined,
    };
    for (const [key, value] of messages) {
        convertedLocales[key as SupportedLanguages] = value;
    }

    return convertedLocales;
}

const localeMessages = loadLocaleMessages();
const messages = {
    en: localeMessages['en'],
    da: localeMessages['da'],
    nl: localeMessages['nl'],
    fi: localeMessages['fi'],
    fr: localeMessages['fr'],
    de: localeMessages['de'],
    it: localeMessages['it'],
    lv: localeMessages['lv'],
    no: localeMessages['no'],
    es: localeMessages['es'],
    sv: localeMessages['sv'],
};

// Some locales can represent the same language, but in different dialects.
// We don't want to show the dialects in the language switcher, so we use this map to map dialects to languages.
const dialectsToLanguagesMap = new Map<string, string>();
dialectsToLanguagesMap.set('nb', 'no');

// i18n instance setup
const i18n = createI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    missingWarn: false,
    globalInjection: true,
    legacy: false,
    silentTranslationWarn: true,
    messages,
});

let i18nModule: Composer<NonNullable<UseI18nOptions['messages']>, NonNullable<UseI18nOptions['datetimeFormats']>, NonNullable<UseI18nOptions['numberFormats']>, NonNullable<UseI18nOptions['locale']>>;

export default {
    get vueModule() {
        return i18n;
    },

    get t() {
        if (!i18nModule) {
            i18nModule = useI18n();
        }

        return i18nModule.t;
    },

    switchLanguage(lang: string): void {
        if (dialectsToLanguagesMap.has(lang)) {
            i18n.global.locale.value = dialectsToLanguagesMap.get(lang) as SupportedLanguages;
        } else {
            i18n.global.locale.value = lang as SupportedLanguages;
        }
        document.querySelector('html')?.setAttribute('lang', lang);
    },
};
