import { JsonObject, JsonProperty } from 'json2typescript';
import Saml2MetadataModel from './Saml2MetadataModel';

@JsonObject('Saml2IdentityProviderModel')
export default class Saml2IdentityProviderModel extends Saml2MetadataModel {
    public constructor() {
        super();

        this.sloEnabled = false;
        this.signAuthnRequests = false;
        this.jitProvisioningSso = false;
        this.certificateRawData = '';
        this.updateClaimsOnSignIn = false;
        this.updateGroupsOnSignIn = false;
    }

    @JsonProperty('metadata_uri', String, true)
    public metadataUri?: string;

    @JsonProperty('single_logout_enabled', Boolean)
    public sloEnabled: boolean;

    @JsonProperty('post_logout_redirect_uri', String, true)
    public postLogoutRedirectUri?: boolean;

    @JsonProperty('sign_authn_requests', Boolean)
    public signAuthnRequests: boolean;

    @JsonProperty('jit_provisioning_sso', Boolean)
    public jitProvisioningSso: boolean;

    @JsonProperty('update_claims_on_signin', Boolean)
    public updateClaimsOnSignIn: boolean;

    @JsonProperty('update_groups_on_signin', Boolean)
    public updateGroupsOnSignIn: boolean;

    @JsonProperty('certificate', String)
    public certificateRawData: string;

    @JsonProperty('relay_state_uri', String, true)
    public relayStateUri?: string;
}
