import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('UserOneTimePassword')
export default class UserOneTimePassword {
    constructor() {
        this.oneTimePassword = '';
    }
    
    @JsonProperty('one_time_password', String)
    public oneTimePassword: string;
}
