export default {
  "page_groups_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppenavn"])},
  "page_groups_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["medlem"])},
  "page_groups_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medlemmer"])},
  "page_groups_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beskrivelse"])},
  "page_groups_table_no_groups_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen grupper tilføjet"])},
  "page_groups_table_no_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen grupper"])},
  "page_groups_table_no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen grupper fundet, der matcher søgekriterierne"])},
  "page_groups_btn_add_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj gruppe"])},
  "page_groups_btn_save_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem gruppe"])},
  "page_groups_btn_edit_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger gruppe"])},
  "page_groups_users_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadresse"])},
  "page_groups_users_fist_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornavn"])},
  "page_groups_users_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efternavn"])},
  "page_groups_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprettet (UTC)"])},
  "page_groups_membership_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medlemskab opdateret (UTC)"])},
  "page_groups_delete_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet gruppen"])},
  "page_groups_delete_group_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker på, at du vil slette gruppen?"])},
  "page_groups_remove_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern medlem"])},
  "page_groups_remove_member_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker på, at du vil fjerne medlemmet"])},
  "page_groups_remove_member_group_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Er du sikker på, at du vil fjerne medlemmet ", _interpolate(_named("member")), " fra gruppen ", _interpolate(_named("group")), "?"])},
  "page_groups_no_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen bruger tilføjet"])},
  "page_groups_no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen brugere matcher søgekriterierne"])},
  "page_groups_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< Tilbage til Grupper"])},
  "page_groups_add_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Føj medlem til gruppen"])},
  "page_groups_add_users_all_are_already_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle brugere er allerede føjet til denne gruppe"])},
  "page_groups_add_users_all_no_users_matching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen brugere matcher søgekriterierne"])},
  "page_groups_member_since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medlem siden"])},
  "page_groups_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern"])},
  "page_groups_group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppemedlemmer:"])},
  "page_groups_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe-id:"])},
  "page_groups_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kilde"])},
  "page_groups_err_group_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der opstod en ukendt fejl under tilføjelse af denne gruppe."])},
  "page_groups_err_group_invalid_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppenavnet er ugyldigt. Maksimal tilladt længde er 255 tegn"])},
  "page_groups_err_group_invalid_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppebeskrivelsen er ugyldig. Maksimalt tilladt længde er 1024 tegn"])},
  "page_groups_err_group_already_exits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den gruppe, du forsøgte at tilføje, eksisterer allerede."])},
  "page_groups_err_group_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der opstod en ukendt fejl under opdatering af denne gruppe."])},
  "page_groups_search_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg efter gruppenavn"])},
  "page_groups_search_group_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg på e-mail"])},
  "page_groups_search_group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg medlemmer"])},
  "page_groups_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grupper"])},
  "page_groups_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gruppe"])},
  "page_groups_filter_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer medlemmer"])},
  "page_groups_member_filter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Medlem: ", _interpolate(_named("name"))])}
}