import Saml2IdentityProviderOutputModel from '@/api/saml2-idp-client/models/Saml2IdentityProviderOutputModel';
import StoreEntity from '../models';

export class Saml2IdentityProviderStoreState extends StoreEntity {
    constructor() {
        super();

        this.isUnauthorizedToViewSaml2Idp = false;
    }

    public saml2IdentityProvider: Saml2IdentityProviderOutputModel | undefined;

    public isUnauthorizedToViewSaml2Idp: boolean;
}

export default new Saml2IdentityProviderStoreState();
