export default {
  "page_provisioning_no_sso_setup_alert_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfiguration för Single Sign-on"])},
  "page_provisioning_no_sso_setup_alert_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du måste aktivera Single Sign-on för att kunna fortsätta med konfigurationen av SCIM-provisionering."])},
  "page_provisioning_no_sso_setup_alert_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå till Single Sign-on"])},
  "page_provisioning_scim_not_supported_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM stöds inte"])},
  "page_provisioning_scim_not_supported_alert_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["System for Cross-domain Identity Management (SCIM) för ", _interpolate(_named("idpName")), " stöds inte."])},
  "page_provisioning_scim_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Med provisionering kan du automatisera vanliga administrativa uppgifter. Genom att aktivera System for Cross-domain Identity Management (SCIM) kan du koppla Visma till ", _interpolate(_named("idpName")), ", så att du kan:"])},
  "page_provisioning_scim_info_create_users_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa användare och grupper"])},
  "page_provisioning_scim_info_grant_revoke_access_to_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevilja och återkalla åtkomst till grupper"])},
  "page_provisioning_scim_info_edit_attributes_users_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera attribut för användare och grupper"])},
  "page_provisioning_scim_info_suspend_deprovisioned_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktivera avregistrerade användare"])},
  "page_provisioning_scim_cache_refresh_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Observera att varje konfigurationsändring kan ta upp till två minuter innan den träder i kraft på SCIM-servern."])},
  "page_provisioning_scim_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM 2.0-provisionering"])},
  "page_provisioning_scim_last_sync_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senaste synkroniseringsaktivitet"])},
  "page_provisioning_scim_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen"])},
  "page_provisioning_scim_endpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM-slutpunkt"])},
  "page_provisioning_scim_bearer_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM-ägartoken"])},
  "page_provisioning_scim_bearer_token_generated_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Genererad den ", _interpolate(_named("date"))])},
  "page_provisioning_scim_bearer_token_expires_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Upphör den ", _interpolate(_named("date"))])},
  "page_provisioning_scim_bearer_token_btn_generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generera SCIM-token"])},
  "page_provisioning_scim_modal_disable_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktivera SCIM 2.0-provisionering"])},
  "page_provisioning_scim_modal_disable_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Är du säker på att du vill inaktivera SCIM?"])},
  "page_provisioning_scim_modal_disable_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktivera"])},
  "page_provisioning_scim_refresh_token_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdatera SCIM-token"])},
  "page_provisioning_scim_modal_refresh_token_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Är du säker på att du vill uppdatera SCIM-token?"])},
  "page_provisioning_scim_modal_refresh_token_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdatera"])},
  "page_provisioning_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provisioneringsåtgärder"])},
  "page_provisioning_action_user_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa användare"])},
  "page_provisioning_action_user_read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa användare"])},
  "page_provisioning_action_user_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdatera användare"])},
  "page_provisioning_action_user_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort användare"])},
  "page_provisioning_action_group_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa grupper"])},
  "page_provisioning_action_group_read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa grupper"])},
  "page_provisioning_action_group_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdatera grupper"])},
  "page_provisioning_action_group_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort grupper"])},
  "page_provisioning_action_user_update_triggers": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["När användare är inaktiverade från ", _interpolate(_named("idpName")), ", utför denna åtgärd i ", _interpolate(_named("tenantOwnerName"))])},
  "page_provisioning_action_user_update_trigger_suspend_useraccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktivera användare"])},
  "page_provisioning_action_user_update_trigger_delete_from_tenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort användare"])},
  "page_provisioning_action_user_update_trigger_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete user"])},
  "page_provisioning_action_user_delete_triggers": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["När användare tas bort i ", _interpolate(_named("idpName")), ", utför denna åtgärd i ", _interpolate(_named("tenantOwnerName"))])},
  "page_provisioning_action_user_delete_trigger_suspend_useraccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktivera användare"])},
  "page_provisioning_action_user_delete_trigger_delete_from_tenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort användare"])},
  "page_provisioning_scim_groups_alert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("tenantOwnerName")), " stöder inte Skapa/Visa/Uppdatera/Ta bort grupper. Däremot kan du tilldela grupper till policyer."])},
  "page_provisioning_scim_unsupported_actions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("tenantOwnerName")), " stöder inte SCIM-synkronisering till ", _interpolate(_named("unsupportedActions")), "."])},
  "page_provisioning_scim_unsupported_actions_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ändringar kommer endast att återspeglas i Vismas identitetssystem."])},
  "page_provisioning_token_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM-token"])},
  "page_provisioning_token_modal_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiera denna hemliga token till din SCIM-leverantör."])},
  "page_provisioning_token_modal_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token kommer bara att visas en gång och kommer att förfalla om 1 år."])},
  "page_provisioning_action_trigger_no_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen åtgärd"])},
  "page_provisioning_btn_view_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View provisioning logs"])},
  "page_provisioning_logs_btn_view_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View settings"])},
  "page_provisioning_logs_btn_refresh_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh logs"])},
  "page_provisioning_logs_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provisioning logs"])},
  "page_provisioning_logs_no_logs_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No SCIM requests have been made on this setup yet."])},
  "page_provisioning_logs_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unknown error has occurred while fetching SCIM logs."])},
  "page_provisioning_logs_item_request_query_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Query string"])},
  "page_provisioning_logs_item_request_json_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request body"])},
  "page_provisioning_logs_item_response_status_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response status code"])},
  "page_provisioning_logs_item_view_json": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View JSON"])},
  "page_provisioning_logs_item_no_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<no input>"])},
  "page_provisioning_logs_item_no_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<missing info>"])}
}