import AuthenticationPolicyModel from '@/api/authenticationpolicy-api-client/models/AuthenticationPolicyModel';
import AuthenticationPolicyStoreModel from './models/AuthenticationPolicyStoreModel';
import IdentityProviderStoreModel from './models/IdentityProviderStoreModel';
import { AuthenticationPolicyStoreState } from './state';
import AuthorizedNetworksStoreModel from './models/AuthorizedNetworksStoreModel';
import IpRangeDisplayModel from '@/components/authenticationpolicies/AuthenticationPolicy/AuthenticationPolicyAuthorizedNetworks/models/IpRangeDisplayModel';
import IpDisplayModel from '@/components/authenticationpolicies/AuthenticationPolicy/AuthenticationPolicyAuthorizedNetworks/models/IpDisplayModel';
import SubnetMaskDisplayModel from '@/components/authenticationpolicies/AuthenticationPolicy/AuthenticationPolicyAuthorizedNetworks/models/SubnetMaskDisplayModel';
import { Guid } from 'guid-typescript';
import AuthenticationPolicyCountryStoreModel from './models/AuthenticationPolicyCountryStoreModel';
import CountryModel from '@/api/country-api-client/models/CountryModel';
import SessionRestrictionsSettingsModel from '@/api/authenticationpolicy-api-client/models/SessionRestrictionsSettingsModel';

export default {
    setAuthenticationPolicies(state: AuthenticationPolicyStoreState, authenticationPolicies: AuthenticationPolicyStoreModel[]): void {
        state.authenticationPolicies = authenticationPolicies;
    },

    updateAuthenticationPolicy(state: AuthenticationPolicyStoreState, authenticationPolicyModel: AuthenticationPolicyModel): void {
        const authPolicyIndex = state.authenticationPolicies.findIndex((p) => p.id == authenticationPolicyModel.id);
        const authenticationPolicyStoreModel = new AuthenticationPolicyStoreModel(authenticationPolicyModel);
        state.authenticationPolicies.splice(authPolicyIndex, 1, authenticationPolicyStoreModel);
    },

    setAllIdentityProviders(state: AuthenticationPolicyStoreState, identityProviders: IdentityProviderStoreModel[]): void {
        state.allIdentityProviders = identityProviders;
    },

    setStoreProcessingState(state: AuthenticationPolicyStoreState, isProcessing: boolean): void {
        state.isProcessing = isProcessing;
    },

    setUnauthorizedToViewAuthenticationPolicies(state: AuthenticationPolicyStoreState): void {
        state.isUnauthorizedToViewAuthenticationPolicies = true;
    },

    setDataLoaded(state: AuthenticationPolicyStoreState, isDataLoaded: boolean): void {
        state.isDataLoaded = isDataLoaded;
    },

    setEditedAuthorizedNetworks(state: AuthenticationPolicyStoreState, authorizedNetworks?: string[]): void {
        const model = new AuthorizedNetworksStoreModel();

        if (!authorizedNetworks || authorizedNetworks.length === 0) {
            state.editedAuthorizedNetworks = model;
            return;
        }

        model.ipRanges = authorizedNetworks
            .filter((n) => n.indexOf('-') > -1)
            .map((n) => {
                const ips = n.split('-');
                return new IpRangeDisplayModel(ips[0], ips[1]);
            });
        model.ips = authorizedNetworks.filter((n) => n.indexOf('-') === -1 && n.indexOf('/') === -1).map((n) => new IpDisplayModel(n));
        model.subnetMasks = authorizedNetworks.filter((n) => n.indexOf('/') > -1).map((n) => new SubnetMaskDisplayModel(n));

        state.editedAuthorizedNetworks = model;
    },

    addEditedAuthorizedNetworksIpRange(state: AuthenticationPolicyStoreState): void {
        state.editedAuthorizedNetworks.ipRanges.push(new IpRangeDisplayModel('', ''));
    },

    addEditedAuthorizedNetworksIp(state: AuthenticationPolicyStoreState): void {
        state.editedAuthorizedNetworks.ips.push(new IpDisplayModel(''));
    },

    addEditedAuthorizedNetworksSubnetMask(state: AuthenticationPolicyStoreState): void {
        state.editedAuthorizedNetworks.subnetMasks.push(new SubnetMaskDisplayModel(''));
    },

    removeEditedAuthorizedNetworksIpRange(state: AuthenticationPolicyStoreState, id: Guid): void {
        const index = state.editedAuthorizedNetworks.ipRanges.findIndex((r) => r.id === id);

        if (index >= 0) {
            state.editedAuthorizedNetworks.ipRanges.splice(index, 1);
        }
    },

    removeEditedAuthorizedNetworksIp(state: AuthenticationPolicyStoreState, id: Guid): void {
        const index = state.editedAuthorizedNetworks.ips.findIndex((i) => i.id === id);

        if (index >= 0) {
            state.editedAuthorizedNetworks.ips.splice(index, 1);
        }
    },

    removeEditedAuthorizedNetworksSubnetMask(state: AuthenticationPolicyStoreState, id: Guid): void {
        const index = state.editedAuthorizedNetworks.subnetMasks.findIndex((s) => s.id === id);

        if (index >= 0) {
            state.editedAuthorizedNetworks.subnetMasks.splice(index, 1);
        }
    },

    updateEditedAuthorizedNetworksIpRange(state: AuthenticationPolicyStoreState, { id, firstValue, secondValue }: { id: Guid; firstValue?: string; secondValue?: string }): void {
        const ipRange = state.editedAuthorizedNetworks.ipRanges.find((r) => r.id === id);

        if (!ipRange) {
            return;
        }

        if (firstValue !== undefined) {
            ipRange.value = firstValue;
            ipRange.isValid = true;
            ipRange.isFirstValueValid = true;
        }

        if (secondValue !== undefined) {
            ipRange.secondValue = secondValue;
            ipRange.isValid = true;
            ipRange.isSecondValueValid = true;
        }
    },

    updateEditedAuthorizedNetworksIp(state: AuthenticationPolicyStoreState, { id, value }: { id: Guid; value: string }): void {
        const ip = state.editedAuthorizedNetworks.ips.find((i) => i.id === id);

        if (!ip) {
            return;
        }

        ip.value = value;
        ip.isValid = true;
    },

    updateEditedAuthorizedNetworksSubnetMask(state: AuthenticationPolicyStoreState, { id, value }: { id: Guid; value: string }): void {
        const subnetMask = state.editedAuthorizedNetworks.subnetMasks.find((s) => s.id === id);

        if (!subnetMask) {
            return;
        }

        subnetMask.value = value;
        subnetMask.isValid = true;
    },

    updateEditedAuthorizedNetworksIpRangeValidation(
        state: AuthenticationPolicyStoreState,
        {
            id,
            isValid = true,
            isFirstValueValid = true,
            isSecondValueValid = true,
            errorMessageId,
        }: { id: Guid; isValid?: boolean; isFirstValueValid?: boolean; isSecondValueValid?: boolean; errorMessageId?: string }
    ): void {
        const ipRange = state.editedAuthorizedNetworks.ipRanges.find((r) => r.id === id);

        if (!ipRange) {
            return;
        }

        ipRange.isValid = isValid;
        ipRange.isFirstValueValid = isFirstValueValid;
        ipRange.isSecondValueValid = isSecondValueValid;
        ipRange.errorMessageId = errorMessageId;
    },

    updateEditedAuthorizedNetworksIpValidation(state: AuthenticationPolicyStoreState, { id, isValid, errorMessageId }: { id: Guid; isValid: boolean; errorMessageId?: string }): void {
        const ip = state.editedAuthorizedNetworks.ips.find((i) => i.id === id);

        if (!ip) {
            return;
        }

        ip.isValid = isValid;
        ip.errorMessageId = errorMessageId;
    },

    updateEditedAuthorizedNetworksSubnetMaskValidation(state: AuthenticationPolicyStoreState, { id, isValid, errorMessageId }: { id: Guid; isValid: boolean; errorMessageId?: string }): void {
        const subnetMask = state.editedAuthorizedNetworks.subnetMasks.find((s) => s.id === id);

        if (!subnetMask) {
            return;
        }

        subnetMask.isValid = isValid;
        subnetMask.errorMessageId = errorMessageId;
    },

    setEditedCountries(state: AuthenticationPolicyStoreState, { allCountries, selectedCountries }: { allCountries: CountryModel[]; selectedCountries: string[] }): void {
        state.editedCountries = allCountries.map((c) => new AuthenticationPolicyCountryStoreModel(c.name, c.iso2Code, selectedCountries.indexOf(c.iso2Code) >= 0));
    },

    toggleEditedCountry(state: AuthenticationPolicyStoreState, { code, selected }: { code: string; selected: boolean }): void {
        const country = state.editedCountries.find((c) => c.iso2Code === code);
        if (country) {
            country.selected = selected;
        }
    },

    setEditedPasswordPolicy(state: AuthenticationPolicyStoreState, changes: object): void {
        state.editedPasswordPolicy = Object.assign(state.editedPasswordPolicy, changes);
    },

    setEditedPasswordPolicyValidation(state: AuthenticationPolicyStoreState, changes: object): void {
        state.editedPasswordPolicyValidation = Object.assign(state.editedPasswordPolicyValidation, changes);
    },

    setEditedSessionRestrictionsPolicy(state: AuthenticationPolicyStoreState, changes: object): void {
        state.editedSessionRestrictions = Object.assign(state.editedSessionRestrictions, changes);
    },

    setSessionRestrictionsSettings(state: AuthenticationPolicyStoreState, model: SessionRestrictionsSettingsModel): void {
        state.sessionRestrictionsSettings = model;
    },
};
