import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('UserOneTimeEmergencyCodeInputModel')
export default class UserOneTimeEmergencyCodeInputModel {
    constructor(sendEmail?: boolean) {
        this.sendEmail = sendEmail;
    }
    
    @JsonProperty('send_email', Boolean)
    public sendEmail?: boolean;
}
