import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@vismaux/vud/dist/img/spinners/spinner_doughnut_grey_light.svg'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["loading-spinner", 'loading-spinner-' + _ctx.size])
      }, [
        _createElementVNode("img", {
          class: _normalizeClass('spinner-' + _ctx.size),
          alt: "spinner",
          src: _imports_0
        }, null, 2)
      ], 2))
    : _createCommentVNode("", true)
}