import StoreEntity from '../models';

export class Saml2SpProviderStoreState extends StoreEntity {
    constructor() {
        super();
        this.isUnauthorizedToViewSaml2Sp = false;
    }

    public vismaPublicCertificate: string | undefined;
    public isUnauthorizedToViewSaml2Sp: boolean;
}

export default new Saml2SpProviderStoreState();
