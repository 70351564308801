import { JsonObject, JsonProperty } from 'json2typescript';
import BaseUserDetailsModel from './Base/BaseUserDetailsModel';

@JsonObject('UserNotMemberOfGroupModel')
export default class UserNotMemberOfGroupModel extends BaseUserDetailsModel {
    constructor() {
        super();

        this.id = '';
        this.email = '';
        this.firstName = '';
        this.lastName = '';
    }

    @JsonProperty('id', String)
    public id: string;

    @JsonProperty('email', String)
    public email: string;

    @JsonProperty('first_name', String)
    public firstName: string;

    @JsonProperty('last_name', String)
    public lastName: string;
}
