import util from '@/util';
import { JsonConverter, JsonCustomConvert } from 'json2typescript';
import { OidcProviderType } from '../enums/OidcProviderType';

@JsonConverter
export default class OidcProviderTypeConverter implements JsonCustomConvert<OidcProviderType> {
    serialize(data: OidcProviderType): string {
        return data?.toString() ?? OidcProviderType.Other.toString();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(data: any): OidcProviderType {
        if (!data) {
            return OidcProviderType.Other;
        }

        const dataString = data.toString();
        if (util.isNullOrEmpty(dataString)) {
            return OidcProviderType.Other;
        }

        const providerType = OidcProviderType[dataString as keyof typeof OidcProviderType];
        if (!providerType) {
            return OidcProviderType.Other;
        }

        return providerType;
    }
}
