import { JsonObject, JsonProperty } from 'json2typescript';
import { OidcProviderType } from '../../enums/OidcProviderType';
import OidcProviderTypeConverter from '../../json-converters/OidcProviderTypeConverter';

@JsonObject('OidcIdentityProviderModel')
export default class OidcIdentityProviderModel {
    public constructor() {
        this.displayName = '';
        this.authority = '';
        this.responseType = '';
        this.clientId = '';
        this.scope = '';
        this.getClaimsFromUserInfoEndpoint = false;
        this.usePkce = false;
        this.jitProvisioningSso = false;
        this.providerType = OidcProviderType.Other;
    }

    @JsonProperty('display_name', String)
    public displayName: string;

    @JsonProperty('authority', String)
    public authority: string;

    @JsonProperty('response_type', String)
    public responseType: string;

    @JsonProperty('client_id', String)
    public clientId: string;

    @JsonProperty('client_secret', String, true)
    public clientSecret?: string;

    @JsonProperty('scope', String)
    public scope: string;

    @JsonProperty('get_claims_from_userinfo_endpoint', Boolean)
    public getClaimsFromUserInfoEndpoint: boolean;

    @JsonProperty('use_pkce', Boolean)
    public usePkce: boolean;

    @JsonProperty('jit_provisioning_sso', Boolean)
    public jitProvisioningSso: boolean;

    @JsonProperty('provider_type', OidcProviderTypeConverter)
    public providerType: OidcProviderType;
}
