import httpClient from '@/http-client';
import ApiRequestPayload from '@/http-client/models/ApiRequestPayload';
import { StatusCodes } from 'http-status-codes';
import ApiClientError from '../error';
import GroupModel from './models/GroupModel';
import GroupUserSearchInputModel from './models/GroupUserSearchInputModel';
import GroupUsersTotalsOutputModel from './models/GroupUsersTotalsOutputModel';
import GroupsModel from './models/GroupsModel';
import GroupSearchInputModel from './models/GroupSearchInputModel';

const routeRoot = '/v1.0/groups';

export default {
    async searchGroupsAsync(model: GroupSearchInputModel): Promise<GroupsModel> {
        let requestUri = `${routeRoot}?page=${model.page}`;
        if (model.groupName) {
            requestUri += `&group_name=${encodeURIComponent(model.groupName)}`;
        }

        if (model.member) {
            requestUri += `&member=${encodeURIComponent(model.member)}`;
        }

        const response = await httpClient.getAsync(requestUri);

        if (response.statusCode === StatusCodes.OK) {
            return response.data(GroupsModel)!;
        }

        if (response.statusCode === StatusCodes.NO_CONTENT) {
            return new GroupsModel();
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async updateAsync(group: GroupModel): Promise<GroupModel | undefined> {
        const response = await httpClient.putAsync(`${routeRoot}/${group.id}`, new ApiRequestPayload(group, GroupModel));

        if (response.statusCode === StatusCodes.OK) {
            return response.data(GroupModel);
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async addGroupAsync(name: string, description: string): Promise<GroupModel | undefined> {
        const model = new GroupModel();
        model.groupName = name;
        model.groupDescription = description;

        const response = await httpClient.postAsync(routeRoot, new ApiRequestPayload(model, GroupModel));

        if (response.statusCode !== StatusCodes.OK) {
            throw new ApiClientError(response.statusCode, response.errorCode);
        }

        return response.data(GroupModel);
    },

    async deleteGroupAsync(groupId: string): Promise<void> {
        const response = await httpClient.deleteAsync(`${routeRoot}/${groupId}`);

        if (response.statusCode !== StatusCodes.NO_CONTENT) {
            throw new ApiClientError(response.statusCode, response.errorCode);
        }
    },

    async getGroupUsersAsync(groupId: string, model: GroupUserSearchInputModel): Promise<GroupUsersTotalsOutputModel | undefined> {
        let requestUri = `${routeRoot}/${groupId}/users`;
        if (model) {
            requestUri += `?page=${model.page}&sort_attribute=${model.sortColumn}&sort_order=${model.sortOrder}`;

            if (model.member) {
                requestUri += `&member=${encodeURIComponent(model.member)}`;
            }
        }

        const response = await httpClient.getAsync(requestUri);

        if (response.statusCode === StatusCodes.OK) {
            return response.data(GroupUsersTotalsOutputModel);
        }

        if (response.statusCode === StatusCodes.NO_CONTENT) {
            return undefined;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async deleteUserAsync(groupId: string, userId: string): Promise<void> {
        const response = await httpClient.deleteAsync(`${routeRoot}/${groupId}/users/${userId}`);

        if (response.statusCode !== StatusCodes.NO_CONTENT) {
            throw new ApiClientError(response.statusCode, response.errorCode);
        }
    },

    async addUserAsync(groupId: string, userId: string) {
        const response = await httpClient.postAsync(`${routeRoot}/${groupId}/users/${userId}`);

        if (response.statusCode !== StatusCodes.NO_CONTENT) {
            throw new ApiClientError(response.statusCode, response.errorCode);
        }
    },
};
