import { ActionContext } from 'vuex';
import authApiClient from '@/api/auth-api-client';
import { BasicStoreAccessors, RootState } from '../types';
import { AuthStoreState } from './state';
import AuthUserStoreModel from './models/AuthUserStoreModel';
import TenantContextStoreModel from './models/TenantContextStoreModel';
import mutations from './mutations';

let storeApi: BasicStoreAccessors<AuthStoreState>;

export function initActions(api: BasicStoreAccessors<AuthStoreState>): void {
    storeApi = api;
}

export default {
    async authenticateAsync(context: ActionContext<AuthStoreState, RootState>, force = false): Promise<boolean> {
        try {
            // Check if we are already authenticated
            if (context.state.isAuthenticated && !force) {
                return true;
            }

            const authenticationDetails = await authApiClient.getAuthenticationDetailsAsync();

            storeApi.commit(mutations.setAuthenticationState, true);
            storeApi.commit(mutations.setUser, new AuthUserStoreModel(authenticationDetails));
            storeApi.commit(mutations.setTenant, new TenantContextStoreModel(authenticationDetails));
            storeApi.commit(mutations.setInitializationState, true);

            return true;
        } catch {
            storeApi.commit(mutations.setAuthenticationState, false);

            return false;
        }
    },
};
