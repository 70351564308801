import { DomainStoreState } from './state';
import DomainStoreModel from './models/DomainStoreModel';

function compareByName(a: DomainStoreModel, b: DomainStoreModel): number {
    return a.name.localeCompare(b.name);
}

export default {
    domains(state: DomainStoreState): DomainStoreModel[] {
        return state.domains.sort((a, b) => compareByName(a, b));
    },

    verifiedDomains(state: DomainStoreState): DomainStoreModel[] {
        return state.domains.filter((domain) => domain.isVerified).sort((a, b) => compareByName(a, b));
    },

    verifiedDomainsWithUsers(state: DomainStoreState): DomainStoreModel[] {
        return state.domains.filter((domain) => domain.isVerified && domain.userCount > 0).sort((a, b) => compareByName(a, b));
    },

    pendingDomains(state: DomainStoreState): DomainStoreModel[] {
        return state.domains.filter((domain) => !domain.isVerified).sort((a, b) => compareByName(a, b));
    },

    isProcessing(state: DomainStoreState): boolean {
        return state.isProcessing;
    },

    isUnauthorizedToViewDomains(state: DomainStoreState): boolean {
        return state.isUnauthorizedToViewDomains;
    },
};
