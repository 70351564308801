
import { computed, defineComponent, onMounted, watch } from 'vue';
import { jobStore } from '@/store';
import {
    JOB_STATE_COMPLETED,
    JOB_STATE_COMPLETED_WITH_ERRORS,
    JOB_STATE_FAILED,
    JOB_STATE_PROCESSING,
    JOB_STATE_QUEUED,
    JOB_TYPE_BULK_DOMAIN_CHANGE,
    JOB_TYPE_USER_AUDIT_EXPORT,
    JOB_TYPE_USER_EXPORT,
    JOB_TYPE_USER_IMPORT,
} from '@/api/job-api-client/constants';
import translations from '@/locales/translations';
import i18n from '@/i18n';
import config from '@/config';
import uiInteractionStore from '@/store/ui-interaction-store';
import AggregatedJobModel from '@/api/job-api-client/models/AggregatedJobModel';

export default defineComponent({
    name: 'NavbarJobsItemComponent',
    setup() {
        //state
        let refreshTimer: null | ReturnType<typeof setTimeout> = null;

        //computed
        const showJobsList = computed(() => {
            return uiInteractionStore.storeApi.get(uiInteractionStore.getters.showJobsList);
        });

        const jobs = computed(() => {
            return jobStore.storeApi.get(jobStore.getters.jobs);
        });

        const isStateLoading = computed(() => {
            return jobStore.storeApi.get(jobStore.getters.isProcessing);
        });

        const isUnauthorizedToViewJobs = computed(() => {
            return jobStore.storeApi.get(jobStore.getters.isUnauthorizedToViewJobs);
        });

        //functions
        function toggleShowJobsList(): void {
            uiInteractionStore.storeApi.commit(uiInteractionStore.mutations.setShowJobsList, !showJobsList.value);
        }

        function closeJobsList(): void {
            uiInteractionStore.storeApi.commit(uiInteractionStore.mutations.setShowJobsList, false);
        }

        watch(
            () => showJobsList.value,
            async (value: boolean) => {
                if (value) {
                    await jobStore.storeApi.dispatch(jobStore.actions.getJobsAsync);

                    refreshTimer = setInterval(async () => {
                        await jobStore.storeApi.dispatch(jobStore.actions.getJobsAsync);
                    }, 30 * 1000);
                } else {
                    clearInterval(Number(refreshTimer));
                }
            }
        );

        function getJobTypeDisplay(type: string): string {
            switch (type) {
                case JOB_TYPE_BULK_DOMAIN_CHANGE:
                    return i18n.t(translations.NAV_MENU_JOBS_CHANGE_DOMAIN_JOB_TYPE);
                case JOB_TYPE_USER_EXPORT:
                    return i18n.t(translations.NAV_MENU_JOBS_USERS_EXPORT_JOB_TYPE);
                case JOB_TYPE_USER_IMPORT:
                    return i18n.t(translations.NAV_MENU_JOBS_USERS_IMPORT_JOB_TYPE);
                case JOB_TYPE_USER_AUDIT_EXPORT:
                    return i18n.t(translations.NAV_MENU_JOBS_USER_AUDIT_EXPORT_JOB_TYPE);
            }
            return '';
        }

        function getProgressDisplay(job: AggregatedJobModel): string {
            if (job.status === JOB_STATE_PROCESSING) {
                return `(${job.progress}%)`;
            }
            return '';
        }

        function getJobStatusIconClass(status: string): string {
            switch (status) {
                case JOB_STATE_QUEUED:
                case JOB_STATE_PROCESSING:
                    return 'vismaicon-info icon-info';
                case JOB_STATE_COMPLETED:
                    return 'vismaicon-success icon-success';
                case JOB_STATE_COMPLETED_WITH_ERRORS:
                    return 'vismaicon-warning icon-warning';
                case JOB_STATE_FAILED:
                    return 'vismaicon-error icon-error';
                default:
                    return '';
            }
        }

        function getStatusDisplay(status: string): string {
            switch (status) {
                case JOB_STATE_QUEUED:
                    return i18n.t(translations.NAV_MENU_JOBS_STATUS_QUEUED);
                case JOB_STATE_PROCESSING:
                    return i18n.t(translations.NAV_MENU_JOBS_STATUS_PROCESSING);
                case JOB_STATE_COMPLETED:
                    return i18n.t(translations.NAV_MENU_JOBS_STATUS_COMPLETED);
                case JOB_STATE_COMPLETED_WITH_ERRORS:
                    return i18n.t(translations.NAV_MENU_JOBS_STATUS_COMPLETED_WITH_ERRORS);
                case JOB_STATE_FAILED:
                    return i18n.t(translations.NAV_MENU_JOBS_STATUS_FAILED);
                default:
                    return '';
            }
        }

        function isJobCompleted(status: string): boolean {
            return status === JOB_STATE_COMPLETED || status === JOB_STATE_COMPLETED_WITH_ERRORS || status === JOB_STATE_FAILED;
        }

        function getDownloadUrl(type: string, jobId: string): string {
            return `${config().backendUrl}/v1.0/jobs/${jobId}/download?type=${type}`;
        }

        // lifecycle
        onMounted(async () => {
            const isProcessing = jobStore.storeApi.get(jobStore.getters.isProcessing);

            if (!isProcessing) {
                await jobStore.storeApi.dispatch(jobStore.actions.getJobsAsync);
            }
        });

        return {
            //state
            refreshTimer,

            //computed
            jobs,
            showJobsList,
            isStateLoading,
            isUnauthorizedToViewJobs,

            //functions
            getJobTypeDisplay,
            getProgressDisplay,
            getStatusDisplay,
            getJobStatusIconClass,
            getDownloadUrl,
            isJobCompleted,
            toggleShowJobsList,
            closeJobsList,
        };
    },
});
