export default {
  "page_users_no_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gebruikers"])},
  "page_users_users_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers"])},
  "page_users_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gebruikers"])},
  "page_users_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gebruiker"])},
  "page_users_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
  "page_users_recovery_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herstelmailadres"])},
  "page_users_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikersnaam"])},
  "page_users_first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
  "page_users_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
  "page_users_last_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste keer ingelogd"])},
  "page_users_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangemaakt"])},
  "page_users_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijgewerkt"])},
  "page_users_language_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal"])},
  "page_users_country_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
  "page_users_applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicaties"])},
  "page_users_electronic_id_enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektronische ID ingeschakeld"])},
  "page_users_2step_enabled_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tweestapsverificatie ingeschakeld"])},
  "page_users_search_email_or_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres of naam zoeken"])},
  "page_users_bulk_email_domain_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-maildomein collectief wijzigen"])},
  "page_users_user_export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers exporteren"])},
  "page_users_modal_domain_change_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarschuwing: Gebruikersaccounts worden gewijzigd"])},
  "page_users_modal_domain_change_body_n_users_n_domains": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["U gaat ", _interpolate(_named("usersCount")), " gebruikersaccounts van ", _interpolate(_named("domainsCount")), " domeinen wijzigen"])},
  "page_users_modal_domain_change_body_1_user_1_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U gaat 1 gebruikersaccount van 1 domein wijzigen"])},
  "page_users_modal_domain_change_body_n_users_1_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["U gaat ", _interpolate(_named("usersCount")), " gebruikersaccounts van 1 domein wijzigen"])},
  "page_users_modal_domain_change_body_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zorg ervoor dat u de gebruikers op de hoogte brengt van deze wijzigingen"])},
  "page_users_modal_domain_change_source_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brondomeinen"])},
  "page_users_modal_domain_change_target_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doeldomein"])},
  "page_users_modal_domain_change_start_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domein wijzigen"])},
  "page_users_modal_domain_change_not_target_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er zijn geen beschikbare doeldomeinen. Demarkeer een van de brondomeinen en probeer het opnieuw"])},
  "page_users_modal_domain_change_approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik ben mij ervan bewust dat deze actie de geselecteerde gebruikersaccounts gaat wijzigen"])},
  "page_users_modal_domain_change_example_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorbeeld"])},
  "page_users_modal_domain_change_example_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["john.doe", "@", _interpolate(_named("sourceDomain")), " wordt gewijzigd in john.doe", "@", _interpolate(_named("targetDomain"))])},
  "page_users_modal_export_users_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers exporteren"])},
  "page_users_modal_export_users_start_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers exporteren"])},
  "page_users_modal_export_users_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domeinen"])},
  "page_users_modal_export_users_body_1_user_1_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 gebruiker van 1 domein wordt geëxporteerd"])},
  "page_users_modal_export_users_body_n_users_1_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("usersCount")), " gebruikers van 1 domein worden geëxporteerd"])},
  "page_users_modal_export_users_body_n_user_n_domains": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("usersCount")), " gebruikers van ", _interpolate(_named("domainsCount")), " domeinen worden geëxporteerd"])},
  "page_users_modal_export_users_select_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer de kolommen met de gegevens die u wilt exporteren"])},
  "page_users_modal_export_users_field_user_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikersidentificatie"])},
  "page_users_modal_export_users_field_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "page_users_modal_export_users_field_first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
  "page_users_modal_export_users_field_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
  "page_users_modal_export_users_field_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
  "page_users_modal_export_users_field_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal"])},
  "page_users_modal_export_users_field_applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicaties"])},
  "page_users_modal_export_users_field_electronic_id_enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektronische ID ingeschakeld"])},
  "page_users_modal_export_users_field_2step_enabled_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tweestapsverificatie ingeschakeld op"])},
  "page_users_modal_export_users_field_applications_selected_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerking: U exporteert applicaties voor een gebruiker. Door deze export wordt er één regel per gebruiker/applicatie aangemaakt. Als die gebruiker over meer dan 1 applicatie beschikt, dan wordt dezelfde gebruiker op meerdere rijen geretourneerd."])},
  "page_users_clear_selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectie wissen"])},
  "page_users_select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles selecteren"])},
  "page_users_add_to_selection_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles aan selectie toevoegen"])},
  "page_users_select_all_from_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles van pagina selecteren"])},
  "page_users_add_to_selection_all_from_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles van pagina aan selectie toevoegen"])},
  "page_users_select_all_from_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles van filter selecteren"])},
  "page_users_add_to_selection_all_from_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles van filter aan selectie toevoegen"])},
  "page_users_selected_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Geselecteerd (", _interpolate(_named("usersCount")), ")"])},
  "page_users_select_usercount_from_filter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("usersCount")), " gebruikers uit het filter selecteren"])},
  "page_users_select_usercount_from_page": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("usersCount")), " gebruikers van de pagina selecteren"])},
  "page_select_all_usercount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Alle ", _interpolate(_named("usersCount")), " gebruikers selecteren"])},
  "page_users_modal_export_users_export_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporteren"])},
  "page_users_modal_export_users_column_name_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolomnaam"])},
  "page_users_1_selected_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 domein"])},
  "page_users_selected_domains_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("domainsCount")), " domeinen"])},
  "page_users_all_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle domeinen"])},
  "page_users_filter_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domeinen filteren"])},
  "page_users_clear_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter wissen"])},
  "page_users_add_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker toevoegen"])},
  "page_users_import_or_update_users_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers importeren"])},
  "page_users_modal_import_users_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers importeren of bijwerken"])},
  "page_users_modal_import_users_upload_file_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestand kiezen"])},
  "page_users_modal_import_users_import_users_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers importeren of bijwerken"])},
  "page_users_modal_import_users_select_csv_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csv-bestand selecteren"])},
  "page_users_modal_import_users_required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verplichte velden zijn user_id, email, first_name, last_name, country en language. Alle voorloop- en volgspaties worden verwijderd"])},
  "page_users_modal_import_users_specify_user_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef altijd een waarde op voor user_id als u gebruikersaccounts wilt bijwerken, anders wordt er een nieuwe gebruiker aangemaakt"])},
  "page_users_modal_import_users_add_edit_user_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikersgegevens in de csv-sjabloon toevoegen of bewerken"])},
  "page_users_modal_import_users_download_csv_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csv-sjabloon downloaden"])},
  "page_users_modal_import_users_download_csv_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csv-sjabloon downloaden"])},
  "page_users_modal_import_users_upload_csv_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csv-bestand uploaden"])},
  "page_users_modal_import_users_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U moet deze stappen uitvoeren om gebruikers te importeren of bij te werken"])},
  "page_users_modal_import_users_mandatory_column_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er ontbreken een of meer verplichte kolommen: "])},
  "page_users_modal_import_users_invalid_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een of meer kolommen zijn ongeldig:"])},
  "page_users_modal_import_users_invalid_number_of_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldig aantal kolommen op regel: "])},
  "page_users_modal_import_users_invalid_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koptekst ontbreekt of is ongeldig"])},
  "page_users_modal_import_users_invalid_csv_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inhoud van csv-bestand is ongeldig"])},
  "page_users_modal_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
  "page_users_modal_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal"])},
  "page_users_modal_all_countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle landen"])},
  "page_users_modal_all_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle talen"])},
  "page_users_modal_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
  "page_users_modal_add_user_invalid_first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldige voornaam"])},
  "page_users_modal_add_user_invalid_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldige achternaam"])},
  "page_users_modal_add_user_invalid_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldige taal"])},
  "page_users_modal_add_user_invalid_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldig land"])},
  "page_users_modal_add_user_invalid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldig e-mailadres"])},
  "page_users_modal_add_user_email_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres bestaat al"])},
  "page_users_modal_add_user_success_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker is toegevoegd"])},
  "page_users_modal_add_user_success_otp_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De gebruiker is aangemaakt en heeft het bovenstaande eenmalige wachtwoord."])},
  "page_users_modal_add_user_success_email_link_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een e-mail met inloginstructies naar het e-mailadres van de gebruiker verzonden."])},
  "page_users_modal_add_user_auto_generate_otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisch eenmalig wachtwoord genereren"])},
  "page_users_modal_add_user_send_email_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail voor het activeren van de account naar de gebruiker verzenden"])},
  "page_users_modal_add_user_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een onbekende fout opgetreden bij het toevoegen van een gebruiker."])},
  "page_users_modal_select_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domein selecteren"])},
  "page_user_details_back_to_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar gebruikers"])},
  "page_user_details_tab_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiel"])},
  "page_user_details_tab_security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beveiliging"])},
  "page_user_details_tab_devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apparaten"])},
  "page_user_details_tab_audit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlelogboeken"])},
  "page_user_details_tab_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepen"])},
  "page_user_details_tab_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessies"])},
  "page_user_details_tab_email_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailhistorie"])},
  "page_user_details_tab_passwordless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoordloos"])},
  "page_user_details_tab_linked_eids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gekoppelde eID's"])},
  "page_user_details_tab_applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicaties"])},
  "page_user_details_recovery_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadressen voor herstel"])},
  "page_user_details_phone_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefoonnummers"])},
  "page_user_details_edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiel bewerken"])},
  "page_user_details_update_user_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een onbekende fout opgetreden tijdens het bijwerken van de gebruiker"])},
  "page_user_details_application_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam applicatie"])},
  "page_user_details_authorization_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goedgekeurd op"])},
  "page_user_details_login_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inlogdatum"])},
  "page_user_details_login_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal keren ingelogd"])},
  "page_user_details_no_applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen applicaties"])},
  "page_user_details_showing_applications": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " van de ", _interpolate(_named("totalCount")), " apps worden getoond"])},
  "page_user_details_showing_application": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " van 1 app wordt getoond"])},
  "page_user_details_show_supporting_applications": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " ondersteunende apps tonen"])},
  "page_user_details_hide_supporting_applications": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " ondersteunende apps verbergen"])},
  "page_user_details_show_supporting_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 ondersteunende app tonen"])},
  "page_user_details_hide_supporting_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 ondersteunende app verbergen"])},
  "page_user_details_update_user_profile_image_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het bestand met de profielafbeelding wordt niet geaccepteerd, want het is ongeldig of beschadigd"])},
  "page_user_details_update_user_profile_edit_profile_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profielafbeelding bewerken"])},
  "page_user_details_update_user_profile_choose_profile_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foto kiezen ..."])},
  "page_user_details_update_user_profile_image_size_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De afmetingen van het bestand met de profielafbeelding zijn te klein. De minimumafmetingen zijn: 200px breed en 200px hoog."])},
  "page_user_details_reset_password_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord herstellen"])},
  "page_user_details_reset_password_modal_body_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u een e-mail voor het herstellen van het wachtwoord naar de gebruiker wilt verzenden?"])},
  "page_user_details_reset_password_modal_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een link voor het herstellen van het wachtwoord verzonden naar het hoofdmailadres en het herstelmailadres van de gebruiker. Het wachtwoord wordt niet automatisch hersteld. De link voor het herstellen van het wachtwoord verloopt 15 minuten nadat deze is verzonden."])},
  "page_user_details_one_time_password_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eenmalig wachtwoord"])},
  "page_user_details_one_time_password_modal_body_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het eenmalige wachtwoord is:"])},
  "page_user_details_one_time_password_modal_body_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u een eenmalig wachtwoord voor de gebruiker wilt aanmaken?"])},
  "page_user_details_one_time_password_modal_body_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De gebruiker moet een nieuw wachtwoord instellen zodra hij of zij zich aanmeldt."])},
  "page_users_modal_generate_emergency_code_totp_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De gebruiker heeft TOTP (eenmalig wachtwoord met tijdslimiet) niet ingeschakeld."])},
  "page_users_modal_generate_emergency_code_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backupcode"])},
  "page_user_details_generate_emergency_code_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u een backupcode voor de gebruiker wilt aanmaken?"])},
  "page_users_modal_generate_emergency_code_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De backupcode is:"])},
  "page_users_modal_generate_emergency_code_how_to_generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies de manier waarop deze moet worden aangemaakt"])},
  "page_users_modal_generate_emergency_code_to_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een nieuwe backupcode aan die kan worden gekopieerd"])},
  "page_users_modal_generate_emergency_code_by_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzend de backupcode naar de inbox van de gebruiker"])},
  "page_users_modal_generate_emergency_code_by_email_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een e-mail met een backupcode naar de inbox van de gebruiker verzonden."])},
  "page_users_modal_generate_emergency_code_generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmaken"])},
  "page_users_modal_generate_emergency_code_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzenden"])},
  "page_user_details_more_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer acties"])},
  "page_user_details_send_reset_password_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail voor herstellen van wachtwoord verzenden"])},
  "page_user_details_generate_otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eenmalig wachtwoord aanmaken"])},
  "page_user_details_generate_one_time_emergency_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backupcode aanmaken"])},
  "page_user_details_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
  "page_user_details_last_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatst gebruikt"])},
  "page_user_details_never_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nooit gebruikt"])},
  "page_user_details_last_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatst gewijzigd"])},
  "page_user_details_password_not_set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord niet ingesteld"])},
  "page_user_reset_password_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste verzoek om wachtwoord te herstellen"])},
  "page_user_not_requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet aangevraagd"])},
  "page_user_password_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord geblokkeerd"])},
  "page_user_not_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet geblokkeerd"])},
  "page_user_2_fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tweestapsverificatie"])},
  "page_user_enforced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afgedwongen"])},
  "page_users_enabled_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingeschakeld"])},
  "page_users_not_enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet ingeschakeld"])},
  "page_users_disabled_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitgeschakeld"])},
  "page_users_last_generated_emergency_code_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatst aangemaakte backupcode"])},
  "page_users_visma_connect_idp_disabled_notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen met Visma is uitgeschakeld."])},
  "page_users_visma_connect_idp_disabled_adjust_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas het beleid aan als u dit wilt inschakelen."])},
  "page_user_details_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingelogd"])},
  "page_user_details_last_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste keer ingelogd"])},
  "page_user_details_never_logged_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nooit ingelogd"])},
  "page_user_details_passkeys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beveiligingssleutels"])},
  "page_user_details_passkeys_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal beveiligingssleutels"])},
  "page_user_details_no_passkeys_registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen beveiligingssleutels geregistreerd"])},
  "page_users_modal_unlock_password_account_not_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het wachtwoord van de gebruikersaccount is niet geblokkeerd."])},
  "page_users_modal_unlock_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord deblokkeren"])},
  "page_user_details_unlock_password_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord deblokkeren"])},
  "page_user_details_unlock_password_modal_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het wachtwoord is gedeblokkeerd."])},
  "page_users_details_auto_unlocked_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Automatisch gedeblokkeerd over ", _interpolate(_named("count")), " minuten"])},
  "page_users_details_auto_unlocked_1minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisch gedeblokkeerd over 1 minuut"])},
  "page_user_details_unlock_password_modal_body_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u het wachtwoord voor de gebruiker wilt deblokkeren?"])},
  "page_user_details_suspend_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account tijdelijk uitschakelen"])},
  "page_user_details_resume_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account weer inschakelen"])},
  "page_user_details_suspend_user_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account tijdelijk uitschakelen"])},
  "page_user_details_suspend_user_modal_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reden voor tijdelijke uitschakeling:"])},
  "page_user_details_suspend_user_modal_body_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u de account tijdelijk wilt uitschakelen?"])},
  "page_users_modal_suspend_user_account_already_suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De gebruikersaccount is al tijdelijk uitgeschakeld."])},
  "page_users_modal_suspend_user_account_not_suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De gebruikersaccount is niet tijdelijk uitgeschakeld."])},
  "page_users_modal_suspend_user_account_invalid_suspend_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldige reden voor tijdelijke uitschakeling"])},
  "page_user_details_account_suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account is tijdelijk uitgeschakeld"])},
  "page_user_details_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reden"])},
  "page_user_details_resume_user_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account weer inschakelen"])},
  "page_user_details_resume_user_modal_body_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u de account weer wilt inschakelen?"])},
  "page_user_details_ip_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-adres"])},
  "page_user_details_device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apparaat"])},
  "page_user_details_browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browser"])},
  "page_user_details_not_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen sessies"])},
  "page_user_details_session_logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitloggen"])},
  "page_user_details_session_logout_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitloggen bij sessie"])},
  "page_user_details_session_logout_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u de gebruiker bij de geselecteerde sessie wilt uitloggen?"])},
  "page_users_modal_delete_session_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker kon niet bij geselecteerde sessie worden uitgelogd"])},
  "page_user_details_current_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huidige sessie"])},
  "page_user_details_view_applications": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bekijk alle ", _interpolate(_named("applicationsCount")), " apps"])},
  "page_user_details_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebeurtenis"])},
  "page_user_details_timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdstip"])},
  "page_user_details_payload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berichtinhoud"])},
  "page_users_no_audit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen controle"])},
  "page_users_audit_log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["controlelogboek"])},
  "page_users_audit_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["controlelogboeken"])},
  "page_users_selected_auditcount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Geselecteerd (", _interpolate(_named("auditCount")), ")"])},
  "page_users_select_auditcount_from_page": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("auditCount")), " controlelogboeken van pagina selecteren"])},
  "page_select_all_auditcount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Alle ", _interpolate(_named("auditCount")), " controlelogboeken selecteren"])},
  "page_users_audit_export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporteren"])},
  "page_users_audit_event_login_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen niet gelukt"])},
  "page_users_audit_event_login_succeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggen gelukt"])},
  "page_users_audit_event_client_application_authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienst goedgekeurd"])},
  "page_users_audit_event_logout_succeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U bent uitgelogd"])},
  "page_users_1_selected_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 gebeurtenis"])},
  "page_users_selected_events_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " gebeurtenissen"])},
  "page_users_all_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle gebeurtenissen"])},
  "page_users_filter_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebeurtenissen filteren"])},
  "page_users_modal_export_audit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlelogboeken exporteren"])},
  "page_users_modal_export_audit_body_1_audit_log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er wordt 1 controlelogboek geëxporteerd"])},
  "page_users_modal_export_users_body_n_audit_logs": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Er worden ", _interpolate(_named("count")), " controlelogboeken geëxporteerd"])},
  "page_users_modal_export_user_audit_logs_field_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controle-ID"])},
  "page_users_modal_export_user_audit_logs_field_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebeurtenis"])},
  "page_users_modal_export_user_audit_logs_field_timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdstip"])},
  "page_users_modal_export_user_audit_logs_field_ipv4_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-adres"])},
  "page_users_modal_export_user_audit_logs_field_application_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicatie-ID"])},
  "page_users_modal_export_user_audit_logs_field_application_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicatienaam"])},
  "page_users_modal_export_user_audit_logs_field_payload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berichtinhoud"])},
  "page_users_modal_export_audit_logs_start_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlelogboeken exporteren"])},
  "page_users_audit_search_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een onbekende fout opgetreden tijdens het zoeken naar controlelogboeken"])},
  "page_users_modal_export_audit_logs_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een onbekende fout opgetreden tijdens het exporteren van controlelogboeken"])},
  "page_user_details_add_recovery_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herstelmailadres toevoegen"])},
  "page_user_details_add_another_recovery_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nog een e-mailadres voor accountherstel toevoegen"])},
  "page_user_details_update_user_invalid_recovery_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldig herstelmailadres"])},
  "page_user_details_update_user_recovery_email_already_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herstelmailadres is gedupliceerd"])},
  "page_user_details_update_user_recovery_email_disposable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In het herstelmailadres wordt een wegwerpdomein gebruikt"])}
}