import SurfConextOutputModel from '@/api/surfconext-api-client/models/SurfConextOutputModel';
import { SurfConextStoreState } from './state';

export default {
    setSurfConextIdentityProvider(state: SurfConextStoreState, model: SurfConextOutputModel): void {
        state.surfConextIdentityProvider = model;
    },

    setProcessingState(state: SurfConextStoreState, isProcessing: boolean): void {
        state.isProcessing = isProcessing;
    },

    setUnauthorizedToViewSurfConext(state: SurfConextStoreState): void {
        state.isUnauthorizedToViewSurfConext = true;
    },
};
