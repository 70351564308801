import httpClient from '@/http-client';
import { StatusCodes } from 'http-status-codes';
import ApiClientError from '../error';
import DomainChangeInputModel from './models/DomainChangeInputModel';
import ApiRequestPayload from '@/http-client/models/ApiRequestPayload';
import AggregatedJobModel from './models/AggregatedJobModel';
import UsersExportInputModel from './models/UserExportInputModel';
import ExportFieldModel from './models/ExportFieldModel';
import AuditExportInputModel from './models/AuditExportInputModel';

const routeRoot = 'v1.0/jobs';

export default {
    async getJobsListAsync(): Promise<AggregatedJobModel[]> {
        const requestUri = routeRoot;
        const response = await httpClient.getAsync(requestUri);

        if (response.statusCode === StatusCodes.OK) {
            const jobsModel = response.dataCollection(AggregatedJobModel);

            if (!jobsModel) {
                return [];
            }

            return jobsModel;
        }

        if (response.statusCode === StatusCodes.NO_CONTENT) {
            return [];
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async getJobAsync(jobId: string): Promise<AggregatedJobModel> {
        const requestUri = `${routeRoot}/${jobId}`;
        const response = await httpClient.getAsync(requestUri);

        if (response.statusCode === StatusCodes.OK) {
            const job = response.data(AggregatedJobModel);

            return job!;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async createDomainChangeJobAsync(model: DomainChangeInputModel): Promise<void> {
        const requestUri = `${routeRoot}/bulkdomainchange`;
        const response = await httpClient.postAsync<DomainChangeInputModel>(requestUri, new ApiRequestPayload(model, DomainChangeInputModel));

        if (response.statusCode === StatusCodes.CREATED) {
            return;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async createUsersExportJobAsync(model: UsersExportInputModel): Promise<void> {
        const requestUri = `${routeRoot}/userexport`;
        const response = await httpClient.postAsync<UsersExportInputModel>(requestUri, new ApiRequestPayload(model, UsersExportInputModel));

        if (response.statusCode === StatusCodes.CREATED) {
            return;
        }
        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async createUsersImportJobAsync(file: File): Promise<void> {
        const requestUri = `${routeRoot}/userimportorupdate`;
        const response = await httpClient.uploadFileAsync(requestUri, file);

        if (response.statusCode === StatusCodes.CREATED) {
            return;
        }

        throw new ApiClientError(response.statusCode, response.errorCode, response.errorDetails);
    },

    async createUserAuditLogsExportJobAsync(model: AuditExportInputModel): Promise<void> {
        const requestUri = `${routeRoot}/userauditlogsexport`;
        const response = await httpClient.postAsync<AuditExportInputModel>(requestUri, new ApiRequestPayload(model, AuditExportInputModel));

        if (response.statusCode === StatusCodes.CREATED) {
            return;
        }
        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async getUserExportFieldsAsync(): Promise<ExportFieldModel[]> {
        const requestUri = `${routeRoot}/userexport/fields`;
        const response = await httpClient.getAsync(requestUri);

        if (response.statusCode === StatusCodes.OK) {
            return response.dataCollection(ExportFieldModel);
        }
        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async getUserAuditLogsExportFieldsAsync(): Promise<ExportFieldModel[]> {
        const requestUri = `${routeRoot}/auditexport/fields`;
        const response = await httpClient.getAsync(requestUri);

        if (response.statusCode === StatusCodes.OK) {
            return response.dataCollection(ExportFieldModel);
        }
        throw new ApiClientError(response.statusCode, response.errorCode);
    },
};
