export default {
  "page_domains_modal_add_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radās kļūda, pievienojot šo domēnu."])},
  "page_domains_modal_add_error_already_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šis domēns jau ir pievienots."])},
  "page_domains_modal_add_error_invalid_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domēns nav pareizā formātā."])},
  "page_domains_modal_add_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["example.com"])},
  "page_domains_modal_add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kādu domēnu vēlaties pievienot?"])},
  "page_domains_modal_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izdzēst domēnu"])},
  "page_domains_modal_delete_info_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai tiešām vēlaties dzēst šo domēnu"])},
  "page_domains_modal_delete_info_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pēc domēna dzēšanas tas būs atkārtoti jāpievieno un jāapstiprina, lai pieprasītu īpašumtiesības. Lietotāji netiks dzēsti un tiks nodrošināti sistēmas standarta autentifikācijas uzstādījumi."])},
  "page_domains_modal_delete_btn_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dzēst"])},
  "page_domains_modal_delete_error_unkown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radās kļūda, dzēšot domēnu."])},
  "page_domains_modal_verify_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprināt ar"])},
  "page_domains_modal_verify_instructions_dns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pieslēdzieties savam DNS apkalpotājam un izveidojiet DNS TXT ierakstu, lai palīdzētu apstiprināt domēnu"])},
  "page_domains_modal_verify_instructions_dns_step_1_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nokopējiet visu informāciju no tabulas zemāk un pievienojiet savai"])},
  "page_domains_modal_verify_instructions_dns_step_1_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DNS īpašnieks."])},
  "page_domains_modal_verify_instructions_dns_step_1_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veids"])},
  "page_domains_modal_verify_instructions_dns_step_1_txt_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TXT nosaukums"])},
  "page_domains_modal_verify_instructions_dns_step_1_txt_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DNS teksta ieraksta vērtība"])},
  "page_domains_modal_verify_instructions_dns_step_1_txt_name_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vai izlaidiet, ja tas nav pakalpojuma nodrošinātāja atbalstīts"])},
  "page_domains_modal_verify_instructions_dns_step_2_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atgriezieties šajā lapā un spiediet"])},
  "page_domains_modal_verify_instructions_dns_step_2_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepieciešamas 5 minūtes (TTL is uzstādīts uz 300s), lai DNS izmaiņas stātos spēkā."])},
  "page_domains_modal_verify_instructions_https_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pirms varam apstiprināt"])},
  "page_domains_modal_verify_instructions_https_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nepieciešams pabeigt šos apstiprināšanas soļus."])},
  "page_domains_modal_verify_instructions_https_step_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apstiprināšanas fails zemāk."])},
  "page_domains_modal_verify_instructions_https_step_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fails uz galveno mapi domēna lapā."])},
  "page_domains_modal_verify_instructions_https_step_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atgriezieties šajā lapā un spiediet"])},
  "page_domains_modal_verify_error_domain_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domēns, kuru vēlaties apstiprināt tika dzēsts."])},
  "page_domains_modal_verify_error_domain_invalid_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domēns netika apstiprināts. Lūdzu pārbaudiet sava domēna konfigurāciju, atbilstoši izvēlētajai apstiprināšanas metodei."])},
  "page_domains_modal_verify_error_domain_already_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domēns, kuru vēlaties apstiprināt jau ir apstiprināts."])},
  "page_domains_notif_common_the_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domēns"])},
  "page_domains_notif_verified_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["veiksmīgi apstiprināts."])},
  "page_domains_notif_deleted_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ir veiksmīgi dzēsts."])},
  "page_domains_table_header_domain_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domēna nosaukums"])},
  "page_domains_table_header_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statuss"])},
  "page_domains_table_no_domains_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neviens domēns nav pievienots."])},
  "page_domains_btn_add_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pievienot domēnu"])},
  "page_domains_modal_delete_error_sso_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domēnu nav iespējams izdzēst. Lai to izdarītu, lūdzu, noņemiet vienreizējās pierakstīšanās (Single Sign-On) iestatījumu!"])},
  "page_domains_modal_delete_error_group_member_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nevar izdzēst domēnu. Ir grupas, kurām joprojām ir piešķirti dalībnieki."])},
  "page_domains_modal_delete_cannot_delete_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nevar izdzēst domēnu: "])},
  "page_domains_modal_delete_cannot_delete_domain_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lai dzēstu domēnu, ir jānoņem vienreizējās pierakstīšanās (Single Sign On) iestatījums vai jāpievieno jauns verificēts domēns."])},
  "page_domains_modal_delete_cannot_delete_domain_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nevar izdzēst domēnu: ", _interpolate(_named("domainDisplayName"))])}
}