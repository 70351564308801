import { CountryStoreState } from './state';
import CountryModel from '@/api/country-api-client/models/CountryModel';

export default {
    isProcessing(state: CountryStoreState): boolean {
        return state.isProcessing;
    },

    countries(state: CountryStoreState): CountryModel[] {
        return state.countries;
    },

    userCountry(state: CountryStoreState): CountryModel | undefined {
        return state.userCountry;
    },
};
