export default {
  "page_groups_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmän nimi"])},
  "page_groups_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jäsen"])},
  "page_groups_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jäsenet"])},
  "page_groups_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuvaus"])},
  "page_groups_table_no_groups_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmiä ei lisätty"])},
  "page_groups_table_no_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei ryhmiä"])},
  "page_groups_table_no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei hakuehtoja vastaavia ryhmiä"])},
  "page_groups_btn_add_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää ryhmä"])},
  "page_groups_btn_save_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tallenna ryhmä"])},
  "page_groups_btn_edit_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa ryhmää"])},
  "page_groups_users_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sähköpostiosoite"])},
  "page_groups_users_fist_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etunimi"])},
  "page_groups_users_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukunimi"])},
  "page_groups_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luotu (UTC)"])},
  "page_groups_membership_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jäsenyys päivitetty (UTC)"])},
  "page_groups_delete_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista ryhmä"])},
  "page_groups_delete_group_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko varmasti poistaa ryhmän?"])},
  "page_groups_remove_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista jäsen"])},
  "page_groups_remove_member_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko varmasti poistaa jäsenen"])},
  "page_groups_remove_member_group_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Haluatko varmasti poistaa jäsenen ", _interpolate(_named("member")), " ryhmästä ", _interpolate(_named("group")), "?"])},
  "page_groups_no_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjiä ei lisätty"])},
  "page_groups_no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei hakuehtoja vastaavia käyttäjiä"])},
  "page_groups_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< Takaisin ryhmiin"])},
  "page_groups_add_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää jäsen ryhmään"])},
  "page_groups_add_users_all_are_already_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaikki käyttäjät on jo lisätty tähän ryhmään"])},
  "page_groups_add_users_all_no_users_matching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei hakuehtoja vastaavia käyttäjiä"])},
  "page_groups_member_since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jäsen alkaen"])},
  "page_groups_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista"])},
  "page_groups_group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmän jäsenet:"])},
  "page_groups_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmän tunnus:"])},
  "page_groups_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lähde"])},
  "page_groups_err_group_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuntematon virhe verkkotunnuksen lisäyksessä ryhmään."])},
  "page_groups_err_group_invalid_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmän nimi on virheellinen. Suurin sallittu pituus on 255 merkkiä"])},
  "page_groups_err_group_invalid_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmän kuvaus on virheellinen. Suurin sallittu pituus on 1024 merkkiä"])},
  "page_groups_err_group_already_exits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmä, jota yritit lisätä, on jo olemassa."])},
  "page_groups_err_group_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuntematon virhe verkkotunnuksen päivityksessä ryhmään."])},
  "page_groups_search_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etsi ryhmän nimi"])},
  "page_groups_search_group_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae sähköpostiosoitteella"])},
  "page_groups_search_group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae jäseniä"])},
  "page_groups_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ryhmää"])},
  "page_groups_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ryhmä"])},
  "page_groups_filter_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suodata jäseniä"])},
  "page_groups_member_filter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jäsen: ", _interpolate(_named("name"))])}
}