import AggregatedJobModel from '@/api/job-api-client/models/AggregatedJobModel';
import ExportFieldModel from '@/api/job-api-client/models/ExportFieldModel';
import { JobStoreState } from './state';
import { DEFAULT_USER_AUDIT_LOGS_EXPORT_FIELD_NAMES } from '@/api/job-api-client/constants';

export default {
    setJobs(state: JobStoreState, model: AggregatedJobModel[]): void {
        state.jobs = model;
    },

    setUnauthorizedToViewJobs(state: JobStoreState): void {
        state.isUnauthorizedToViewJobs = true;
    },

    setProcessingState(state: JobStoreState, isProcessing: boolean): void {
        state.isProcessing = isProcessing;
    },

    setDataLoaded(state: JobStoreState, isDataLoaded: boolean): void {
        state.isDataLoaded = isDataLoaded;
    },

    setUserExportFields(state: JobStoreState, model: ExportFieldModel[]): void {
        state.userExportFields = model;
    },

    setUserAuditLogsExportFields(state: JobStoreState, model: ExportFieldModel[]): void {
        model.forEach((field) => {
            field.isSelected = DEFAULT_USER_AUDIT_LOGS_EXPORT_FIELD_NAMES.indexOf(field.fieldName) >= 0;
        });
        state.auditExportFields = model;
    },

    resetUserAuditLogsExportFields(state: JobStoreState): void {
        if (state.auditExportFields) {
            state.auditExportFields.forEach((field) => {
                field.isSelected = DEFAULT_USER_AUDIT_LOGS_EXPORT_FIELD_NAMES.indexOf(field.fieldName) >= 0;
            });
        }
    },
};
