import httpClient from '@/http-client';
import ApiRequestPayload from '@/http-client/models/ApiRequestPayload';
import { StatusCodes } from 'http-status-codes';
import ApiClientError from '../error';
import OidcIdentityProviderOutputModel from './models/OidcIdentityProviderOutputModel';
import OidcIdentityProviderInputModel from './models/OidcIdentityProviderInputModel';
import OidcIdentityProviderClientSecretInputModel from './models/OidcIdentityProviderClientSecretInputModel';

const routeRoot = '/v1.0/sso/oidc-idp';

// public functions
export default {
    async getAsync(): Promise<OidcIdentityProviderOutputModel | undefined> {
        const response = await httpClient.getAsync(routeRoot);

        if (response.statusCode === StatusCodes.OK) {
            return response.data(OidcIdentityProviderOutputModel);
        }

        if (response.statusCode === StatusCodes.NO_CONTENT) {
            return void 0;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async addAsync(oidcIdp: OidcIdentityProviderInputModel): Promise<OidcIdentityProviderOutputModel> {
        const response = await httpClient.postAsync(`${routeRoot}`, new ApiRequestPayload(oidcIdp, OidcIdentityProviderInputModel));

        if (response.statusCode === StatusCodes.CREATED) {
            const createdOidcIdp = response.data(OidcIdentityProviderOutputModel);

            if (!createdOidcIdp) {
                throw new ApiClientError(response.statusCode);
            }

            return createdOidcIdp;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async updateAsync(oidcIdp: OidcIdentityProviderInputModel): Promise<OidcIdentityProviderOutputModel> {
        const response = await httpClient.putAsync(`${routeRoot}`, new ApiRequestPayload(oidcIdp, OidcIdentityProviderInputModel));

        if (response.statusCode === StatusCodes.OK) {
            const updatedOidcIdp = response.data(OidcIdentityProviderOutputModel);

            if (!updatedOidcIdp) {
                throw new ApiClientError(response.statusCode);
            }

            return updatedOidcIdp;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async updateClientSecretAsync(clientSecret: string): Promise<void> {
        const model = new OidcIdentityProviderClientSecretInputModel();
        model.clientSecret = clientSecret;

        const response = await httpClient.patchAsync(`${routeRoot}/clientSecret`, new ApiRequestPayload(model, OidcIdentityProviderClientSecretInputModel));

        if (response.statusCode === StatusCodes.NO_CONTENT) {
            return;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async removeClientSecretAsync(): Promise<void> {
        const response = await httpClient.deleteAsync(`${routeRoot}/clientSecret`);

        if (response.statusCode === StatusCodes.NO_CONTENT) {
            return;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async deleteAsync(): Promise<void> {
        const response = await httpClient.deleteAsync(`${routeRoot}`);

        if (response.statusCode === StatusCodes.NO_CONTENT) {
            return;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },
};
