export default {
  "page_policies_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
  "page_policies_default_policy_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denna policy gäller för alla användare"])},
  "page_policies_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera"])},
  "page_policies_reset_to_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återställ till standard"])},
  "page_policies_2_step_verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvåstegsverifiering"])},
  "page_policies_2_step_verification_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kräv tvåstegsverifiering vid inloggning"])},
  "page_policies_2_step_verification_on_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["På"])},
  "page_policies_2_step_verification_off_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Av"])},
  "page_policies_identity_providers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identitetsleverantörer"])},
  "page_policies_networks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nätverk"])},
  "page_policies_ip_ranges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-intervall"])},
  "page_policies_ips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP:er"])},
  "page_policies_subnet_masks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nätmasker"])},
  "page_policies_first_ip_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Första IP i intervallet är ogiltig"])},
  "page_policies_second_ip_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andra IP i intervallet är ogiltig"])},
  "page_policies_ip_range_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-intervallet är ogiltigt"])},
  "page_policies_ip_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP är ogiltig"])},
  "page_policies_subnet_mask_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nätmasken är ogiltig"])},
  "page_policies_add_ip_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till IP-intervall"])},
  "page_policies_add_ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till IP"])},
  "page_policies_add_subnet_mask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till nätmask"])},
  "page_policies_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< Tillbaka till Policyer"])},
  "page_policies_notif_update_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ett okänt fel uppstod när policyn skulle uppdateras."])},
  "page_policies_ip_range_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-intervallet är duplicerat"])},
  "page_policies_ip_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP är duplicerad"])},
  "page_policies_subnet_mask_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nätmasken är duplicerad"])},
  "page_policies_network_your_ip_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din IP-adress är"])},
  "page_policies_network_ips_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange nätverk som använder IP-intervall, IP-adresser eller CIDR:er. Dessa måste vara offentliga IP-adresser och inte lokala eller privata IP-adresser."])},
  "page_policies_network_auth_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentiseringen kommer att godkännas om det finns en matchning med någon av de tillåtna IP-adresserna. Om nätverken inte inkluderar din IP-adress blir du utelåst."])},
  "page_policies_network_all_networks_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om inget har konfigurerats kommer alla nätverk att tillåtas."])},
  "page_policies_countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Länder"])},
  "page_policies_countries_no_suggestions_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga förslag hittades"])},
  "page_policies_countries_your_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landet för din IP-adress är"])},
  "page_policies_countries_auth_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentiseringen kommer att godkännas om det finns en matchning med något av de tillåtna länderna. Om länderna inte inkluderar landet för din IP-adress blir du utelåst."])},
  "page_policies_countries_select_countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj länder"])},
  "page_policies_countries_all_countries_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om inget har valts kommer alla länder att tillåtas."])},
  "page_policies_all_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla tillåts"])},
  "page_policies_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenord"])},
  "page_policies_password_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenordsinnehåll"])},
  "page_policies_password_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenord låst"])},
  "page_policies_password_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenordets giltighetstid"])},
  "page_policies_password_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenordsändring"])},
  "page_policies_password_minimum_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenordets minsta längd"])},
  "page_policies_password_minimum_required_digit_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minsta antal siffror (0-9) som krävs"])},
  "page_policies_password_minimum_required_uppercase_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minsta antal stora bokstäver som krävs"])},
  "page_policies_password_minimum_required_lowercase_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minsta antal små bokstäver som krävs"])},
  "page_policies_password_minimum_required_special_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minsta antal specialtecken som krävs"])},
  "page_policies_password_accounts_locked_login_failures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konton kan låsas ute på grund av inloggningsfel"])},
  "page_policies_password_locked_account_after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lås konto efter"])},
  "page_policies_password_login_failures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inloggningsfel"])},
  "page_policies_password_reset_failure_count_after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återställ felantalet efter"])},
  "page_policies_password_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minut(er)"])},
  "page_policies_password_lockout_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varaktighet för utelåsning"])},
  "page_policies_password_never_expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenord upphör aldrig att gälla"])},
  "page_policies_password_expires_after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenord upphör att gälla efter"])},
  "page_policies_password_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dag(ar)"])},
  "page_policies_password_send_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa varning efter inloggning"])},
  "page_policies_password_minutes_before_password_expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minut(er) innan lösenordet går ut"])},
  "page_policies_password_keep_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behåll lösenordshistorik"])},
  "page_policies_password_remember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kom ihåg"])},
  "page_policies_password_passwords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lösenord"])},
  "page_policies_password_invalid_value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Värdet måste vara mellan ", _interpolate(_named("value1")), " och ", _interpolate(_named("value2"))])},
  "page_policies_password_pwd_expire_warning_minutes_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["och mindre än lösenordets utgångsdatum"])},
  "page_policies_password_save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara ändringar"])},
  "page_policies_password_update_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Är du säker på att du vill uppdatera lösenordspolicyn? Användarna kommer att påverkas, och om deras nuvarande lösenord inte överensstämmer med de nya inställningarna kommer de att tvingas ändra det."])},
  "page_policies_password_reset_to_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återställ till Visma-standard"])},
  "page_policies_password_reset_to_default_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Är du säker på att du vill återställa lösenordspolicyn till Visma-standard?"])},
  "page_policies_password_minimmum_length": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Minsta längd: ", _interpolate(_named("value"))])},
  "page_policies_password_require_digit_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kräv 1 siffra"])},
  "page_policies_password_require_digit_characters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kräv ", _interpolate(_named("value")), " siffror"])},
  "page_policies_password_require_uppercase_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kräv 1 stor bokstav"])},
  "page_policies_password_require_uppercase_characters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kräv ", _interpolate(_named("value")), " stora bokstäver"])},
  "page_policies_password_require_lowercase_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kräv 1 liten bokstav"])},
  "page_policies_password_require_lowercase_characters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kräv ", _interpolate(_named("value")), " små bokstäver"])},
  "page_policies_password_require_special_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kräv 1 specialtecken"])},
  "page_policies_password_require_special_characters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kräv ", _interpolate(_named("value")), " specialtecken"])},
  "page_policies_password_accounts_locked_after_one_login_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konton låses efter 1 inloggningsfel"])},
  "page_policies_password_accounts_locked_after_login_failures": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Konton låses efter ", _interpolate(_named("value")), " inloggningsfel"])},
  "page_policies_password_expires_after_one_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenord upphör att gälla efter 1 dag"])},
  "page_policies_password_expires_after_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lösenord upphör att gälla efter ", _interpolate(_named("value")), " dagar"])},
  "page_policies_password_keep_one_in_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara 1 lösenord i historiken"])},
  "page_policies_password_keep_in_history": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Spara ", _interpolate(_named("value")), " lösenord i historiken"])},
  "page_policies_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessioner"])},
  "page_policies_sessions_max_concurrent_sessions_per_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max antal samtidiga sessioner per användare"])},
  "page_policies_sessions_max_concurrent_sessions_per_users_value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Max antal samtidiga sessioner per användare: ", _interpolate(_named("value"))])},
  "page_policies_sessions_lock_to_origin_ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga ut användare efter byte av IP-adress"])},
  "page_policies_sessions_lock_to_origin_ip_value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Logga ut användare efter byte av IP-adress: ", _interpolate(_named("value"))])}
}