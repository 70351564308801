import IpDisplayModel from '@/components/authenticationpolicies/AuthenticationPolicy/AuthenticationPolicyAuthorizedNetworks/models/IpDisplayModel';
import IpRangeDisplayModel from '@/components/authenticationpolicies/AuthenticationPolicy/AuthenticationPolicyAuthorizedNetworks/models/IpRangeDisplayModel';
import SubnetMaskDisplayModel from '@/components/authenticationpolicies/AuthenticationPolicy/AuthenticationPolicyAuthorizedNetworks/models/SubnetMaskDisplayModel';
import StoreEntity from '@/store/models';

export default class AuthorizedNetworksStoreModel extends StoreEntity {
    public constructor() {
        super();

        this.ipRanges = [];
        this.ips = [];
        this.subnetMasks = [];
    }

    public ipRanges: IpRangeDisplayModel[];
    public ips: IpDisplayModel[];
    public subnetMasks: SubnetMaskDisplayModel[];
}
