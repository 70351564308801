import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('SurfConextInputModel')
export default class SurfConextInputModel {
    constructor() {
        this.homeRealm = '';
        this.jitProvisioningSso = false;
    }

    @JsonProperty('home_realm', String)
    public homeRealm: string;

    @JsonProperty('jit_provisioning_sso', Boolean)
    public jitProvisioningSso: boolean;
}
