import httpClient from '@/http-client';
import logger from '@/util/logger';
import Util from '@/util';
import routes from './routes';
import AuthenticationModel from './models/AuthenticationModel';
import ApiClientError from '../error';

export default {
    async getAuthenticationDetailsAsync(): Promise<AuthenticationModel> {
        const response = await httpClient.getAsync(routes.userDetails);

        if (!response.isSuccess) {
            logger.log(`Unsuccessful status code received in "getAuthenticationDetails": ${response.statusCode}`);
            throw new ApiClientError(response.statusCode);
        }

        const data = response.data(AuthenticationModel);

        if (Util.isNullOrEmpty(data)) {
            logger.log('Returned data in "getAuthenticationDetails" is null');
            throw new ApiClientError(response.statusCode);
        }

        return data;
    },
};
