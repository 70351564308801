import ApiClientError from '@/api/error';
import provisioningApiClient from '@/api/provisioning-api-client';
import ProvisioningActionModel from '@/api/provisioning-api-client/models/ProvisioningActionModel';
import ProvisioningConfigurationModel from '@/api/provisioning-api-client/models/ProvisioningConfigurationModel';
import { ActionContext } from 'vuex';
import { BasicStoreAccessors, RootState } from '../types';
import mutations from './mutations';
import { ProvisioningStoreState } from './state';

let storeApi: BasicStoreAccessors<ProvisioningStoreState>;

export function initActions(api: BasicStoreAccessors<ProvisioningStoreState>): void {
    storeApi = api;
}

export default {
    async getScimAsync(): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            const provisioningData = await provisioningApiClient.getScimAsync();

            if (provisioningData) {
                storeApi.commit(mutations.setProvisioningData, provisioningData);
            }
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewProvisioning);
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
        }
    },

    async enableScimAsync(context: ActionContext<ProvisioningStoreState, RootState>, defaultActions: string[]): Promise<void> {
        if (context.state.provisioningData) {
            return;
        }

        try {
            storeApi.commit(mutations.setProcessingState, true);

            const configuration = new ProvisioningConfigurationModel();
            configuration.actions = defaultActions.map((a) => {
                const model = new ProvisioningActionModel();
                model.name = a;
                return model;
            });

            const provisioningData = await provisioningApiClient.enableScimAsync(configuration);
            storeApi.commit(mutations.setProvisioningData, provisioningData);
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewProvisioning);
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
        }
    },

    async disableScimAsync(context: ActionContext<ProvisioningStoreState, RootState>): Promise<void> {
        if (!context.state.provisioningData) {
            return;
        }

        try {
            storeApi.commit(mutations.setProcessingState, true);

            await provisioningApiClient.disableScimAsync();

            storeApi.commit(mutations.disableProvisioning);
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewProvisioning);
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
        }
    },

    async updateScimAsync(context: ActionContext<ProvisioningStoreState, RootState>): Promise<void> {
        if (!context.state.provisioningData) {
            return;
        }

        try {
            storeApi.commit(mutations.setProcessingState, true);
            const provisioningData = await provisioningApiClient.updateScimAsync(context.state.provisioningData.configuration);
            storeApi.commit(mutations.setProvisioningData, provisioningData);
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewProvisioning);
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
        }
    },

    async generateScimTokenAsync(): Promise<string> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            const tokenData = await provisioningApiClient.generateScimTokenAync();

            storeApi.commit(mutations.setTokenData, tokenData);

            return tokenData.token;
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewProvisioning);
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
        }
    },

    async getIdentityProvidersScimConfigurationAsync(): Promise<void> {
        try {
            const identityProvidersScimConfig = await provisioningApiClient.getIdentityProviderScimConfigurationAsync();

            if (identityProvidersScimConfig) {
                storeApi.commit(mutations.setIdentityProvidersScimConfig, identityProvidersScimConfig);
                storeApi.commit(mutations.setIsIdentityProvidersScimConfigLoaded);
            }

            return void 0;
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewProvisioning);
            }

            throw err;
        }
    },
};
