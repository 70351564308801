import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('TenantClientSignInUriModel')
export default class TenantClientSignInUriModel {
    public constructor() {
        this.clientIdentity = '';
        this.clientName = '';
        this.signInUri = '';
    }

    @JsonProperty('application_id', String)
    public clientIdentity: string;

    @JsonProperty('application_name', String)
    public clientName: string;

    @JsonProperty('sign_in_uri', String)
    public signInUri: string;
}
