export default {
  "page_users_no_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga användare"])},
  "page_users_users_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användare"])},
  "page_users_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["användare"])},
  "page_users_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["användare"])},
  "page_users_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postadress"])},
  "page_users_recovery_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postadress för återställning"])},
  "page_users_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användarnamn"])},
  "page_users_first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förnamn"])},
  "page_users_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efternamn"])},
  "page_users_last_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senaste inloggning"])},
  "page_users_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapad"])},
  "page_users_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdaterad"])},
  "page_users_language_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])},
  "page_users_country_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
  "page_users_applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikationer"])},
  "page_users_electronic_id_enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-legitimation aktiverad"])},
  "page_users_2step_enabled_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvåstegsverifiering aktiverad"])},
  "page_users_search_email_or_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök e-post eller namn"])},
  "page_users_bulk_email_domain_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulkändring av e-postdomän"])},
  "page_users_user_export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användarexport"])},
  "page_users_modal_domain_change_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varning - användarkonton kommer att ändras"])},
  "page_users_modal_domain_change_body_n_users_n_domains": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du håller på att ändra ", _interpolate(_named("usersCount")), " användarkonton från ", _interpolate(_named("domainsCount")), " domäner"])},
  "page_users_modal_domain_change_body_1_user_1_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du håller på att ändra 1 användarkonto från 1 domän"])},
  "page_users_modal_domain_change_body_n_users_1_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du håller på att ändra ", _interpolate(_named("usersCount")), " användarkonton från 1 domän"])},
  "page_users_modal_domain_change_body_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se till att du informerar användarna om ändringarna"])},
  "page_users_modal_domain_change_source_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Källdomäner"])},
  "page_users_modal_domain_change_target_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Måldomän"])},
  "page_users_modal_domain_change_start_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Byt domän"])},
  "page_users_modal_domain_change_not_target_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det finns inga tillgängliga måldomäner. Avmarkera en av källdomänerna och försök igen"])},
  "page_users_modal_domain_change_approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jag är medveten om att denna åtgärd kommer att ändra valda användarkonton"])},
  "page_users_modal_domain_change_example_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exempel"])},
  "page_users_modal_domain_change_example_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["john.doe", "@", _interpolate(_named("sourceDomain")), " kommer att ändras till john.doe", "@", _interpolate(_named("targetDomain"))])},
  "page_users_modal_export_users_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportera användare"])},
  "page_users_modal_export_users_start_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportera användare"])},
  "page_users_modal_export_users_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domäner"])},
  "page_users_modal_export_users_body_1_user_1_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 användare från 1 domän kommer att exporteras"])},
  "page_users_modal_export_users_body_n_users_1_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("usersCount")), " användare från 1 domän kommer att exporteras"])},
  "page_users_modal_export_users_body_n_user_n_domains": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("usersCount")), " användare från ", _interpolate(_named("domainsCount")), " domäner kommer att exporteras"])},
  "page_users_modal_export_users_select_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj kolumnerna med data som du vill exportera"])},
  "page_users_modal_export_users_field_user_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användar-ID"])},
  "page_users_modal_export_users_field_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-post"])},
  "page_users_modal_export_users_field_first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förnamn"])},
  "page_users_modal_export_users_field_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efternamn"])},
  "page_users_modal_export_users_field_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
  "page_users_modal_export_users_field_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])},
  "page_users_modal_export_users_field_applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikationer"])},
  "page_users_modal_export_users_field_electronic_id_enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-legitimation aktiverad"])},
  "page_users_modal_export_users_field_2step_enabled_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum för aktivering av tvåstegsautentisering"])},
  "page_users_modal_export_users_field_applications_selected_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obs. att du håller på att exportera applikationer för en användare. Exporten kommer att skapa en rad per användare och applikation, så om en användare har fler än 1 applikation, kommer samma användare att returneras på flera rader."])},
  "page_users_clear_selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rensa urval"])},
  "page_users_select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj alla"])},
  "page_users_add_to_selection_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till alla i urvalet"])},
  "page_users_select_all_from_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj alla från sidan"])},
  "page_users_add_to_selection_all_from_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till allt från sidan till urvalet"])},
  "page_users_select_all_from_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj alla från filter"])},
  "page_users_add_to_selection_all_from_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till allt från filtret till urvalet"])},
  "page_users_selected_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["(", _interpolate(_named("usersCount")), ") vald(a)"])},
  "page_users_select_usercount_from_filter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Välj ", _interpolate(_named("usersCount")), " användare från filtret"])},
  "page_users_select_usercount_from_page": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Välj ", _interpolate(_named("usersCount")), " användare från sidan"])},
  "page_select_all_usercount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Välj alla ", _interpolate(_named("usersCount")), " användare"])},
  "page_users_modal_export_users_export_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
  "page_users_modal_export_users_column_name_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolumnnamn"])},
  "page_users_1_selected_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 domän"])},
  "page_users_selected_domains_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("domainsCount")), " domäner"])},
  "page_users_all_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla domäner"])},
  "page_users_filter_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrera domäner"])},
  "page_users_clear_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rensa filter"])},
  "page_users_add_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till användare"])},
  "page_users_import_or_update_users_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importera användare"])},
  "page_users_modal_import_users_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importera eller uppdatera användare"])},
  "page_users_modal_import_users_upload_file_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj fil"])},
  "page_users_modal_import_users_import_users_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importera eller uppdatera användare"])},
  "page_users_modal_import_users_select_csv_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj en csv-fil"])},
  "page_users_modal_import_users_required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användar-ID, e-post, förnamn, efternamn, land och språk är obligatoriska fält. Alla mellanslag före och efter kommer att tas bort."])},
  "page_users_modal_import_users_specify_user_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange alltid användar-ID:t när du vill uppdatera ett användarkonto, annars skapas en ny användare."])},
  "page_users_modal_import_users_add_edit_user_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till eller redigera användarinformation i csv-mallen"])},
  "page_users_modal_import_users_download_csv_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladda ner csv-mall"])},
  "page_users_modal_import_users_download_csv_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladda ner csv-mall"])},
  "page_users_modal_import_users_upload_csv_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladda upp csv-fil"])},
  "page_users_modal_import_users_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du måste slutföra dessa steg för att importera eller uppdatera användare"])},
  "page_users_modal_import_users_mandatory_column_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En eller flera obligatoriska kolumner saknas: "])},
  "page_users_modal_import_users_invalid_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En eller flera kolumner är ogiltiga:"])},
  "page_users_modal_import_users_invalid_number_of_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogiltigt antal kolumner på rad: "])},
  "page_users_modal_import_users_invalid_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rubriken saknas eller är ogiltig"])},
  "page_users_modal_import_users_invalid_csv_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogiltigt csv-filinnehåll"])},
  "page_users_modal_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
  "page_users_modal_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])},
  "page_users_modal_all_countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla länder"])},
  "page_users_modal_all_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla språk"])},
  "page_users_modal_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenord"])},
  "page_users_modal_add_user_invalid_first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogiltigt förnamn"])},
  "page_users_modal_add_user_invalid_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogiltigt efternamn"])},
  "page_users_modal_add_user_invalid_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogiltigt språk"])},
  "page_users_modal_add_user_invalid_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogiltigt land"])},
  "page_users_modal_add_user_invalid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogiltig e-postadress"])},
  "page_users_modal_add_user_email_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postadress finns redan"])},
  "page_users_modal_add_user_success_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användaren har lagts till"])},
  "page_users_modal_add_user_success_otp_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användaren skapades och har ovanstående engångslösenord."])},
  "page_users_modal_add_user_success_email_link_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ett e-postmeddelande med inloggningsinstruktioner skickades till användarens e-postadress."])},
  "page_users_modal_add_user_auto_generate_otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generera engångslösenord automatiskt"])},
  "page_users_modal_add_user_send_email_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skicka e-postmeddelande för kontoaktivering till användaren"])},
  "page_users_modal_add_user_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ett okänt fel uppstod när användaren skulle läggas till."])},
  "page_users_modal_select_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj domän"])},
  "page_user_details_back_to_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillbaka till användare"])},
  "page_user_details_tab_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
  "page_user_details_tab_security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Säkerhet"])},
  "page_user_details_tab_devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enheter"])},
  "page_user_details_tab_audit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisionsloggar"])},
  "page_user_details_tab_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupper"])},
  "page_user_details_tab_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessioner"])},
  "page_user_details_tab_email_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posthistorik"])},
  "page_user_details_tab_passwordless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utan lösenord"])},
  "page_user_details_tab_linked_eids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopplade e-legitimationer"])},
  "page_user_details_tab_applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikationer"])},
  "page_user_details_recovery_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postadresser för återställning"])},
  "page_user_details_phone_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
  "page_user_details_edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera profil"])},
  "page_user_details_update_user_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ett okänt fel uppstod när användaren skulle uppdateras"])},
  "page_user_details_application_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikationsnamn"])},
  "page_user_details_authorization_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auktoriseringsdatum"])},
  "page_user_details_login_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggningsdatum"])},
  "page_user_details_login_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal inloggningar"])},
  "page_user_details_no_applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga applikationer"])},
  "page_user_details_showing_applications": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Visar ", _interpolate(_named("count")), " av ", _interpolate(_named("totalCount")), " appar"])},
  "page_user_details_showing_application": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Visar ", _interpolate(_named("count")), " av 1 app"])},
  "page_user_details_show_supporting_applications": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Visa ", _interpolate(_named("count")), " stödjande appar"])},
  "page_user_details_hide_supporting_applications": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dölj ", _interpolate(_named("count")), " stödjande appar"])},
  "page_user_details_show_supporting_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa 1 stödjande app"])},
  "page_user_details_hide_supporting_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dölj 1 stödjande app"])},
  "page_user_details_update_user_profile_image_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilbildfilen är ogiltig eller skadad och kunde inte accepteras"])},
  "page_user_details_update_user_profile_edit_profile_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera profilbild"])},
  "page_user_details_update_user_profile_choose_profile_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj foto ..."])},
  "page_user_details_update_user_profile_image_size_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilden är för liten. Minsta tillåtna storlek är 200 px bred och 200 px hög"])},
  "page_user_details_reset_password_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återställ lösenord"])},
  "page_user_details_reset_password_modal_body_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Är du säker på att du vill skicka ett e-postmeddelande om återställning av lösenord till användaren?"])},
  "page_user_details_reset_password_modal_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det har skickats en länk för återställning av lösenord till användarens primära e-postadress och e-postadress för återställning. Lösenordet återställs inte automatiskt. Länken för återställning av lösenord upphör att gälla 15 minuter efter att den har skickats."])},
  "page_user_details_one_time_password_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engångslösenord"])},
  "page_user_details_one_time_password_modal_body_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engångslösenordet är:"])},
  "page_user_details_one_time_password_modal_body_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Är du säker på att du vill skapa ett engångslösenord för användaren?"])},
  "page_user_details_one_time_password_modal_body_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användaren kommer att behöva ange ett nytt lösenord vid inloggning."])},
  "page_users_modal_generate_emergency_code_totp_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användaren har inte TOTP aktiverat."])},
  "page_users_modal_generate_emergency_code_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engångskod"])},
  "page_user_details_generate_emergency_code_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Är du säker på att du vill skapa en engångskod för användaren?"])},
  "page_users_modal_generate_emergency_code_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engångskoden är:"])},
  "page_users_modal_generate_emergency_code_how_to_generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj hur du vill skapa"])},
  "page_users_modal_generate_emergency_code_to_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa en ny engångskod att kopiera"])},
  "page_users_modal_generate_emergency_code_by_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skicka engångskoden till användarens inkorg"])},
  "page_users_modal_generate_emergency_code_by_email_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ett e-postmeddelande med engångskoden skickades till användarens inkorg."])},
  "page_users_modal_generate_emergency_code_generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa"])},
  "page_users_modal_generate_emergency_code_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skicka"])},
  "page_user_details_more_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fler åtgärder"])},
  "page_user_details_send_reset_password_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skicka e-post för återställning av lösenord"])},
  "page_user_details_generate_otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa ett engångslösenord"])},
  "page_user_details_generate_one_time_emergency_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapa en engångskod"])},
  "page_user_details_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenord"])},
  "page_user_details_last_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senast använd"])},
  "page_user_details_never_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aldrig använd"])},
  "page_user_details_last_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senast ändrad"])},
  "page_user_details_password_not_set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenord ej angivet"])},
  "page_user_reset_password_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senaste begäran om återställning av lösenord"])},
  "page_user_not_requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ej begärd"])},
  "page_user_password_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenord låst"])},
  "page_user_not_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inte låst"])},
  "page_user_2_fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvåstegsverifiering"])},
  "page_user_enforced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Framtvingad"])},
  "page_users_enabled_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiverad"])},
  "page_users_not_enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inte aktiverad"])},
  "page_users_disabled_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiverad"])},
  "page_users_last_generated_emergency_code_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senast skapade engångskod"])},
  "page_users_visma_connect_idp_disabled_notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga in med Visma är inaktiverat."])},
  "page_users_visma_connect_idp_disabled_adjust_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Justera din policy om du vill aktivera detta."])},
  "page_user_details_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggning"])},
  "page_user_details_last_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senaste inloggning"])},
  "page_user_details_never_logged_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aldrig loggat in"])},
  "page_user_details_passkeys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åtkomstkoder"])},
  "page_user_details_passkeys_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antal åtkomstkoder"])},
  "page_user_details_no_passkeys_registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga åtkomstkoder registrerade"])},
  "page_users_modal_unlock_password_account_not_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användarkontot är inte låst med lösenord."])},
  "page_users_modal_unlock_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lås upp lösenord"])},
  "page_user_details_unlock_password_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lås upp lösenord"])},
  "page_user_details_unlock_password_modal_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenordet låstes upp."])},
  "page_users_details_auto_unlocked_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Låses upp automatiskt om ", _interpolate(_named("count")), " minuter"])},
  "page_users_details_auto_unlocked_1minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Låses upp automatiskt om 1 minut"])},
  "page_user_details_unlock_password_modal_body_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Är du säker på att du vill låsa upp lösenordet för användaren?"])},
  "page_user_details_suspend_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktivera konto"])},
  "page_user_details_resume_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återuppta konto"])},
  "page_user_details_suspend_user_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktivera konto"])},
  "page_user_details_suspend_user_modal_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orsak till inaktivering:"])},
  "page_user_details_suspend_user_modal_body_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Är du säker på att du vill inaktivera kontot?"])},
  "page_users_modal_suspend_user_account_already_suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användarkontot är redan inaktiverat."])},
  "page_users_modal_suspend_user_account_not_suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användarkontot är inte inaktiverat."])},
  "page_users_modal_suspend_user_account_invalid_suspend_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogiltig orsak till inaktivering"])},
  "page_user_details_account_suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontot har inaktiverats"])},
  "page_user_details_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orsak"])},
  "page_user_details_resume_user_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återuppta konto"])},
  "page_user_details_resume_user_modal_body_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Är du säker på att du vill återuppta kontot?"])},
  "page_user_details_ip_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-adress"])},
  "page_user_details_device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enhet"])},
  "page_user_details_browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webbläsare"])},
  "page_user_details_not_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga sessioner"])},
  "page_user_details_session_logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga ut"])},
  "page_user_details_session_logout_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga ut från sessionen"])},
  "page_user_details_session_logout_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Är du säker på att du vill logga ut användaren från den valda sessionen?"])},
  "page_users_modal_delete_session_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde inte logga ut användaren från den valda sessionen"])},
  "page_user_details_current_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuell session"])},
  "page_user_details_view_applications": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Visa alla ", _interpolate(_named("applicationsCount")), " appar"])},
  "page_user_details_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Händelse"])},
  "page_user_details_timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidsstämpel"])},
  "page_user_details_payload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyttolast"])},
  "page_users_no_audit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen revision"])},
  "page_users_audit_log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["revisionslogg"])},
  "page_users_audit_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["revisionsloggar"])},
  "page_users_selected_auditcount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Valda (", _interpolate(_named("auditCount")), ")"])},
  "page_users_select_auditcount_from_page": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Välj ", _interpolate(_named("auditCount")), " revisionsloggar från sidan"])},
  "page_select_all_auditcount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Välj alla ", _interpolate(_named("auditCount")), " revisionsloggar"])},
  "page_users_audit_export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
  "page_users_audit_event_login_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggningen misslyckades"])},
  "page_users_audit_event_login_succeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inloggningen lyckades"])},
  "page_users_audit_event_client_application_authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikation godkänd"])},
  "page_users_audit_event_logout_succeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har nu loggat ut"])},
  "page_users_1_selected_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 händelse"])},
  "page_users_selected_events_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " händelser"])},
  "page_users_all_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alla händelser"])},
  "page_users_filter_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrera händelser"])},
  "page_users_modal_export_audit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportera revisionsloggar"])},
  "page_users_modal_export_audit_body_1_audit_log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 revisionslogg kommer att exporteras"])},
  "page_users_modal_export_users_body_n_audit_logs": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " revisionsloggar kommer att exporteras"])},
  "page_users_modal_export_user_audit_logs_field_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisions-ID"])},
  "page_users_modal_export_user_audit_logs_field_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Händelse"])},
  "page_users_modal_export_user_audit_logs_field_timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidsstämpel"])},
  "page_users_modal_export_user_audit_logs_field_ipv4_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-adress"])},
  "page_users_modal_export_user_audit_logs_field_application_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikations-ID"])},
  "page_users_modal_export_user_audit_logs_field_application_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikationsnamn"])},
  "page_users_modal_export_user_audit_logs_field_payload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyttolast"])},
  "page_users_modal_export_audit_logs_start_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportera revisionsloggar"])},
  "page_users_audit_search_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ett okänt fel uppstod vid sökning efter revisionsloggar"])},
  "page_users_modal_export_audit_logs_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ett okänt fel uppstod vid export av revisionsloggar"])},
  "page_user_details_add_recovery_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till en e-postadress för återställning"])},
  "page_user_details_add_another_recovery_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till ytterligare en e-postadress för återställning"])},
  "page_user_details_update_user_invalid_recovery_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogiltig e-postadress för återställning"])},
  "page_user_details_update_user_recovery_email_already_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postadressen för återställning är duplicerad"])},
  "page_user_details_update_user_recovery_email_disposable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postadressen för återställning använder en engångsdomän"])}
}