import Saml2IdentityProviderOutputModel from '@/api/saml2-idp-client/models/Saml2IdentityProviderOutputModel';
import { Saml2IdentityProviderStoreState } from './state';

export default {
    saml2IdentityProvider(state: Saml2IdentityProviderStoreState): Saml2IdentityProviderOutputModel | undefined {
        return state.saml2IdentityProvider;
    },

    isUnauthorizedToViewSaml2Idp(state: Saml2IdentityProviderStoreState): boolean {
        return state.isUnauthorizedToViewSaml2Idp;
    },

    isStateLoading(state: Saml2IdentityProviderStoreState): boolean {
        return state.isProcessing;
    },
};
