import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = {
  key: 0,
  class: "dropdown-menu tbl rounded-20",
  role: "menu"
}
const _hoisted_3 = { class: "ml-12" }
const _hoisted_4 = {
  key: 1,
  class: "dropdown-menu tbl rounded-20",
  role: "menu"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 1,
  class: "ml-12"
}
const _hoisted_7 = { class: "job-item" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-2 pt-8" }
const _hoisted_10 = ["title"]
const _hoisted_11 = { class: "col" }
const _hoisted_12 = { class: "row font-weight-bold" }
const _hoisted_13 = { class: "col-auto" }
const _hoisted_14 = { class: "col-auto pl-8" }
const _hoisted_15 = { class: "pl-4" }
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "col-auto offset-2" }
const _hoisted_18 = { class: "col-auto ml-n24" }
const _hoisted_19 = {
  key: 0,
  class: "row"
}
const _hoisted_20 = { class: "col-auto offset-2" }
const _hoisted_21 = { class: "col-auto ml-n24" }
const _hoisted_22 = {
  key: 1,
  class: "row pt-4"
}
const _hoisted_23 = { class: "col-auto offset-2" }
const _hoisted_24 = { class: "col-auto ml-n24" }
const _hoisted_25 = ["href"]
const _hoisted_26 = ["href"]
const _hoisted_27 = { class: "pl-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_spinner = _resolveComponent("loading-spinner")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(["dropdown jobs-details icon", { open: _ctx.showJobsList }]),
    title: _ctx.$t('nav_menu_jobs_title')
  }, [
    _createElementVNode("a", {
      id: "jobs-details-dropdown",
      class: "dropdown-toggle button-context",
      "data-toggle": "dropdown",
      role: "button",
      "aria-expanded": "true",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleShowJobsList && _ctx.toggleShowJobsList(...args)))
    }, _cache[1] || (_cache[1] = [
      _createElementVNode("span", { class: "vismaicon vismaicon-menu vismaicon vismaicon-hourglass" }, null, -1)
    ])),
    (_ctx.isUnauthorizedToViewJobs)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          _createElementVNode("li", _hoisted_3, _toDisplayString(_ctx.$t('notif_warn_unauthorized_to_perform_action')), 1)
        ]))
      : (_openBlock(), _createElementBlock("ul", _hoisted_4, [
          (_ctx.isStateLoading)
            ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
                _createVNode(_component_loading_spinner, {
                  class: "loading-spinner",
                  show: _ctx.isStateLoading
                }, null, 8, ["show"])
              ]))
            : _createCommentVNode("", true),
          (!_ctx.isStateLoading && _ctx.jobs.length === 0)
            ? (_openBlock(), _createElementBlock("li", _hoisted_6, _toDisplayString(_ctx.$t('nav_menu_jobs_no_activity')), 1))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jobs, (job) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: job.id
            }, [
              _createElementVNode("li", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("span", {
                      class: _normalizeClass([_ctx.getJobStatusIconClass(job.status), "vismaicon vismaicon-filled pl-8 mt-n8"]),
                      title: _ctx.$t('nav_menu_jobs_created_by', { email: job.createdBy })
                    }, null, 10, _hoisted_10)
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.getJobTypeDisplay(job.type)), 1)
                      ]),
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("span", null, _toDisplayString(_ctx.getStatusDisplay(job.status)), 1),
                        _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.getProgressDisplay(job)), 1)
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t('nav_menu_jobs_started_on')) + ":", 1),
                  _createElementVNode("span", _hoisted_18, _toDisplayString(job.displayCreatedDateTime), 1)
                ]),
                (_ctx.isJobCompleted(job.status))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                      _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.$t('nav_menu_jobs_completed_on')) + ":", 1),
                      _createElementVNode("span", _hoisted_21, _toDisplayString(job.displayCompletedDateTime), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.isJobCompleted(job.status))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                      _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.$t('nav_menu_jobs_download_report')) + ":", 1),
                      _createElementVNode("div", _hoisted_24, [
                        _createElementVNode("a", {
                          href: _ctx.getDownloadUrl('json', job.id),
                          class: "d-inline btn btn-link"
                        }, "JSON", 8, _hoisted_25),
                        _createElementVNode("a", {
                          href: _ctx.getDownloadUrl('csv', job.id),
                          class: "d-inline btn btn-link"
                        }, "CSV", 8, _hoisted_26)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _cache[2] || (_cache[2] = _createElementVNode("li", null, [
                _createElementVNode("div", { class: "divider pl-64" })
              ], -1))
            ], 64))
          }), 128)),
          _createElementVNode("li", null, [
            _createElementVNode("em", _hoisted_27, _toDisplayString(_ctx.$t('nav_menu_jobs_activities_cleared')), 1)
          ])
        ]))
  ], 10, _hoisted_1)), [
    [_directive_click_outside, _ctx.closeJobsList]
  ])
}