import config from '@/config';
import httpClient from '@/http-client';
export default {
    async fetchVismaPublicCertificateAsync(): Promise<string | undefined> {
        const vismaPublicCertificateUrl = `${config().backendUrl}/v1.0/sso/saml2-sp/certificate`;
        const vismaMetadataResponse = await httpClient.getAsync(vismaPublicCertificateUrl, { responseType: 'text' });

        if (vismaMetadataResponse.isSuccess && vismaMetadataResponse.rawData) {
            return vismaMetadataResponse.rawData.toString().slice(1, -1);
        }
        return void 0;
    },
};
