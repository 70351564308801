export default {
  "page_groups_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du groupe"])},
  "page_groups_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["membre"])},
  "page_groups_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membres"])},
  "page_groups_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "page_groups_table_no_groups_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun groupe ajouté"])},
  "page_groups_table_no_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de groupes"])},
  "page_groups_table_no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun groupe trouvé correspondant aux critères de recherche"])},
  "page_groups_btn_add_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un Groupe"])},
  "page_groups_btn_save_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder le groupe"])},
  "page_groups_btn_edit_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le groupe"])},
  "page_groups_users_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
  "page_groups_users_fist_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
  "page_groups_users_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
  "page_groups_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créé (UTC)"])},
  "page_groups_membership_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adhésion actualisée (UTC)"])},
  "page_groups_delete_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le groupe"])},
  "page_groups_delete_group_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous certain de vouloir supprimer le groupe?"])},
  "page_groups_remove_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le membre"])},
  "page_groups_remove_member_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer le membre"])},
  "page_groups_remove_member_group_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Êtes-vous certain de vouloir supprimer le membre ", _interpolate(_named("member")), " du groupe ", _interpolate(_named("group")), "?"])},
  "page_groups_no_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas d'utilisateur ajouté"])},
  "page_groups_no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun utilisateur ne correspond aux critères de recherche"])},
  "page_groups_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< Retour aux Groupes"])},
  "page_groups_add_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter membre au groupe"])},
  "page_groups_add_users_all_are_already_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les utilisateurs ont déjà été ajoutés à ce groupe"])},
  "page_groups_add_users_all_no_users_matching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun utilisateur ne correspond aux critères de recherche"])},
  "page_groups_member_since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membre depuis"])},
  "page_groups_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
  "page_groups_group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membres du groupe:"])},
  "page_groups_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de groupe:"])},
  "page_groups_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])},
  "page_groups_err_group_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur inconnue s'est produite lors de l'ajout de ce groupe."])},
  "page_groups_err_group_invalid_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom du groupe est invalide. Longueur maximale autorisée de 255 caractères"])},
  "page_groups_err_group_invalid_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La description du groupe est invalide. Longueur maximale autorisée de 1024 caractères"])},
  "page_groups_err_group_already_exits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le groupe que vous essayez d’ajouter existe déjà."])},
  "page_groups_err_group_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur inconnue s'est produite lors de la mise à jour de ce groupe."])},
  "page_groups_search_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un nom de groupe"])},
  "page_groups_search_group_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher par email"])},
  "page_groups_search_group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher des membres"])},
  "page_groups_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["groupes"])},
  "page_groups_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["groupe"])},
  "page_groups_filter_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer les membres"])},
  "page_groups_member_filter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Membre : ", _interpolate(_named("name"))])}
}