import BaseUserDetailsModel from '@/api/user-api-client/models/Base/BaseUserDetailsModel';
import util from '@/util';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('GroupUserModel')
export default class GroupUserModel extends BaseUserDetailsModel {
    public constructor() {
        super();

        this.id = '';
        this.email = '';
        this.firstName = '';
        this.lastName = '';
        this.membershipDate = '';
    }

    @JsonProperty('id', String)
    public id: string;

    @JsonProperty('email', String)
    public email: string;

    @JsonProperty('first_name', String)
    public firstName: string;

    @JsonProperty('last_name', String)
    public lastName: string;

    @JsonProperty('membership_date', String)
    public membershipDate: string;

    public get displayMembershipDate(): string {
        return util.formatDate(this.membershipDate);
    }
}
