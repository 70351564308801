import { JsonObject, JsonProperty } from 'json2typescript';
import IdentityProviderSupportedProvisioningAction from './IdentityProviderSupportedProvisioningAction';

@JsonObject('IdentityProviderScimConfiguration')
export default class IdentityProviderScimConfiguration {
    public constructor() {
        this.supportedProvisioningActions = void 0;
    }

    @JsonProperty('supported_provisioning_actions', [IdentityProviderSupportedProvisioningAction], true)
    public supportedProvisioningActions?: IdentityProviderSupportedProvisioningAction[];
}
