// elint-disable no-unused-vars
import { ActionContext } from 'vuex';
import { NotificationStoreState } from './state';
import NotificationStoreModel from './models/NotificationStoreModel';
import { RootState, BasicStoreAccessors } from '../types';
import mutations from './mutations';

const NotificationLifetimeMs = 5000;
const NotificationMaxDisplayed = 3;

let storeApi: BasicStoreAccessors<NotificationStoreState>;

export function initActions(api: BasicStoreAccessors<NotificationStoreState>): void {
    storeApi = api;
}

export default {
    addNotification(context: ActionContext<NotificationStoreState, RootState>, notification: NotificationStoreModel): void {
        if (context.state.notifications.length >= NotificationMaxDisplayed) {
            storeApi.commit(mutations.deleteFirstNotification);
        }

        storeApi.commit(mutations.addNotification, notification);

        setTimeout(() => {
            storeApi.commit(mutations.deleteNotification, notification.id);
        }, NotificationLifetimeMs);
    },

    deleteNotification(context: ActionContext<NotificationStoreState, RootState>, id: string): void {
        storeApi.commit(mutations.deleteNotification, id);
    },
};
