export default {
  "page_sso_alert_no_verified_domains_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domeininstellingen"])},
  "page_sso_alert_no_verified_domains_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U moet minstens één domein toevoegen en verifiëren voordat u verder kunt gaan met de instellingen voor Single Sign-On."])},
  "page_sso_alert_no_verified_domains_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naar Domeinen"])},
  "page_sso_alert_no_added_mydomain_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen MyDomain"])},
  "page_sso_alert_no_added_mydomain_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U moet een MyDomain toevoegen voordat u verder kunt gaan de instellingen voor Single Sign-On."])},
  "page_sso_alert_no_added_mydomain_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naar MyDomain"])},
  "page_sso_alert_idp_already_added_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity provider instellen"])},
  "page_sso_alert_idp_already_added_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U hebt al een identiteitsprovider ingesteld. Er kan voor uw bedrijf maar één externe SSO-identiteitsprovider worden geconfigureerd."])},
  "page_sso_alert_idp_already_added_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naar Single Sign-On"])},
  "page_sso_mydomain_info_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MyDomain is uw persoonlijke inlogpagina bij Visma. Deze pagina bevat ook uw unieke SAM2L-of OpenID Connect eindpunten."])},
  "page_sso_mydomain_info_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een bekende url zorgt ervoor dat gebruikers zien dat de website van uw organisatie is."])},
  "page_sso_mydomain_info_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt uw MyDomain alleen verwijderen of hernoemen als geen identiteitsprovider hebt ingesteld."])},
  "page_sso_mydomain_modal_add_or_update_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welk MyDomain wilt u toevoegen?"])},
  "page_sso_mydomain_modal_add_or_update_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["voorbeeld"])},
  "page_sso_mydomain_modal_add_or_update_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer een naam in die gebruikers kennen. Voor deze naam mag u alleen kleine letters, cijfers en koppelstreepjes (-) gebruiken. De naam mag niet beginnen of eindigen met een koppelstreepje. De maximale lengte is 40 tekens."])},
  "page_sso_mydomain_modal_add_or_update_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is onbekende fout opgetreden bij het instellen van dit MyDomain."])},
  "page_sso_mydomain_modal_add_or_update_error_invalid_mydomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het MyDomain heeft niet het juiste formaat."])},
  "page_sso_mydomain_modal_add_or_update_error_already_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit MyDomain is al in gebruik."])},
  "page_sso_mydomain_modal_add_or_update_error_sso_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw MyDomain kan niet worden gewijzigd. U maakt gebruik van identiteitsproviders."])},
  "page_sso_mydomain_modal_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MyDomain verwijderen"])},
  "page_sso_mydomain_modal_delete_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weet u zeker dat u uw MyDomain ", _interpolate(_named("myDomainUri")), " wilt verwijderen?"])},
  "page_sso_mydomain_notif_add_mydomain_success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("myDomainUri")), " is toegevoegd als uw MyDomain."])},
  "page_sso_mydomain_notif_update_mydomain_success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uw MyDomain is bijgewerkt naar ", _interpolate(_named("myDomainUri")), "."])},
  "page_sso_mydomain_notif_delete_mydomain_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw MyDomain is verwijderd."])},
  "page_sso_idp_notif_add_or_update_success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Single Sign-On met ", _interpolate(_named("providerDisplayName")), " is succesvol ingesteld."])},
  "page_sso_idp_selection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identiteitsprovider selecteren"])},
  "page_sso_idp_selection_title_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer de identiteitsprovider die u voor Single Sign-On wilt gebruiken."])},
  "page_sso_idp_selection_list_title_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overige"])},
  "page_sso_idp_selection_back_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< Terug naar Single Sign-On"])},
  "page_sso_idp_selection_list_body_coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komt binnenkort. Houd deze pagina in de gaten!"])},
  "page_sso_idp_selection_list_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Als u Visma met ", _interpolate(_named("providerDisplayName")), " integreert, dan kunt u uw gebruikers automatisch in Visma laten inloggen met hun ", _interpolate(_named("providerDisplayName")), "-account."])},
  "page_sso_idp_notif_add_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een onbekende fout opgetreden bij het toevoegen van uw identiteitsprovider."])},
  "page_sso_idp_notif_update_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een onbekende fout opgetreden tijdens het updaten van uw identiteitsprovider."])},
  "page_sso_idp_notif_update_idp_no_longer_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze identiteitsprovider bestaat niet meer voor uw bedrijf."])},
  "page_sso_idp_notif_delete_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een onbekende fout opgetreden bij het verwijderen van uw identiteitsprovider."])},
  "page_sso_idp_notif_delete_success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uw ", _interpolate(_named("providerName")), "-identiteitsprovider is verwijderd."])},
  "page_sso_idp_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identiteitsprovider"])},
  "page_sso_idp_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg de identiteitsprovider toe die u voor Single Sign-On wilt gebruiken."])},
  "page_sso_idp_no_idp_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen identiteitsprovider toegevoegd."])},
  "page_sso_idp_setup_back_to_selection_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< Terug naar selectiepagina"])},
  "page_sso_idp_setup_step_advanced_config_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitgebreide configuratie"])},
  "page_sso_idp_setup_step_advanced_config_jit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just in time (JIT)-gebruikersprovisioning"])},
  "page_sso_idp_setup_step_advanced_config_jit_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als deze is ingeschakeld, wordt elke gebruiker die niet in het Visma-systeem bestaat, automatisch aangemaakt (zonder toegangsrechten voor toepassingen, behalve inloggen). Als deze is uitgeschakeld, dan moet u alle gebruikers aanmaken in het Admin-paneel van uw Visma-applicatie voordat ze kunnen inloggen. U moet hoe dan ook toegangsrechten voor toepassingen verlenen binnen Visma-beheerpanelen."])},
  "page_sso_idp_setup_help_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bekijk de stap voor stap de handleiding om ", _interpolate(_named("providerDisplayName")), " te configureren als een ", _interpolate(_named("idpType")), " identiteitsaanbieder binnen Visma."])},
  "page_sso_idp_modal_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identiteitsprovider verwijderen"])},
  "page_sso_idp_modal_delete_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weet u zeker dat u identiteitsprovider ", _interpolate(_named("idpName")), " wilt verwijderen?"])},
  "page_sso_idp_modal_delete_scim_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM 2.0-provisioning is momenteel ingeschakeld. Als u doorgaat, dan wordt deze uitgeschakeld en gaat de configuratie verloren."])},
  "page_sso_saml2idp_notif_invalid_metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De SAML2-metadata zijn niet geldig."])},
  "page_sso_saml2idp_notif_invalid_metadata_certificate_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het certificaat van uw SAML2-metadata is verlopen."])},
  "page_sso_saml2idp_notif_invalid_metadata_certificate_still_expired": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Het certificaat is nog verlopen. Ga naar ", _interpolate(_named("providerDisplayName")), " om een nieuw certificaat aan te maken. Kom dan hier terug en klik op de knop 'Certificaat verversen'."])},
  "page_sso_saml2idp_btn_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML2-identiteitsprovider toevoegen"])},
  "page_sso_saml2idp_certificate_expires_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Certificaat verloopt op ", _interpolate(_named("expirationTime"))])},
  "page_sso_saml2idp_certificate_expired_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Verlopen op ", _interpolate(_named("expirationTime")), ". Ga naar ", _interpolate(_named("saml2Idp")), " om een nieuw certificaat aan te maken. Kom dan hier terug, bewerk deze provider en klik op de knop 'Certificaat verversen'. Controleer de gegevens van het nieuwe certificaat en klik op 'Opslaan' om het bij te werken."])},
  "page_sso_saml2idp_certificate_expired_on_mobile": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Het certificaat is verlopen op ", _interpolate(_named("expirationTime")), ". Ga naar ", _interpolate(_named("saml2Idp")), " om een nieuw certificaat aan te maken. Kom dan hier terug, bewerk deze provider en klik op de knop 'Certificaat verversen'. Controleer de gegevens van het nieuwe certificaat en klik op 'Opslaan' om het bij te werken."])},
  "page_sso_saml2idp_details_header_certificate_expire_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vervaldatum certificaat"])},
  "page_sso_saml2idp_setup_step_visma_metadata_title_upload": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Metadatabestand van Visma uploaden naar ", _interpolate(_named("providerDisplayName"))])},
  "page_sso_saml2idp_setup_step_visma_metadata_title_copy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gegevens van Visma-metadata naar ", _interpolate(_named("providerDisplayName")), " kopiëren"])},
  "page_sso_saml2idp_setup_step_visma_metadata_title_copy_with_public_certificate_download": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gegevens van metadata in ", _interpolate(_named("providerDisplayName")), " configureren."])},
  "page_sso_saml2idp_setup_step_visma_metadata_title_upload_or_copy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("providerDisplayName")), " configureren met de Visma-metadata uit bestand of individuele velden"])},
  "page_sso_saml2idp_setup_step_visma_metadata_info_upload": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Upload in ", _interpolate(_named("providerDisplayName")), " het Visma-metadatabestand dat u hieronder hebt gedownload."])},
  "page_sso_saml2idp_setup_step_visma_metadata_info_copy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kopieer elk onderstaand metadataveld en plak dit in de overeenkomstige configuratie in ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_visma_metadata_info_copy_with_public_certificate_download": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kopieer de onderstaande links naar uw configuratie van ", _interpolate(_named("providerDisplayName")), ". Als u de functie Single Sign-Out wilt inschakelen, dan downloadt u hieronder het certificaat en uploadt u het naar ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_visma_metadata_info_upload_or_copy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Upload het bestand met de Visma-metadata dat u hieronder hebt gedownload of kopieer elk onderstaand metadataveld en plak dit in de overeenkomstige configuratie in ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_visma_metadata_info_copy_metadata_url_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kopieer onderstaande metadata-url en plak deze in de overeenkomstige configuratie in ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_visma_metadata_metadata_file_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadatabestand"])},
  "page_sso_saml2idp_setup_step_visma_metadata_or_divider_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OF"])},
  "page_sso_saml2idp_setup_step_claims_config_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Claims configureren in ", _interpolate(_named("providerDisplayName"))])},
  "page_sso_saml2idp_setup_step_claims_config_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zorg ervoor dat de configuratie in ", _interpolate(_named("providerDisplayName")), " zo is dat de volgende claimnamen worden gebruikt wanneer er SAML-responses naar Visma worden verzonden."])},
  "page_sso_saml2idp_setup_step_provider_metadata_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Metadata-url van ", _interpolate(_named("providerDisplayName")), " in Visma plakken"])},
  "page_sso_saml2idp_setup_step_provider_metadata_title_upload": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Metadata van ", _interpolate(_named("providerDisplayName")), " naar Visma uploaden"])},
  "page_sso_saml2idp_setup_step_provider_metadata_info_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kopieer de metadata-URL vanuit ", _interpolate(_named("providerDisplayName")), " en plak die hieronder."])},
  "page_sso_saml2idp_setup_step_provider_metadata_info_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deze kunt u bij ", _interpolate(_named("providerDisplayName")), " verkrijgen en bevat het openbare certificaat, de claims en de SAML-eindpunten die Visma nodig heeft voor zijn eigen instellingen."])},
  "page_sso_saml2idp_setup_step_provider_metadata_info_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voor de gebruikersidentiteit moet u een geldig e-mailadres als NameID verzenden plus de voor- en achternaam."])},
  "page_sso_saml2idp_setup_step_provider_metadata_info_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Download het metadatabestand uit ", _interpolate(_named("providerDisplayName")), " en upload het hieronder."])},
  "page_sso_saml2idp_setup_step_provider_metadata_url_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata-url plakken"])},
  "page_sso_saml2idp_setup_step_provider_metadata_preview_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorbeeld"])},
  "page_sso_saml2idp_setup_step_provider_metadata_empty_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het veld mag niet leeg zijn."])},
  "page_sso_saml2idp_setup_step_provider_metadata_max_length_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De maximale lengte is 100 tekens."])},
  "page_sso_saml2idp_setup_step_provider_metadata_upload_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadatabestand uploaden"])},
  "page_sso_saml2idp_setup_step_provider_metadata_upload_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestand selecteren voor voorbeeld"])},
  "page_sso_saml2idp_setup_step_provider_metadata_upload_refresh_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestand met nieuwe gegevens selecteren"])},
  "page_sso_saml2idp_setup_step_provider_metadata_refresh_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegevens vernieuwen"])},
  "page_sso_saml2idp_setup_step_provider_metadata_preview_from_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadatavoorbeeld van URL"])},
  "page_sso_saml2idp_setup_step_provider_metadata_preview_from_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadatavoorbeeld van bestand"])},
  "page_sso_saml2idp_setup_step_provider_metadata_error_metadata_empty": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dit veld is verplicht. Kopieer de inhoud uit ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_provider_metadata_error_metadata_url_invalid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ongeldige metadata-url. Kopieer deze bij ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_provider_metadata_error_metadata_file_invalid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ongeldig metadatabestand. Download dit bij ", _interpolate(_named("providerDisplayName")), " en upload het hier."])},
  "page_sso_saml2idp_setup_step_provider_metadata_certificate_expires_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Verloopt op ", _interpolate(_named("expirationTime"))])},
  "page_sso_saml2idp_setup_step_provider_metadata_certificate_expired_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Verlopen op ", _interpolate(_named("expirationTime")), ". Ga naar ", _interpolate(_named("providerDisplayName")), " om een nieuw certificaat aan te maken. Kom dan hier terug en klik op de bewerkpagina op de knop 'Certificaat verversen'. Controleer de gegevens van het nieuwe certificaat en klik op 'Opslaan' om het bij te werken."])},
  "page_sso_saml2idp_setup_step_advanced_config_slo_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single Sign-Out"])},
  "page_sso_saml2idp_setup_step_advanced_config_slo_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wilt u dat Visma het Single Sign-Out-eindpunt van uw ", _interpolate(_named("providerDisplayName")), " aanroept als de gebruiker uitlogt bij Visma-applicaties? Klik op 'Nee' als u uw ", _interpolate(_named("providerDisplayName")), "-sessie actief wilt houden totdat het initiatief tot uitloggen vanuit ", _interpolate(_named("providerDisplayName")), " naar het SAML-Single-Sign-Out-eindpunt wordt gestuurd."])},
  "page_sso_saml2idp_setup_step_advanced_config_updateclaims_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiel telkens bijwerken wanneer een gebruiker inlogt"])},
  "page_sso_saml2idp_setup_step_advanced_config_updateclaims_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Als het is ingeschakeld, dan worden, telkens wanneer een gebruiker zich aanmeldt, de beschikbare gebruikersprofielkenmerken gesynchroniseerd vanaf ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_advanced_config_updategroups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepslidmaatschappen telkens bijwerken wanneer een gebruiker inlogt"])},
  "page_sso_saml2idp_setup_step_advanced_config_updategroups_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Als het is ingeschakeld, dan worden, telkens wanneer een gebruiker zich aanmeldt, de groepslidmaatschappen van de gebruiker gesynchroniseerd vanaf ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_metadata_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata-url"])},
  "page_sso_saml2idp_setup_step_entity_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entiteit-id"])},
  "page_sso_saml2idp_setup_step_saml_logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML Logout"])},
  "page_sso_saml2idp_setup_step_sign_on_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmeldings-url"])},
  "page_sso_saml2idp_setup_step_logout_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afmeldings-url"])},
  "page_sso_saml2idp_setup_step_certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificaat"])},
  "page_sso_saml2idp_setup_step_thumbprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vingerafdruk"])},
  "page_sso_saml2idp_setup_step_sign_authn_requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Authn Requests"])},
  "page_sso_saml2idp_setup_step_saml_assertion_consumer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML Assertion Consumer"])},
  "page_sso_saml2sp_setup_step_visma_public_certificate_file_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publiek certificaat"])},
  "page_sso_oidcidp_btn_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OIDC-identiteitsprovider toevoegen"])},
  "page_sso_oidcidp_configure_uris_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Uri's configureren in ", _interpolate(_named("providerDisplayName"))])},
  "page_sso_oidcidp_signin_redirect_uri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirect-uri"])},
  "page_sso_oidcidp_signout_redirect_uri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirect-uri voor uitloggen"])},
  "page_sso_oidcidp_setup_step_visma_uris": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kopieer onderstaande links naar uw configuratie van ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_oidcidp_invalid_authority_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldige autoriteit."])},
  "page_sso_oidcidp_invalid_authority_for_provider_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ongeldige autoriteit voor ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_oidcidp_configure_oidc_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenID Connect-client configureren"])},
  "page_sso_oidcidp_authority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriteit"])},
  "page_sso_oidcidp_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client-id"])},
  "page_sso_oidcidp_client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client secret"])},
  "page_sso_oidcidp_update_client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client secret bijwerken"])},
  "page_sso_oidcidp_authentication_flow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticatieworkflow"])},
  "page_sso_oidcidp_authentication_web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web"])},
  "page_sso_oidcidp_authentication_pkce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PKCE"])},
  "page_sso_oidcidp_authentication_web_and_pkce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web + PKCE"])},
  "page_sso_surfconext_setup_home_realm_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SURFconext-thuisrealm"])},
  "page_sso_surfconext_setup_home_realm_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer de thuisrealm in die u in SURFconext hebt vastgelegd"])},
  "page_sso_surfconext_setup_home_realm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thuisrealm"])},
  "page_sso_surfconext_select_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domein selecteren"])},
  "page_sso_surfconext_notif_already_added_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U hebt de SURFconext-identiteitsprovider al ingesteld."])}
}