import { isMobile } from 'mobile-device-detect';

export default class RuntimeConfig {
    public constructor() {
        this.clientId = process.env.VUE_APP_CLIENT_ID!;
        this.appName = process.env.VUE_APP_NAME!;
        this.environment = process.env.NODE_ENV!;

        this.connectEnvironment = '';
        this.identityServerDomain = '';
        this.identityServerUrl = '';
        this.backendUrl = '';
        this.frontendUrl = '';
        this.accountSettingsUrl = '';
        this.appLogoUrl = '';

        // feature flaggig
        this.featureFlags = new Map<string, boolean>();
    }

    public clientId: string;

    public appName: string;

    public environment: string;

    public connectEnvironment: string;

    public identityServerDomain: string;

    public identityServerUrl: string;

    public frontendUrl: string;

    public backendUrl: string;

    public accountSettingsUrl: string;

    public appLogoUrl: string;

    public featureFlags: Map<string, boolean>;

    public get isDevEnv(): boolean {
        return !this.isProdEnv;
    }

    public get isProdEnv(): boolean {
        return process.env.NODE_ENV === 'production';
    }

    public get isMobile(): boolean {
        return isMobile;
    }

    public isFeatureEnabled(flag: string): boolean {
        const existingFlag = this.featureFlags.get(flag);

        return existingFlag !== undefined && existingFlag;
    }
}
