import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('DomainVerificationRecordModel')
export default class DomainVerificationRecordModel {
    public constructor() {
        this.verificationRecord = '';
    }

    @JsonProperty('verification_record')
    public verificationRecord: string;
}
