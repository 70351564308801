import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-title row" }
const _hoisted_2 = ["title"]
const _hoisted_3 = {
  key: 0,
  class: "font-weight-bold"
}
const _hoisted_4 = { class: "d-flex justify-content-start action-btn-group" }
const _hoisted_5 = { class: "row w-100 m-0 p-0 text-center action-btn-group-mobile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_dialog = _resolveComponent("modal-dialog")!

  return (_openBlock(), _createBlock(_component_modal_dialog, {
    id: "session-expired-modal",
    show: true,
    hideWhenSessionExpired: false,
    icon: _ctx.isScreenMaxSm ? 'none' : 'warning',
    showCloseBtn: false
  }, _createSlots({
    body: _withCtx(() => [
      (!_ctx.isScreenMaxSm)
        ? (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_ctx.$t('notif_modal_session_expired_title')), 1))
        : _createCommentVNode("", true),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('notif_modal_session_expired_body')), 1)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          class: "btn btn-primary btn-ok",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.confirm && _ctx.confirm(...args)))
        }, _toDisplayString(_ctx.$t('common_ok')), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          class: "btn btn-secondary text-primary-green font-weight-bold btn-ok",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.confirm && _ctx.confirm(...args)))
        }, _toDisplayString(_ctx.$t('common_ok')), 1)
      ])
    ]),
    _: 2
  }, [
    (_ctx.isScreenMaxSm)
      ? {
          name: "title",
          fn: _withCtx(() => [
            _createElementVNode("h4", _hoisted_1, [
              _cache[2] || (_cache[2] = _createElementVNode("span", { class: "col-2 vismaicon vismaicon-filled vismaicon-warning" }, null, -1)),
              _createElementVNode("span", {
                class: "col-auto",
                title: _ctx.$t('notif_modal_session_expired_title')
              }, _toDisplayString(_ctx.$t('notif_modal_session_expired_title')), 9, _hoisted_2)
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["icon"]))
}