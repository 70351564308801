import util from '@/util';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('BaseJobModel')
export default abstract class BaseJobModel {
    public constructor() {
        this.id = '';
        this.type = '';
        this.status = '';
        this.progress = 0;
        this.createdBy = '';
    }

    @JsonProperty('job_id', String)
    public id: string;

    @JsonProperty('type', String)
    public type: string;

    @JsonProperty('status', String)
    public status: string;

    @JsonProperty('progress', Number)
    public progress: number;

    @JsonProperty('created_date', String, true)
    public createdDate?: string;

    @JsonProperty('completed_date', String, true)
    public completedDate?: string;

    @JsonProperty('created_by', String)
    public createdBy: string;

    public get displayCreatedDateTime(): string {
        if (!this.createdDate) {
            return '';
        }

        return util.formatDateTime(this.createdDate);
    }

    public get displayCompletedDateTime(): string {
        if (!this.completedDate) {
            return '';
        }

        return util.formatDateTime(this.completedDate);
    }
}
