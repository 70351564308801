import SurfConextOutputModel from '@/api/surfconext-api-client/models/SurfConextOutputModel';
import { SurfConextStoreState } from './state';

export default {
    surfConextIdentityProvider(state: SurfConextStoreState): SurfConextOutputModel | undefined {
        return state.surfConextIdentityProvider;
    },

    isUnauthorizedToViewSurfConext(state: SurfConextStoreState): boolean {
        return state.isUnauthorizedToViewSurfConext;
    },

    isStateLoading(state: SurfConextStoreState): boolean {
        return state.isProcessing;
    },
};
