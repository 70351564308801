import { BasicStoreAccessors } from '../types';
import state, { Saml2SpProviderStoreState } from './state';
import getters from './getters';
import mutations from './mutations';
import actions, { initActions } from './actions';

export default {
    namespaced: true,
    storeApi: {} as BasicStoreAccessors<Saml2SpProviderStoreState>,

    state,
    getters,
    mutations,
    actions,

    init(storeApi: BasicStoreAccessors<Saml2SpProviderStoreState>): void {
        this.storeApi = storeApi;
        initActions(storeApi);
    },
};
