export default {
    /** 420px */
    XS: 420,

    /** 576px */
    SM: 576,

    /** 768px */
    MD: 768,

    /** 992px */
    LG: 992,

    /** 1200px */
    XL: 1200,

    /** 1400px */
    XXL: 1400,
};
