import httpClient from '@/http-client';
import { StatusCodes } from 'http-status-codes';
import ApiClientError from '../error';
import LanguageModel from './models/LanguageModel';

const routeRoot = '/v1.0/languages';

export default {
    async getListAsync(): Promise<LanguageModel[]> {
        const response = await httpClient.getAsync(routeRoot);

        if (response.statusCode === StatusCodes.OK) {
            const languages = response.dataCollection(LanguageModel);

            if (!languages) {
                return [];
            }

            return languages;
        }

        if (response.statusCode === StatusCodes.NO_CONTENT) {
            return [];
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },
};
