import { JsonObject, JsonProperty } from 'json2typescript';
import AuthenticationTenantModel from './AuthenticationTenantModel';

@JsonObject('AuthenticationModel')
export default class AuthenticationModel {
    public constructor() {
        this.tenantId = '';
        this.id = '';
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.profilePicture = '';
        this.languageCode = '';
        this.lastLoginTime = '';
        this.tenants = [];
        this.accessTokenValidTo = '';
        this.ipv4Address = '';
        this.sessionId = '';
    }

    @JsonProperty('tenant_id', String)
    public tenantId: string;

    @JsonProperty('session_id', String)
    public sessionId: string;

    @JsonProperty('id', String)
    public id: string;

    @JsonProperty('first_name', String)
    public firstName: string;

    @JsonProperty('last_name', String)
    public lastName: string;

    @JsonProperty('email', String)
    public email: string;

    @JsonProperty('profile_picture', String)
    public profilePicture: string;

    @JsonProperty('language_code', String)
    public languageCode: string;

    @JsonProperty('last_login_time', String)
    public lastLoginTime: string;

    @JsonProperty('tenants', [AuthenticationTenantModel])
    public tenants: AuthenticationTenantModel[];

    @JsonProperty('access_token_valid_to', String)
    public accessTokenValidTo: string;

    @JsonProperty('ipv4_address', String)
    public ipv4Address: string;
}
