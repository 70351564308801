import util from '@/util';
import { JsonConverter, JsonCustomConvert } from 'json2typescript';
import { Saml2ProviderType } from '../enums/Saml2ProviderType';

@JsonConverter
export default class Saml2ProviderTypeConverter implements JsonCustomConvert<Saml2ProviderType> {
    serialize(data: Saml2ProviderType): string {
        return data?.toString() ?? Saml2ProviderType.Other.toString();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(data: any): Saml2ProviderType {
        if (!data) {
            return Saml2ProviderType.Other;
        }

        const dataString = data.toString();
        if (util.isNullOrEmpty(dataString)) {
            return Saml2ProviderType.Other;
        }

        const providerType = Saml2ProviderType[dataString as keyof typeof Saml2ProviderType];
        if (!providerType) {
            return Saml2ProviderType.Other;
        }

        return providerType;
    }
}
