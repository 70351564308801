export default {
  "common_learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Läs mer"])},
  "common_ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "common_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avbryt"])},
  "common_keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behåll"])},
  "common_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiera"])},
  "common_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopierad"])},
  "common_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladda ner"])},
  "common_downloaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedladdad"])},
  "common_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladda upp"])},
  "common_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort"])},
  "common_verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifiera"])},
  "common_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifierad"])},
  "common_not_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ej verifierad"])},
  "common_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klar"])},
  "common_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misslyckades"])},
  "common_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totalt"])},
  "common_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nästa"])},
  "common_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till"])},
  "common_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdatera"])},
  "common_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Åtgärder"])},
  "common_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara"])},
  "common_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namn"])},
  "common_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapat den"])},
  "common_completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slutfört datum"])},
  "common_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stäng"])},
  "notif_error_server_unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde inte kontakta webbservern. Försök igen om en stund."])},
  "notif_warn_unauthorized_to_perform_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har ingen behörighet att utföra denna åtgärd."])},
  "notif_warn_unauthorized_to_view_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har inte behörighet att se den här informationen."])},
  "notif_modal_session_expired_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessionen har gått ut"])},
  "notif_modal_session_expired_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din nuvarande session har gått ut. Av säkerhetsskäl måste du logga in igen."])},
  "alert_no_verified_domains_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du måste lägga till och verifiera minst en domän för att kunna fortsätta."])},
  "pagination_first_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå till första sidan"])},
  "pagination_last_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå till sista sidan"])},
  "pagination_previous_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Föregående sida"])},
  "pagination_next_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nästa sida"])},
  "pagination_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["av"])},
  "common_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "common_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detaljer"])},
  "common_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beskrivning"])},
  "common_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
  "common_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapad"])},
  "common_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slutförd"])},
  "common_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tillbaka"])},
  "common_sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortera"])},
  "common_apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Använd"])},
  "common_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera"])},
  "common_yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
  "common_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nej"])},
  "common_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återställ"])},
  "leave_page_confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har ändringar som inte har sparats"])},
  "leave_page_confirmation_modal_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Är du säker på att du lämna den här sidan? Ändringar som inte har sparats kommer att förloras."])},
  "common_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa"])},
  "common_show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visa"])},
  "common_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])}
}