import util from '@/util';
import { JsonObject, JsonProperty } from 'json2typescript';
import BaseUserDetailsModel from './Base/BaseUserDetailsModel';
import UserApplicationTruncatedModel from './UserApplicationTruncatedModel';

@JsonObject('UserModel')
export default class UserModel extends BaseUserDetailsModel {
    public constructor() {
        super();

        this.id = '';
        this.email = '';
        this.name = '';
        this.firstName = '';
        this.lastName = '';
        this.electronicIdEnabled = false;
    }

    @JsonProperty('id', String)
    public id: string;

    @JsonProperty('email', String)
    public email: string;

    @JsonProperty('name', String)
    public name: string;

    @JsonProperty('first_name', String)
    public firstName: string;

    @JsonProperty('last_name', String)
    public lastName: string;

    @JsonProperty('country', String, true)
    public country?: string;

    @JsonProperty('language', String, true)
    public language?: string;

    @JsonProperty('login_date', String, true)
    public loginDate?: string;

    @JsonProperty('password', String, true)
    public password?: string;

    @JsonProperty('electronic_id_enabled', Boolean, true)
    public electronicIdEnabled: boolean;

    @JsonProperty('2step_enabled_date', String, true)
    public twoStepEnabledDate?: string;

    @JsonProperty('applications', [UserApplicationTruncatedModel], true)
    public applications?: UserApplicationTruncatedModel[] = [];

    public get displayCreatedDate(): string {
        if (!this.createdDate) {
            return '';
        }

        return util.formatDate(this.createdDate);
    }

    public get displayUpdatedDate(): string {
        if (!this.updatedDate) {
            return '';
        }

        return util.formatDate(this.updatedDate);
    }

    public get displayLoginDate(): string {
        if (!this.loginDate) {
            return '';
        }

        return util.formatDateTime(this.loginDate);
    }

    public get domain(): string {
        return this.email.slice(this.email.indexOf('@') + 1).toLowerCase();
    }

    public get displayApplicationsCount(): string {
        if (!this.applications) {
            return '0';
        }

        return this.applications.length.toString();
    }

    public get displayTwoStepEnabledDate(): string {
        if (!this.twoStepEnabledDate) {
            return '';
        }

        return util.formatDate(this.twoStepEnabledDate);
    }
}
