export default {
  "page_provisioning_no_sso_setup_alert_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single sign-on -asetukset"])},
  "page_provisioning_no_sso_setup_alert_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinun on otettava kertakirjautuminen (Single sign-on) käyttöön, jotta voit jatkaa SCIM-käyttöönoton määrittämistä."])},
  "page_provisioning_no_sso_setup_alert_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siirry Single sign-on -kirjautumiseen"])},
  "page_provisioning_scim_not_supported_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM:ää ei tueta"])},
  "page_provisioning_scim_not_supported_alert_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["System for Cross-domain Identity Management (SCIM) ", _interpolate(_named("idpName")), ":lle ei ole tuettu."])},
  "page_provisioning_scim_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Provisionnin avulla voit automatisoida yleisiä hallinnollisia tehtäviä. Ottamalla käyttöön System for Cross-domain Identity Managementin (SCIM), voit yhdistää Visman ", _interpolate(_named("idpName")), ":aan, jolloin voit:"])},
  "page_provisioning_scim_info_create_users_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luoda käyttäjiä ja ryhmiä"])},
  "page_provisioning_scim_info_grant_revoke_access_to_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Myöntää ja perua ryhmien käyttöoikeuksia"])},
  "page_provisioning_scim_info_edit_attributes_users_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokata käyttäjien ja ryhmien määritteitä"])},
  "page_provisioning_scim_info_suspend_deprovisioned_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskeyttää deprovisioidut käyttäjät"])},
  "page_provisioning_scim_cache_refresh_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huomaa, että jokaisen kokoonpanomuutoksen voimaantulo SCIM-palvelimella voi kestää jopa 2 minuuttia."])},
  "page_provisioning_scim_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM 2.0 -provisiointi"])},
  "page_provisioning_scim_last_sync_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viimeisin synkronointitapahtuma"])},
  "page_provisioning_scim_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei mitään"])},
  "page_provisioning_scim_endpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM-päätepiste"])},
  "page_provisioning_scim_bearer_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM-käyttöoikeusavain"])},
  "page_provisioning_scim_bearer_token_generated_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Luotu ", _interpolate(_named("date"))])},
  "page_provisioning_scim_bearer_token_expires_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Päättyy ", _interpolate(_named("date"))])},
  "page_provisioning_scim_bearer_token_btn_generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo SCIM-tunnus"])},
  "page_provisioning_scim_modal_disable_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista SCIM 2.0 -provisiointi käytöstä"])},
  "page_provisioning_scim_modal_disable_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko varmasti poistaa SCIM:n käytöstä?"])},
  "page_provisioning_scim_modal_disable_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista käytöstä"])},
  "page_provisioning_scim_refresh_token_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä SCIM-tunnus"])},
  "page_provisioning_scim_modal_refresh_token_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko varmasti päivittää SCIM-tunnuksen?"])},
  "page_provisioning_scim_modal_refresh_token_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä"])},
  "page_provisioning_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provisiointitoiminnot"])},
  "page_provisioning_action_user_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo käyttäjiä"])},
  "page_provisioning_action_user_read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lue käyttäjiä"])},
  "page_provisioning_action_user_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä käyttäjiä"])},
  "page_provisioning_action_user_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista käyttäjiä"])},
  "page_provisioning_action_group_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luo ryhmiä"])},
  "page_provisioning_action_group_read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lue ryhmät"])},
  "page_provisioning_action_group_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä ryhmiä"])},
  "page_provisioning_action_group_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista ryhmiä"])},
  "page_provisioning_action_user_update_triggers": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kun käyttäjät on poistettu käytöstä ", _interpolate(_named("idpName")), "sta, suorita tämä toiminto kohdassa ", _interpolate(_named("tenantOwnerName"))])},
  "page_provisioning_action_user_update_trigger_suspend_useraccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskeytä käyttäjä"])},
  "page_provisioning_action_user_update_trigger_delete_from_tenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista käyttäjä"])},
  "page_provisioning_action_user_update_trigger_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista käyttäjä"])},
  "page_provisioning_action_user_delete_triggers": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kun käyttäjät on poistettu ", _interpolate(_named("idpName")), "sta, suorita tämä toiminto kohdassa ", _interpolate(_named("tenantOwnerName"))])},
  "page_provisioning_action_user_delete_trigger_suspend_useraccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskeytä käyttäjä"])},
  "page_provisioning_action_user_delete_trigger_delete_from_tenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista käyttäjä"])},
  "page_provisioning_scim_groups_alert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("tenantOwnerName")), " ei tue Luo/Lue/Päivitä/Poista ryhmiä -toimintoja. Voit kuitenkin määrittää ryhmiä käytäntöihin."])},
  "page_provisioning_scim_unsupported_actions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("tenantOwnerName")), " ei tue SCIM-synkronointia kohteisiin ", _interpolate(_named("unsupportedActions")), "."])},
  "page_provisioning_scim_unsupported_actions_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muutokset näkyvät vain Visman tunnusjärjestelmässä."])},
  "page_provisioning_token_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM-tunnus"])},
  "page_provisioning_token_modal_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopioi tämä salainen tunnus SCIM-toimittajallesi."])},
  "page_provisioning_token_modal_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunnus näytetään vain kerran, ja se vanhenee vuodessa."])},
  "page_provisioning_action_trigger_no_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei toimenpiteitä"])},
  "page_provisioning_btn_view_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä tilaukset lokeissa"])},
  "page_provisioning_logs_btn_view_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä asetukset"])},
  "page_provisioning_logs_btn_refresh_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä lokit"])},
  "page_provisioning_logs_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varaukset lokeista"])},
  "page_provisioning_logs_no_logs_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM-pyyntöjä ei ole vielä tehty tälle asetukselle."])},
  "page_provisioning_logs_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuntematon virhe on tapahtunut haettaessa SCIM lokia."])},
  "page_provisioning_logs_item_request_query_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyselyn merkkijono"])},
  "page_provisioning_logs_item_request_json_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pyynnön runko"])},
  "page_provisioning_logs_item_response_status_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vastauksen tilakoodi"])},
  "page_provisioning_logs_item_view_json": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä JSON"])},
  "page_provisioning_logs_item_no_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<no input>"])},
  "page_provisioning_logs_item_no_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<missing info>"])}
}