import Saml2IdentityProviderOutputModel from '@/api/saml2-idp-client/models/Saml2IdentityProviderOutputModel';
import { Saml2IdentityProviderStoreState } from './state';

export default {
    setSaml2IdentityProvider(state: Saml2IdentityProviderStoreState, model: Saml2IdentityProviderOutputModel): void {
        state.saml2IdentityProvider = model;
    },

    setProcessingState(state: Saml2IdentityProviderStoreState, isProcessing: boolean): void {
        state.isProcessing = isProcessing;
    },

    setUnauthorizedToViewSaml2Idp(state: Saml2IdentityProviderStoreState): void {
        state.isUnauthorizedToViewSaml2Idp = true;
    },
};
