import OidcIdentityProviderOutputModel from '@/api/oidc-idp-client/models/OidcIdentityProviderOutputModel';
import { OidcIdentityProviderStoreState } from './state';

export default {
    oidcIdentityProvider(state: OidcIdentityProviderStoreState): OidcIdentityProviderOutputModel | undefined {
        return state.oidcIdentityProvider;
    },

    isUnauthorizedToViewOidcIdp(state: OidcIdentityProviderStoreState): boolean {
        return state.isUnauthorizedToViewOidcIdp;
    },

    isStateLoading(state: OidcIdentityProviderStoreState): boolean {
        return state.isProcessing;
    },
};
