import { JsonObject, JsonProperty } from 'json2typescript';
import util from '@/util';

@JsonObject('AuditModel')
export default class AuditModel {
    public constructor() {
        this.id = '';
        this.event = '';
        this.createdDate = '';
        this.clientIdentity = '';
        this.clientName = '';
    }

    @JsonProperty('id', String)
    public id: string;

    @JsonProperty('event', String)
    public event: string;

    @JsonProperty('timestamp', String)
    public createdDate: string;

    @JsonProperty('ipv4_address', String, true)
    public ipv4Address?: string;

    @JsonProperty('application_id', String)
    public clientIdentity: string;

    @JsonProperty('application_name', String)
    public clientName: string;

    @JsonProperty('payload', String, true)
    public payload?: string;

    public get displayCreatedDate(): string {
        return util.formatDateTime(this.createdDate);
    }
}
