import { computed } from 'vue';
import { notificationStore } from '@/store';
import NotificationStoreModel from '@/store/notification-store/models/NotificationStoreModel';

function setupNotificationsOverlay() {
    const notifications = computed(() => {
        return notificationStore.storeApi.get(notificationStore.getters.getNotifications);
    });

    function getIndex(id: string): number {
        return notifications.value.findIndex((notification: NotificationStoreModel) => notification.id === id);
    }

    function closeNotification(id: string): void {
        notificationStore.storeApi.dispatch(notificationStore.actions.deleteNotification, id);
    }

    return {
        // Computed
        notifications,

        // Functions
        getIndex,
        closeNotification,
    };
}

export { setupNotificationsOverlay };
