import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  id: "app-body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_init_splash = _resolveComponent("app-init-splash")!
  const _component_notifications_overlay = _resolveComponent("notifications-overlay")!
  const _component_session_expired_modal = _resolveComponent("session-expired-modal")!
  const _component_leave_page_confirmation_modal = _resolveComponent("leave-page-confirmation-modal")!
  const _component_app_navbar = _resolveComponent("app-navbar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isInitializing)
      ? (_openBlock(), _createBlock(_component_app_init_splash, {
          key: 0,
          id: "init-splash"
        }))
      : _createCommentVNode("", true),
    (!_ctx.isInitializing)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_notifications_overlay, { id: "notifications-overlay" }),
          (!_ctx.isInitializing && _ctx.isSessionExpired)
            ? (_openBlock(), _createBlock(_component_session_expired_modal, { key: 0 }))
            : _createCommentVNode("", true),
          _createVNode(_component_leave_page_confirmation_modal, { show: _ctx.showLeavePageConfirmationModal }, null, 8, ["show"]),
          (!_ctx.isPageNotFound)
            ? (_openBlock(), _createBlock(_component_app_navbar, {
                key: 1,
                ref: "appNavbar"
              }, null, 512))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(["container h-100", _ctx.containerCss]),
            id: "app-content",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onAppContentClicked && _ctx.onAppContentClicked(...args)))
          }, [
            _createVNode(_component_router_view)
          ], 2)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}