import { JsonObject, JsonProperty } from 'json2typescript';
import AuthenticationPolicyInputModel from './AuthenticationPolicyInputModel';

@JsonObject('AuthenticationPolicyModel')
export default class AuthenticationPolicyModel extends AuthenticationPolicyInputModel {
    public constructor() {
        super();
        this.id = '';
        this.createdDate = '';
        this.updatedDate = '';
    }

    @JsonProperty('id', String)
    public id: string;

    @JsonProperty('created_date', String)
    public createdDate: string;

    @JsonProperty('updated_date', String)
    public updatedDate: string;
}
