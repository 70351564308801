import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = {
  class: "navbar navbar-secondary navbar-first-level",
  id: "nc4navTopMain",
  role: "navigation"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = ["title"]
const _hoisted_4 = ["title"]
const _hoisted_5 = { class: "modal-body-content" }
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = { class: "form-group d-flex align-items-center" }
const _hoisted_8 = ["placeholder", "title"]
const _hoisted_9 = {
  key: 0,
  class: "dropdown-menu",
  role: "menu",
  "aria-expanded": "false",
  "aria-hidden": "true",
  "aria-label": "Dropdown options"
}
const _hoisted_10 = ["title"]
const _hoisted_11 = ["href"]
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = {
  key: 1,
  class: "dropdown-menu",
  role: "menu",
  "aria-expanded": "false",
  "aria-hidden": "true",
  "aria-label": "Dropdown options"
}
const _hoisted_15 = {
  href: "#",
  class: "text-disabled",
  "aria-disabled": "true",
  disabled: ""
}
const _hoisted_16 = { class: "text-disabled" }
const _hoisted_17 = ["title"]
const _hoisted_18 = {
  readonly: "",
  class: "col-4"
}
const _hoisted_19 = ["title"]
const _hoisted_20 = {
  readonly: "",
  class: "col-4"
}
const _hoisted_21 = ["title"]
const _hoisted_22 = ["href"]
const _hoisted_23 = ["href"]
const _hoisted_24 = {
  key: 0,
  class: "vertical-nav"
}
const _hoisted_25 = { class: "nav" }
const _hoisted_26 = { class: "show-nav" }
const _hoisted_27 = {
  class: "collapse navbar-collapse navbar-mobile",
  "aria-label": "Menu"
}
const _hoisted_28 = {
  class: "nav navbar-nav nav-tabs first-level",
  role: "menubar",
  "aria-label": "Menu"
}
const _hoisted_29 = { id: "toggle-hamburger-item" }
const _hoisted_30 = {
  class: "nav navbar-nav nav-tabs first-level",
  role: "menubar",
  "aria-label": "Menu"
}
const _hoisted_31 = { id: "navbar-collapsed-title" }
const _hoisted_32 = { class: "text-blue" }
const _hoisted_33 = { class: "nav navbar-nav nav-tabs navbar-right first-level context-selector" }
const _hoisted_34 = ["title"]
const _hoisted_35 = ["title"]
const _hoisted_36 = { class: "navbar-header" }
const _hoisted_37 = { class: "navbar-brand" }
const _hoisted_38 = {
  class: "collapse navbar-collapse",
  "aria-label": "Menu"
}
const _hoisted_39 = {
  class: "nav navbar-nav nav-tabs first-level",
  role: "menubar",
  "aria-label": "Menu"
}
const _hoisted_40 = { class: "nav navbar-nav nav-tabs navbar-right first-level context-selector" }
const _hoisted_41 = ["title"]
const _hoisted_42 = ["title"]
const _hoisted_43 = { class: "selectedContext" }
const _hoisted_44 = {
  class: "user-details-area clear row",
  role: "menuitem"
}
const _hoisted_45 = ["src"]
const _hoisted_46 = { class: "user-text col" }
const _hoisted_47 = ["title"]
const _hoisted_48 = ["title"]
const _hoisted_49 = { disabled: "" }
const _hoisted_50 = {
  role: "menuitem",
  id: "tenant-selector"
}
const _hoisted_51 = { class: "form-group d-flex align-items-center" }
const _hoisted_52 = ["placeholder", "title"]
const _hoisted_53 = {
  key: 0,
  class: "dropdown-menu tenants-dropdown-menu",
  role: "menu",
  "aria-expanded": "false",
  "aria-hidden": "true",
  "aria-label": "Dropdown options"
}
const _hoisted_54 = ["title"]
const _hoisted_55 = ["href"]
const _hoisted_56 = { key: 0 }
const _hoisted_57 = { key: 1 }
const _hoisted_58 = {
  key: 1,
  class: "dropdown-menu",
  role: "menu",
  "aria-expanded": "false",
  "aria-hidden": "true",
  "aria-label": "Dropdown options"
}
const _hoisted_59 = {
  href: "#",
  class: "text-disabled",
  "aria-disabled": "true",
  disabled: ""
}
const _hoisted_60 = { class: "text-disabled" }
const _hoisted_61 = { class: "form-group mb-6" }
const _hoisted_62 = ["value", "title"]
const _hoisted_63 = { class: "form-group mb-6" }
const _hoisted_64 = {
  class: "control-label",
  for: "orgName"
}
const _hoisted_65 = ["value", "title"]
const _hoisted_66 = { class: "form-group mb-6" }
const _hoisted_67 = {
  class: "control-label",
  for: "orgNumber"
}
const _hoisted_68 = ["value", "title"]
const _hoisted_69 = { role: "menuitem" }
const _hoisted_70 = ["href"]
const _hoisted_71 = { role: "menuitem" }
const _hoisted_72 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_dialog = _resolveComponent("modal-dialog")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_navbar_jobs_item = _resolveComponent("navbar-jobs-item")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    (_ctx.isScreenMaxXL)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_modal_dialog, {
            id: "signin-info-modal",
            size: "sm",
            show: _ctx.showSigninInformation,
            onClose: _cache[9] || (_cache[9] = ($event: any) => (_ctx.hideSignInInformationMenu()))
          }, {
            title: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["container-fluid", { 'pl-0': !_ctx.isScreenMaxSm }])
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["row", _ctx.isScreenMaxSm ? 'justify-content-center' : 'justify-content-start'])
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.isScreenMaxSm ? 'profile-picture-sm' : 'profile-picture')
                  }, [
                    _createElementVNode("img", {
                      class: "rounded-circle",
                      src: _ctx.user.profilePicture,
                      alt: "rounded-circle"
                    }, null, 8, _hoisted_2)
                  ], 2),
                  _createElementVNode("div", {
                    class: _normalizeClass(["user-details-header", { 'text-center': _ctx.isScreenMaxXs }])
                  }, [
                    _createElementVNode("span", {
                      class: "d-block",
                      title: _ctx.userFullName
                    }, _toDisplayString(_ctx.userFullName), 9, _hoisted_3),
                    _createElementVNode("span", {
                      class: "d-block text-disabled",
                      title: _ctx.user.email
                    }, _toDisplayString(_ctx.user.email), 9, _hoisted_4)
                  ], 2)
                ], 2)
              ], 2)
            ]),
            body: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                (_ctx.hasOtherTenants)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("div", {
                        class: "divider",
                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hideTenantsList && _ctx.hideTenantsList(...args)))
                      }),
                      _createElementVNode("span", {
                        class: "d-block change-company-text",
                        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.hideTenantsList && _ctx.hideTenantsList(...args)))
                      }, _toDisplayString(_ctx.$t('nav_menu_change_company')), 1),
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("div", {
                            class: _normalizeClass(["btn-group w-100 align-items-center border", { open: _ctx.showTenantsSwitchList }])
                          }, [
                            _withDirectives(_createElementVNode("input", {
                              type: "text",
                              class: "border-0 w-100",
                              id: "nav-modal-btn-change-tenant-dropdown",
                              "data-toggle": "dropdown",
                              "aria-haspopup": "true",
                              "aria-expanded": "false",
                              ref: "tenantSearchInputRef",
                              placeholder: _ctx.$t('nav_menu_tenant_search_placeholder'),
                              title: _ctx.currentTenant.organizationName,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.tenantSearchInput) = $event)),
                              onKeydown: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.showTenantsList && _ctx.showTenantsList(...args))),
                              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.toggleTenantsList && _ctx.toggleTenantsList(...args)))
                            }, null, 40, _hoisted_8), [
                              [_vModelText, _ctx.tenantSearchInput]
                            ]),
                            _createElementVNode("span", {
                              class: "caret",
                              onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.toggleTenantsList && _ctx.toggleTenantsList(...args)))
                            }),
                            (_ctx.otherTenants.length > 0)
                              ? (_openBlock(), _createElementBlock("ul", _hoisted_9, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.otherTenants, (tenant) => {
                                    return (_openBlock(), _createElementBlock("li", {
                                      key: 'modal-' + tenant.id,
                                      title: tenant.organizationName
                                    }, [
                                      _createElementVNode("a", {
                                        class: "tenant-list-item",
                                        href: _ctx.computeTenantContextLink(tenant.id)
                                      }, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedMarkedTenantNameParts(tenant.organizationName), (organizationNamePart, organizationNamePartIndex) => {
                                          return (_openBlock(), _createElementBlock(_Fragment, {
                                            key: 'modal-' + tenant.id + organizationNamePart
                                          }, [
                                            (organizationNamePartIndex != 1)
                                              ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(organizationNamePart), 1))
                                              : (_openBlock(), _createElementBlock("span", _hoisted_13, [
                                                  _createElementVNode("mark", null, _toDisplayString(organizationNamePart), 1)
                                                ]))
                                          ], 64))
                                        }), 128))
                                      ], 8, _hoisted_11)
                                    ], 8, _hoisted_10))
                                  }), 128))
                                ]))
                              : (_openBlock(), _createElementBlock("ul", _hoisted_14, [
                                  _createElementVNode("li", null, [
                                    _createElementVNode("a", _hoisted_15, [
                                      _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t('nav_menu_tenant_search_no_items_found_placeholder')), 1)
                                    ])
                                  ])
                                ]))
                          ], 2)
                        ])
                      ])
                    ], 64))
                  : _createCommentVNode("", true),
                _cache[26] || (_cache[26] = _createElementVNode("div", { class: "divider" }, null, -1)),
                _createElementVNode("div", {
                  class: "row justify-content-between",
                  onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.hideTenantsList && _ctx.hideTenantsList(...args)))
                }, [
                  _cache[23] || (_cache[23] = _createElementVNode("span", {
                    readonly: "",
                    class: "col-4"
                  }, "Tenant ID", -1)),
                  _createElementVNode("span", {
                    readonly: "",
                    class: "col",
                    title: _ctx.currentTenant.id
                  }, _toDisplayString(_ctx.currentTenant.id), 9, _hoisted_17)
                ]),
                _createElementVNode("div", {
                  class: "row justify-content-between",
                  onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.hideTenantsList && _ctx.hideTenantsList(...args)))
                }, [
                  _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.$t('nav_menu_tenant_org_name')), 1),
                  _createElementVNode("span", {
                    readonly: "",
                    class: "col",
                    title: _ctx.currentTenant.organizationName
                  }, _toDisplayString(_ctx.currentTenant.organizationName), 9, _hoisted_19)
                ]),
                _createElementVNode("div", {
                  class: "row justify-content-between",
                  onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.hideTenantsList && _ctx.hideTenantsList(...args)))
                }, [
                  _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.$t('nav_menu_tenant_org_number')), 1),
                  _createElementVNode("span", {
                    readonly: "",
                    class: "col",
                    title: _ctx.currentTenant.organizationNumber
                  }, _toDisplayString(_ctx.currentTenant.organizationNumber), 9, _hoisted_21)
                ]),
                _cache[27] || (_cache[27] = _createElementVNode("div", { class: "divider" }, null, -1)),
                _createElementVNode("div", null, [
                  _createElementVNode("a", {
                    class: "text-default",
                    id: "nav-modal-btn-accsett",
                    href: _ctx.accountSettingsUrl,
                    target: "_blank",
                    rel: "noreferrer"
                  }, [
                    _cache[24] || (_cache[24] = _createElementVNode("span", { class: "vismaicon vismaicon-user_settings" }, null, -1)),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('nav_menu_account_settings')), 1)
                  ], 8, _hoisted_22)
                ]),
                _cache[28] || (_cache[28] = _createElementVNode("div", { class: "divider" }, null, -1)),
                _createElementVNode("div", null, [
                  _createElementVNode("a", {
                    class: "text-default",
                    id: "nav-modal-btn-logout",
                    href: _ctx.logoutUrl
                  }, [
                    _cache[25] || (_cache[25] = _createElementVNode("span", { class: "vismaicon vismaicon-logout" }, null, -1)),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('nav_menu_logout')), 1)
                  ], 8, _hoisted_23)
                ])
              ])
            ]),
            _: 1
          }, 8, ["show"]),
          (_ctx.showHamburgerMenu)
            ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                _createElementVNode("ul", _hoisted_25, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.featureRoutes, (featureRoute) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: 'hamburger-' + featureRoute.name!.toString(),
                      class: _normalizeClass({ active: _ctx.isActiveRoute(featureRoute.name) }),
                      onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.toggleHamburgerMenu && _ctx.toggleHamburgerMenu(...args)))
                    }, [
                      _createVNode(_component_router_link, {
                        class: _normalizeClass('nav-burger-location-' + featureRoute.name!.toString()),
                        to: featureRoute.path
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.getRouteTitleTranslation(featureRoute.name)), 1)
                        ]),
                        _: 2
                      }, 1032, ["class", "to"])
                    ], 2))
                  }), 128)),
                  _createElementVNode("li", _hoisted_26, [
                    _createElementVNode("a", {
                      id: "nav-burger-btn-hide",
                      onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.hideHamburgerMenu && _ctx.hideHamburgerMenu(...args)))
                    })
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("nav", _hoisted_27, [
            _createElementVNode("ul", _hoisted_28, [
              _createElementVNode("li", _hoisted_29, [
                _createElementVNode("a", {
                  id: "btn-toggle-hamburger",
                  onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.toggleHamburgerMenu && _ctx.toggleHamburgerMenu(...args)))
                }, [
                  _createElementVNode("span", {
                    class: _normalizeClass(["vismaicon vismaicon-burger", { open: _ctx.showHamburgerMenu }]),
                    id: "nav-burger-btn-toggle"
                  }, null, 2)
                ])
              ])
            ]),
            _createElementVNode("ul", _hoisted_30, [
              _createElementVNode("li", _hoisted_31, [
                _createVNode(_component_router_link, { to: "/" }, {
                  default: _withCtx(() => [
                    _createElementVNode("strong", _hoisted_32, _toDisplayString(_ctx.title), 1)
                  ]),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("ul", _hoisted_33, [
              _createElementVNode("li", {
                class: "icon nav-btn-help",
                onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.openHelpPage()))
              }, [
                _createElementVNode("a", {
                  title: _ctx.$t('nav_menu_help'),
                  role: "button"
                }, _cache[29] || (_cache[29] = [
                  _createElementVNode("span", {
                    class: "vismaicon vismaicon-menu vismaicon-help",
                    "aria-hidden": "true"
                  }, null, -1)
                ]), 8, _hoisted_34)
              ]),
              _createElementVNode("li", {
                class: "icon mr-4",
                onClick: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.toggleShowSigninInformation && _ctx.toggleShowSigninInformation(...args)))
              }, [
                _createElementVNode("a", {
                  id: "user-menu-btn",
                  title: _ctx.userFullName,
                  role: "button"
                }, _cache[30] || (_cache[30] = [
                  _createElementVNode("span", { class: "vismaicon vismaicon-user-placeholder" }, null, -1)
                ]), 8, _hoisted_35)
              ])
            ])
          ])
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_36, [
            _createElementVNode("div", _hoisted_37, [
              _createVNode(_component_router_link, { to: "/" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.title), 1)
                ]),
                _: 1
              })
            ])
          ]),
          _createElementVNode("nav", _hoisted_38, [
            _createElementVNode("ul", _hoisted_39, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.featureRoutes, (featureRoute) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: featureRoute.name,
                  class: _normalizeClass({ active: _ctx.isActiveRoute(featureRoute.name) })
                }, [
                  _createVNode(_component_router_link, {
                    class: _normalizeClass('nav-location-' + featureRoute.name!.toString()),
                    to: featureRoute.path
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.getRouteTitleTranslation(featureRoute.name)), 1)
                    ]),
                    _: 2
                  }, 1032, ["class", "to"])
                ], 2))
              }), 128))
            ]),
            _createElementVNode("ul", _hoisted_40, [
              _createElementVNode("li", {
                class: "icon nav-btn-help",
                onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.openHelpPage()))
              }, [
                _createElementVNode("a", {
                  title: _ctx.$t('nav_menu_help'),
                  role: "button"
                }, _cache[31] || (_cache[31] = [
                  _createElementVNode("span", {
                    class: "vismaicon vismaicon-menu vismaicon-help",
                    "aria-hidden": "true"
                  }, null, -1)
                ]), 8, _hoisted_41)
              ]),
              _createVNode(_component_navbar_jobs_item),
              _withDirectives((_openBlock(), _createElementBlock("li", {
                class: _normalizeClass(["dropdown user-dropdown pristine icon", { open: _ctx.showSigninInformation }]),
                title: _ctx.$t('nav_menu_profile_title')
              }, [
                _createElementVNode("a", {
                  id: "user-menu-dropdown",
                  class: "dropdown-toggle button-context",
                  "data-toggle": "dropdown",
                  role: "button",
                  "aria-expanded": "true",
                  onClick: _cache[16] || (_cache[16] = 
//@ts-ignore
(...args) => (_ctx.toggleShowSigninInformation && _ctx.toggleShowSigninInformation(...args)))
                }, [
                  _createTextVNode(_toDisplayString(_ctx.userFullName) + " ", 1),
                  _cache[32] || (_cache[32] = _createElementVNode("span", { class: "vismaicon vismaicon-menu vismaicon-user" }, null, -1)),
                  _createElementVNode("small", _hoisted_43, _toDisplayString(_ctx.currentTenant.organizationName), 1),
                  _cache[33] || (_cache[33] = _createElementVNode("span", { class: "caret" }, null, -1))
                ]),
                _createElementVNode("ul", {
                  class: _normalizeClass(["dropdown-menu", { 'company-selection': _ctx.isScreenMaxXXL }]),
                  role: "menu"
                }, [
                  _createElementVNode("li", _hoisted_44, [
                    _createElementVNode("div", null, [
                      _createElementVNode("img", {
                        class: "rounded-circle",
                        src: _ctx.user.profilePicture,
                        alt: "rounded-circle"
                      }, null, 8, _hoisted_45),
                      _createElementVNode("div", _hoisted_46, [
                        _createElementVNode("span", { title: _ctx.userFullName }, _toDisplayString(_ctx.userFullName), 9, _hoisted_47),
                        _createElementVNode("span", {
                          class: "text-disabled",
                          title: _ctx.user.email
                        }, _toDisplayString(_ctx.user.email), 9, _hoisted_48)
                      ])
                    ])
                  ]),
                  (_ctx.hasOtherTenants)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _cache[34] || (_cache[34] = _createElementVNode("li", { class: "divider" }, null, -1)),
                        _createElementVNode("li", {
                          role: "menuitem",
                          id: "tenant-selector-label",
                          onClick: _cache[17] || (_cache[17] = 
//@ts-ignore
(...args) => (_ctx.hideTenantsList && _ctx.hideTenantsList(...args)))
                        }, [
                          _createElementVNode("a", _hoisted_49, _toDisplayString(_ctx.$t('nav_menu_change_company')), 1)
                        ]),
                        _createElementVNode("li", _hoisted_50, [
                          _createElementVNode("div", _hoisted_51, [
                            _createElementVNode("div", {
                              class: _normalizeClass(["btn-group w-100 align-items-center border", { open: _ctx.showTenantsSwitchList }])
                            }, [
                              _withDirectives(_createElementVNode("input", {
                                type: "text",
                                class: "border-0",
                                id: "nav-btn-change-tenant-dropdown",
                                "data-toggle": "dropdown",
                                "aria-haspopup": "true",
                                "aria-expanded": "false",
                                ref: "tenantSearchInputRef",
                                placeholder: _ctx.$t('nav_menu_tenant_search_placeholder'),
                                title: _ctx.currentTenant.organizationName,
                                "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.tenantSearchInput) = $event)),
                                onKeydown: _cache[19] || (_cache[19] = 
//@ts-ignore
(...args) => (_ctx.showTenantsList && _ctx.showTenantsList(...args))),
                                onClick: _cache[20] || (_cache[20] = 
//@ts-ignore
(...args) => (_ctx.toggleTenantsList && _ctx.toggleTenantsList(...args)))
                              }, null, 40, _hoisted_52), [
                                [_vModelText, _ctx.tenantSearchInput]
                              ]),
                              _createElementVNode("span", {
                                class: "caret",
                                onClick: _cache[21] || (_cache[21] = 
//@ts-ignore
(...args) => (_ctx.toggleTenantsList && _ctx.toggleTenantsList(...args)))
                              }),
                              (_ctx.otherTenants.length > 0)
                                ? (_openBlock(), _createElementBlock("ul", _hoisted_53, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.otherTenants, (tenant) => {
                                      return (_openBlock(), _createElementBlock("li", {
                                        key: tenant.id,
                                        title: tenant.organizationName
                                      }, [
                                        _createElementVNode("a", {
                                          class: "tenant-list-item",
                                          href: _ctx.computeTenantContextLink(tenant.id)
                                        }, [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedMarkedTenantNameParts(tenant.organizationName), (organizationNamePart, organizationNamePartIndex) => {
                                            return (_openBlock(), _createElementBlock(_Fragment, {
                                              key: tenant.id + organizationNamePart
                                            }, [
                                              (organizationNamePartIndex != 1)
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_56, _toDisplayString(organizationNamePart), 1))
                                                : (_openBlock(), _createElementBlock("span", _hoisted_57, [
                                                    _createElementVNode("mark", null, _toDisplayString(organizationNamePart), 1)
                                                  ]))
                                            ], 64))
                                          }), 128))
                                        ], 8, _hoisted_55)
                                      ], 8, _hoisted_54))
                                    }), 128))
                                  ]))
                                : (_openBlock(), _createElementBlock("ul", _hoisted_58, [
                                    _createElementVNode("li", null, [
                                      _createElementVNode("a", _hoisted_59, [
                                        _createElementVNode("span", _hoisted_60, _toDisplayString(_ctx.$t('nav_menu_tenant_search_no_items_found_placeholder')), 1)
                                      ])
                                    ])
                                  ]))
                            ], 2)
                          ])
                        ])
                      ], 64))
                    : _createCommentVNode("", true),
                  _cache[38] || (_cache[38] = _createElementVNode("li", { class: "divider" }, null, -1)),
                  _createElementVNode("li", {
                    role: "menuitem",
                    onClick: _cache[22] || (_cache[22] = 
//@ts-ignore
(...args) => (_ctx.hideTenantsList && _ctx.hideTenantsList(...args)))
                  }, [
                    _createElementVNode("div", _hoisted_61, [
                      _cache[35] || (_cache[35] = _createElementVNode("label", {
                        class: "control-label",
                        for: "tenantId"
                      }, "Tenant ID", -1)),
                      _createElementVNode("input", {
                        readonly: "",
                        class: "text-right",
                        id: "tenantId",
                        value: _ctx.currentTenant.id,
                        title: _ctx.currentTenant.id
                      }, null, 8, _hoisted_62)
                    ]),
                    _createElementVNode("div", _hoisted_63, [
                      _createElementVNode("label", _hoisted_64, _toDisplayString(_ctx.$t('nav_menu_tenant_org_name')), 1),
                      _createElementVNode("input", {
                        readonly: "",
                        class: "text-right",
                        id: "orgName",
                        value: _ctx.currentTenant.organizationName,
                        title: _ctx.currentTenant.organizationName
                      }, null, 8, _hoisted_65)
                    ]),
                    _createElementVNode("div", _hoisted_66, [
                      _createElementVNode("label", _hoisted_67, _toDisplayString(_ctx.$t('nav_menu_tenant_org_number')), 1),
                      _createElementVNode("input", {
                        readonly: "",
                        class: "text-right",
                        id: "orgNumber",
                        value: _ctx.currentTenant.organizationNumber,
                        title: _ctx.currentTenant.organizationNumber
                      }, null, 8, _hoisted_68)
                    ])
                  ]),
                  _cache[39] || (_cache[39] = _createElementVNode("li", { class: "divider" }, null, -1)),
                  _createElementVNode("li", _hoisted_69, [
                    _createElementVNode("a", {
                      id: "nav-btn-accsett",
                      href: _ctx.accountSettingsUrl,
                      target: "_blank",
                      rel: "noreferrer"
                    }, [
                      _cache[36] || (_cache[36] = _createElementVNode("span", { class: "vismaicon vismaicon-user_settings" }, null, -1)),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('nav_menu_account_settings')), 1)
                    ], 8, _hoisted_70)
                  ]),
                  _cache[40] || (_cache[40] = _createElementVNode("li", { class: "divider" }, null, -1)),
                  _createElementVNode("li", _hoisted_71, [
                    _createElementVNode("a", {
                      id: "nav-btn-logout",
                      href: _ctx.logoutUrl
                    }, [
                      _cache[37] || (_cache[37] = _createElementVNode("span", { class: "vismaicon vismaicon-logout" }, null, -1)),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('nav_menu_logout')), 1)
                    ], 8, _hoisted_72)
                  ])
                ], 2)
              ], 10, _hoisted_42)), [
                [_directive_click_outside, _ctx.closeSigninInformation]
              ])
            ])
          ])
        ], 64))
  ]))
}