import { JsonConvert } from 'json2typescript';
import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';
import logger from '@/util/logger';
import ApiResponseInterceptor from './models/ApiResponseInterceptor';
import ApiResponse from './models/ApiResponse';
import config from '@/config';
import ApiRequestPayload from './models/ApiRequestPayload';
import translations from '@/locales/translations';
import notificationService from '@/services/notification-service';
import { StatusCodes } from 'http-status-codes';
import sessionHandler from './session-handler';

export class HttpClient {
    private readonly httpClient: AxiosInstance;
    private readonly jsonConverter: JsonConvert;

    private interceptors: ApiResponseInterceptor[] = [];

    constructor() {
        this.jsonConverter = new JsonConvert();
        this.jsonConverter.ignorePrimitiveChecks = false;

        this.httpClient = axios.create({
            baseURL: config().backendUrl,
            responseType: 'json',
            withCredentials: true,
            timeout: 30000,
            maxRedirects: 0,
        });
    }

    public addInterceptors(interceptors: ApiResponseInterceptor[]) {
        for (const interceptor of interceptors) {
            this.interceptors.push(interceptor);
        }
    }

    /**
     * Get a resource from the backend
     * @param requestPath API endpoint path
     * @param commit Commit action of a view store that gives acces to namespaced mutations
     * @param options HTTP Request configuration options
     */
    public getAsync(requestPath: string, options?: AxiosRequestConfig, ignoreInterceptors = false): Promise<ApiResponse> {
        return this.executeAsync(async () => this.httpClient.get(requestPath, options), ignoreInterceptors);
    }

    /**
     * Create a new resource in the backend
     * @param requestPath API endpoint path
     * @param data Data object that will be sent in as JSON in the HTTP Request Body
     * @param options HTTP Request configuration options
     */
    public postAsync<T extends object>(requestPath: string, data?: ApiRequestPayload<T>, options?: AxiosRequestConfig, ignoreInterceptors = false): Promise<ApiResponse> {
        const serializedData = data?.serialize(this.jsonConverter);
        return this.executeAsync(async () => this.httpClient.post(requestPath, serializedData, options), ignoreInterceptors);
    }

    /**
     * Replace a resource from the backend
     * @param requestPath API endpoint path
     * @param data Data object that will be sent in as JSON in the HTTP Request Body
     * @param options HTTP Request configuration options
     */
    public putAsync<T extends object>(requestPath: string, data?: ApiRequestPayload<T>, options?: AxiosRequestConfig, ignoreInterceptors = false): Promise<ApiResponse> {
        const serializedData = data?.serialize(this.jsonConverter);
        return this.executeAsync(async () => this.httpClient.put(requestPath, serializedData, options), ignoreInterceptors);
    }

    /**
     * Update a resource from the backend
     * @param requestPath API endpoint path
     * @param data Data object that will be sent in as JSON in the HTTP Request Body
     * @param options HTTP Request configuration options
     */
    public patchAsync<T extends object>(requestPath: string, data?: ApiRequestPayload<T>, options?: AxiosRequestConfig, ignoreInterceptors = false): Promise<ApiResponse> {
        const serializedData = data?.serialize(this.jsonConverter);
        return this.executeAsync(async () => this.httpClient.patch(requestPath, serializedData, options), ignoreInterceptors);
    }

    /**
     * Delete a resource from the backend
     * @param requestPath API endpoint path, containing target resource in route path
     * @param options HTTP Request configuration options
     */
    public deleteAsync(requestPath: string, options?: AxiosRequestConfig, ignoreInterceptors = false): Promise<ApiResponse> {
        return this.executeAsync(async () => this.httpClient.delete(requestPath, options), ignoreInterceptors);
    }

    /**
     * Upload a file as multipart form-data.
     * @param requestPath API endpoint path
     * @param file The physical file that will be uploaded
     * @param options HTTP Request configuration options
     */
    public uploadFileAsync(requestPath: string, file: File, fileParameterName?: string, options?: AxiosRequestConfig, ignoreInterceptors = false): Promise<ApiResponse> {
        // Make sure that we have the correct Content-Type set in the headers
        if (!options) {
            options = {
                headers: {},
            } as AxiosRequestConfig;
        } else if (!options.headers) {
            options.headers = {};
        }

        options.headers!['Content-Type'] = 'multipart/form-data';

        // Compute the input form to send-in the file
        const form = new FormData();
        if (fileParameterName) {
            form.append(fileParameterName, file, file.name);
        } else {
            form.append('file', file, file.name);
        }

        // Send the file upload request
        return this.executeAsync(async () => this.httpClient.post(requestPath, form, options), ignoreInterceptors);
    }

    private async executeAsync(httpRequest: () => Promise<AxiosResponse>, ignoreInterceptors: boolean): Promise<ApiResponse> {
        try {
            await sessionHandler.ensureAccessTokenValidityAsync();
            await sessionHandler.ensureAntiforgeryTokenValidityAsync(this.httpClient);

            const response = new ApiResponse(await httpRequest(), this.jsonConverter);

            return ignoreInterceptors ? response : await this.executeInterceptors(response);
        } catch (err) {
            if (ignoreInterceptors) {
                throw err;
            } else {
                const error = err as Error;

                // Check for 'timeout exceeded' errors
                if (error.message === 'Network Error' || (error.message.startsWith('timeout') && error.message.endsWith('exceeded'))) {
                    logger.log('Server is not responding', error);
                    notificationService.error(translations.NOTIF_ERROR_SERVER_UNAVAILABLE);
                    return Promise.reject(error);
                }

                // Check for antiforgery fetching errors
                if (error.message === 'Antiforgery') {
                    logger.log('Error fetching Antiforgery tokens from server', error);
                    notificationService.error(translations.NOTIF_ERROR_SERVER_UNAVAILABLE);
                    return Promise.reject(error);
                }

                // Return the error response
                const axiosError = err as AxiosError<Record<string, unknown>>;
                if (!axiosError?.response) {
                    logger.log(`Unkown error occurred in HTTP call at ${(axiosError.request as AxiosRequestConfig).url}`);
                    notificationService.error(translations.NOTIF_ERROR_SERVER_UNAVAILABLE);
                    return Promise.reject(axiosError);
                }

                const errorResponse = new ApiResponse(axiosError.response, this.jsonConverter);

                // If the AF cookie is missing, reload the AF Token + Cookie and replay the request
                if (errorResponse.statusCode === StatusCodes.BAD_REQUEST) {
                    try {
                        const afError = errorResponse.rawData ? JSON.stringify(errorResponse.rawData) : void 0;
                        if (afError && afError.indexOf('https://tools.ietf.org/html/rfc') !== -1) {
                            await sessionHandler.ensureAntiforgeryTokenValidityAsync(this.httpClient, true);
                            return await this.executeAsync(httpRequest, ignoreInterceptors);
                        }
                    } catch (retryErr) {
                        return await this.executeInterceptors(errorResponse, retryErr as Error);
                    }
                }

                return await this.executeInterceptors(errorResponse, error);
            }
        }
    }

    private async executeInterceptors(response: ApiResponse, err?: Error | unknown): Promise<ApiResponse> {
        for (const interceptor of this.interceptors) {
            if (!(await interceptor.intercept(response))) {
                logger.log(`Rejected response by interceptor: ${interceptor.name()}`);
                return Promise.reject(err);
            }
        }

        return Promise.resolve(response);
    }
}

export default new HttpClient();
