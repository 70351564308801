import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('SessionRestrictionsSettingsModel')
export default class SessionRestrictionsSettingsModel {
    public constructor() {
        this.defaultMaxConcurrentUserSessions = 20;
    }

    @JsonProperty('default_max_concurrent_user_sessions', Number, true)
    public defaultMaxConcurrentUserSessions: number;
}
