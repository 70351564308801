import mutations from './mutations';
import { BasicStoreAccessors, RootState } from '../types';
import { UserStoreState } from './state';
import userApiClient from '@/api/user-api-client';
import ApiClientError from '@/api/error';
import { ActionContext } from 'vuex';
import util from '@/util';
import UserInputModel from '@/api/user-api-client/models/UserInputModel';
import UserProfileModel from '@/api/user-api-client/models/UserProfileModel';
import SuspendUserInputModel from '@/api/user-api-client/models/SuspendUserInputModel';
import groupApiClient from '@/api/group-api-client';
import AddUserRecoveryEmailsInputModel from '@/api/user-api-client/models/AddUserRecoveryEmailsInputModel';

let storeApi: BasicStoreAccessors<UserStoreState>;

export function initActions(api: BasicStoreAccessors<UserStoreState>): void {
    storeApi = api;
}

export default {
    async getUsersAsync(context: ActionContext<UserStoreState, RootState>): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            const result = await userApiClient.getListAsync(context.state.userSearchInput);
            if (result) {
                storeApi.commit(mutations.setUserList, result);
            }
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewUsers);
                return;
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
            storeApi.commit(mutations.setDataLoaded, true);
        }
    },

    async getUserCountAsync(context: ActionContext<UserStoreState, RootState>, domains?: string[]): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            const result = await userApiClient.getUserCountByDomainsAsync(domains);
            if (result) {
                storeApi.commit(mutations.setUserCount, result.totalUsers);
            }
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewUsers);
                return;
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
            storeApi.commit(mutations.setDataLoaded, true);
        }
    },

    async debouncedGetUsersAsync(context: ActionContext<UserStoreState, RootState>): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);
            await debouncedApiGetUsersAsync(context);
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewUsers);
                return;
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
            storeApi.commit(mutations.setDataLoaded, true);
        }
    },

    async getUserProfileAsync(context: ActionContext<UserStoreState, RootState>, userId: string): Promise<void> {
        try {
            storeApi.commit(mutations.setDataLoaded, false);
            storeApi.commit(mutations.setProcessingState, true);

            const result = await userApiClient.getAsync(userId);
            if (result) {
                storeApi.commit(mutations.setUserProfile, result);
                storeApi.commit(mutations.setUserDetailsId, userId);
            }
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewUsers);
                return;
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
            storeApi.commit(mutations.setDataLoaded, true);
        }
    },

    async updateUserAsync(context: ActionContext<UserStoreState, RootState>, { userId, model }: { userId: string; model: UserProfileModel }): Promise<void> {
        try {
            const userInputModel = new UserInputModel();
            userInputModel.email = model.email;
            userInputModel.firstName = model.firstName;
            userInputModel.lastName = model.lastName;
            userInputModel.countryCode = model.countryCode;
            userInputModel.languageCode = model.languageCode;

            storeApi.commit(mutations.setProcessingState, true);
            await userApiClient.updateUserAsync(userId, userInputModel);

            const previousRecoveryEmails = context.state.userDetails.profile.recoveryEmails || [];
            if (model.recoveryEmails) {
                const recoveryEmailsToDelete = previousRecoveryEmails
                    .filter((previousRecoveryEmail) => model.recoveryEmails!.findIndex((recoveryEmail) => recoveryEmail.email === previousRecoveryEmail.email) === -1)
                    .map((e) => e.email);

                if (recoveryEmailsToDelete.length > 0) {
                    await userApiClient.deleteUserRecoveryEmailsAsync(userId, recoveryEmailsToDelete);
                }

                const recoveryEmailsToAdd = model.recoveryEmails
                    .filter((recoveryEmail) => previousRecoveryEmails.findIndex((previousRecoveryEmail) => previousRecoveryEmail.email === recoveryEmail.email) === -1)
                    .map((e) => e.email);

                if (recoveryEmailsToAdd.length > 0) {
                    await userApiClient.addRecoveryEmailAddressesAsync(userId, new AddUserRecoveryEmailsInputModel(recoveryEmailsToAdd));
                }
            }

            const previousPhones = context.state.userDetails.profile.phones;
            if (model.phones && previousPhones) {
                const phoneIdsToDelete = previousPhones.filter((previousPhone) => model.phones!.findIndex((phone) => phone.id === previousPhone.id) === -1).map((p) => p.id);

                if (phoneIdsToDelete.length > 0) {
                    await userApiClient.deleteUserPhonesAsync(userId, phoneIdsToDelete);
                }
            }

            const updatedUserProfile = await userApiClient.getAsync(userId);

            storeApi.commit(mutations.setUserProfile, updatedUserProfile);
            storeApi.commit(mutations.tempRefreshUserProfilePicture);
        } catch (err) {
            const apiError = err as ApiClientError;
            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewUsers);
                return;
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
        }
    },

    async updateUserProfilePictureAsync(context: ActionContext<UserStoreState, RootState>, { userId, file }: { userId: string; file: File }): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            await userApiClient.updateUserProfilePictureAsync(userId, file);
            storeApi.commit(mutations.tempRefreshUserProfilePicture);
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewUsers);
                return;
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
        }
    },

    async getUserApplicationsAsync(context: ActionContext<UserStoreState, RootState>, userId: string): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            const result = await userApiClient.getUserApplicationsAsync(userId);
            storeApi.commit(mutations.setUserApplications, result ?? []);
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewUsers);
                return;
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
        }
    },

    async getUserSessionsAsync(context: ActionContext<UserStoreState, RootState>, userId: string): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            const result = await userApiClient.getUserSessionsAsync(userId);
            storeApi.commit(mutations.setUserSessions, result ?? []);
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewUsers);
                return;
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
        }
    },

    async resetUserPassword(context: ActionContext<UserStoreState, RootState>, userId: string): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);
            await userApiClient.resetUserPasswordAsync(userId);
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewUsers);
                return;
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
        }
    },

    async unlockUserPasswordAsync(context: ActionContext<UserStoreState, RootState>, userId: string): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);
            await userApiClient.unlockUserPasswordAsync(userId);
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewUsers);
                return;
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
        }
    },

    async suspendUserAsync(context: ActionContext<UserStoreState, RootState>, { userId, model }: { userId: string; model: SuspendUserInputModel }): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);
            await userApiClient.suspendUserAsync(userId, model);
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewUsers);
                return;
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
        }
    },

    async resumeUserAsync(context: ActionContext<UserStoreState, RootState>, userId: string): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);
            await userApiClient.resumeUserAsync(userId);
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewUsers);
                return;
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
        }
    },

    async deleteUserSessionAsync(context: ActionContext<UserStoreState, RootState>, { userId, sessionId }: { userId: string; sessionId: string }): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);
            await userApiClient.deleteUserSessionAsync(userId, sessionId);
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewUsers);
                return;
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
        }
    },

    async getUserAuditAsync(context: ActionContext<UserStoreState, RootState>): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            const result = await userApiClient.getUserAuditAsync(context.state.userDetails.id!, context.state.userDetails.auditSearchInput);
            if (result) {
                storeApi.commit(mutations.setUserAudit, result);
            }
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewUsers);
                return;
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
            storeApi.commit(mutations.setDataLoaded, true);
        }
    },

    async debouncedGetUserAuditAsync(context: ActionContext<UserStoreState, RootState>): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);
            await debouncedApiGetUserAuditAsync(context);
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewUsers);
                return;
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
            storeApi.commit(mutations.setDataLoaded, true);
        }
    },

    async getAuditEventsAsync(): Promise<void> {
        try {
            const result = await userApiClient.getAuditEventsAsync();
            if (result) {
                storeApi.commit(mutations.setUserAuditSearchInputEvents, result);
            }
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewUsers);
                return;
            }

            throw err;
        }
    },

    async getGroupsAsync(context: ActionContext<UserStoreState, RootState>): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);
            await debouncedApiGetGroupsAsync(context);
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewUsers);
                return;
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
            storeApi.commit(mutations.setDataLoaded, true);
        }
    },
};

const debouncedApiGetUsersAsync = util.debounceAsync(async (context: ActionContext<UserStoreState, RootState>) => {
    const result = await userApiClient.getListAsync(context.state.userSearchInput);
    if (result) {
        storeApi.commit(mutations.setUserList, result);
    }
}, 500);

const debouncedApiGetUserAuditAsync = util.debounceAsync(async (context: ActionContext<UserStoreState, RootState>) => {
    const result = await userApiClient.getUserAuditAsync(context.state.userDetails.id!, context.state.userDetails.auditSearchInput);
    if (result) {
        storeApi.commit(mutations.setUserAudit, result);
    }
}, 500);

const debouncedApiGetGroupsAsync = util.debounceAsync(async (context: ActionContext<UserStoreState, RootState>) => {
    context.state.userDetails.groupSearchInputModel.member = context.state.userDetails.profile.email;
    const result = await groupApiClient.searchGroupsAsync(context.state.userDetails.groupSearchInputModel);
    if (result) {
        storeApi.commit(mutations.setUserGroups, result);
    }
}, 500);
