import GroupUserModel from '@/api/group-api-client/models/GroupUserModel';
import GroupUserSearchInputModel from '@/api/group-api-client/models/GroupUserSearchInputModel';
import UserNotMemberOfGroupModel from '@/api/user-api-client/models/UserNotMemberOfGroupModel';
import { GroupStoreState } from './state';
import GroupsModel from '@/api/group-api-client/models/GroupsModel';
import GroupSearchInputModel from '@/api/group-api-client/models/GroupSearchInputModel';

export default {
    groupsList(state: GroupStoreState): GroupsModel {
        return state.groupsList;
    },

    groupSearchInput(state: GroupStoreState): GroupSearchInputModel {
        return state.groupSearchInputModel;
    },

    isProcessing(state: GroupStoreState): boolean {
        return state.isProcessing;
    },

    isDataLoaded(state: GroupStoreState): boolean {
        return state.isDataLoaded;
    },

    isUnauthorizedToViewGroups(state: GroupStoreState): boolean {
        return state.isUnauthorizedToViewGroups;
    },

    isEditMode(state: GroupStoreState): boolean {
        return state.isEditMode;
    },

    userSearchModel(state: GroupStoreState): GroupUserSearchInputModel {
        return state.userSearchModel;
    },

    groupUsers(state: GroupStoreState): GroupUserModel[] {
        return state.groupUsersTotalsModel.groupUsers;
    },

    groupUserCount(state: GroupStoreState): number {
        return state.groupUsersTotalsModel.groupUserCount;
    },

    groupUserPages(state: GroupStoreState): number {
        return state.groupUsersTotalsModel.groupUserPages;
    },

    userAddSearchResult(state: GroupStoreState): UserNotMemberOfGroupModel[] {
        return state.userAddSearchResult;
    },
};
