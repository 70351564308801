export default {
  "nav_menu_logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kirjaudu ulos"])},
  "nav_menu_account_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilin asetukset"])},
  "nav_menu_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohje"])},
  "nav_menu_change_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaihda organisaatiota"])},
  "nav_menu_tenant_org_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yrityksen nimi"])},
  "nav_menu_tenant_org_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yritystunnus"])},
  "nav_menu_route_name_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kojelauta"])},
  "nav_menu_route_name_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkkotunnukset"])},
  "nav_menu_route_name_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjät"])},
  "nav_menu_route_name_provisioning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provisiointi"])},
  "nav_menu_route_name_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ryhmät"])},
  "nav_menu_route_name_policies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käytännöt"])},
  "nav_menu_tenant_search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haku yrityksen nimellä"])},
  "nav_menu_tenant_search_no_items_found_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisaatioita ei löytynyt"])},
  "nav_menu_profile_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiili"])},
  "nav_menu_jobs_change_domain_job_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muuta sähköpostin verkkotunnusta"])},
  "nav_menu_jobs_users_export_job_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjien vienti"])},
  "nav_menu_jobs_users_import_job_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjie tuonti"])},
  "nav_menu_jobs_user_audit_export_job_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjien tarkastuslokien vienti"])},
  "nav_menu_jobs_status_queued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jonossa"])},
  "nav_menu_jobs_status_processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käsittelyssä"])},
  "nav_menu_jobs_status_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmis"])},
  "nav_menu_jobs_status_completed_with_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmis, virheitä esiintyi"])},
  "nav_menu_jobs_status_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Epäonnistui"])},
  "nav_menu_jobs_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tila"])},
  "nav_menu_jobs_started_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aloitettu"])},
  "nav_menu_jobs_completed_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmis"])},
  "nav_menu_jobs_download_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lataa raportti"])},
  "nav_menu_jobs_no_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei toimintaa"])},
  "nav_menu_jobs_activities_cleared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yli 15 päivää vanhat aktiviteetit poistetaan tästä luettelosta"])},
  "nav_menu_jobs_created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tekijä ", _interpolate(_named("email"))])},
  "nav_menu_jobs_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taustatyöt"])}
}