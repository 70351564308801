import UserSearchInputModel from '@/api/user-api-client/models/UserSearchInputModel';
import UsersModel from '@/api/user-api-client/models/UsersModel';
import { UserStoreState } from './state';
import UserModel from '@/api/user-api-client/models/UserModel';
import SelectDomainsModel from '@/api/user-api-client/models/SelectedUsers/SelectDomainsModel';
import UserProfileModel from '@/api/user-api-client/models/UserProfileModel';
import UserDetailsStoreModel from './models/UserDetailsStoreModel';
import UserApplicationModel from '@/api/user-api-client/models/UserApplicationModel';
import UserApplicationsModel from './models/UserApplicationsModel';
import { UserApplicationSortColumn } from '@/api/user-api-client/enums/UserApplicationSortColumn';
import { SortOrder } from '@/api/user-api-client/enums/SortOrder';
import util from '@/util';
import { SortColumn } from '@/api/user-api-client/enums/SortColumn';
import UserSessionModel from '@/api/user-api-client/models/UserSessionModel';
import AuditLogsModel from '@/api/user-api-client/models/AuditLogsModel';
import AuditModel from '@/api/user-api-client/models/AuditModel';
import AuditEventModel from '@/api/user-api-client/models/AuditEventModel';
import AuditSearchInputModel from '@/api/user-api-client/models/AuditSearchInputModel';
import GroupsModel from '@/api/group-api-client/models/GroupsModel';

export default {
    setUserList(state: UserStoreState, model: UsersModel): void {
        state.userList = model;
    },

    setUserSearchInputPage(state: UserStoreState, page: number): void {
        state.userSearchInput.page = page;
    },

    setUserSearchInputSortColumn(state: UserStoreState, sortColumn: SortColumn): void {
        state.userSearchInput.sortColumn = sortColumn;
    },
    setUserSearchInputSearchTerm(state: UserStoreState, searchTerm: string): void {
        state.userSearchInput.emailOrName = searchTerm;
    },

    addDomainsToUserSearchInput(state: UserStoreState, domainNames: string[]): void {
        domainNames.forEach((domainName) => {
            if (state.userSearchInput.domainNames.indexOf(domainName) === -1) {
                state.userSearchInput.domainNames.push(domainName);
            }
        });
    },

    removeDomainFromUserSearchInput(state: UserStoreState, domainName: string): void {
        if (state.userSearchInput.domainNames.indexOf(domainName) >= 0) {
            state.userSearchInput.domainNames.splice(state.userSearchInput.domainNames.indexOf(domainName), 1);
        }
    },

    clearDomainsFromUserSearchInput(state: UserStoreState): void {
        state.userSearchInput.domainNames.splice(0, state.userSearchInput.domainNames.length);
    },

    resetUserSearchInput(state: UserStoreState): void {
        state.userSearchInput = new UserSearchInputModel();
    },

    setUserSearchInputSortOrder(state: UserStoreState, sortOrder: SortOrder): void {
        state.userSearchInput.sortOrder = sortOrder;
    },

    setUnauthorizedToViewUsers(state: UserStoreState): void {
        state.isUnauthorizedToViewUsers = true;
    },

    setProcessingState(state: UserStoreState, isProcessing: boolean): void {
        state.isProcessing = isProcessing;
    },

    setDataLoaded(state: UserStoreState, isDataLoaded: boolean): void {
        state.isDataLoaded = isDataLoaded;
    },

    selectDomains(state: UserStoreState, input: SelectDomainsModel): void {
        state.selectedUsers.selectDomains(input);
    },

    selectUsers(state: UserStoreState, users: UserModel[]): void {
        state.selectedUsers.selectUsers(users);
    },

    deselectUser(state: UserStoreState, user: UserModel): void {
        state.selectedUsers.deselectUser(user);
    },

    clearSelectedUsers(state: UserStoreState): void {
        state.selectedUsers.clearSelection();
    },

    setUserCount(state: UserStoreState, userCount: number): void {
        state.selectedUsers.updateUserCount(userCount);
    },

    resetUserDetails(state: UserStoreState): void {
        state.userDetails = new UserDetailsStoreModel();
    },

    setUserDetailsId(state: UserStoreState, userId: string): void {
        state.userDetails.id = userId;
    },

    setUserProfile(state: UserStoreState, userProfile: UserProfileModel): void {
        state.userDetails.profile = userProfile;
    },

    setUserApplications(state: UserStoreState, list: UserApplicationModel[]): void {
        state.userDetails.applications = new UserApplicationsModel(list);
    },

    setUserSessions(state: UserStoreState, sessions: UserSessionModel[]): void {
        state.userDetails.sessions = sessions;
    },

    setUserAudit(state: UserStoreState, model: AuditLogsModel): void {
        state.userDetails.audit = model;
    },

    setUserGroups(state: UserStoreState, model: GroupsModel): void {
        state.userDetails.groups = model;
    },

    setUserGroupSearchInputPage(state: UserStoreState, page: number): void {
        state.userDetails.groupSearchInputModel.page = page;
    },

    setUserGroupSearchInputGroupName(state: UserStoreState, groupName: string): void {
        state.userDetails.groupSearchInputModel.groupName = groupName;
    },

    setUserAuditSearchInputPage(state: UserStoreState, page: number): void {
        state.userDetails.auditSearchInput.page = page;
    },

    setUserAuditSearchInputEvents(state: UserStoreState, eventNames: string[]): void {
        state.userDetails.auditSearchInput.events = eventNames.map((eventName) => new AuditEventModel(eventName));
    },

    resetAuditSearchInput(state: UserStoreState): void {
        state.userDetails.auditSearchInput = new AuditSearchInputModel();
    },

    toggleAuditEvent(state: UserStoreState, eventName: string): void {
        const event = state.userDetails.auditSearchInput.events?.find((event) => event.name === eventName);
        if (event) {
            event.selected = !event.selected;
        }
    },

    deselectAllAuditEvents(state: UserStoreState): void {
        state.userDetails.auditSearchInput.events?.forEach((event) => {
            event.selected = false;
        });
    },

    selectAuditLogs(state: UserStoreState, auditLogs: AuditModel[]): void {
        state.userDetails.selectedAuditLogs = [...new Set(state.userDetails.selectedAuditLogs.concat(auditLogs.map((auditLog) => auditLog.id)))];
    },

    deselectAuditLog(state: UserStoreState, auditLog: AuditModel): void {
        if (state.userDetails.allAuditLogsSelected) {
            state.userDetails.excludedAuditLogs.push(auditLog.id);
        } else {
            state.userDetails.selectedAuditLogs.splice(state.userDetails.selectedAuditLogs.indexOf(auditLog.id), 1);
        }
    },

    selectAllAuditLogs(state: UserStoreState): void {
        state.userDetails.allAuditLogsSelected = true;
        state.userDetails.selectedAuditLogs = [];
        state.userDetails.excludedAuditLogs = [];
    },

    deselectAllAuditLogs(state: UserStoreState): void {
        state.userDetails.allAuditLogsSelected = false;
        state.userDetails.selectedAuditLogs = [];
        state.userDetails.excludedAuditLogs = [];
    },

    /**
     * Temporarily alter the profile picture updated date in order to force-refresh it on the current page.
     * This eliminates the need to re-read data from the backend on the current page.
     * @param state
     */
    tempRefreshUserProfilePicture(state: UserStoreState): void {
        state.userDetails.profile.profilePictureUpdatedDate = util.formatDateTimeUtc(new Date().toISOString());
    },

    setUserApplicationsSorting(state: UserStoreState, { newSortColumn, newSortOrder }: { newSortColumn: UserApplicationSortColumn; newSortOrder?: SortOrder }): void {
        if (!state.userDetails.applications) {
            return;
        }

        // on web the sort order isn't specified, when you click on a new sort column the order is ascending at first, and if you click again it's toggled to descending
        // on mobile we use a sorting modal in which you can specify the sort order and column directly
        if (!newSortOrder) {
            // if the sort column hasn't changed then toggle the sort order
            if (state.userDetails.applications.sortColumn === newSortColumn) {
                if (state.userDetails.applications.sortOrder === SortOrder.Ascending) {
                    state.userDetails.applications.sortOrder = SortOrder.Descending;
                } else {
                    state.userDetails.applications.sortOrder = SortOrder.Ascending;
                }
                state.userDetails.applications.list.reverse();

                return;
            }

            state.userDetails.applications.sortOrder = SortOrder.Ascending;
        } else {
            state.userDetails.applications.sortOrder = newSortOrder;
        }

        state.userDetails.applications.sortColumn = newSortColumn;

        const clientNameSortFunction = (a: UserApplicationModel, b: UserApplicationModel): number => {
            if (a.clientName.toLowerCase() < b.clientName.toLowerCase()) {
                return -1;
            } else {
                return 1;
            }
        };

        let newList: UserApplicationModel[];

        switch (newSortColumn) {
            case UserApplicationSortColumn.ClientName:
                newList = [...state.userDetails.applications.list].sort(clientNameSortFunction);
                break;
            case UserApplicationSortColumn.CreatedDate:
                newList = [...state.userDetails.applications.list].sort((a, b) => {
                    if (!a.createdDate && !b.createdDate) {
                        return clientNameSortFunction(a, b);
                    }

                    if (!a.createdDate) {
                        return -1;
                    }

                    if (!b.createdDate) {
                        return 1;
                    }

                    if (a.createdDate === b.createdDate) {
                        return clientNameSortFunction(a, b);
                    }

                    return new Date(a.createdDate).valueOf() - new Date(b.createdDate).valueOf();
                });
                break;
            case UserApplicationSortColumn.LoginDate:
                newList = [...state.userDetails.applications.list].sort((a, b) => {
                    if (!a.loginDate && !b.loginDate) {
                        return clientNameSortFunction(a, b);
                    }

                    if (!a.loginDate) {
                        return -1;
                    }

                    if (!b.loginDate) {
                        return 1;
                    }

                    if (a.loginDate === b.loginDate) {
                        return clientNameSortFunction(a, b);
                    }

                    return new Date(a.loginDate).valueOf() - new Date(b.loginDate).valueOf();
                });
                break;
            case UserApplicationSortColumn.LoginCount:
                newList = [...state.userDetails.applications.list].sort((a, b) => {
                    if (a.loginCount === b.loginCount) {
                        return clientNameSortFunction(a, b);
                    }

                    return a.loginCount - b.loginCount;
                });
                break;
        }

        state.userDetails.applications.list = state.userDetails.applications.sortOrder === SortOrder.Ascending ? newList : newList.reverse();
    },

    toggleShowSupportingApplications(state: UserStoreState): void {
        state.userDetails.applications!.showSupportingApplications = !state.userDetails.applications!.showSupportingApplications;
    },
};
