import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('CountryModel')
export default class CountryModel {
    public constructor() {
        this.name = '';
        this.iso2Code = '';
    }

    @JsonProperty('name', String)
    public name: string;

    @JsonProperty('iso2_code', String)
    public iso2Code: string;
}
