export default {
  "page_sso_alert_no_verified_domains_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain setup"])},
  "page_sso_alert_no_verified_domains_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to add and verify at least one domain in order to continue with your Single Sign-On setup."])},
  "page_sso_alert_no_verified_domains_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Domains"])},
  "page_sso_alert_no_added_mydomain_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MyDomain setup"])},
  "page_sso_alert_no_added_mydomain_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to add a MyDomain in order to continue with your Single Sign-On setup."])},
  "page_sso_alert_no_added_mydomain_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to MyDomain"])},
  "page_sso_alert_idp_already_added_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity provider setup"])},
  "page_sso_alert_idp_already_added_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You already have an identity provider set up. Only one external SSO identity provider can be configured for your company."])},
  "page_sso_alert_idp_already_added_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Single Sign-On"])},
  "page_sso_mydomain_info_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The MyDomain is your personal sign in page at Visma which also contains your unique SAML2 or OpenID Connect endpoints."])},
  "page_sso_mydomain_info_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A familiar URL will help your users know that the site belongs to your organization."])},
  "page_sso_mydomain_info_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can only delete or rename your MyDomain if you do not have any identity provider setup."])},
  "page_sso_mydomain_modal_add_or_update_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What MyDomain would you like to add?"])},
  "page_sso_mydomain_modal_add_or_update_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["example"])},
  "page_sso_mydomain_modal_add_or_update_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a familiar name for your users, which can only contain lowercase letters, numbers, dashes (-), but can't start or end with dash. The maximum length is 40 characters."])},
  "page_sso_mydomain_modal_add_or_update_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unknown error has occurred while setting this MyDomain."])},
  "page_sso_mydomain_modal_add_or_update_error_invalid_mydomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The MyDomain is not in the correct format."])},
  "page_sso_mydomain_modal_add_or_update_error_already_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This MyDomain is already taken."])},
  "page_sso_mydomain_modal_add_or_update_error_sso_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot change your MyDomain. You have Identity Providers in use."])},
  "page_sso_mydomain_modal_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Mydomain"])},
  "page_sso_mydomain_modal_delete_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to delete your MyDomain: ", _interpolate(_named("myDomainUri")), "?"])},
  "page_sso_mydomain_notif_add_mydomain_success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Successfully added ", _interpolate(_named("myDomainUri")), " as your MyDomain."])},
  "page_sso_mydomain_notif_update_mydomain_success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Successfully updated your MyDomain to: ", _interpolate(_named("myDomainUri")), "."])},
  "page_sso_mydomain_notif_delete_mydomain_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully deleted your MyDomain."])},
  "page_sso_idp_notif_add_or_update_success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Single Sign-On with ", _interpolate(_named("providerDisplayName")), " is set up with success."])},
  "page_sso_idp_selection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose identity provider"])},
  "page_sso_idp_selection_title_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select an identity provider you want to use for Single Sign-On."])},
  "page_sso_idp_selection_list_title_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])},
  "page_sso_idp_selection_back_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< Back to Single Sign-On"])},
  "page_sso_idp_selection_list_body_coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming soon. Stay tuned!"])},
  "page_sso_idp_selection_list_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["When you integrate Visma with ", _interpolate(_named("providerDisplayName")), " you can enable your users to be automatically signed-in to Visma using their ", _interpolate(_named("providerDisplayName")), " account."])},
  "page_sso_idp_notif_add_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unkown error occured while adding your identity provider."])},
  "page_sso_idp_notif_update_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unkown error occured while updating your identity provider."])},
  "page_sso_idp_notif_update_idp_no_longer_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This identity provider does not exist for your company anymore."])},
  "page_sso_idp_notif_delete_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unkown error occured while deleting your identity provider."])},
  "page_sso_idp_notif_delete_success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Successfully deleted your ", _interpolate(_named("providerName")), " identity provider."])},
  "page_sso_idp_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity provider"])},
  "page_sso_idp_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add the identity provider you want to use for Single Sign-On."])},
  "page_sso_idp_no_idp_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No identity provider added."])},
  "page_sso_idp_setup_back_to_selection_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< Back to selection page"])},
  "page_sso_idp_setup_step_advanced_config_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advanced configuration"])},
  "page_sso_idp_setup_step_advanced_config_jit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just-in-Time (JIT) User Provisioning"])},
  "page_sso_idp_setup_step_advanced_config_jit_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If it is enabled, any user not existing in the Visma system will be automatically created (with no application access rights apart from sign in). When it is disabled, you need to create all the users in the Admin panel of your Visma application before single sign-on is possible. In any way, you need to grant application access rights within Visma admin panels."])},
  "page_sso_idp_setup_help_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Check the step by step guide to configure ", _interpolate(_named("providerDisplayName")), " as a ", _interpolate(_named("idpType")), " identity provider within Visma."])},
  "page_sso_idp_modal_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete identity provider"])},
  "page_sso_idp_modal_delete_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to delete the identity provider ", _interpolate(_named("idpName")), "?"])},
  "page_sso_idp_modal_delete_scim_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM 2.0 Provisioning is currently enabled, if you proceed then it will be disabled and the configuration will be lost."])},
  "page_sso_saml2idp_notif_invalid_metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The SAML2 metadata is not valid."])},
  "page_sso_saml2idp_notif_invalid_metadata_certificate_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The certificate from your SAML2 metadata is expired."])},
  "page_sso_saml2idp_notif_invalid_metadata_certificate_still_expired": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The certificate is still expired. Please go to ", _interpolate(_named("providerDisplayName")), " in order to create a new certificate. Then come back here and click the 'Refresh certificate' button."])},
  "page_sso_saml2idp_btn_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add SAML2 Identity Provider"])},
  "page_sso_saml2idp_certificate_expires_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Certificate expires on ", _interpolate(_named("expirationTime"))])},
  "page_sso_saml2idp_certificate_expired_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Expired on ", _interpolate(_named("expirationTime")), ". Please go to ", _interpolate(_named("saml2Idp")), " in order to create a new certificate. Then come back here, edit this provider, click the 'Refresh certificate' button on the edit page, verify the new certificate details and click 'Save' to update it."])},
  "page_sso_saml2idp_certificate_expired_on_mobile": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Certificate expired on ", _interpolate(_named("expirationTime")), ". Please go to ", _interpolate(_named("saml2Idp")), " in order to create a new certificate. Then come back here, edit this provider, click the 'Refresh certificate' button on the edit page, verify the new certificate details and click 'Save' to update it."])},
  "page_sso_saml2idp_details_header_certificate_expire_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate expire date"])},
  "page_sso_saml2idp_setup_step_visma_metadata_title_upload": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Upload the Visma metadata file into ", _interpolate(_named("providerDisplayName"))])},
  "page_sso_saml2idp_setup_step_visma_metadata_title_copy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Copy the Visma metadata details into ", _interpolate(_named("providerDisplayName"))])},
  "page_sso_saml2idp_setup_step_visma_metadata_title_copy_with_public_certificate_download": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Configure metadata details in ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_visma_metadata_title_upload_or_copy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Configure ", _interpolate(_named("providerDisplayName")), " with the Visma metadata from the file or individual fields"])},
  "page_sso_saml2idp_setup_step_visma_metadata_info_upload": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Upload in ", _interpolate(_named("providerDisplayName")), " the Visma metadata file that you downloaded below."])},
  "page_sso_saml2idp_setup_step_visma_metadata_info_copy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Copy each metadata field below and paste it to the corresponding configuration in ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_visma_metadata_info_copy_with_public_certificate_download": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Copy the links listed below into your ", _interpolate(_named("providerDisplayName")), " configuration. For enabling the Single Sign-Out feature download from below the certificate and upload it into ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_visma_metadata_info_upload_or_copy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Upload the Visma metadata file that you downloaded below or copy each metadata field below and paste it into the corresponding configuration in ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_visma_metadata_info_copy_metadata_url_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Copy Metadata URL below and paste it to the corresponding configuration in ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_visma_metadata_metadata_file_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata file"])},
  "page_sso_saml2idp_setup_step_visma_metadata_or_divider_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OR"])},
  "page_sso_saml2idp_setup_step_claims_config_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Configure claims in ", _interpolate(_named("providerDisplayName"))])},
  "page_sso_saml2idp_setup_step_claims_config_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ensure that ", _interpolate(_named("providerDisplayName")), " is configured to use the following claim names when sending SAML responses to Visma."])},
  "page_sso_saml2idp_setup_step_provider_metadata_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Paste ", _interpolate(_named("providerDisplayName")), " metadata URL into Visma"])},
  "page_sso_saml2idp_setup_step_provider_metadata_title_upload": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Upload ", _interpolate(_named("providerDisplayName")), " metadata into Visma"])},
  "page_sso_saml2idp_setup_step_provider_metadata_info_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Copy the Metadata URL from ", _interpolate(_named("providerDisplayName")), " and paste it below."])},
  "page_sso_saml2idp_setup_step_provider_metadata_info_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This is available from your ", _interpolate(_named("providerDisplayName")), " and contains the public certificate, claims and SAML endpoints that Visma needs for its own setup."])},
  "page_sso_saml2idp_setup_step_provider_metadata_info_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For the user identity you need to pass a valid email as NameID and also the given name and surname."])},
  "page_sso_saml2idp_setup_step_provider_metadata_info_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Download the Metadata file from ", _interpolate(_named("providerDisplayName")), " and upload it below."])},
  "page_sso_saml2idp_setup_step_provider_metadata_url_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paste metadata URL"])},
  "page_sso_saml2idp_setup_step_provider_metadata_preview_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview data"])},
  "page_sso_saml2idp_setup_step_provider_metadata_empty_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The field cannot be empty."])},
  "page_sso_saml2idp_setup_step_provider_metadata_max_length_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The maximum length is 100 characters."])},
  "page_sso_saml2idp_setup_step_provider_metadata_upload_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload metadata file"])},
  "page_sso_saml2idp_setup_step_provider_metadata_upload_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select file to preview data"])},
  "page_sso_saml2idp_setup_step_provider_metadata_upload_refresh_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select file to refresh data"])},
  "page_sso_saml2idp_setup_step_provider_metadata_refresh_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh data"])},
  "page_sso_saml2idp_setup_step_provider_metadata_preview_from_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata preview from URL"])},
  "page_sso_saml2idp_setup_step_provider_metadata_preview_from_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata preview from file"])},
  "page_sso_saml2idp_setup_step_provider_metadata_error_metadata_empty": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This field is mandatory. Please copy it from ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_provider_metadata_error_metadata_url_invalid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Invalid metadata URL. Please copy it from ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_provider_metadata_error_metadata_file_invalid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Invalid metadata file. Please download it from ", _interpolate(_named("providerDisplayName")), " and upload it here."])},
  "page_sso_saml2idp_setup_step_provider_metadata_certificate_expires_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Expires on ", _interpolate(_named("expirationTime"))])},
  "page_sso_saml2idp_setup_step_provider_metadata_certificate_expired_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Expired on ", _interpolate(_named("expirationTime")), ". Please go to ", _interpolate(_named("providerDisplayName")), " in order to create a new certificate. Then come back here, click the 'Refresh certificate' button on the edit page, verify the new certificate details and click 'Save' to update it."])},
  "page_sso_saml2idp_setup_step_advanced_config_slo_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single Sign-Out"])},
  "page_sso_saml2idp_setup_step_advanced_config_slo_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you want Visma to call your ", _interpolate(_named("providerDisplayName")), " single logout endpoint when the user signs out from Visma apps? The answer to this is \"NO\" if you want to keep your own ", _interpolate(_named("providerDisplayName")), " session active until sign out is initiated from ", _interpolate(_named("providerDisplayName")), " towards Visma SAML single logout endpoint."])},
  "page_sso_saml2idp_setup_step_advanced_config_updateclaims_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update profile each time a user signs in"])},
  "page_sso_saml2idp_setup_step_advanced_config_updateclaims_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If it is enabled, available user profile attributes will be synched from ", _interpolate(_named("providerDisplayName")), " every time a user signs in."])},
  "page_sso_saml2idp_setup_step_advanced_config_updategroups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update group memberships each time a user signs in"])},
  "page_sso_saml2idp_setup_step_advanced_config_updategroups_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["If it is enabled, the group memberships of the user will be synched from ", _interpolate(_named("providerDisplayName")), " every time the user signs in."])},
  "page_sso_saml2idp_setup_step_metadata_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata URL"])},
  "page_sso_saml2idp_setup_step_entity_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entity ID"])},
  "page_sso_saml2idp_setup_step_saml_logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML Logout"])},
  "page_sso_saml2idp_setup_step_sign_on_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign on URL"])},
  "page_sso_saml2idp_setup_step_logout_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout URL"])},
  "page_sso_saml2idp_setup_step_certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate"])},
  "page_sso_saml2idp_setup_step_thumbprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbprint"])},
  "page_sso_saml2idp_setup_step_sign_authn_requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Authn Requests"])},
  "page_sso_saml2idp_setup_step_saml_assertion_consumer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML Assertion Consumer"])},
  "page_sso_saml2sp_setup_step_visma_public_certificate_file_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public Certificate"])},
  "page_sso_oidcidp_btn_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add OIDC Identity Provider"])},
  "page_sso_oidcidp_configure_uris_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Configure URIs in ", _interpolate(_named("providerDisplayName"))])},
  "page_sso_oidcidp_signin_redirect_uri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirect URI"])},
  "page_sso_oidcidp_signout_redirect_uri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign-out redirect URI"])},
  "page_sso_oidcidp_setup_step_visma_uris": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Copy the links listed below into your ", _interpolate(_named("providerDisplayName")), " configuration."])},
  "page_sso_oidcidp_invalid_authority_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid authority."])},
  "page_sso_oidcidp_invalid_authority_for_provider_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Invalid authority for ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_oidcidp_configure_oidc_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure OpenID Connect Client"])},
  "page_sso_oidcidp_authority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authority"])},
  "page_sso_oidcidp_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client ID"])},
  "page_sso_oidcidp_client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client secret"])},
  "page_sso_oidcidp_update_client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update client secret"])},
  "page_sso_oidcidp_authentication_flow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication flow"])},
  "page_sso_oidcidp_authentication_web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web"])},
  "page_sso_oidcidp_authentication_pkce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PKCE"])},
  "page_sso_oidcidp_authentication_web_and_pkce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web + PKCE"])},
  "page_sso_surfconext_setup_home_realm_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SURFconext home realm"])},
  "page_sso_surfconext_setup_home_realm_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set the home realm you have setup within SURFconext"])},
  "page_sso_surfconext_setup_home_realm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home realm"])},
  "page_sso_surfconext_select_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select domain"])},
  "page_sso_surfconext_notif_already_added_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You already have the SURFconext identity provider set up."])}
}