import httpClient from '@/http-client';
import ApiRequestPayload from '@/http-client/models/ApiRequestPayload';
import { StatusCodes } from 'http-status-codes';
import ApiClientError from '../error';
import UserApplicationModel from './models/UserApplicationModel';
import UserCountModel from './models/UserCountModel';
import UserModel from './models/UserModel';
import UserNotMemberOfGroupModel from './models/UserNotMemberOfGroupModel';
import UserNotMemberOfGroupSearchInputModel from './models/UserNotMemberOfGroupSearchInputModel';
import UserOneTimeEmergencyCodeOutputModel from './models/UserOneTimeEmergencyCodeOutputModel';
import UserOneTimeEmergencyCodeInputModel from './models/UserOneTimeEmergencyCodeInputModel';
import UserOneTimePassword from './models/UserOneTimePassword';
import UserProfileModel from './models/UserProfileModel';
import UserSearchInputModel from './models/UserSearchInputModel';
import UsersModel from './models/UsersModel';
import UserInputModel from './models/UserInputModel';
import SuspendUserInputModel from './models/SuspendUserInputModel';
import config from '@/config';
import UserSessionModel from './models/UserSessionModel';
import AuditSearchInputModel from './models/AuditSearchInputModel';
import AuditLogsModel from './models/AuditLogsModel';
import notificationService from '@/services/notification-service';
import i18n from '@/i18n';
import translations from '@/locales/translations';
import AddUserRecoveryEmailsInputModel from './models/AddUserRecoveryEmailsInputModel';

const routeRoot = '/v1.0/users';

export default {
    async getListAsync(userSearchInput?: UserSearchInputModel): Promise<UsersModel> {
        let requestUri = routeRoot;
        if (userSearchInput) {
            requestUri += `?page=${userSearchInput.page}&sort_attribute=${userSearchInput.sortColumn}&sort_order=${userSearchInput.sortOrder}&per_page=${userSearchInput.perPage}`;

            if (userSearchInput.domainNames) {
                userSearchInput.domainNames.forEach((domainName) => {
                    requestUri += `&domain=${encodeURIComponent(domainName)}`;
                });
            }

            if (userSearchInput.email) {
                requestUri += `&email=${encodeURIComponent(userSearchInput.email)}`;
            }

            if (userSearchInput.firstName) {
                requestUri += `&first_name=${encodeURIComponent(userSearchInput.firstName)}`;
            }

            if (userSearchInput.lastName) {
                requestUri += `&last_name=${encodeURIComponent(userSearchInput.lastName)}`;
            }

            if (userSearchInput.emailOrName) {
                requestUri += `&email_or_name=${encodeURIComponent(userSearchInput.emailOrName)}`;
            }
        }

        const response = await httpClient.getAsync(requestUri);

        if (response.statusCode === StatusCodes.OK) {
            const usersModel = response.data(UsersModel);
            if (!usersModel) {
                throw new ApiClientError(response.statusCode);
            }

            return usersModel;
        }

        if (response.statusCode === StatusCodes.NO_CONTENT) {
            return new UsersModel();
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async getUserCountByDomainsAsync(domains?: string[]): Promise<UserCountModel> {
        let requestUri = `${routeRoot}/count?`;
        if (domains) {
            domains.forEach((domainName) => {
                requestUri += `&domain=${encodeURIComponent(domainName)}`;
            });
        }

        const response = await httpClient.getAsync(requestUri);

        if (response.statusCode === StatusCodes.OK) {
            const userCountModel = response.data(UserCountModel);
            if (!userCountModel) {
                throw new ApiClientError(response.statusCode);
            }

            return userCountModel;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async getListOfNonGroupMembersAsync(userSearchInput: UserNotMemberOfGroupSearchInputModel): Promise<UserNotMemberOfGroupModel[]> {
        const requestUri = `${routeRoot}/groups/${userSearchInput.groupId}/nonmembers?page=${userSearchInput.page}&per_page=${userSearchInput.perPage}&search=${userSearchInput.searchTerm}`;

        const response = await httpClient.getAsync(requestUri);

        if (response.statusCode === StatusCodes.OK) {
            const users = response.dataCollection(UserNotMemberOfGroupModel);
            return users;
        }

        if (response.statusCode === StatusCodes.NO_CONTENT) {
            return [];
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async getAsync(userId: string): Promise<UserProfileModel | undefined> {
        const requestUri = `${routeRoot}/${userId}`;

        const response = await httpClient.getAsync(requestUri);

        if (response.statusCode === StatusCodes.OK) {
            const user = response.data(UserProfileModel);
            return user;
        }

        if (response.statusCode === StatusCodes.NO_CONTENT) {
            return void 0;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async addAsync(model: UserInputModel): Promise<UserModel> {
        const response = await httpClient.postAsync(`${routeRoot}`, new ApiRequestPayload(model, UserInputModel));

        if (response.statusCode === StatusCodes.CREATED) {
            const user = response.data(UserModel);

            if (!user) {
                throw new ApiClientError(response.statusCode);
            }

            return user;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async updateUserAsync(userId: string, model: UserInputModel): Promise<void> {
        const response = await httpClient.putAsync<UserInputModel>(`${routeRoot}/${userId}`, new ApiRequestPayload(model, UserInputModel));
        if (response.statusCode !== StatusCodes.NO_CONTENT) {
            throw new ApiClientError(response.statusCode, response.errorCode);
        }
    },

    async deleteUserRecoveryEmailsAsync(userId: string, emails: string[]): Promise<void> {
        const requestUri = new URL(`${config().backendUrl}${routeRoot}/${userId}/recoveryEmails`);
        emails.forEach((email) => {
            requestUri.searchParams.append('recovery_email', email);
        });

        const response = await httpClient.deleteAsync(requestUri.toString());
        if (response.statusCode !== StatusCodes.NO_CONTENT) {
            throw new ApiClientError(response.statusCode, response.errorCode);
        }
    },

    async addRecoveryEmailAddressesAsync(userId: string, model: AddUserRecoveryEmailsInputModel): Promise<void> {
        const requestUri = new URL(`${config().backendUrl}${routeRoot}/${userId}/recoveryEmails`);
        const response = await httpClient.postAsync(requestUri.toString(), new ApiRequestPayload(model, AddUserRecoveryEmailsInputModel));
        if (response.statusCode !== StatusCodes.NO_CONTENT) {
            throw new ApiClientError(response.statusCode, response.errorCode);
        }
    },

    async deleteUserPhonesAsync(userId: string, phones: string[]): Promise<void> {
        const requestUri = new URL(`${config().backendUrl}${routeRoot}/${userId}/phones`);
        phones.forEach((phone) => {
            requestUri.searchParams.append('phone_id', encodeURIComponent(phone));
        });

        const response = await httpClient.deleteAsync(requestUri.toString());
        if (response.statusCode !== StatusCodes.NO_CONTENT) {
            throw new ApiClientError(response.statusCode, response.errorCode);
        }
    },

    async getUserApplicationsAsync(userId: string): Promise<UserApplicationModel[] | undefined> {
        const requestUri = `${routeRoot}/${userId}/applications`;

        const response = await httpClient.getAsync(requestUri);

        if (response.statusCode === StatusCodes.OK) {
            const applications = response.dataCollection(UserApplicationModel);
            return applications;
        }

        if (response.statusCode === StatusCodes.NO_CONTENT) {
            return void 0;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async updateUserProfilePictureAsync(userId: string, file: File): Promise<void> {
        const requestUri = `${routeRoot}/${userId}/profilepicture`;
        const response = await httpClient.uploadFileAsync(requestUri, file);

        if (response.statusCode === StatusCodes.CREATED) {
            return;
        }

        throw new ApiClientError(response.statusCode, response.errorCode, response.errorDetails);
    },

    async resetUserPasswordAsync(userId: string): Promise<void> {
        const requestUri = `${routeRoot}/${userId}/password/reset`;
        const response = await httpClient.postAsync(requestUri);

        if (response.statusCode === StatusCodes.ACCEPTED) {
            return;
        }

        throw new ApiClientError(response.statusCode, response.errorCode, response.errorDetails);
    },

    async unlockUserPasswordAsync(userId: string): Promise<void> {
        const requestUri = `${routeRoot}/${userId}/password/unlock`;
        const response = await httpClient.putAsync(requestUri);

        if (response.statusCode === StatusCodes.NO_CONTENT) {
            return;
        }

        throw new ApiClientError(response.statusCode, response.errorCode, response.errorDetails);
    },

    async suspendUserAsync(userId: string, model: SuspendUserInputModel): Promise<void> {
        const response = await httpClient.putAsync(`${routeRoot}/${userId}/suspend`, new ApiRequestPayload(model, SuspendUserInputModel));

        if (response.statusCode === StatusCodes.NO_CONTENT) {
            return;
        }

        throw new ApiClientError(response.statusCode, response.errorCode, response.errorDetails);
    },

    async resumeUserAsync(userId: string): Promise<void> {
        const response = await httpClient.putAsync(`${routeRoot}/${userId}/resume`);

        if (response.statusCode === StatusCodes.NO_CONTENT) {
            return;
        }

        throw new ApiClientError(response.statusCode, response.errorCode, response.errorDetails);
    },

    async generateOneTimeUserPasswordAsync(userId: string): Promise<UserOneTimePassword> {
        const requestUri = `${routeRoot}/${userId}/password/onetime`;
        const response = await httpClient.postAsync(requestUri);

        if (response.statusCode === StatusCodes.OK) {
            const password = response.data(UserOneTimePassword);

            if (!password) {
                throw new ApiClientError(response.statusCode);
            }

            return password;
        }

        throw new ApiClientError(response.statusCode, response.errorCode, response.errorDetails);
    },

    async generateOneTimeEmergencyCodeAsync(userId: string, model: UserOneTimeEmergencyCodeInputModel): Promise<UserOneTimeEmergencyCodeOutputModel | undefined> {
        const requestUri = `${routeRoot}/${userId}/2step/emergencyCode`;
        const response = await httpClient.postAsync(requestUri, new ApiRequestPayload(model, UserOneTimeEmergencyCodeInputModel));

        if (response.statusCode === StatusCodes.OK) {
            return response.data(UserOneTimeEmergencyCodeOutputModel);
        } else if (response.statusCode === StatusCodes.NO_CONTENT) {
            return void 0;
        }

        throw new ApiClientError(response.statusCode, response.errorCode, response.errorDetails);
    },

    async getUserSessionsAsync(userId: string): Promise<UserSessionModel[] | undefined> {
        const requestUri = `${routeRoot}/${userId}/sessions`;

        const response = await httpClient.getAsync(requestUri);

        if (response.statusCode === StatusCodes.OK) {
            const sessions = response.dataCollection(UserSessionModel);
            return sessions;
        }

        if (response.statusCode === StatusCodes.NO_CONTENT) {
            return void 0;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async deleteUserSessionAsync(userId: string, sessionId: string): Promise<void> {
        const response = await httpClient.deleteAsync(`${routeRoot}/${userId}/sessions/${sessionId}`);

        if (response.statusCode === StatusCodes.NO_CONTENT) {
            return;
        }

        throw new ApiClientError(response.statusCode, response.errorCode, response.errorDetails);
    },

    async getUserAuditAsync(userId: string, auditSearchInput?: AuditSearchInputModel): Promise<AuditLogsModel> {
        let requestUri = `${routeRoot}/${userId}/auditLogs`;
        if (auditSearchInput) {
            requestUri += `?page=${auditSearchInput.page}&per_page=${auditSearchInput.perPage}&include_totals=${auditSearchInput.includeTotals}`;

            if (auditSearchInput.events && auditSearchInput.events.filter((e) => e.selected).length > 0) {
                requestUri += `&events=${encodeURIComponent(
                    auditSearchInput.events
                        .filter((e) => e.selected)
                        .map((e) => e.name)
                        .join(',')
                )}`;
            }
        }

        const response = await httpClient.getAsync(requestUri);

        if (response.statusCode === StatusCodes.OK) {
            const auditLogsModel = response.data(AuditLogsModel);
            if (!auditLogsModel) {
                throw new ApiClientError(response.statusCode);
            }

            return auditLogsModel;
        }

        if (response.statusCode === StatusCodes.NO_CONTENT) {
            return new AuditLogsModel();
        }

        const ex = new ApiClientError(response.statusCode, response.errorCode);
        if (!ex.isAccessRestrictedStatus) {
            // TODO: handle 500 status code in the base `http-client` with a generic error message
            // and remove the specific error meessage in all the places
            notificationService.error(i18n.t(translations.PAGE_USERS_AUDIT_SEARCH_UNKNOWN_ERROR));
        }

        throw ex;
    },

    async getAuditEventsAsync(): Promise<string[]> {
        const response = await httpClient.getAsync(`${routeRoot}/auditEvents`);

        if (response.statusCode === StatusCodes.OK) {
            if (!response.rawData) {
                throw new ApiClientError(response.statusCode);
            }

            return response.rawData as unknown as string[];
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },
};
