import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('UserCountModel')
export default class UserCountModel {
    public constructor() {
        this.totalUsers = 0;
    }

    @JsonProperty('total_users', Number)
    public totalUsers: number;
}
