
import { defineComponent } from 'vue';
import WelcomePane from '@/components/dashboard/WelcomePane.vue';
import UserStatisticsPane from '@/components/dashboard/UserStatisticsPane.vue';

export default defineComponent({
    name: 'DashboardView',

    components: {
        WelcomePane,
        UserStatisticsPane,
    },

    setup() {
        return {};
    },
});
