import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('AntiforgeryTokenResponse')
export default class AntiforgeryTokenResponse {
    public constructor() {
        this.token = '';
    }

    @JsonProperty('token', String)
    public token: string;
}
