import UserTableSettingsModel from '@/api/ui-interaction-api-client/models/UserTableSettingsModel';
import { UIInteractionStoreState } from './state';

export default {
    setShowAddDomainOnTabLoad(state: UIInteractionStoreState, value: boolean): void {
        state.showAddDomainOnTabLoad = value;
    },

    setShowJobsList(state: UIInteractionStoreState, value: boolean): void {
        state.showJobsList = value;
    },

    setIsStateChanged(state: UIInteractionStoreState, value: boolean): void {
        state.isStateChanged = value;
    },

    setConfirmPageLeaveCallback(state: UIInteractionStoreState, value?: () => void): void {
        state.confirmPageLeaveCallback = value;
    },

    setProcessingState(state: UIInteractionStoreState, isProcessing: boolean): void {
        state.isProcessing = isProcessing;
    },

    setUserTableSettings(state: UIInteractionStoreState, model: UserTableSettingsModel): void {
        state.userTableSettings = model;
    },
};
