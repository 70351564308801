import AggregatedJobModel from '@/api/job-api-client/models/AggregatedJobModel';
import ExportFieldModel from '@/api/job-api-client/models/ExportFieldModel';
import StoreEntity from '@/store/models';

export class JobStoreState extends StoreEntity {
    constructor() {
        super();

        this.jobs = new Array<AggregatedJobModel>();
        this.isUnauthorizedToViewJobs = false;
        this.isDataLoaded = false;
        this.userExportFields = new Array<ExportFieldModel>();
    }

    public jobs: AggregatedJobModel[];

    public isUnauthorizedToViewJobs: boolean;

    public isDataLoaded: boolean;

    public userExportFields: ExportFieldModel[];

    public auditExportFields: ExportFieldModel[] | undefined;
}

export default new JobStoreState();
