export default {
  "page_domains_modal_add_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unknown error has occurred while adding this domain."])},
  "page_domains_modal_add_error_already_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This domain was already added."])},
  "page_domains_modal_add_error_invalid_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The domain is not in the correct format."])},
  "page_domains_modal_add_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["example.com"])},
  "page_domains_modal_add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What domain would you like to add?"])},
  "page_domains_modal_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete domain"])},
  "page_domains_modal_delete_info_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the domain"])},
  "page_domains_modal_delete_info_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After you delete this domain you will need to add and verify it again in order to claim ownership. Users are not deleted and they go back to the default authentication settings."])},
  "page_domains_modal_delete_btn_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "page_domains_modal_delete_error_unkown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unknown error has occurred while deleting this domain."])},
  "page_domains_modal_verify_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify by"])},
  "page_domains_modal_verify_instructions_dns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in to your DNS provider and create a DNS TXT record to help us verify your domain"])},
  "page_domains_modal_verify_instructions_dns_step_1_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy all the information from the table below and add them to your"])},
  "page_domains_modal_verify_instructions_dns_step_1_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DNS host."])},
  "page_domains_modal_verify_instructions_dns_step_1_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "page_domains_modal_verify_instructions_dns_step_1_txt_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TXT name"])},
  "page_domains_modal_verify_instructions_dns_step_1_txt_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DNS text record value"])},
  "page_domains_modal_verify_instructions_dns_step_1_txt_name_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or skip it if not supported by the provider"])},
  "page_domains_modal_verify_instructions_dns_step_2_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to this page and click"])},
  "page_domains_modal_verify_instructions_dns_step_2_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It will take 5 minutes (TTL is set to 300s) until the DNS changes propagate."])},
  "page_domains_modal_verify_instructions_https_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before we can verify"])},
  "page_domains_modal_verify_instructions_https_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you will need to complete these verification steps."])},
  "page_domains_modal_verify_instructions_https_step_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the verification file below."])},
  "page_domains_modal_verify_instructions_https_step_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the file to the root folder of your domain's website."])},
  "page_domains_modal_verify_instructions_https_step_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return to this page and click"])},
  "page_domains_modal_verify_error_domain_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The domain you are trying to verify has been deleted by you or someone else."])},
  "page_domains_modal_verify_error_domain_invalid_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your domain could not be verified. Please check your domain configuration according to the chosen verification method."])},
  "page_domains_modal_verify_error_domain_already_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The domain you are trying to verify has already been verified by you or someone else."])},
  "page_domains_notif_common_the_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The domain"])},
  "page_domains_notif_verified_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["successfully verified."])},
  "page_domains_notif_deleted_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has been successfully removed."])},
  "page_domains_table_header_domain_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain name"])},
  "page_domains_table_header_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "page_domains_table_no_domains_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No domains added."])},
  "page_domains_btn_add_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add domain"])},
  "page_domains_modal_delete_error_sso_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to delete the domain. Please remove the Single Sign-On setup if you wish to delete the domain."])},
  "page_domains_modal_delete_error_group_member_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to delete the domain. There are groups which still have members assigned to them."])},
  "page_domains_modal_delete_cannot_delete_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to delete domain: "])},
  "page_domains_modal_delete_cannot_delete_domain_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to delete the domain you need to either remove the Single Sign On setup or add a new verified domain."])},
  "page_domains_modal_delete_cannot_delete_domain_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cannot delete domain: ", _interpolate(_named("domainDisplayName"))])}
}