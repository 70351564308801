import { JsonObject, JsonProperty } from 'json2typescript';
import DomainModel from './DomainModel';

@JsonObject('DomainCreatedModel')
export default class DomainCreatedModel extends DomainModel {
    public constructor() {
        super();

        this.fetchUrl = '';
    }

    @JsonProperty('fetch_url', String)
    public fetchUrl: string;
}
