import httpClient from '@/http-client';
import ApiRequestPayload from '@/http-client/models/ApiRequestPayload';
import ApiResponse from '@/http-client/models/ApiResponse';
import { StatusCodes } from 'http-status-codes';
import ApiClientError from '../error';
import { Saml2ProviderType } from './enums/Saml2ProviderType';
import MetadataUriInputModel from './models/MetadataUriInputModel';
import Saml2IdentityProviderInputModel from './models/Saml2IdentityProviderInputModel';
import Saml2IdentityProviderOutputModel from './models/Saml2IdentityProviderOutputModel';
import Saml2MetadataParseResultModel from './models/Saml2MetadataParseResultModel';

const routeRoot = '/v1.0/sso/saml2-idp';

// private functions
function handleMetadataPreviewResponse(response: ApiResponse, type: Saml2ProviderType): Saml2MetadataParseResultModel | undefined {
    if (response.statusCode === StatusCodes.OK) {
        const parsedMetadata = response.data(Saml2MetadataParseResultModel);

        if (!parsedMetadata) {
            return void 0;
        }

        parsedMetadata.providerType = type;
        return parsedMetadata;
    }

    return void 0;
}

// public functions
export default {
    async getAsync(): Promise<Saml2IdentityProviderOutputModel | undefined> {
        const response = await httpClient.getAsync(routeRoot);

        if (response.statusCode === StatusCodes.OK) {
            return response.data(Saml2IdentityProviderOutputModel);
        }

        if (response.statusCode === StatusCodes.NO_CONTENT) {
            return void 0;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async addAsync(saml2Idp: Saml2IdentityProviderInputModel): Promise<Saml2IdentityProviderOutputModel> {
        const response = await httpClient.postAsync(`${routeRoot}`, new ApiRequestPayload(saml2Idp, Saml2IdentityProviderInputModel));

        if (response.statusCode === StatusCodes.CREATED) {
            const createdMyDomain = response.data(Saml2IdentityProviderOutputModel);

            if (!createdMyDomain) {
                throw new ApiClientError(response.statusCode);
            }

            return createdMyDomain;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async updateAsync(saml2Idp: Saml2IdentityProviderInputModel): Promise<Saml2IdentityProviderOutputModel> {
        const response = await httpClient.putAsync(`${routeRoot}`, new ApiRequestPayload(saml2Idp, Saml2IdentityProviderInputModel));

        if (response.statusCode === StatusCodes.OK) {
            const createdMyDomain = response.data(Saml2IdentityProviderOutputModel);

            if (!createdMyDomain) {
                throw new ApiClientError(response.statusCode);
            }

            return createdMyDomain;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async deleteAsync(): Promise<void> {
        const response = await httpClient.deleteAsync(`${routeRoot}`);

        if (response.statusCode === StatusCodes.NO_CONTENT) {
            return;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async parseMetadataUriAsync(metadataUri: string, type: Saml2ProviderType): Promise<Saml2MetadataParseResultModel | undefined> {
        const response = await httpClient.postAsync(`${routeRoot}/metadata`, new ApiRequestPayload(new MetadataUriInputModel(metadataUri, type), MetadataUriInputModel));

        return handleMetadataPreviewResponse(response, type);
    },

    async parseMetadataFileAsync(metadataFile: File, type: Saml2ProviderType): Promise<Saml2MetadataParseResultModel | undefined> {
        const response = await httpClient.uploadFileAsync(`${routeRoot}/metadata/file/${type}`, metadataFile, 'metadataFile');

        return handleMetadataPreviewResponse(response, type);
    },
};
