import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "panel-title row justify-content-center" }
const _hoisted_2 = {
  key: 0,
  class: "",
  id: "rocket-img-title"
}
const _hoisted_3 = {
  key: 1,
  class: "",
  id: "globe-img-title"
}
const _hoisted_4 = {
  class: "row d-flex justify-content-left",
  id: "panel-body-web"
}
const _hoisted_5 = { class: "col-7" }
const _hoisted_6 = { clas: "" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { id: "welcome-info" }
const _hoisted_9 = { class: "col-2" }
const _hoisted_10 = {
  key: 0,
  type: "image/svg+xml",
  alt: "rocket-img",
  id: "rocket-img"
}
const _hoisted_11 = {
  key: 1,
  type: "image/svg+xml",
  alt: "globe-img",
  id: "globe-img"
}
const _hoisted_12 = {
  class: "text-center",
  id: "panel-body-mobile"
}
const _hoisted_13 = { clas: "" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { id: "welcome-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.areDomainsLoading)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["panel", { 'bg-gradient mg-l': _ctx.hasVerifiedDomains }]),
        id: "welcome-panel"
      }, [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.hasVerifiedDomains)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[6] || (_cache[6] = [
                _createElementVNode("img", {
                  type: "image/svg+xml",
                  alt: "rocket-img-title"
                }, null, -1)
              ])))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[7] || (_cache[7] = [
                _createElementVNode("img", {
                  type: "image/svg+xml",
                  alt: "globe-img-title"
                }, null, -1)
              ])))
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["panel-body", { 'pb-0': _ctx.hasVerifiedDomains }])
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("h1", _hoisted_6, [
                _createTextVNode(_toDisplayString(_ctx.$t('page_dashboard_welcome_title')) + ", ", 1),
                _createElementVNode("strong", null, _toDisplayString(_ctx.userFirstName), 1)
              ]),
              (!_ctx.hasVerifiedDomains)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("p", _hoisted_8, [
                      _createTextVNode(_toDisplayString(_ctx.$t('page_dashboard_welcome_content_no_domains')) + " ", 1),
                      _createElementVNode("a", {
                        id: "link-learn-more",
                        href: "#",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openHelpPage()))
                      }, _toDisplayString(_ctx.$t('common_learn_more')), 1)
                    ]),
                    (_ctx.hasDomains)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          class: "btn btn-primary btn-lg",
                          id: "btn-domain",
                          type: "button",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.performDomainAction()))
                        }, _toDisplayString(_ctx.$t(_ctx.pendingDomainsCount > 1 ? 'page_dashboard_btn_verify_domains' : 'page_dashboard_btn_verify_domain')), 1))
                      : (_openBlock(), _createElementBlock("button", {
                          key: 1,
                          class: "btn btn-primary btn-lg",
                          id: "btn-domain",
                          type: "button",
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.performDomainAction()))
                        }, _toDisplayString(_ctx.$t('page_dashboard_btn_add_domain')), 1))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_9, [
              (_ctx.hasVerifiedDomains)
                ? (_openBlock(), _createElementBlock("img", _hoisted_10))
                : (_openBlock(), _createElementBlock("img", _hoisted_11))
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", null, [
              _createElementVNode("h1", _hoisted_13, [
                _createTextVNode(_toDisplayString(_ctx.$t('page_dashboard_welcome_title')) + ", ", 1),
                _createElementVNode("strong", null, _toDisplayString(_ctx.userFirstName), 1)
              ]),
              (!_ctx.hasVerifiedDomains)
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createElementVNode("p", _hoisted_15, [
                      _createTextVNode(_toDisplayString(_ctx.$t('page_dashboard_welcome_content_no_domains')) + " ", 1),
                      _createElementVNode("a", {
                        id: "link-learn-more",
                        href: "#",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openHelpPage()))
                      }, _toDisplayString(_ctx.$t('common_learn_more')), 1)
                    ]),
                    (_ctx.hasDomains)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          class: "btn btn-primary btn-lg",
                          id: "btn-domain",
                          type: "button",
                          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.performDomainAction()))
                        }, _toDisplayString(_ctx.$t(_ctx.pendingDomainsCount > 1 ? 'page_dashboard_btn_verify_domains' : 'page_dashboard_btn_verify_domain')), 1))
                      : (_openBlock(), _createElementBlock("button", {
                          key: 1,
                          class: "btn btn-primary btn-lg",
                          id: "btn-domain",
                          type: "button",
                          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.performDomainAction()))
                        }, _toDisplayString(_ctx.$t('page_dashboard_btn_add_domain')), 1))
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ], 2)
      ], 2))
    : _createCommentVNode("", true)
}