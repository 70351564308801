import httpClient from '@/http-client';
import { StatusCodes } from 'http-status-codes';
import ApiClientError from '../error';
import CountryModel from './models/CountryModel';

const routeRoot = '/v1.0/countries';

export default {
    async getListAsync(): Promise<CountryModel[]> {
        const response = await httpClient.getAsync(routeRoot);

        if (response.statusCode === StatusCodes.OK) {
            const countries = response.dataCollection(CountryModel);

            if (!countries) {
                return [];
            }

            return countries;
        }

        if (response.statusCode === StatusCodes.NO_CONTENT) {
            return [];
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async getUserCountryAsync(): Promise<CountryModel | undefined> {
        const response = await httpClient.getAsync(`${routeRoot}/user`);

        if (response.statusCode === StatusCodes.OK) {
            return response.data(CountryModel);
        }

        if (response.statusCode === StatusCodes.NO_CONTENT) {
            return void 0;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },
};
