import util from '@/util';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('BaseUserDetailsModel')
export default abstract class BaseUserDetailsModel {
    constructor() {
        this.baseProfilePictureUri = '';
    }

    @JsonProperty('created_date', String, true)
    public createdDate?: string;

    @JsonProperty('updated_date', String, true)
    public updatedDate?: string;

    @JsonProperty('profile_picture_uri', String)
    public baseProfilePictureUri: string;

    @JsonProperty('profile_picture_changed_date', String, true)
    public profilePictureUpdatedDate?: string;

    public get profilePictureUri(): string {
        if (!this.baseProfilePictureUri) {
            return '';
        }

        if (this.profilePictureUpdatedDate) {
            return this.generateProfilePictureUri(this.profilePictureUpdatedDate);
        }

        if (this.updatedDate) {
            return this.generateProfilePictureUri(this.updatedDate);
        }

        return this.baseProfilePictureUri;
    }

    private generateProfilePictureUri(updatedDate: string): string {
        const uri = new URL(this.baseProfilePictureUri);
        uri.searchParams.set('timestamp', new Date(updatedDate).getTime().toString());

        return uri.href;
    }

    public get displayCreatedDateTime(): string {
        if (!this.createdDate) {
            return '';
        }

        return util.formatDateTime(this.createdDate);
    }

    public get displayUpdatedDateTime(): string {
        if (!this.updatedDate) {
            return '';
        }

        return util.formatDateTime(this.updatedDate);
    }
}
