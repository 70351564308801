import { JsonObject, JsonProperty } from 'json2typescript';
import DomainSourceModel from './DomainSourceModel';

@JsonObject('DomainChangeInputModel')
export default class DomainChangeInputModel {
    public constructor() {
        this.domains = new Array<DomainSourceModel>();
        this.targetDomain = '';
    }

    @JsonProperty('domains', [DomainSourceModel])
    public domains: DomainSourceModel[];

    @JsonProperty('target_domain', String)
    public targetDomain: string;
}
