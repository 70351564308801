import AuthenticationTenantModel from '@/api/auth-api-client/models/AuthenticationTenantModel';

export default class TenantStoreModel {
    public constructor(model?: AuthenticationTenantModel) {
        if (model) {
            this.id = model.tenantId;
            this.organizationName = model.organizationName;
            this.organizationNumber = model.organizationNumber;
            this.countryCode = model.countryCode;
        }
    }

    public id!: string;

    public organizationName!: string;

    public organizationNumber!: string;

    public countryCode!: string;
}
