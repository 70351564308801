import environment from './environment';
import RuntimeConfig from './models/RuntimeConfig';

function getDevConfig(): RuntimeConfig {
    const runtimeConfig = new RuntimeConfig();

    runtimeConfig.connectEnvironment = 'internal';
    runtimeConfig.identityServerDomain = 'connect.int.identity.testaws.visma.com';
    runtimeConfig.identityServerUrl = 'https://connect.int.identity.testaws.visma.com';
    runtimeConfig.frontendUrl = 'http://localhost:8080';
    runtimeConfig.backendUrl = 'http://localhost';
    runtimeConfig.accountSettingsUrl = 'https://accountsettings.connect.int.identity.testaws.visma.com';
    runtimeConfig.appLogoUrl = 'https://applogo.connect.int.identity.testaws.visma.com';

    return runtimeConfig;
}

function getProdConfig(): RuntimeConfig {
    const runtimeConfig = new RuntimeConfig();

    runtimeConfig.connectEnvironment = '#{vismaconnect_environment_name}';
    runtimeConfig.identityServerDomain = '#{vismaconnect_identity_server_domain}';
    runtimeConfig.identityServerUrl = '#{vismaconnect_identity_server_url}';
    runtimeConfig.frontendUrl = '#{vismaconnect_frontend_url}';
    runtimeConfig.backendUrl = '#{vismaconnect_backend_url}';
    runtimeConfig.accountSettingsUrl = '#{vismaconnect_web_accountsettings_url}';
    runtimeConfig.appLogoUrl = '#{vismaconnect_api_applogo_url}';

    return runtimeConfig;
}

function configureFeatureFlags(config: RuntimeConfig): RuntimeConfig {
    config.featureFlags.set('dashboard_stats', false);
    config.featureFlags.set('OtherSso', config.identityServerDomain === 'connect.int.identity.testaws.visma.com');
    return config;
}

export default function config(): RuntimeConfig {
    const config = environment.isProd() ? getProdConfig() : getDevConfig();

    // add feature flags
    return configureFeatureFlags(config);
}
