import { AuthenticationPolicyStoreState } from './state';
import AuthenticationPolicyStoreModel from './models/AuthenticationPolicyStoreModel';
import IdentityProviderStoreModel from './models/IdentityProviderStoreModel';
import AuthenticationPolicyPasswordPolicyModel from '@/api/authenticationpolicy-api-client/models/AuthenticationPolicyPasswordPolicyModel';
import PasswordPolicyValidationStoreModel from './models/PasswordPolicyValidationStoreModel';
import AuthorizedNetworksStoreModel from './models/AuthorizedNetworksStoreModel';
import AuthenticationPolicyCountryStoreModel from './models/AuthenticationPolicyCountryStoreModel';
import AuthenticationPolicySessionRestrictionsModel from '@/api/authenticationpolicy-api-client/models/AuthenticationPolicySessionRestrictionsModel';
import SessionRestrictionsSettingsModel from '@/api/authenticationpolicy-api-client/models/SessionRestrictionsSettingsModel';

export default {
    authenticationPolicies(state: AuthenticationPolicyStoreState): AuthenticationPolicyStoreModel[] {
        // only return the Default policy in the first phase
        // so a user wouldn't be able to manually go to another policy by changing the UUID in the URL
        return state.authenticationPolicies.filter((p) => p.name === 'Default');
    },

    defaultAuthenticationPolicy(state: AuthenticationPolicyStoreState): AuthenticationPolicyStoreModel {
        return state.authenticationPolicies.find((p) => p.name === 'Default')!;
    },

    allIdentityProviders(state: AuthenticationPolicyStoreState): IdentityProviderStoreModel[] {
        return state.allIdentityProviders;
    },

    isProcessing(state: AuthenticationPolicyStoreState): boolean {
        return state.isProcessing;
    },

    isUnauthorizedToViewAuthenticationPolicies(state: AuthenticationPolicyStoreState): boolean {
        return state.isUnauthorizedToViewAuthenticationPolicies;
    },

    isDataLoaded(state: AuthenticationPolicyStoreState): boolean {
        return state.isDataLoaded;
    },

    editedAuthorizedNetworks(state: AuthenticationPolicyStoreState): AuthorizedNetworksStoreModel {
        return state.editedAuthorizedNetworks;
    },

    editedCountries(state: AuthenticationPolicyStoreState): AuthenticationPolicyCountryStoreModel[] {
        return state.editedCountries;
    },

    editedPasswordPolicy(state: AuthenticationPolicyStoreState): AuthenticationPolicyPasswordPolicyModel {
        return state.editedPasswordPolicy;
    },

    editedPasswordPolicyValidation(state: AuthenticationPolicyStoreState): PasswordPolicyValidationStoreModel {
        return state.editedPasswordPolicyValidation;
    },

    editedSessionRestrictions(state: AuthenticationPolicyStoreState): AuthenticationPolicySessionRestrictionsModel {
        return state.editedSessionRestrictions;
    },

    sessionRestrictionsSettings(state: AuthenticationPolicyStoreState): SessionRestrictionsSettingsModel {
        return state.sessionRestrictionsSettings;
    },
};
