import httpClient from '@/http-client';
import ApiClientError from '../error';
import { StatusCodes } from 'http-status-codes';
import ApiRequestPayload from '@/http-client/models/ApiRequestPayload';
import AuthenticationPolicyModel from './models/AuthenticationPolicyModel';
import AuthenticationPolicyInputModel from './models/AuthenticationPolicyInputModel';
import IdentityProviderModel from './models/IdentityProviderModel';
import SessionRestrictionsSettingsModel from './models/SessionRestrictionsSettingsModel';

const routeRoot = '/v1.0/authenticationPolicies';

export default {
    async getAllAsync(): Promise<AuthenticationPolicyModel[]> {
        const response = await httpClient.getAsync(routeRoot);

        if (response.statusCode === StatusCodes.OK) {
            return response.dataCollection(AuthenticationPolicyModel);
        }

        if (response.statusCode === StatusCodes.NO_CONTENT) {
            return [];
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async updateAsync(id: string, model: AuthenticationPolicyInputModel): Promise<AuthenticationPolicyModel> {
        const response = await httpClient.putAsync(`${routeRoot}/${id}`, new ApiRequestPayload(model, AuthenticationPolicyInputModel));

        if (response.statusCode === StatusCodes.OK) {
            const authenticationPolicy = response.data(AuthenticationPolicyModel);

            if (!authenticationPolicy) {
                throw new ApiClientError(response.statusCode);
            }

            return authenticationPolicy;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async getAllIdentityProvidersAsync(): Promise<IdentityProviderModel[]> {
        const response = await httpClient.getAsync(`${routeRoot}/identityProviders`);

        if (response.statusCode === StatusCodes.OK) {
            return response.dataCollection(IdentityProviderModel);
        }

        if (response.statusCode === StatusCodes.NO_CONTENT) {
            return [];
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async getSessionRestrictionsSettingsAsync(): Promise<SessionRestrictionsSettingsModel> {
        const requestUri = `${routeRoot}/settings/sessionrestrictions`;
        const response = await httpClient.getAsync(requestUri);

        if (response.statusCode === StatusCodes.OK) {
            return response.data(SessionRestrictionsSettingsModel)!;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },
};
