import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('LanguageModel')
export default class LanguageModel {
    public constructor() {
        this.name = '';
        this.code = '';
    }

    @JsonProperty('name', String)
    public name: string;

    @JsonProperty('code', String)
    public code: string;
}
