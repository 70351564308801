export default {
  "page_sso_alert_no_verified_domains_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkkotunnuksen asetukset"])},
  "page_sso_alert_no_verified_domains_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää ja vahvista vähintään yksi verkkotunnus jatkaaksesi Single sign-onin määritystä."])},
  "page_sso_alert_no_verified_domains_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siirry verkkotunnuksiin"])},
  "page_sso_alert_no_added_mydomain_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MyDomain-asetukset"])},
  "page_sso_alert_no_added_mydomain_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää MyDomain jatkaaksesi Single sign-on -kirjautumisen asetuksien määrittelyä."])},
  "page_sso_alert_no_added_mydomain_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siirry MyDomainiin"])},
  "page_sso_alert_idp_already_added_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity provider asetukset"])},
  "page_sso_alert_idp_already_added_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinulla on jo identiteetin tarjoaja asetettu. Yrityksellesi voidaan konfiguroida vain yksi ulkoinen SSO identiteetin tarjoaja."])},
  "page_sso_alert_idp_already_added_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siirry Single sign-on -kirjautumiseen"])},
  "page_sso_mydomain_info_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MyDomain on henkilökohtainen sisäänkirjautumissivusi Vismalla, joka sisältää myös yksilöivät SAML- tai OpenID Connect endpointisi."])},
  "page_sso_mydomain_info_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuttu URL auttaa käyttäjiäsi varmistamaan, että sivusto kuuluu organisaatioosi."])},
  "page_sso_mydomain_info_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voit vain poistaa MyDomainisi tai nimetä sen uudelleen, jos sinulla ei ole ulkoista tunnistustietojen tarjoajaa määriteltynä."])},
  "page_sso_mydomain_modal_add_or_update_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minkä MyDomainin haluat lisätä?"])},
  "page_sso_mydomain_modal_add_or_update_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["esimerkki"])},
  "page_sso_mydomain_modal_add_or_update_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse käyttäjillesi tuttu nimi, joka voi sisältää vain pieniä kirjaimia, numeroita ja väliviivoja (-), mutta se ei voi alkaa viivalla tai päättyä siihen. Enimmäispituus on 40 merkkiä."])},
  "page_sso_mydomain_modal_add_or_update_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuntematon virhe MyDomainin määrittelyssä."])},
  "page_sso_mydomain_modal_add_or_update_error_invalid_mydomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MyDomainin muoto on virheellinen."])},
  "page_sso_mydomain_modal_add_or_update_error_already_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tämä MyDomain on jo varattu."])},
  "page_sso_mydomain_modal_add_or_update_error_sso_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et voi muuttaa MyDomainiasi. Käytössäsi on ulkoisia tunnistustietojen tarjoajia."])},
  "page_sso_mydomain_modal_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista MyDomain"])},
  "page_sso_mydomain_modal_delete_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Haluatko varmasti poistaa MyDomainisi ", _interpolate(_named("myDomainUri")), "?"])},
  "page_sso_mydomain_notif_add_mydomain_success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("myDomainUri")), " lisätty MyDomainiksesi."])},
  "page_sso_mydomain_notif_update_mydomain_success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["MyDomainisi on päivitetty: ", _interpolate(_named("myDomainUri")), "."])},
  "page_sso_mydomain_notif_delete_mydomain_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MyDomain on poistettu."])},
  "page_sso_idp_notif_add_or_update_success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Yksi kirjautuminen ", _interpolate(_named("providerDisplayName")), " -tunnuksella on asetettu onnistuneesti."])},
  "page_sso_idp_selection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse ID-tarjoaja"])},
  "page_sso_idp_selection_title_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse ID-tarjoaja, jota haluat käyttää Single sign-on -kirjautumiseen."])},
  "page_sso_idp_selection_list_title_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muut"])},
  "page_sso_idp_selection_back_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< Takaisin Single sign-on -kirjautumiseen"])},
  "page_sso_idp_selection_list_body_coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tulossa pian. Pysy kuulolla!"])},
  "page_sso_idp_selection_list_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kun integroit Visman ", _interpolate(_named("providerDisplayName")), "n kanssa, käyttäjät voivat automaattisesti kirjautua sisään Vismaan ", _interpolate(_named("providerDisplayName")), "-tilinsä avulla."])},
  "page_sso_idp_notif_add_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuntematon virhe lisätessäsi henkilöllisyytesi tarjoajaa."])},
  "page_sso_idp_notif_update_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuntematon virhe päivitettäessä identiteettitarjoajaasi."])},
  "page_sso_idp_notif_update_idp_no_longer_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tätä ID-tarjoajaa ei enää ole yrityksessäsi."])},
  "page_sso_idp_notif_delete_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuntematon virhe poistettaessa henkilöllisyytesi tarjoajaa."])},
  "page_sso_idp_notif_delete_success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["ID-tarjoaja ", _interpolate(_named("providerName")), " poistettu."])},
  "page_sso_idp_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID-tarjoaja"])},
  "page_sso_idp_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää ID-tarjoaja, jota haluat käyttää Single sign on -kirjautumiseen."])},
  "page_sso_idp_no_idp_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID-tarjoajaa ei lisätty."])},
  "page_sso_idp_setup_back_to_selection_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< Takaisin valintasivulle"])},
  "page_sso_idp_setup_step_advanced_config_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laajennetut määritykset"])},
  "page_sso_idp_setup_step_advanced_config_jit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just-in-Time (JIT) -käyttäjäprovisiointi"])},
  "page_sso_idp_setup_step_advanced_config_jit_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jos tämä on käytössä, ne käyttäjät perustetaan automaattisesti, joita ei ole Visman järjestelmässä (ilman sovelluksien käyttöoikeuksia, vain sisäänkirjautuminen). Kun tämä ei ole käytössä, kaikki käyttäjät täytyy perustaa Visman sovelluksen pääkäyttäjäpaneelissa, ennen kuin sisäänkirjautuminen on mahdollista. Sovellusten käyttöoikeudet täytyy joka tapauksessa antaa Visman pääkäyttäjäpaneeleissa."])},
  "page_sso_idp_setup_help_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tarkista vaiheittaiset ohjeet määrittääksesi ", _interpolate(_named("providerDisplayName")), " ", _interpolate(_named("idpType")), " identiteetin tarjoajaksi Vismassa."])},
  "page_sso_idp_modal_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista ID-tarjoaja"])},
  "page_sso_idp_modal_delete_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Haluatko varmasti poistaa ID-tarjoajan ", _interpolate(_named("idpName")), "?"])},
  "page_sso_idp_modal_delete_scim_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM 2.0 -hallinta on tällä hetkellä käytössä. Jos jatkat, se poistetaan käytöstä ja kokoonpano menetetään."])},
  "page_sso_saml2idp_notif_invalid_metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML2-metadata on virheellistä."])},
  "page_sso_saml2idp_notif_invalid_metadata_certificate_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML2-metadatan varmenne on vanhentunut."])},
  "page_sso_saml2idp_notif_invalid_metadata_certificate_still_expired": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Varmenne on edelleen vanhentunut. Siirry ", _interpolate(_named("providerDisplayName")), ":hen muodostaaksesi uuden varmenteen, palaa takaisin ja paina \"Päivitä varmenne\"."])},
  "page_sso_saml2idp_btn_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää SAML2-Identity Provider"])},
  "page_sso_saml2idp_certificate_expires_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Varmenne päättyy ", _interpolate(_named("expirationTime"))])},
  "page_sso_saml2idp_certificate_expired_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Päättynyt ", _interpolate(_named("expirationTime")), ". Avaa ", _interpolate(_named("saml2Idp")), " luodaksesi uuden varmenteen. Palaa sitten tähän, muokkaa tarjoajaa, paina \"Refresh certificate\" -painiketta muokkaussivulla, vahvista uuden varmenteen tiedot ja päivitä se painamalla \"Save\"."])},
  "page_sso_saml2idp_certificate_expired_on_mobile": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Varmenne vanhentunut ", _interpolate(_named("expirationTime")), ". Avaa ", _interpolate(_named("saml2Idp")), " luodaksesi uuden varmenteen. Palaa sitten tähän, muokkaa tarjoajaa, paina \"Refresh certificate\" -painiketta muokkaussivulla, vahvista uuden varmenteen tiedot ja päivitä se painamalla \"Save\"."])},
  "page_sso_saml2idp_details_header_certificate_expire_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varmenteen päättymispäivä"])},
  "page_sso_saml2idp_setup_step_visma_metadata_title_upload": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Siirrä Visman metadata-tiedosto ", _interpolate(_named("providerDisplayName")), "iin"])},
  "page_sso_saml2idp_setup_step_visma_metadata_title_copy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kopioi Visman metadatatiedosto ", _interpolate(_named("providerDisplayName")), "iin"])},
  "page_sso_saml2idp_setup_step_visma_metadata_title_copy_with_public_certificate_download": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Määrittele metadatan tiedot ", _interpolate(_named("providerDisplayName")), "ssa."])},
  "page_sso_saml2idp_setup_step_visma_metadata_title_upload_or_copy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Määritä ", _interpolate(_named("providerDisplayName")), " Visman metadatan kanssa tiedostosta tai yksittäisistä kentistä"])},
  "page_sso_saml2idp_setup_step_visma_metadata_info_upload": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Siirrä ", _interpolate(_named("providerDisplayName")), "ssa alla ladattu Visman metadatatiedosto."])},
  "page_sso_saml2idp_setup_step_visma_metadata_info_copy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kopioi kukin metadatakenttä alla ja liitä ne vastaavaan kohtaan ", _interpolate(_named("providerDisplayName")), "ssa."])},
  "page_sso_saml2idp_setup_step_visma_metadata_info_copy_with_public_certificate_download": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kopioi allaolevat linkit ", _interpolate(_named("providerDisplayName")), "n määrityksiin. Ota single sign-on käyttöön lataamalla varmenne alta ja siirrä se ", _interpolate(_named("providerDisplayName")), "iin."])},
  "page_sso_saml2idp_setup_step_visma_metadata_info_upload_or_copy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Siirrä Visman metadatatiedosto, jonka latasit alla tai kopioi kukin metadatakenttä ja liitä se vastaavaan kohtaan ", _interpolate(_named("providerDisplayName")), "ssa."])},
  "page_sso_saml2idp_setup_step_visma_metadata_info_copy_metadata_url_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kopioi kukin metadatakenttä alla ja liitä ne vastaavaan kohtaan asetuksissa (", _interpolate(_named("providerDisplayName")), ")."])},
  "page_sso_saml2idp_setup_step_visma_metadata_metadata_file_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata-tiedosto"])},
  "page_sso_saml2idp_setup_step_visma_metadata_or_divider_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TAI"])},
  "page_sso_saml2idp_setup_step_claims_config_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Määrittele väitteet ", _interpolate(_named("providerDisplayName")), "ssa"])},
  "page_sso_saml2idp_setup_step_claims_config_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Varmista että ", _interpolate(_named("providerDisplayName")), " on määritelty käyttämään seuraavia väitteiden nimiä lähettäessään SAML-vastauksia Vismalle."])},
  "page_sso_saml2idp_setup_step_provider_metadata_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Liitä ", _interpolate(_named("providerDisplayName")), "n metadatan URL Vismaan"])},
  "page_sso_saml2idp_setup_step_provider_metadata_title_upload": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Siirrä ", _interpolate(_named("providerDisplayName")), "n metadata Vismaan"])},
  "page_sso_saml2idp_setup_step_provider_metadata_info_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kopioi metadatan URL ", _interpolate(_named("providerDisplayName")), "sta ja liitä se alle."])},
  "page_sso_saml2idp_setup_step_provider_metadata_info_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Se on käytettävissä ", _interpolate(_named("providerDisplayName")), "sta ja sisältää julkisen varmenteen, väitteet ja SAML-endpointit, joita Visma tarvitsee omiin asetuksiinsa."])},
  "page_sso_saml2idp_setup_step_provider_metadata_info_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunnistaudu antamalla sähköpostiosoitteesi, etunimesi ja sukunimesi."])},
  "page_sso_saml2idp_setup_step_provider_metadata_info_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lataa metadatatiedosto ", _interpolate(_named("providerDisplayName")), "sta ja siirrä se alle."])},
  "page_sso_saml2idp_setup_step_provider_metadata_url_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liitä metadatan URL"])},
  "page_sso_saml2idp_setup_step_provider_metadata_preview_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esikatsele tiedot"])},
  "page_sso_saml2idp_setup_step_provider_metadata_empty_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenttä ei saa olla tyhjä."])},
  "page_sso_saml2idp_setup_step_provider_metadata_max_length_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enimmäispituus on 100 merkkiä."])},
  "page_sso_saml2idp_setup_step_provider_metadata_upload_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siirrä metadata-tiedosto"])},
  "page_sso_saml2idp_setup_step_provider_metadata_upload_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse tiedosto esikatsellaksesi tietoja"])},
  "page_sso_saml2idp_setup_step_provider_metadata_upload_refresh_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse tiedosto päivittääksesi tiedot"])},
  "page_sso_saml2idp_setup_step_provider_metadata_refresh_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä tiedot"])},
  "page_sso_saml2idp_setup_step_provider_metadata_preview_from_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadatan esikatselu URL:stä"])},
  "page_sso_saml2idp_setup_step_provider_metadata_preview_from_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadatan esikatselu tiedostosta"])},
  "page_sso_saml2idp_setup_step_provider_metadata_error_metadata_empty": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kenttä on pakollinen. Kopioi se ", _interpolate(_named("providerDisplayName")), "sta."])},
  "page_sso_saml2idp_setup_step_provider_metadata_error_metadata_url_invalid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Virheellinen metadatan URL. Kopioi se ", _interpolate(_named("providerDisplayName")), "sta."])},
  "page_sso_saml2idp_setup_step_provider_metadata_error_metadata_file_invalid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Virheellinen metadata-tiedosto. Lataa se ", _interpolate(_named("providerDisplayName")), "sta ja siirrä tähän."])},
  "page_sso_saml2idp_setup_step_provider_metadata_certificate_expires_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Päättyy ", _interpolate(_named("expirationTime"))])},
  "page_sso_saml2idp_setup_step_provider_metadata_certificate_expired_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Päättynyt ", _interpolate(_named("expirationTime")), ". Siirry ", _interpolate(_named("providerDisplayName")), ":iin muodostaaksesi uuden varmenteen. Palaa sitten tähän, paina 'Refresh certificate' muokkaussivulla, vahvista uuden varmenteen tiedot ja päivitä painamalla 'Save'."])},
  "page_sso_saml2idp_setup_step_advanced_config_slo_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single sign out -uloskirjautuminen"])},
  "page_sso_saml2idp_setup_step_advanced_config_slo_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Haluatko Visman kutsuvan ", _interpolate(_named("providerDisplayName")), "n kertauloskirjautumisen endpointia, kun käyttäjä kirjautuu ulos Visman sovelluksista? Vastaa EI, jos haluat pitää oman ", _interpolate(_named("providerDisplayName")), " -istuntosi aktiviisena, kunnes uloskirjautuminen on käynnistetty ", _interpolate(_named("providerDisplayName")), "sta Visman SAML-kertauloskirjautumisendpointiin."])},
  "page_sso_saml2idp_setup_step_advanced_config_updateclaims_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä profiili aina, kun käyttäjä kirjautuu sisään"])},
  "page_sso_saml2idp_setup_step_advanced_config_updateclaims_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jos se on käytössä, käytettävissä olevat käyttäjäprofiilin määritteet synkronoidaan ", _interpolate(_named("providerDisplayName")), "sta aina, kun käyttäjä kirjautuu sisään."])},
  "page_sso_saml2idp_setup_step_advanced_config_updategroups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä ryhmien jäsenyydet aina, kun käyttäjä kirjautuu sisään"])},
  "page_sso_saml2idp_setup_step_advanced_config_updategroups_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jos se on käytössä, käyttäjän ryhmäjäsenyydet synkronoidaan kohteesta ", _interpolate(_named("providerDisplayName")), " aina, kun käyttäjä kirjautuu sisään."])},
  "page_sso_saml2idp_setup_step_metadata_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadatan URL"])},
  "page_sso_saml2idp_setup_step_entity_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entity ID"])},
  "page_sso_saml2idp_setup_step_saml_logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML Logout"])},
  "page_sso_saml2idp_setup_step_sign_on_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign on URL"])},
  "page_sso_saml2idp_setup_step_logout_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout URL"])},
  "page_sso_saml2idp_setup_step_certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate"])},
  "page_sso_saml2idp_setup_step_thumbprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbprint"])},
  "page_sso_saml2idp_setup_step_sign_authn_requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Authn Requests"])},
  "page_sso_saml2idp_setup_step_saml_assertion_consumer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML Assertion Consumer"])},
  "page_sso_saml2sp_setup_step_visma_public_certificate_file_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Julkinen varmenne"])},
  "page_sso_oidcidp_btn_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää OIDC Identity Provider"])},
  "page_sso_oidcidp_configure_uris_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Määritä URI: t kohteessa ", _interpolate(_named("providerDisplayName"))])},
  "page_sso_oidcidp_signin_redirect_uri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirect URI"])},
  "page_sso_oidcidp_signout_redirect_uri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign-out redirect URI"])},
  "page_sso_oidcidp_setup_step_visma_uris": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kopioi alla luetellut linkit asetuksesi ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_oidcidp_invalid_authority_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virheellinen authority."])},
  "page_sso_oidcidp_invalid_authority_for_provider_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Virheellinen authority ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_oidcidp_configure_oidc_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Määritä OpenID Connect Client"])},
  "page_sso_oidcidp_authority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authority"])},
  "page_sso_oidcidp_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client ID"])},
  "page_sso_oidcidp_client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client secret"])},
  "page_sso_oidcidp_update_client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä client secret"])},
  "page_sso_oidcidp_authentication_flow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication flow"])},
  "page_sso_oidcidp_authentication_web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web"])},
  "page_sso_oidcidp_authentication_pkce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PKCE"])},
  "page_sso_oidcidp_authentication_web_and_pkce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web + PKCE"])},
  "page_sso_surfconext_setup_home_realm_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SURFconext-kotiorganisaatio"])},
  "page_sso_surfconext_setup_home_realm_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aseta kotiorganisaatio, jonka olet määrittänyt SURFconextissa"])},
  "page_sso_surfconext_setup_home_realm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kotiorganisaatio"])},
  "page_sso_surfconext_select_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valitse verkkotunnus"])},
  "page_sso_surfconext_notif_already_added_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SURFconext-ID-tarjoaja on jo määritetty."])}
}