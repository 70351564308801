import { Guid } from 'guid-typescript';
import NotificationMessagePart from './NotificationMessagePart';

export enum NotificationType {
    Info = 'info',
    Success = 'success',
    Warning = 'warning',
    Error = 'error',
}

export default class NotificationStoreModel {
    public constructor(messageParts: NotificationMessagePart[], type: NotificationType) {
        this.id = Guid.create().toString();
        this.messageParts = messageParts;
        this.type = type;
    }

    public id: string;

    public messageParts: NotificationMessagePart[];

    public type: NotificationType;
}
