import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('AddDomainModel')
export default class AddDomainModel {
    public constructor(domainName?: string) {
        if (domainName) {
            this.domainName = domainName;
        } else {
            this.domainName = '';
        }
    }

    @JsonProperty('domain_name')
    public domainName: string;
}
