import CountryModel from '@/api/country-api-client/models/CountryModel';
import util from '@/util';
import { CountryStoreState } from './state';

export default {
    setCountries(state: CountryStoreState, model: CountryModel[]): void {
        state.countries = model;
    },

    setUserCountry(state: CountryStoreState, userCountry?: CountryModel): void {
        if (!util.isNullOrEmpty(userCountry)) {
            state.userCountry = userCountry;
        }
    },

    setProcessingState(state: CountryStoreState, isProcessing: boolean): void {
        state.isProcessing = isProcessing;
    },

    setUnauthorizedToViewCountries(state: CountryStoreState): void {
        state.isUnauthorizedToViewCountries = true;
    },

    setDataLoaded(state: CountryStoreState, isDataLoaded: boolean): void {
        state.isDataLoaded = isDataLoaded;
    },
};
