import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('DomainSourceModel')
export default class DomainSourceModel {
    public constructor() {
        this.domain = '';
    }

    @JsonProperty('domain', String)
    public domain: string;

    @JsonProperty('user_ids', [String])
    public userIds: string[] = [];

    @JsonProperty('excluded_user_ids', [String])
    public excludedUserIds: string[] = [];
}
