export default {
  "nav_menu_logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnettersi"])},
  "nav_menu_account_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Settings"])},
  "nav_menu_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
  "nav_menu_change_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change organization"])},
  "nav_menu_tenant_org_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Org. name"])},
  "nav_menu_tenant_org_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Org. number"])},
  "nav_menu_route_name_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "nav_menu_route_name_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domini"])},
  "nav_menu_route_name_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utenti"])},
  "nav_menu_route_name_provisioning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provisioning"])},
  "nav_menu_route_name_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppi"])},
  "nav_menu_route_name_policies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Policies"])},
  "nav_menu_tenant_search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by organization name"])},
  "nav_menu_tenant_search_no_items_found_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No organizations found"])},
  "nav_menu_profile_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "nav_menu_jobs_change_domain_job_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change email domain"])},
  "nav_menu_jobs_users_export_job_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User export"])},
  "nav_menu_jobs_users_import_job_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User import"])},
  "nav_menu_jobs_user_audit_export_job_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User audit logs export"])},
  "nav_menu_jobs_status_queued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queued"])},
  "nav_menu_jobs_status_processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing"])},
  "nav_menu_jobs_status_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
  "nav_menu_jobs_status_completed_with_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed with errors"])},
  "nav_menu_jobs_status_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
  "nav_menu_jobs_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
  "nav_menu_jobs_started_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Started on"])},
  "nav_menu_jobs_completed_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed on"])},
  "nav_menu_jobs_download_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download report"])},
  "nav_menu_jobs_no_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no activity"])},
  "nav_menu_jobs_activities_cleared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities beyond 15 days get cleared from this list"])},
  "nav_menu_jobs_created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Created by ", _interpolate(_named("email"))])},
  "nav_menu_jobs_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Background jobs"])}
}