import { JsonObject, JsonProperty } from 'json2typescript';
import { Saml2ProviderType } from '../enums/Saml2ProviderType';

@JsonObject('MetadataUriInputModel')
export default class MetadataUriInputModel {
    constructor(metadataUri?: string, type?: Saml2ProviderType) {
        this.metadataUri = metadataUri ?? '';
        this.type = type?.toString() ?? '';
    }

    @JsonProperty('metadata_uri', String)
    public metadataUri: string;

    @JsonProperty('type', String)
    public type: string;
}
