import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('ProvisioningTokenModel')
export default class ProvisioningTokenModel {
    public constructor() {
        this.tokenGeneratedTime = '';
        this.tokenExpirationTime = '';
        this.token = '';
    }

    @JsonProperty('token_generated_date', String)
    public tokenGeneratedTime: string;

    @JsonProperty('token_expiry_date', String)
    public tokenExpirationTime: string;

    @JsonProperty('token', String)
    public token: string;
}
