import { JsonObject, JsonProperty } from 'json2typescript';
import ProvisioningActionModel from './ProvisioningActionModel';

@JsonObject('ProvisioningConfigurationModel')
export default class ProvisioningConfigurationModel {
    public constructor() {
        this.actions = [];
    }

    @JsonProperty('provisioning_actions', [ProvisioningActionModel])
    public actions: ProvisioningActionModel[];
}
