import { NotificationStoreState } from './state';
import NotificationStoreModel from './models/NotificationStoreModel';

export default {
    addNotification(state: NotificationStoreState, notification: NotificationStoreModel): void {
        state.notifications.unshift(notification);
    },

    deleteFirstNotification(state: NotificationStoreState): void {
        if (state.notifications.length > 0) {
            state.notifications.pop();
        }
    },

    deleteNotification(state: NotificationStoreState, id: string): void {
        const index = state.notifications.findIndex((notification) => notification.id === id);

        if (index >= 0) {
            state.notifications.splice(index, 1);
        }
    },
};
