import AuthenticationModel from '@/api/auth-api-client/models/AuthenticationModel';
import util from '@/util';

export default class AuthUserStoreModel {
    public constructor(model?: AuthenticationModel) {
        if (model) {
            this.id = model.id;
            this.email = model.email;
            this.firstName = model.firstName;
            this.lastName = model.lastName;
            this.profilePicture = model.profilePicture;
            this.languageCode = model.languageCode;
            this.lastLoginTime = model.lastLoginTime;
            this.accessTokenValidTo = Date.parse(model.accessTokenValidTo);
            this.ipv4Address = model.ipv4Address;
            this.sessionId = model.sessionId;
        }
    }

    public id!: string;

    public sessionId!: string;

    public email!: string;

    public firstName!: string;

    public lastName!: string;

    public profilePicture!: string;

    public languageCode!: string;

    public lastLoginTime!: string;

    public accessTokenValidTo!: number;

    public ipv4Address!: string;

    public get displayLastLoginTime(): string {
        return util.formatDateTimeUtc(this.lastLoginTime);
    }
}
