import AuditEventModel from './AuditEventModel';

export default class AuditSearchInputModel {
    public constructor() {
        this.page = 1;
        this.perPage = 50;
        this.includeTotals = true;
    }

    public page: number;

    public perPage: number;

    public includeTotals: boolean;

    public events?: AuditEventModel[] | undefined;

    public get isStateChanged() {
        return this.page !== 1 || this.events;
    }
}
