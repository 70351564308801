import UserProfileModel from '@/api/user-api-client/models/UserProfileModel';
import StoreEntity from '@/store/models';
import { JsonProperty } from 'json2typescript';
import UserApplicationsModel from './UserApplicationsModel';
import UserSessionModel from '@/api/user-api-client/models/UserSessionModel';
import AuditSearchInputModel from '@/api/user-api-client/models/AuditSearchInputModel';
import AuditLogsModel from '@/api/user-api-client/models/AuditLogsModel';
import GroupSearchInputModel from '@/api/group-api-client/models/GroupSearchInputModel';
import GroupsModel from '@/api/group-api-client/models/GroupsModel';

export default class UserDetailsStoreModel extends StoreEntity {
    public constructor() {
        super();

        this.profile = new UserProfileModel();
        this.sessions = [];
        this.auditSearchInput = new AuditSearchInputModel();
        this.groupSearchInputModel = new GroupSearchInputModel();
    }

    @JsonProperty('id', String, true)
    public id?: string;

    public profile: UserProfileModel;

    public applications: UserApplicationsModel | undefined;

    public sessions: UserSessionModel[];

    public audit: AuditLogsModel | undefined;

    public auditSearchInput: AuditSearchInputModel;

    public groups: GroupsModel | undefined;

    public groupSearchInputModel: GroupSearchInputModel;

    public selectedAuditLogs: string[] = [];

    public excludedAuditLogs: string[] = [];

    public allAuditLogsSelected = false;
}
