import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('ProvisioningActionModel')
export default class ProvisioningActionModel {
    public constructor() {
        this.name = '';
        this.triggers = void 0;
    }

    @JsonProperty('name', String)
    public name: string;

    @JsonProperty('triggers', [String], true)
    public triggers?: string[];
}
