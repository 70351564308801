
import { defineComponent, computed } from 'vue';
import { domainStore } from '@/store';
import config from '@/config';

export default defineComponent({
    name: 'UserStatisticsPaneComponent',

    setup() {
        // computed
        const isFeatureEnabled = computed(() => {
            return config().isFeatureEnabled('dashboard_stats');
        });

        const areStatsAvailable = computed(() => {
            // There has to be at least 1 verified domain
            const domains = domainStore.storeApi.get(domainStore.getters.verifiedDomains);
            if (!domains || domains.length === 0) {
                return false;
            }

            return true;
        });

        return {
            // computed
            isFeatureEnabled,
            areStatsAvailable,
        };
    },
});
