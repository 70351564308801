import mutations from './mutations';
import { BasicStoreAccessors } from '../types';
import { LanguageStoreState } from './state';
import ApiClientError from '@/api/error';
import languageApiClient from '@/api/language-api-client';

let storeApi: BasicStoreAccessors<LanguageStoreState>;

export function initActions(api: BasicStoreAccessors<LanguageStoreState>): void {
    storeApi = api;
}

export default {
    async getLanguagesAsync(): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            const result = await languageApiClient.getListAsync();

            if (result) {
                storeApi.commit(mutations.setLanguages, result);
            }
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewLanguages);
                return;
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
            storeApi.commit(mutations.setDataLoaded, true);
        }
    },
};
