export default {
  "page_groups_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del grupo"])},
  "page_groups_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["miembro"])},
  "page_groups_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miembros"])},
  "page_groups_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
  "page_groups_table_no_groups_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se agregaron grupos"])},
  "page_groups_table_no_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No groups"])},
  "page_groups_table_no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No groups found matching search criteria"])},
  "page_groups_btn_add_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir Grupo"])},
  "page_groups_btn_save_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar Grupo"])},
  "page_groups_btn_edit_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar grupo"])},
  "page_groups_users_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
  "page_groups_users_fist_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primer nombre"])},
  "page_groups_users_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
  "page_groups_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creado (UTC)"])},
  "page_groups_membership_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membresía actualizada (UTC)"])},
  "page_groups_delete_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar grupo"])},
  "page_groups_delete_group_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Está seguro de que desea eliminar el grupo?"])},
  "page_groups_remove_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitar miembro"])},
  "page_groups_remove_member_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Está seguro de que desea eliminar al miembro"])},
  "page_groups_remove_member_group_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["¿Estás seguro de que deseas eliminar al miembro ", _interpolate(_named("member")), " del grupo ", _interpolate(_named("group")), "?"])},
  "page_groups_no_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se agregaron usuarios"])},
  "page_groups_no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No users found matching search criteria"])},
  "page_groups_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< Volver a Grupos"])},
  "page_groups_add_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar miembro al grupo"])},
  "page_groups_add_users_all_are_already_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los usuarios ya están agregados a este grupo"])},
  "page_groups_add_users_all_no_users_matching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay usuarios que coincidan con los criterios de búsqueda"])},
  "page_groups_member_since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miembro desde"])},
  "page_groups_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
  "page_groups_group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miembros del grupo:"])},
  "page_groups_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificación del grupo:"])},
  "page_groups_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuente"])},
  "page_groups_err_group_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un error desconocido ha ocurrido mientras se agregaba este grupo."])},
  "page_groups_err_group_invalid_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El nombre del grupo no es válido. Longitud máxima permitida de 255 caracteres"])},
  "page_groups_err_group_invalid_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La descripción del grupo no es válida. Longitud máxima permitida de 1024 caracteres"])},
  "page_groups_err_group_already_exits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El grupo que intentaba agregar ya existe."])},
  "page_groups_err_group_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un error desconocido ha ocurrido mientras se actualizaba este grupo."])},
  "page_groups_search_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search group name"])},
  "page_groups_search_group_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by email"])},
  "page_groups_search_group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search members"])},
  "page_groups_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grupos"])},
  "page_groups_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["group"])},
  "page_groups_filter_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter members"])},
  "page_groups_member_filter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Member: ", _interpolate(_named("name"))])}
}