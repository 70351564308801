import { JsonObject, JsonProperty } from 'json2typescript';
import MyDomainInputModel from './MyDomainInputModel';

@JsonObject('MyDomainModel')
export default class MyDomainModel extends MyDomainInputModel {
    public constructor() {
        super();

        this.myDomainUri = '';
        this.isModifiable = false;
    }

    @JsonProperty('my_domain_uri', String)
    public myDomainUri: string;

    @JsonProperty('is_modifiable', Boolean)
    public isModifiable: boolean;
}
