import AuthUserStoreModel from './models/AuthUserStoreModel';
import TenantContextStoreModel from './models/TenantContextStoreModel';

export class AuthStoreState {
    public constructor() {
        this.isInitialized = false;
        this.isAuthenticated = false;
        this.user = new AuthUserStoreModel();
        this.tenant = new TenantContextStoreModel();
    }

    public isInitialized: boolean;

    public isAuthenticated: boolean;

    public user: AuthUserStoreModel;

    public tenant: TenantContextStoreModel;
}

export default new AuthStoreState();
