import RoutePaths from '@/router/routes';
import { Router, RouteRecordName } from 'vue-router';
import RuntimeConfig from '@/config/models/RuntimeConfig';
import { authStore } from '@/store';
import routes from '@/router/routes';

let router: Router;
let config: RuntimeConfig;

// define which routes need a redirect to the main page in case the tenant is changed
// for instance if you are on "/policies/:id" and the tenant is changed,
// the user will have to be redirected back to "/policies", since the previous tenant's policy doesn't exist on the new one
const routesToRedirect = new Map<RouteRecordName | null | undefined, string>([
    [routes.authenticationPolicy.name, routes.authenticationPolicies.path],
    [routes.userDetails.name, routes.users.path],
]);

function isAppInitialized(): boolean {
    return config.backendUrl.startsWith('http');
}

export function initLocator(vueRouter: Router, appConfig: RuntimeConfig): void {
    router = vueRouter;
    config = appConfig;
}

export default {
    isNotFoundRoute(): boolean {
        return router && router.currentRoute.value.path === RoutePaths.notFound.path;
    },

    redirectToOidcAuthentication(tenantHint?: string, redirectUri?: string): void {
        if (!isAppInitialized()) {
            return;
        }

        window.location.href = this.computedRedirectToOidcAuthenticationUrl(tenantHint, redirectUri);
    },

    computedRedirectToOidcAuthenticationUrl(tenantHint?: string, redirectUri?: string): string {
        if (!redirectUri) {
            redirectUri = router.currentRoute.value.path;

            if (redirectUri.includes('/sso/saml2-idp') && router.currentRoute.value.name !== routes.singleSignOn.name && router.currentRoute.value.name) {
                redirectUri = routes.singleSignOn.path;
            }

            if (redirectUri.includes('/sso/oidc-idp') && router.currentRoute.value.name !== routes.singleSignOn.name && router.currentRoute.value.name) {
                redirectUri = routes.singleSignOn.path;
            }

            for (let i = 0; i < router.currentRoute.value.matched.length; i++) {
                if (routesToRedirect.has(router.currentRoute.value.matched[i].name)) {
                    const tenantDetails = authStore.storeApi.get(authStore.getters.currentTenant);
                    if (tenantDetails && tenantHint && tenantDetails.id !== tenantHint) {
                        redirectUri = routesToRedirect.get(router.currentRoute.value.matched[i].name);
                    }
                    break;
                }
            }
        }

        let reauthLocation = `${config.backendUrl}/oidc/reauth?redirect_uri=${redirectUri}`;

        if (tenantHint) {
            reauthLocation += `&tenant_hint=${tenantHint}`;
        } else {
            const tenantDetails = authStore.storeApi.get(authStore.getters.currentTenant);
            if (tenantDetails) {
                reauthLocation += `&tenant_hint=${tenantDetails?.id}`;
            }
        }

        return reauthLocation;
    },
};
