import httpClient from '@/http-client';
import ApiRequestPayload from '@/http-client/models/ApiRequestPayload';
import { StatusCodes } from 'http-status-codes';
import ApiClientError from '../error';
import IdentityProviderScimConfigModel from './models/IdentityProviderScimConfigModel';
import ProvisioningConfigurationModel from './models/ProvisioningConfigurationModel';
import ProvisioningModel from './models/ProvisioningModel';
import ProvisioningTokenModel from './models/ProvisioningTokenModel';
import ScimRequestLogs from './models/ScimRequestLog';

const routeRoot = '/v1.0/provisioning';

export default {
    async getScimAsync(): Promise<ProvisioningModel | undefined> {
        const response = await httpClient.getAsync(routeRoot);

        if (response.statusCode === StatusCodes.OK) {
            return response.data(ProvisioningModel);
        }

        if (response.statusCode === StatusCodes.NO_CONTENT) {
            return void 0;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async enableScimAsync(model: ProvisioningConfigurationModel): Promise<ProvisioningModel> {
        const response = await httpClient.postAsync(routeRoot, new ApiRequestPayload(model, ProvisioningConfigurationModel));

        if (response.statusCode === StatusCodes.OK) {
            return response.data(ProvisioningModel)!;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async disableScimAsync(): Promise<void> {
        const response = await httpClient.deleteAsync(routeRoot);

        if (response.statusCode !== StatusCodes.NO_CONTENT) {
            throw new ApiClientError(response.statusCode, response.errorCode);
        }
    },

    async updateScimAsync(model: ProvisioningConfigurationModel): Promise<ProvisioningModel> {
        const response = await httpClient.putAsync(routeRoot, new ApiRequestPayload(model, ProvisioningConfigurationModel));

        if (response.statusCode === StatusCodes.OK) {
            return response.data(ProvisioningModel)!;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async generateScimTokenAync(): Promise<ProvisioningTokenModel> {
        const response = await httpClient.postAsync(`${routeRoot}/token`);

        if (response.statusCode === StatusCodes.OK) {
            return response.data(ProvisioningTokenModel)!;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async getIdentityProviderScimConfigurationAsync(): Promise<IdentityProviderScimConfigModel[]> {
        const response = await httpClient.getAsync(`${routeRoot}/identityProvidersScimConfiguration`);

        if (response.statusCode === StatusCodes.OK) {
            return response.dataCollection(IdentityProviderScimConfigModel);
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async getScimLogsAsync(): Promise<ScimRequestLogs[] | undefined> {
        const response = await httpClient.getAsync(`${routeRoot}/logs`);

        if (response.statusCode === StatusCodes.NO_CONTENT) {
            return void 0;
        }

        if (response.statusCode === StatusCodes.OK) {
            return response.dataCollection(ScimRequestLogs);
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },
};
