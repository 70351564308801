import { App, DirectiveBinding } from 'vue';

interface ClickOutsideElement extends HTMLElement {
    clickOutsideEvent(evt: Event): void;
}

export default function directive(app: App<Element>) {
    app.directive('click-outside', {
        beforeMount(el: ClickOutsideElement, binding: DirectiveBinding) {
            el.clickOutsideEvent = (evt: Event) => {
                evt.stopPropagation();
                if (!(el === evt.target || el.contains(evt.target as Node))) {
                    binding.value(evt, el);
                }
            };
            window.requestAnimationFrame(() => {
                document.addEventListener('click', el.clickOutsideEvent);
            });
        },
        unmounted(el: ClickOutsideElement) {
            document.removeEventListener('click', el.clickOutsideEvent);
        },
    });
}
