import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('UserRecoveryEmailModel')
export default class UserRecoveryEmailModel {
    constructor() {
        this.email = '';
        this.key = new Date().toString();
    }

    public key: string;

    @JsonProperty('email', String)
    public email: string;

    @JsonProperty('created_date', String)
    public createdDate?: string;
}
