import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('UserTableSettingsModel')
export default class UserTableSettingsModel {
    public constructor() {
        this.columns = new Array<string>();
    }

    @JsonProperty('columns', [String])
    public columns: string[];
}
