// eslint-disable no-unused-vars
import domainsApiClient from '@/api/domain-api-client';
import { ERROR_DOMAIN_NOT_EXISTS } from '@/api/domain-api-client/error-codes';
import { ActionContext } from 'vuex';
import ApiClientError from '@/api/error';
import mutations from './mutations';
import DomainStoreModel from './models/DomainStoreModel';
import { DomainStoreState } from './state';
import { BasicStoreAccessors, RootState } from '../types';
import { StatusCodes } from 'http-status-codes';

let storeApi: BasicStoreAccessors<DomainStoreState>;

export function initActions(api: BasicStoreAccessors<DomainStoreState>): void {
    storeApi = api;
}

export default {
    async fetchAllDomainsAsync(): Promise<void> {
        try {
            storeApi.commit(mutations.setStoreProcessingState, true);

            const domains = (await domainsApiClient.getAllAsync()) ?? [];

            if (domains && domains.length > 0) {
                const storeModels = domains.map((domain) => new DomainStoreModel(domain));
                storeApi.commit(mutations.setDomains, storeModels);

                await Promise.all(
                    domains
                        .filter((domain) => !domain.isVerified)
                        .map(async (domain) => {
                            try {
                                storeApi.commit(mutations.setDomainProcessingState, { domainName: domain.name, isProcessing: true });

                                const verificationRecord = await domainsApiClient.getVerificationRecordAsync(domain.name);
                                storeApi.commit(mutations.setVerificationRecord, { domainName: domain.name, verificationRecord });
                            } finally {
                                storeApi.commit(mutations.setDomainProcessingState, { domainName: domain.name, isProcessing: false });
                            }
                        })
                );
            }
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewDomains);
            }
        } finally {
            storeApi.commit(mutations.setStoreProcessingState, false);
        }
    },

    async addDomainAsync(context: ActionContext<DomainStoreState, RootState>, domainName: string): Promise<boolean> {
        try {
            storeApi.commit(mutations.setStoreProcessingState, true);

            const createdDomain = await domainsApiClient.addAsync(domainName);

            if (createdDomain) {
                storeApi.commit(mutations.addDomain, new DomainStoreModel(createdDomain));

                try {
                    const verificationRecord = await domainsApiClient.getVerificationRecordAsync(createdDomain.name);
                    storeApi.commit(mutations.setVerificationRecord, { domainName: createdDomain.name, verificationRecord });
                } catch {
                    // ignore any errors here
                }

                return createdDomain.isVerified;
            }

            return false;
        } finally {
            storeApi.commit(mutations.setStoreProcessingState, false);
        }
    },

    /**
     * This store method already handles the case ERROR_DOMAIN_NOT_EXISTS
     */
    async verifyDomainAsync(context: ActionContext<DomainStoreState, RootState>, domainName: string): Promise<void> {
        try {
            storeApi.commit(mutations.setDomainProcessingState, { domainName, isProcessing: true });

            await domainsApiClient.verifyAsync(domainName);

            storeApi.commit(mutations.verifyDomain, domainName);
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.statusCode === StatusCodes.NOT_FOUND && apiError.errorCode === ERROR_DOMAIN_NOT_EXISTS) {
                storeApi.commit(mutations.removeDomain, domainName);
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setDomainProcessingState, { domainName, isProcessing: false });
        }
    },

    async deleteDomainAsync(context: ActionContext<DomainStoreState, RootState>, domainName: string): Promise<void> {
        try {
            storeApi.commit(mutations.setDomainProcessingState, { domainName, isProcessing: true });

            await domainsApiClient.deleteDomainAsync(domainName);

            storeApi.commit(mutations.removeDomain, domainName);
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.statusCode === StatusCodes.NOT_FOUND && apiError.errorCode === ERROR_DOMAIN_NOT_EXISTS) {
                storeApi.commit(mutations.removeDomain, domainName);
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setDomainProcessingState, { domainName, isProcessing: false });
        }
    },
};
