import util from '@/util';
import { JsonObject, JsonProperty } from 'json2typescript';
import OidcIdentityProviderModel from './base/OidcIdentityProviderModel';
import { OidcFlow } from '@/services/oidc-provider-service/OidcFlows';

@JsonObject('OidcIdentityProviderOutputModel')
export default class OidcIdentityProviderOutputModel extends OidcIdentityProviderModel {
    constructor() {
        super();

        this.isClientSecretSet = false;
        this.createdDate = '';
    }

    @JsonProperty('is_client_secret_set', Boolean)
    public isClientSecretSet: boolean;

    @JsonProperty('created_date', String)
    public createdDate: string;

    public get displayCreatedDate(): string {
        return util.formatDate(this.createdDate);
    }

    public get oidcFlowType(): OidcFlow {
        if (!this.usePkce) {
            return OidcFlow.AuthCode;
        }

        if (this.isClientSecretSet) {
            return OidcFlow.AuthCode_PKCE;
        }

        return OidcFlow.PKCE;
    }
}
