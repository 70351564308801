import util from '@/util';
import { JsonObject, JsonProperty } from 'json2typescript';
import UserSessionApplicationModel from './UserSessionApplicationModel';

@JsonObject('UserSessionModel')
export default class UserSessionModel {
    public constructor() {
        this.sessionId = '';
        this.ipv4Address = '';
        this.countryName = '';
        this.deviceName = '';
        this.browserName = '';
        this.browserVersion = '';
        this.applications = [];
    }

    @JsonProperty('session_id', String)
    public sessionId: string;

    @JsonProperty('login_date', String, true)
    public loginDate?: string;

    @JsonProperty('ipv4_address', String)
    public ipv4Address: string;

    @JsonProperty('country_name', String)
    public countryName: string;

    @JsonProperty('device_name', String)
    public deviceName: string;

    @JsonProperty('browser_name', String)
    public browserName: string;

    @JsonProperty('browser_version', String)
    public browserVersion: string;

    @JsonProperty('applications', [UserSessionApplicationModel], true)
    public applications: UserSessionApplicationModel[];

    public get displayLoginDate(): string {
        if (!this.loginDate) {
            return '';
        }

        return util.formatDateTime(this.loginDate);
    }

    public get displayApplicationNames(): string {
        return this.applications.map((m) => m.applicationName).join(',');
    }
}
