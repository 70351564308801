import ApiClientError from '@/api/error';
import { ERROR_MYDOMAIN_NOT_EXISTS } from '@/api/mydomain-api-client/error-codes';
import saml2IdpClient from '@/api/saml2-idp-client';
import Saml2IdentityProviderInputModel from '@/api/saml2-idp-client/models/Saml2IdentityProviderInputModel';
import Saml2IdentityProviderOutputModel from '@/api/saml2-idp-client/models/Saml2IdentityProviderOutputModel';
import { StatusCodes } from 'http-status-codes';
import { ActionContext } from 'vuex';
import { BasicStoreAccessors, RootState } from '../types';
import mutations from './mutations';
import { Saml2IdentityProviderStoreState } from './state';
import { ERROR_IDP_NOT_EXISTS } from '@/api/common-error-codes';

let storeApi: BasicStoreAccessors<Saml2IdentityProviderStoreState>;

export function initActions(api: BasicStoreAccessors<Saml2IdentityProviderStoreState>): void {
    storeApi = api;
}

export default {
    async getSaml2IdentityProviderAsync(): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            const saml2IdentityProviderModel = await saml2IdpClient.getAsync();

            if (saml2IdentityProviderModel) {
                storeApi.commit(mutations.setSaml2IdentityProvider, saml2IdentityProviderModel);
            }
        } catch (err) {
            const apiError = err as ApiClientError;

            // Ignore the MyDomain does not exist error and simply let the SAML2 IDP be null
            if (apiError.statusCode === StatusCodes.NOT_FOUND && apiError.errorCode === ERROR_MYDOMAIN_NOT_EXISTS) {
                return;
            }

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewSaml2Idp);
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
        }
    },

    async addSaml2IdentityProviderAsync(context: ActionContext<Saml2IdentityProviderStoreState, RootState>, model: Saml2IdentityProviderInputModel): Promise<Saml2IdentityProviderOutputModel> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            const saml2IdentityProviderModel = await saml2IdpClient.addAsync(model);

            storeApi.commit(mutations.setSaml2IdentityProvider, saml2IdentityProviderModel);

            return saml2IdentityProviderModel;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
        }
    },

    async updateSaml2IdentityProviderAsync(context: ActionContext<Saml2IdentityProviderStoreState, RootState>, model: Saml2IdentityProviderInputModel): Promise<Saml2IdentityProviderOutputModel> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            const saml2IdentityProviderModel = await saml2IdpClient.updateAsync(model);

            storeApi.commit(mutations.setSaml2IdentityProvider, saml2IdentityProviderModel);

            return saml2IdentityProviderModel;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
        }
    },

    async deleteSaml2IdentityProviderAsync(): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            await saml2IdpClient.deleteAsync();

            storeApi.commit(mutations.setSaml2IdentityProvider, void 0);
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.statusCode === StatusCodes.NOT_FOUND && apiError.errorCode === ERROR_IDP_NOT_EXISTS) {
                storeApi.commit(mutations.setSaml2IdentityProvider, void 0);
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
        }
    },
};
