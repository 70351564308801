export default {
  "nav_menu_logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitloggen"])},
  "nav_menu_account_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accountinstellingen"])},
  "nav_menu_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
  "nav_menu_change_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisatie wijzigen"])},
  "nav_menu_tenant_org_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Org.naam"])},
  "nav_menu_tenant_org_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Org.nummer"])},
  "nav_menu_route_name_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "nav_menu_route_name_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domeinen"])},
  "nav_menu_route_name_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers"])},
  "nav_menu_route_name_provisioning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provisioning"])},
  "nav_menu_route_name_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepen"])},
  "nav_menu_route_name_policies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beleid"])},
  "nav_menu_tenant_search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Op organisatienaam zoeken"])},
  "nav_menu_tenant_search_no_items_found_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen organisaties gevonden"])},
  "nav_menu_profile_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiel"])},
  "nav_menu_jobs_change_domain_job_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-maildomein wijzigen"])},
  "nav_menu_jobs_users_export_job_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers exporteren"])},
  "nav_menu_jobs_users_import_job_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikers importeren"])},
  "nav_menu_jobs_user_audit_export_job_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export van controlelogboeken van gebruiker"])},
  "nav_menu_jobs_status_queued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In wachtrij"])},
  "nav_menu_jobs_status_processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wordt verwerkt"])},
  "nav_menu_jobs_status_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltooid"])},
  "nav_menu_jobs_status_completed_with_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltooid, met fouten"])},
  "nav_menu_jobs_status_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet gelukt"])},
  "nav_menu_jobs_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "nav_menu_jobs_started_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begonnen om"])},
  "nav_menu_jobs_completed_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltooid om"])},
  "nav_menu_jobs_download_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapport downloaden"])},
  "nav_menu_jobs_no_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is geen activiteit"])},
  "nav_menu_jobs_activities_cleared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activiteiten ouder dan 15 dagen worden uit deze lijst verwijderd"])},
  "nav_menu_jobs_created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aangemaakt door ", _interpolate(_named("email"))])},
  "nav_menu_jobs_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtergrondtaken"])}
}