export default {
  "page_users_no_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun utilisateur"])},
  "page_users_users_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
  "page_users_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utilisateurs"])},
  "page_users_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utilisateur"])},
  "page_users_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
  "page_users_recovery_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail de récupération"])},
  "page_users_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])},
  "page_users_first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
  "page_users_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
  "page_users_last_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière connexion "])},
  "page_users_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créé"])},
  "page_users_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualisé"])},
  "page_users_language_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
  "page_users_country_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
  "page_users_applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications"])},
  "page_users_electronic_id_enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID électronique activé"])},
  "page_users_2step_enabled_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2-Step activées"])},
  "page_users_search_email_or_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un email ou un nom"])},
  "page_users_bulk_email_domain_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement de domaine de messagerie en masse"])},
  "page_users_user_export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportation d'utilisateurs"])},
  "page_users_modal_domain_change_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention – les comptes utilisateurs seront modifiés"])},
  "page_users_modal_domain_change_body_n_users_n_domains": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous êtes sur le point de modifier ", _interpolate(_named("usersCount")), " comptes utilisateurs de ", _interpolate(_named("domainsCount")), " domaines"])},
  "page_users_modal_domain_change_body_1_user_1_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sur le point de modifier 1 compte utilisateur d'un domaine"])},
  "page_users_modal_domain_change_body_n_users_1_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous êtes sur le point de modifier ", _interpolate(_named("usersCount")), " comptes utilisateurs d'un domaine"])},
  "page_users_modal_domain_change_body_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurez-vous d'informer les utilisateurs des changements"])},
  "page_users_modal_domain_change_source_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domaines sources"])},
  "page_users_modal_domain_change_target_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domaine cible"])},
  "page_users_modal_domain_change_start_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer de domaine"])},
  "page_users_modal_domain_change_not_target_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun domaine cible n'est disponible. Décochez l'un des domaines sources et réessayez"])},
  "page_users_modal_domain_change_approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je reconnais que cette action modifiera les comptes d'utilisateurs sélectionnés"])},
  "page_users_modal_domain_change_example_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemple"])},
  "page_users_modal_domain_change_example_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["john.doe", "@", _interpolate(_named("sourceDomain")), " sera remplacé par john.doe", "@", _interpolate(_named("targetDomain"))])},
  "page_users_modal_export_users_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter les utilisateurs"])},
  "page_users_modal_export_users_start_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter les utilisateurs"])},
  "page_users_modal_export_users_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domaines"])},
  "page_users_modal_export_users_body_1_user_1_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 utilisateur d'un domaine sera exporté"])},
  "page_users_modal_export_users_body_n_users_1_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("usersCount")), " utilisateurs d'un domaine seront exportés"])},
  "page_users_modal_export_users_body_n_user_n_domains": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("usersCount")), " utilisateurs de ", _interpolate(_named("domainsCount")), " domaines seront exportés"])},
  "page_users_modal_export_users_select_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les colonnes de données à exporter"])},
  "page_users_modal_export_users_field_user_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID utilisateur"])},
  "page_users_modal_export_users_field_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "page_users_modal_export_users_field_first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
  "page_users_modal_export_users_field_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
  "page_users_modal_export_users_field_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
  "page_users_modal_export_users_field_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
  "page_users_modal_export_users_field_applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications"])},
  "page_users_modal_export_users_field_electronic_id_enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ElectronicID activé"])},
  "page_users_modal_export_users_field_2step_enabled_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'authentification en 2-Step activée"])},
  "page_users_modal_export_users_field_applications_selected_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarque : Vous exportez des applications pour un utilisateur. L'export va créer une ligne par utilisateur/application, de sorte que le même utilisateur sera retourné sur plusieurs lignes s'il a plus d'une application."])},
  "page_users_clear_selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer la sélection"])},
  "page_users_select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner tous"])},
  "page_users_add_to_selection_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter tout à la sélection"])},
  "page_users_select_all_from_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner tout à partir de la page"])},
  "page_users_add_to_selection_all_from_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter tout de la page à la sélection"])},
  "page_users_select_all_from_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner tout à partir du filtre"])},
  "page_users_add_to_selection_all_from_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter tout du filtre à la sélection"])},
  "page_users_selected_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sélectionné (", _interpolate(_named("usersCount")), ")"])},
  "page_users_select_usercount_from_filter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sélectionnez ", _interpolate(_named("usersCount")), " utilisateurs à partir du filtre"])},
  "page_users_select_usercount_from_page": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sélectionnez ", _interpolate(_named("usersCount")), " utilisateurs de la page"])},
  "page_select_all_usercount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sélectionner tous les ", _interpolate(_named("usersCount")), " utilisateurs"])},
  "page_users_modal_export_users_export_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter"])},
  "page_users_modal_export_users_column_name_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de colonne"])},
  "page_users_1_selected_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 domaine"])},
  "page_users_selected_domains_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("domainsCount")), " domaines"])},
  "page_users_all_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les domaines"])},
  "page_users_filter_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer les domaines"])},
  "page_users_clear_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer le filtre"])},
  "page_users_add_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un utilisateur"])},
  "page_users_import_or_update_users_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer des utilisateurs"])},
  "page_users_modal_import_users_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer ou mettre à jour les utilisateurs"])},
  "page_users_modal_import_users_upload_file_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir fichier"])},
  "page_users_modal_import_users_import_users_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer ou mettre à jour les utilisateurs"])},
  "page_users_modal_import_users_select_csv_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un fichier CSV"])},
  "page_users_modal_import_users_required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les champs obligatoires sont l'Id utilisateur, e-mail, le prénom, le nom, le pays et la langue. Tous les espaces de début et de fin seront supprimés"])},
  "page_users_modal_import_users_specify_user_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécifiez toujours une valeur pour l'ID utilisateur si vous souhaitez mettre à jour les comptes utilisateurs, sinon un nouvel utilisateur sera créé"])},
  "page_users_modal_import_users_add_edit_user_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter ou modifier les informations utilisateur dans le modèle CSV"])},
  "page_users_modal_import_users_download_csv_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le modèle CSV"])},
  "page_users_modal_import_users_download_csv_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le modèle CSV"])},
  "page_users_modal_import_users_upload_csv_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload CSV file"])},
  "page_users_modal_import_users_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You'll need to complete these steps in order to import or update users"])},
  "page_users_modal_import_users_mandatory_column_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One or more mandatory columns are missing: "])},
  "page_users_modal_import_users_invalid_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One ore more columns are invalid:"])},
  "page_users_modal_import_users_invalid_number_of_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid number of columns at line: "])},
  "page_users_modal_import_users_invalid_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Header is missing or is invalid"])},
  "page_users_modal_import_users_invalid_csv_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid CSV file content"])},
  "page_users_modal_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
  "page_users_modal_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
  "page_users_modal_all_countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les pays"])},
  "page_users_modal_all_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les langues"])},
  "page_users_modal_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "page_users_modal_add_user_invalid_first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom non valide"])},
  "page_users_modal_add_user_invalid_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom non valide"])},
  "page_users_modal_add_user_invalid_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue non valide"])},
  "page_users_modal_add_user_invalid_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays non valide"])},
  "page_users_modal_add_user_invalid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email"])},
  "page_users_modal_add_user_email_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email already exists"])},
  "page_users_modal_add_user_success_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User added successfully"])},
  "page_users_modal_add_user_success_otp_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user was created successfully and has the above one-time password."])},
  "page_users_modal_add_user_success_email_link_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An email with login instructions was sent to user email address."])},
  "page_users_modal_add_user_auto_generate_otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-generate one time password"])},
  "page_users_modal_add_user_send_email_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send account activation email to user"])},
  "page_users_modal_add_user_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unknown error has occurred while adding a user."])},
  "page_users_modal_select_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select domain"])},
  "page_user_details_back_to_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to users"])},
  "page_user_details_tab_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
  "page_user_details_tab_security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sécurité"])},
  "page_user_details_tab_devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devices"])},
  "page_user_details_tab_audit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audit logs"])},
  "page_user_details_tab_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes"])},
  "page_user_details_tab_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessions"])},
  "page_user_details_tab_email_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email history"])},
  "page_user_details_tab_passwordless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sans mot de passe"])},
  "page_user_details_tab_linked_eids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linked eIDs"])},
  "page_user_details_tab_applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications"])},
  "page_user_details_recovery_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovery email addresses"])},
  "page_user_details_phone_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone numbers"])},
  "page_user_details_edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le profil"])},
  "page_user_details_update_user_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unknown error has occurred while updating the user"])},
  "page_user_details_application_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l’application"])},
  "page_user_details_authorization_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'autorisation"])},
  "page_user_details_login_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de connexion"])},
  "page_user_details_login_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de connexions"])},
  "page_user_details_no_applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune application"])},
  "page_user_details_showing_applications": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Affichage de ", _interpolate(_named("count")), " applications sur ", _interpolate(_named("totalCount"))])},
  "page_user_details_showing_application": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Affichage de ", _interpolate(_named("count")), " application sur 1"])},
  "page_user_details_show_supporting_applications": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Afficher ", _interpolate(_named("count")), " applications de support"])},
  "page_user_details_hide_supporting_applications": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Masquer ", _interpolate(_named("count")), " applications de support"])},
  "page_user_details_show_supporting_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher 1 application de support"])},
  "page_user_details_hide_supporting_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masquer 1 application de support"])},
  "page_user_details_update_user_profile_image_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile image file is invalid or corrupted and could not be accepted"])},
  "page_user_details_update_user_profile_edit_profile_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit profile picture"])},
  "page_user_details_update_user_profile_choose_profile_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose photo ..."])},
  "page_user_details_update_user_profile_image_size_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile image file dimensions are too small. The minimum accepted are 200px width and 200px height"])},
  "page_user_details_reset_password_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le mot de passe"])},
  "page_user_details_reset_password_modal_body_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to send a reset password email to the user?"])},
  "page_user_details_reset_password_modal_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A password reset link is sent to user's primary and recovery email addresses. The password is not automatically reset. The password reset link will expire 15 minutes after it is sent."])},
  "page_user_details_one_time_password_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One-time password"])},
  "page_user_details_one_time_password_modal_body_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The one-time password is:"])},
  "page_user_details_one_time_password_modal_body_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to generate a one-time password for user?"])},
  "page_user_details_one_time_password_modal_body_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user will be required to set a new password as soon as they log in."])},
  "page_users_modal_generate_emergency_code_totp_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The user does not have TOTP enabled."])},
  "page_users_modal_generate_emergency_code_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One-Time Emergency code"])},
  "page_user_details_generate_emergency_code_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to generate an one-time emergency code for user?"])},
  "page_users_modal_generate_emergency_code_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The one-time emergency code is:"])},
  "page_users_modal_generate_emergency_code_how_to_generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose how to generate"])},
  "page_users_modal_generate_emergency_code_to_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate a new one-time emergency code to copy"])},
  "page_users_modal_generate_emergency_code_by_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send the one-time emergency code to the user's inbox"])},
  "page_users_modal_generate_emergency_code_by_email_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An email with one-time emergency code was sent to user's inbox."])},
  "page_users_modal_generate_emergency_code_generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate"])},
  "page_users_modal_generate_emergency_code_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
  "page_user_details_more_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus d'actions"])},
  "page_user_details_send_reset_password_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send reset password email"])},
  "page_user_details_generate_otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate one-time password"])},
  "page_user_details_generate_one_time_emergency_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate one-time emergency code"])},
  "page_user_details_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "page_user_details_last_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière utilisation"])},
  "page_user_details_never_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Never used"])},
  "page_user_details_last_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last changed"])},
  "page_user_details_password_not_set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password not set"])},
  "page_user_reset_password_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last reset password request"])},
  "page_user_not_requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non demandé"])},
  "page_user_password_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe verrouillé"])},
  "page_user_not_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non verrouillé"])},
  "page_user_2_fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification à deux étapes"])},
  "page_user_enforced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forcé"])},
  "page_users_enabled_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activé"])},
  "page_users_not_enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactivé"])},
  "page_users_disabled_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactivé"])},
  "page_users_last_generated_emergency_code_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernier code d'urgence généré à usage unique"])},
  "page_users_visma_connect_idp_disabled_notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in avec Visma est désactivée."])},
  "page_users_visma_connect_idp_disabled_adjust_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez ajuster votre politique si vous souhaitez activer cela."])},
  "page_user_details_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
  "page_user_details_last_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière connexion "])},
  "page_user_details_never_logged_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jamais connecté"])},
  "page_user_details_passkeys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passkeys"])},
  "page_user_details_passkeys_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de passkeys"])},
  "page_user_details_no_passkeys_registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune clé d'accès enregistrée"])},
  "page_users_modal_unlock_password_account_not_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le compte utilisateur n'est pas verrouillé par mot de passe."])},
  "page_users_modal_unlock_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déverrouiller le mot de passe"])},
  "page_user_details_unlock_password_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déverrouiller le mot de passe"])},
  "page_user_details_unlock_password_modal_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe a été déverrouillé avec succès."])},
  "page_users_details_auto_unlocked_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Déverrouillage automatique dans ", _interpolate(_named("count")), " minutes"])},
  "page_users_details_auto_unlocked_1minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déverrouillage automatique en 1 minute"])},
  "page_user_details_unlock_password_modal_body_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir déverrouiller le mot de passe de l'utilisateur ?"])},
  "page_user_details_suspend_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspendre le compte"])},
  "page_user_details_resume_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reprendre le compte"])},
  "page_user_details_suspend_user_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspendre le compte"])},
  "page_user_details_suspend_user_modal_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison de la suspension :"])},
  "page_user_details_suspend_user_modal_body_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etes-vous sûr de vouloir suspendre le compte ?"])},
  "page_users_modal_suspend_user_account_already_suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le compte utilisateur est déjà suspendu."])},
  "page_users_modal_suspend_user_account_not_suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le compte utilisateur n'est pas suspendu."])},
  "page_users_modal_suspend_user_account_invalid_suspend_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison de suspension invalide"])},
  "page_user_details_account_suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte suspendu"])},
  "page_user_details_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison"])},
  "page_user_details_resume_user_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reprendre le compte"])},
  "page_user_details_resume_user_modal_body_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etes-vous sûr de vouloir reprendre le compte ?"])},
  "page_user_details_ip_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse IP"])},
  "page_user_details_device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appareil"])},
  "page_user_details_browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browser"])},
  "page_user_details_not_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune session"])},
  "page_user_details_session_logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
  "page_user_details_session_logout_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion de la session"])},
  "page_user_details_session_logout_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir déconnecter l’utilisateur de la session sélectionnée ?"])},
  "page_users_modal_delete_session_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de déconnecter l'utilisateur de la session sélectionnée"])},
  "page_user_details_current_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session actuelle"])},
  "page_user_details_view_applications": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Afficher toutes les ", _interpolate(_named("applicationsCount")), " applications"])},
  "page_user_details_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Événement"])},
  "page_user_details_timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timestamp"])},
  "page_user_details_payload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payload"])},
  "page_users_no_audit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas d'audit"])},
  "page_users_audit_log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["log d'audit"])},
  "page_users_audit_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logs d'audit"])},
  "page_users_selected_auditcount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sélectionné (", _interpolate(_named("auditCount")), ")"])},
  "page_users_select_auditcount_from_page": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sélectionnez ", _interpolate(_named("auditCount")), " logs d'audit de la page"])},
  "page_select_all_auditcount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sélectionnez tous les ", _interpolate(_named("auditCount")), " logs d'audit"])},
  "page_users_audit_export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter"])},
  "page_users_audit_event_login_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La connexion a échoué"])},
  "page_users_audit_event_login_succeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion réussie"])},
  "page_users_audit_event_client_application_authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application autorisée"])},
  "page_users_audit_event_logout_succeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion réussie"])},
  "page_users_1_selected_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 event"])},
  "page_users_selected_events_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " events"])},
  "page_users_all_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All events"])},
  "page_users_filter_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter events"])},
  "page_users_modal_export_audit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export audit logs"])},
  "page_users_modal_export_audit_body_1_audit_log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 audit log will be exported"])},
  "page_users_modal_export_users_body_n_audit_logs": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " audit logs will be exported"])},
  "page_users_modal_export_user_audit_logs_field_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audit ID"])},
  "page_users_modal_export_user_audit_logs_field_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
  "page_users_modal_export_user_audit_logs_field_timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timestamp"])},
  "page_users_modal_export_user_audit_logs_field_ipv4_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse IP"])},
  "page_users_modal_export_user_audit_logs_field_application_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application ID"])},
  "page_users_modal_export_user_audit_logs_field_application_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l’application"])},
  "page_users_modal_export_user_audit_logs_field_payload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payload"])},
  "page_users_modal_export_audit_logs_start_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export audit logs"])},
  "page_users_audit_search_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unknown error has occurred while searching for audit logs"])},
  "page_users_modal_export_audit_logs_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur inconnue s'est produite lors de l'exportation des journaux d'audit"])},
  "page_user_details_add_recovery_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une adresse e-mail de récupération"])},
  "page_user_details_add_another_recovery_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une autre adresse e-mail de récupération"])},
  "page_user_details_update_user_invalid_recovery_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail de récupération invalide"])},
  "page_user_details_update_user_recovery_email_already_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovery email address is duplicated"])},
  "page_user_details_update_user_recovery_email_disposable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recovery email address is using a disposable domain"])}
}