import ApiClientError from '@/api/error';
import { ERROR_MYDOMAIN_NOT_EXISTS } from '@/api/mydomain-api-client/error-codes';
import oidcIdpClient from '@/api/oidc-idp-client';
import { StatusCodes } from 'http-status-codes';
import { ActionContext } from 'vuex';
import { BasicStoreAccessors, RootState } from '../types';
import mutations from './mutations';
import { OidcIdentityProviderStoreState } from './state';
import OidcIdentityProviderInputModel from '@/api/oidc-idp-client/models/OidcIdentityProviderInputModel';
import OidcIdentityProviderOutputModel from '@/api/oidc-idp-client/models/OidcIdentityProviderOutputModel';
import { ERROR_IDP_NOT_EXISTS } from '@/api/common-error-codes';

let storeApi: BasicStoreAccessors<OidcIdentityProviderStoreState>;

export function initActions(api: BasicStoreAccessors<OidcIdentityProviderStoreState>): void {
    storeApi = api;
}

export default {
    async getOidcIdentityProviderAsync(): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            const oidcIdentityProviderModel = await oidcIdpClient.getAsync();

            if (oidcIdentityProviderModel) {
                storeApi.commit(mutations.setOidcIdentityProvider, oidcIdentityProviderModel);
            }
        } catch (err) {
            const apiError = err as ApiClientError;

            // Ignore the MyDomain does not exist error and simply let the OIDC IDP be null
            if (apiError.statusCode === StatusCodes.NOT_FOUND && apiError.errorCode === ERROR_MYDOMAIN_NOT_EXISTS) {
                return;
            }

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewOidcIdp);
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
        }
    },

    async addOidcIdentityProviderAsync(context: ActionContext<OidcIdentityProviderStoreState, RootState>, model: OidcIdentityProviderInputModel): Promise<OidcIdentityProviderOutputModel> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            const oidcIdentityProviderModel = await oidcIdpClient.addAsync(model);

            storeApi.commit(mutations.setOidcIdentityProvider, oidcIdentityProviderModel);

            return oidcIdentityProviderModel;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
        }
    },

    async updateOidcIdentityProviderAsync(context: ActionContext<OidcIdentityProviderStoreState, RootState>, model: OidcIdentityProviderInputModel): Promise<OidcIdentityProviderOutputModel> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            const oidcIdentityProviderModel = await oidcIdpClient.updateAsync(model);

            storeApi.commit(mutations.setOidcIdentityProvider, oidcIdentityProviderModel);

            return oidcIdentityProviderModel;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
        }
    },

    async deleteOidcIdentityProviderAsync(): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            await oidcIdpClient.deleteAsync();

            storeApi.commit(mutations.setOidcIdentityProvider, void 0);
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.statusCode === StatusCodes.NOT_FOUND && apiError.errorCode === ERROR_IDP_NOT_EXISTS) {
                storeApi.commit(mutations.setOidcIdentityProvider, void 0);
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
        }
    },

    async updateOidcIdentityProviderClientSecretAsync(context: ActionContext<OidcIdentityProviderStoreState, RootState>, clientSecret: string): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);
            await oidcIdpClient.updateClientSecretAsync(clientSecret);
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.statusCode === StatusCodes.NOT_FOUND && apiError.errorCode === ERROR_IDP_NOT_EXISTS) {
                storeApi.commit(mutations.setOidcIdentityProvider, void 0);
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
        }
    },

    async removeOidcIdentityProviderClientSecretAsync(): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);
            await oidcIdpClient.removeClientSecretAsync();
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.statusCode === StatusCodes.NOT_FOUND && apiError.errorCode === ERROR_IDP_NOT_EXISTS) {
                storeApi.commit(mutations.setOidcIdentityProvider, void 0);
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
        }
    },
};
