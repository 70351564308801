import { authStore } from '@/store';
import util from '@/util';
import locator from '@/util/locator';
import logger from '@/util/logger';
import { AxiosInstance } from 'axios';
import { JsonConvert } from 'json2typescript';
import AntiforgeryTokenResponse from './models/AntiforgeryTokenResponse';
import ApiResponse from './models/ApiResponse';

// state
const jsonConverter = new JsonConvert();

let isAntiforgeryTokenRefreshing = false;
let antiforgeryToken: string | undefined;
let antiforgeryTokenExpiration: number | undefined;

let isReauthorizationInProgress = false;
let reauthIframe: HTMLIFrameElement | undefined;
let isReauthIframeLoaded = false;

// helper functions
function createReauthIframe(): void {
    isReauthIframeLoaded = false;

    reauthIframe = document.createElement('iframe');

    reauthIframe.id = 'reauth-iframe';
    reauthIframe.src = locator.computedRedirectToOidcAuthenticationUrl(void 0, '/status'); // /status in order to avoid XHR calls inside the iframe
    reauthIframe.style.display = 'none';
    reauthIframe.style.height = '0';
    reauthIframe.style.width = '0';

    reauthIframe.addEventListener('load', onIframeLoaded);

    document.body.appendChild(reauthIframe);
}

function onIframeLoaded(): void {
    isReauthIframeLoaded = true;
}

async function waitForIframeToLoadAsync(): Promise<void> {
    while (!isReauthIframeLoaded) {
        await util.sleep(50);
    }
}

async function ensureAccessTokenIsRefreshedAsync(): Promise<void> {
    const currentTokenExpiration = authStore.storeApi.get(authStore.getters.accessTokenValidTo);
    let newTokenExpiration: number;

    do {
        await util.sleep(1000);

        await authStore.storeApi.dispatch(authStore.actions.authenticateAsync, true);
        newTokenExpiration = authStore.storeApi.get(authStore.getters.accessTokenValidTo);
    } while (currentTokenExpiration === newTokenExpiration);
}

// default module export
export default {
    async ensureAntiforgeryTokenValidityAsync(httpClient: AxiosInstance, force = false): Promise<void> {
        if (isAntiforgeryTokenRefreshing) {
            return;
        }

        try {
            isAntiforgeryTokenRefreshing = true;

            if (!force && antiforgeryToken && antiforgeryTokenExpiration && Date.now() < antiforgeryTokenExpiration) {
                return;
            }

            logger.log('Refreshing Antiforgery Token');

            const antiforgeryTokenResponse = new ApiResponse(await httpClient.get('/antiforgery'), jsonConverter);
            antiforgeryToken = antiforgeryTokenResponse.data(AntiforgeryTokenResponse)?.token;

            if (util.isNullOrEmpty(antiforgeryToken)) {
                throw new Error('Antiforgery');
            }

            // substract 30s out of the actual availability to cover edge cases and slow networks.
            // so, availability = now() + 600000 ms (10 min, backend config) - 30000 ms (30 s) => 570000 ms (9 min 30 s)
            antiforgeryTokenExpiration = Date.now() + 570000;
            httpClient.defaults.headers.common['X-XSRF-TOKEN'] = antiforgeryToken;
        } finally {
            isAntiforgeryTokenRefreshing = false;
        }
    },

    async ensureAccessTokenValidityAsync(): Promise<void> {
        if (isReauthorizationInProgress) {
            return;
        }

        try {
            isReauthorizationInProgress = true;

            const accessTokenValidTo = authStore.storeApi.get(authStore.getters.accessTokenValidTo);

            // we don't have a session yet or the current session info is still loading
            if (!accessTokenValidTo) {
                return;
            }

            // substract 60s (60000 ms) out of the validity period when verifying token validtity, to cover edge cases and slow networks
            if (Date.now() < accessTokenValidTo - 60000) {
                return;
            }

            // refresh the access token in the backend
            logger.log('Refreshing backend access_token');
            createReauthIframe();
            await waitForIframeToLoadAsync();

            // refrehs token data in the front-end
            logger.log('Refreshing authentication data');
            await ensureAccessTokenIsRefreshedAsync();
        } finally {
            isReauthorizationInProgress = false;

            reauthIframe?.remove();
            reauthIframe = void 0;
        }
    },
};
