export default {
    // notifications
    NOTIF_WARN_UNAUTHORIZED_TO_PERFORM_ACTION: 'notif_warn_unauthorized_to_perform_action',
    NOTIF_WARN_UNAUTHORIZED_TO_VIEW_DATA: 'notif_warn_unauthorized_to_view_data',
    NOTIF_ERROR_SERVER_UNAVAILABLE: 'notif_error_server_unavailable',

    // domains
    PAGE_DOMAINS_MODAL_ADD_ERROR_INVALID_FORMAT: 'page_domains_modal_add_error_invalid_format',
    PAGE_DOMAINS_MODAL_ADD_ERROR_ALREADY_ADDED: 'page_domains_modal_add_error_already_added',
    PAGE_DOMAINS_MODAL_ADD_ERROR: 'page_domains_modal_add_error',
    PAGE_DOMAINS_NOTIF_COMMON_THE_DOMAIN: 'page_domains_notif_common_the_domain',
    PAGE_DOMAINS_NOTIF_VERIFIED_SUCCESSFULLY: 'page_domains_notif_verified_successfully',
    PAGE_DOMAINS_NOTIF_DELETED_SUCCESSFULLY: 'page_domains_notif_deleted_successfully',
    PAGE_DOMAINS_MODAL_DELETE_ERROR_UNKOWN_ERROR: 'page_domains_modal_delete_error_unkown_error',
    PAGE_DOMAINS_MODAL_VERIFY_ERROR_DOMAIN_NOT_FOUND: 'page_domains_modal_verify_error_domain_not_found',
    PAGE_DOMAINS_MODAL_VERIFY_ERROR_DOMAIN_INVALID_SETUP: 'page_domains_modal_verify_error_domain_invalid_setup',
    PAGE_DOMAINS_MODAL_VERIFY_ERROR_DOMAIN_ALREADY_VERIFIED: 'page_domains_modal_verify_error_domain_already_verified',
    PAGE_DOMAINS_MODAL_DELETE_ERROR_SSO_EXIST: 'page_domains_modal_delete_error_sso_exist',
    PAGE_DOMAINS_MODAL_DELETE_ERROR_GROUP_MEMBER_EXISTS: 'page_domains_modal_delete_error_group_member_exists',

    // SSO
    PAGE_SSO_ERROR_NO_DOMAIN_SETUP: 'page_sso_alert_no_verified_domains_text',
    PAGE_SSO_ALERT_NO_ADDED_MYDOMAIN_TEXT: 'page_sso_alert_no_added_mydomain_text',
    PAGE_SSO_ALERT_NO_VERIFIED_DOMAINS_TEXT: 'page_sso_alert_no_verified_domains_text',
    PAGE_SSO_MYDOMAIN_MODAL_ADD_OR_UPDATE_ERROR: 'page_sso_mydomain_modal_add_or_update_error',
    PAGE_SSO_MYDOMAIN_MODAL_ADD_OR_UPDATE_ERROR_INVALID_MYDOMAIN: 'page_sso_mydomain_modal_add_or_update_error_invalid_mydomain',
    PAGE_SSO_MYDOMAIN_MODAL_ADD_OR_UPDATE_ERROR_ALREADY_TAKEN: 'page_sso_mydomain_modal_add_or_update_error_already_taken',
    PAGE_SSO_MYDOMAIN_MODAL_ADD_OR_UPDATE_ERROR_SSO_EXISTS: 'page_sso_mydomain_modal_add_or_update_error_sso_exists',
    PAGE_SSO_MYDOMAIN_NOTIF_ADD_MYDOMAIN_SUCCESS: 'page_sso_mydomain_notif_add_mydomain_success',
    PAGE_SSO_MYDOMAIN_NOTIF_UPDATE_MYDOMAIN_SUCCESS: 'page_sso_mydomain_notif_update_mydomain_success',
    PAGE_SSO_MYDOMAIN_NOTIF_DELETE_MYDOMAIN_SUCCESS: 'page_sso_mydomain_notif_delete_mydomain_success',
    PAGE_SSO_ALERT_IDP_ALREADY_ADDED_TEXT: 'page_sso_alert_idp_already_added_text',
    PAGE_SSO_IDP_NOTIF_ADD_OR_UPDATE_SUCCESS: 'page_sso_idp_notif_add_or_update_success',
    PAGE_SSO_IDP_NOTIF_DELETE_SUCCESS: 'page_sso_idp_notif_delete_success',
    PAGE_SSO_IDP_NOTIF_DELETE_UNKNOWN_ERROR: 'page_sso_idp_notif_delete_unknown_error',
    PAGE_SSO_IDP_NOTIF_ADD_UNKNOWN_ERROR: 'page_sso_idp_notif_add_unknown_error',
    PAGE_SSO_IDP_NOTIF_UPDATE_NO_IDP_ADDED: 'page_sso_idp_notif_update_idp_no_longer_exists',
    PAGE_SSO_IDP_NOTIF_UPDATE_UNKNOWN_ERROR: 'page_sso_idp_notif_update_unknown_error',
    PAGE_SSO_SAML2IDP_NOTIF_INVALID_METADATA: 'page_sso_saml2idp_notif_invalid_metadata',
    PAGE_SSO_SAML2IDP_NOTIF_INVALID_METADATA_CERTIFICATE_EXPIRED: 'page_sso_saml2idp_notif_invalid_metadata_certificate_expired',
    PAGE_SSO_SAML2IDP_NOTIF_INVALID_METADATA_CERTIFICATE_STILL_EXPIRED: 'page_sso_saml2idp_notif_invalid_metadata_certificate_still_expired',
    PAGE_SSO_SAML2IDP_SETUP_STEP_PROVIDER_METADATA_ERROR_METADATA_EMPTY: 'page_sso_saml2idp_setup_step_provider_metadata_error_metadata_empty',
    PAGE_SSO_SAML2IDP_SETUP_STEP_PROVIDER_METADATA_ERROR_METADATA_URL_INVALID: 'page_sso_saml2idp_setup_step_provider_metadata_error_metadata_url_invalid',
    PAGE_SSO_SAML2IDP_SETUP_STEP_PROVIDER_METADATA_ERROR_METADATA_FILE_INVALID: 'page_sso_saml2idp_setup_step_provider_metadata_error_metadata_file_invalid',
    PAGE_SSO_SAML2IDP_SETUP_MISMATCHING_METADATA: 'page_sso_saml2idp_setup_mismatching_metadata',
    PAGE_SSO_SAML2IDP_INVALID_RELAY_STATE_URI: 'page_sso_saml2idp_invalid_relay_state_uri',
    PAGE_SSO_OIDCIDP_INVALID_AUTHORITY_ERROR: 'page_sso_oidcidp_invalid_authority_error',
    PAGE_SSO_OIDCIDP_INVALID_AUTHORITY_FOR_PROVIDER_ERROR: 'page_sso_oidcidp_invalid_authority_for_provider_error',
    PAGE_SSO_OIDCIDP_NOTIF_CLIENT_SECRET_NOT_ALLOWED: 'page_sso_oidcidp_notif_client_secret_not_allowed',
    PAGE_SSO_SURFCONEXT_NOTIF_ALREADY_ADDED_TEXT: 'page_sso_surfconext_notif_already_added_text',

    // provisioning
    PAGE_PROVISIONING_SCIM_UNSUPPORTED_ACTIONS: 'page_provisioning_scim_unsupported_actions',
    PAGE_PROVISIONING_LOGS_UNKNOWN_ERROR: 'page_provisioning_logs_unknown_error',

    // Nav menu - jobs
    NAV_MENU_JOBS_CHANGE_DOMAIN_JOB_TYPE: 'nav_menu_jobs_change_domain_job_type',
    NAV_MENU_JOBS_USERS_EXPORT_JOB_TYPE: 'nav_menu_jobs_users_export_job_type',
    NAV_MENU_JOBS_USERS_IMPORT_JOB_TYPE: 'nav_menu_jobs_users_import_job_type',
    NAV_MENU_JOBS_USER_AUDIT_EXPORT_JOB_TYPE: 'nav_menu_jobs_user_audit_export_job_type',
    NAV_MENU_JOBS_STATUS_QUEUED: 'nav_menu_jobs_status_queued',
    NAV_MENU_JOBS_STATUS_PROCESSING: 'nav_menu_jobs_status_processing',
    NAV_MENU_JOBS_STATUS_COMPLETED: 'nav_menu_jobs_status_completed',
    NAV_MENU_JOBS_STATUS_COMPLETED_WITH_ERRORS: 'nav_menu_jobs_status_completed_with_errors',
    NAV_MENU_JOBS_STATUS_FAILED: 'nav_menu_jobs_status_failed',

    // policies
    PAGE_POLICIES_UPDATE_UNKNOWN_ERROR: 'page_policies_notif_update_unknown_error',
    PAGE_POLICIES_2_STEP_VERIFICATION_ON_STATE: 'page_policies_2_step_verification_on_state',
    PAGE_POLICIES_2_STEP_VERIFICATION_OFF_STATE: 'page_policies_2_step_verification_off_state',
    PAGE_POLICIES_ALL_ALLOWED: 'page_policies_all_allowed',
    PAGE_POLICIES_PASSWORD_MINIMMUM_LENGTH: 'page_policies_password_minimmum_length',
    PAGE_POLICIES_PASSWORD_REQUIRE_DIGIT_CHARACTER: 'page_policies_password_require_digit_character',
    PAGE_POLICIES_PASSWORD_REQUIRE_DIGIT_CHARACTERS: 'page_policies_password_require_digit_characters',
    PAGE_POLICIES_PASSWORD_REQUIRE_UPPERCASE_CHARACTER: 'page_policies_password_require_uppercase_character',
    PAGE_POLICIES_PASSWORD_REQUIRE_UPPERCASE_CHARACTERS: 'page_policies_password_require_uppercase_characters',
    PAGE_POLICIES_PASSWORD_REQUIRE_LOWERCASE_CHARACTER: 'page_policies_password_require_lowercase_character',
    PAGE_POLICIES_PASSWORD_REQUIRE_LOWERCASE_CHARACTERS: 'page_policies_password_require_lowercase_characters',
    PAGE_POLICIES_PASSWORD_REQUIRE_SPECIAL_CHARACTER: 'page_policies_password_require_special_character',
    PAGE_POLICIES_PASSWORD_REQUIRE_SPECIAL_CHARACTERS: 'page_policies_password_require_special_characters',
    PAGE_POLICIES_PASSWORD_ACCOUNTS_LOCKED_AFTER_ONE_LOGIN_FAILURE: 'page_policies_password_accounts_locked_after_one_login_failure',
    PAGE_POLICIES_PASSWORD_ACCOUNTS_LOCKED_AFTER_LOGIN_FAILURES: 'page_policies_password_accounts_locked_after_login_failures',
    PAGE_POLICIES_PASSWORD_EXPIRES_AFTER_ONE_DAY: 'page_policies_password_expires_after_one_day',
    PAGE_POLICIES_PASSWORD_EXPIRES_AFTER_DAYS: 'page_policies_password_expires_after_days',
    PAGE_POLICIES_PASSWORD_KEEP_ONE_IN_HISTORY: 'page_policies_password_keep_one_in_history',
    PAGE_POLICIES_PASSWORD_KEEP_IN_HISTORY: 'page_policies_password_keep_in_history',
    PAGE_POLICIES_SESSIONS_MAX_CONCURRENT_SESSIONS_PER_USERS_VALUE: 'page_policies_sessions_max_concurrent_sessions_per_users_value',
    PAGE_POLICIES_SESSIONS_LOCK_TO_ORIGIN_IP_VALUE: 'page_policies_sessions_lock_to_origin_ip_value',

    // users import modal
    PAGE_USERS_MODAL_IMPORT_USERS_MANDATORY_COLUMN_MISSING: 'page_users_modal_import_users_mandatory_column_missing',
    PAGE_USERS_MODAL_IMPORT_USERS_INVALID_COLUMN: 'page_users_modal_import_users_invalid_column',
    PAGE_USERS_MODAL_IMPORT_USERS_INVALID_NUMBER_OF_COLUMNS: 'page_users_modal_import_users_invalid_number_of_columns',
    PAGE_USERS_MODAL_IMPORT_USERS_INVALID_HEADER: 'page_users_modal_import_users_invalid_header',
    PAGE_USERS_MODAL_IMPORT_USERS_INVALID_CSV_CONTENT: 'page_users_modal_import_users_invalid_csv_content',

    // users create modal
    PAGE_USERS_MODAL_ADD_USER_INVALID_FIRST_NAME: 'page_users_modal_add_user_invalid_first_name',
    PAGE_USERS_MODAL_ADD_USER_INVALID_LAST_NAME: 'page_users_modal_add_user_invalid_last_name',
    PAGE_USERS_MODAL_ADD_USER_INVALID_LANGUAGE: 'page_users_modal_add_user_invalid_language',
    PAGE_USERS_MODAL_ADD_USER_INVALID_COUNTRY: 'page_users_modal_add_user_invalid_country',
    PAGE_USERS_MODAL_ADD_USER_INVALID_EMAIL: 'page_users_modal_add_user_invalid_email',
    PAGE_USERS_MODAL_ADD_USER_EMAIL_EXISTS: 'page_users_modal_add_user_email_exists',
    PAGE_USERS_MODAL_ADD_USER_UNKNOWN_ERROR: 'page_users_modal_add_user_unknown_error',

    // user details
    PAGE_USER_DETAILS_UPDATE_USER_UNKNOWN_ERROR: 'page_user_details_update_user_unknown_error',
    PAGE_USER_DETAILS_UPDATE_USER_INVALID_RECOVERY_EMAIL: 'page_user_details_update_user_invalid_recovery_email',
    PAGE_USER_DETAILS_UPDATE_USER_RECOVERY_EMAIL_ALREADY_EXISTS: 'page_user_details_update_user_recovery_email_already_exists',
    PAGE_USER_DETAILS_UPDATE_USER_RECOVERY_EMAIL_DISPOSABLE: 'page_user_details_update_user_recovery_email_disposable',

    // user profile image
    PAGE_USER_DETAILS_UPDATE_USER_PROFILE_IMAGE_ERROR: 'page_user_details_update_user_profile_image_error',
    PAGE_USER_DETAILS_UPDATE_USER_PROFILE_IMAGE_SIZE_ERROR: 'page_user_details_update_user_profile_image_size_error',

    // user security
    PAGE_USERS_MODAL_GENERATE_EMERGENCY_CODE_TOTP_DISABLED: 'page_users_modal_generate_emergency_code_totp_disabled',
    PAGE_USERS_MODAL_UNLOCK_PASSWORD_ACCOUNT_NOT_LOCKED: 'page_users_modal_unlock_password_account_not_locked',
    PAGE_USERS_MODAL_SUSPEND_USER_ACCOUNT_ALREADY_SUSPENDED: 'page_users_modal_suspend_user_account_already_suspended',
    PAGE_USERS_MODAL_SUSPEND_USER_ACCOUNT_NOT_SUSPENDED: 'page_users_modal_suspend_user_account_not_suspended',
    PAGE_USERS_MODAL_SUSPEND_USER_ACCOUNT_INVALID_SUSPEND_REASON: 'page_users_modal_suspend_user_account_invalid_suspend_reason',

    // user sessions
    PAGE_USERS_MODAL_DELETE_SESSION_ERROR: 'page_users_modal_delete_session_error',

    // user audit logs
    PAGE_USERS_AUDIT_SEARCH_UNKNOWN_ERROR: 'page_users_audit_search_unknown_error',
    PAGE_USERS_MODAL_EXPORT_AUDIT_LOGS_UNKNOWN_ERROR: 'page_users_modal_export_audit_logs_unknown_error',

    // delete groups modal
    PAGE_GROUPS_REMOVE_MEMBER_INFO: 'page_groups_remove_member_info',
    PAGE_GROUPS_DELETE_GROUP_INFO: 'page_groups_delete_group_info',
    PAGE_GROUPS_REMOVE_MEMBER_GROUP_INFO: 'page_groups_remove_member_group_info',

    //common
    COMMON_YES: 'common_yes',
    COMMON_NO: 'common_no',
};
