import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "user-statistics-pane",
  class: "row"
}
const _hoisted_2 = { class: "panel-heading" }
const _hoisted_3 = { class: "panel-title" }
const _hoisted_4 = { class: "panel-body row" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = { class: "row stats-titles" }
const _hoisted_7 = { class: "col" }
const _hoisted_8 = { class: "col" }
const _hoisted_9 = { class: "panel-heading" }
const _hoisted_10 = { class: "panel-title" }
const _hoisted_11 = { class: "panel-body row" }
const _hoisted_12 = { class: "col" }
const _hoisted_13 = { class: "row stats-titles" }
const _hoisted_14 = { class: "col" }
const _hoisted_15 = {
  id: "authentication-charts",
  class: "panel w-100"
}
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { class: "stats-pt" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isFeatureEnabled)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          id: "authentication-status",
          class: _normalizeClass(["panel col", { disabled: !_ctx.areStatsAvailable }])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t('page_dashboard_statistics_auth_status_title')), 1)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.$t('common_success')), 1),
                _createElementVNode("h3", _hoisted_8, _toDisplayString(_ctx.$t('common_failed')), 1)
              ]),
              _cache[0] || (_cache[0] = _createElementVNode("div", { class: "row stats-values" }, [
                _createElementVNode("h1", { class: "col" }, "0"),
                _createElementVNode("h1", { class: "col" }, "0")
              ], -1))
            ]),
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "col" }, "illustration here", -1))
          ])
        ], 2),
        _createElementVNode("div", {
          id: "users-status",
          class: _normalizeClass(["panel col-5", _ctx.areStatsAvailable ? 'users-status-enabled' : 'disabled users-status-disabled'])
        }, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("h3", _hoisted_10, _toDisplayString(_ctx.$t('page_dashboard_statistics_users')), 1)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("h3", _hoisted_14, _toDisplayString(_ctx.$t('common_total')), 1)
              ]),
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "row stats-values" }, [
                _createElementVNode("h1", { class: "col" }, "0")
              ], -1))
            ])
          ])
        ], 2),
        _createElementVNode("div", _hoisted_15, [
          (_ctx.areStatsAvailable)
            ? (_openBlock(), _createElementBlock("div", _hoisted_16, "Stats available"))
            : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                _cache[5] || (_cache[5] = _createElementVNode("object", {
                  class: "float-left",
                  id: "no-stats-arrow",
                  type: "image/svg+xml",
                  data: "/img/pages/dashboard/twisted_pointing_arrow.svg"
                }, null, -1)),
                _createElementVNode("div", _hoisted_18, [
                  _cache[3] || (_cache[3] = _createElementVNode("object", {
                    id: "no-stats-file",
                    type: "image/svg+xml",
                    data: "/img/pages/dashboard/happy_file.svg"
                  }, null, -1)),
                  _cache[4] || (_cache[4] = _createElementVNode("h3", null, [
                    _createElementVNode("strong", null, "No data available")
                  ], -1)),
                  _createElementVNode("h4", {
                    class: _normalizeClass({ disabled: !_ctx.areStatsAvailable })
                  }, "Please \"Add domain\" above in order to see authentications", 2)
                ])
              ]))
        ])
      ]))
    : _createCommentVNode("", true)
}