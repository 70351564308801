
import { computed, defineComponent, watch } from 'vue';
import { authStore } from '@/store';

export default defineComponent({
    name: 'ModalDialogComponent',

    components: {},

    props: {
        size: {
            type: String,
            required: false,
            default: 'md',
            validator: (value: string) => {
                return ['md', 'sm', 'lg', 'xls', 'xl'].indexOf(value) !== -1;
            },
        },
        show: {
            type: Boolean,
            required: true,
        },
        showCloseBtn: {
            type: Boolean,
            required: false,
            default: true,
        },
        title: {
            type: String,
            required: false,
            default: '',
        },
        icon: {
            type: String,
            required: false,
            default: 'none',
            validator: (value: string) => {
                return ['none', 'info', 'help', 'success', 'warning', 'error'].indexOf(value) !== -1;
            },
        },
        errorMessage: {
            type: String,
            required: false,
        },
        showLoadingSpinner: {
            type: Boolean,
            required: false,
            default: false,
        },
        hideWhenSessionExpired: {
            type: Boolean,
            required: false,
            default: true,
        },
        bodyOverflow: {
            type: String,
            required: false,
            validator: (value: string) => {
                return ['auto', 'scroll', 'visible', 'hidden'].indexOf(value) !== -1;
            },
        },
    },

    emits: ['close', 'show', 'clear-error'],

    setup(props, { emit }) {
        // computed
        const isSessionExpired = computed(() => !authStore.storeApi.get(authStore.getters.isAuthenticated) && props.hideWhenSessionExpired);

        // functions
        function close() {
            emit('close');
        }

        function clearError() {
            emit('clear-error');
        }

        watch(
            () => props.show,
            async (value: boolean) => {
                if (value) {
                    emit('show');
                }
            }
        );

        return {
            // computed
            isSessionExpired,

            // functions
            close,
            clearError,
        };
    },
});
