import ProvisioningModel from '@/api/provisioning-api-client/models/ProvisioningModel';
import StoreEntity from '../models';
import IdentityProviderScimConfigModel from '@/api/provisioning-api-client/models/IdentityProviderScimConfigModel';

export class ProvisioningStoreState extends StoreEntity {
    constructor() {
        super();

        this.provisioningData = void 0;
        this.isUnauthorizedToViewProvisioning = false;
        this.identityProvidersScimConfig = [];
        this.isIdentityProvidersScimConfigLoaded = false;
    }

    public provisioningData: ProvisioningModel | undefined;

    public isUnauthorizedToViewProvisioning: boolean;

    public identityProvidersScimConfig: IdentityProviderScimConfigModel[];

    public isIdentityProvidersScimConfigLoaded: boolean;
}

export default new ProvisioningStoreState();
