import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('ClientIdInputModel')
export default class ClientIdInputModel {
    public constructor(clientIdentity?: string) {
        if (clientIdentity) {
            this.clientIdentity = clientIdentity;
        } else {
            this.clientIdentity = '';
        }
    }

    @JsonProperty('application_id', String)
    public clientIdentity: string;
}
