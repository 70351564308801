import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('AuthenticationPolicyBodyTwoStepVerificationModel')
export default class AuthenticationPolicyBodyTwoStepVerificationModel {
    public constructor() {
        this.enforced = false;
    }

    @JsonProperty('enforced', Boolean)
    public enforced: boolean;

    @JsonProperty('trusted_device_days', Number, true)
    public trustedDeviceDays?: number;

    @JsonProperty('methods', [String], true)
    public methods?: string[];

    @JsonProperty('enforced_third_party_identity_providers', [String], true)
    public enforcedThirdPartyIdentityProviders?: string[];
}
