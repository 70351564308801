import { UserStoreState } from './state';
import UsersModel from '@/api/user-api-client/models/UsersModel';
import UserSearchInputModel from '@/api/user-api-client/models/UserSearchInputModel';
import SelectedUsersModel from '@/api/user-api-client/models/SelectedUsers/SelectedUsersModel';
import UserProfileModel from '@/api/user-api-client/models/UserProfileModel';
import UserApplicationsModel from './models/UserApplicationsModel';
import UserSessionModel from '@/api/user-api-client/models/UserSessionModel';
import AuditLogsModel from '@/api/user-api-client/models/AuditLogsModel';
import AuditSearchInputModel from '@/api/user-api-client/models/AuditSearchInputModel';
import GroupsModel from '@/api/group-api-client/models/GroupsModel';
import GroupSearchInputModel from '@/api/group-api-client/models/GroupSearchInputModel';

export default {
    userList(state: UserStoreState): UsersModel {
        return state.userList;
    },

    userSearchInput(state: UserStoreState): UserSearchInputModel {
        return state.userSearchInput;
    },

    isProcessing(state: UserStoreState): boolean {
        return state.isProcessing;
    },

    isUnauthorizedToViewUsers(state: UserStoreState): boolean {
        return state.isUnauthorizedToViewUsers;
    },

    isDataLoaded(state: UserStoreState): boolean {
        return state.isDataLoaded;
    },

    selectedUsers(state: UserStoreState): SelectedUsersModel {
        return state.selectedUsers;
    },

    selectedUsersCount(state: UserStoreState): number {
        return state.selectedUsers.totalUsersCount;
    },

    userDetailsId(state: UserStoreState): string | undefined {
        return state.userDetails.id;
    },

    userDetailsProfile(state: UserStoreState): UserProfileModel {
        return state.userDetails.profile;
    },

    userDetailsApplications(state: UserStoreState): UserApplicationsModel | undefined {
        return state.userDetails.applications;
    },

    userSessions(state: UserStoreState): UserSessionModel[] {
        return state.userDetails.sessions;
    },

    userGroups(state: UserStoreState): GroupsModel | undefined {
        return state.userDetails.groups;
    },

    userGroupSearchInput(state: UserStoreState): GroupSearchInputModel {
        return state.userDetails.groupSearchInputModel;
    },

    userAudit(state: UserStoreState): AuditLogsModel | undefined {
        return state.userDetails.audit;
    },

    auditSearchInput(state: UserStoreState): AuditSearchInputModel {
        return state.userDetails.auditSearchInput;
    },

    selectedAuditLogs(state: UserStoreState): string[] {
        return state.userDetails.selectedAuditLogs;
    },

    excludedAuditLogs(state: UserStoreState): string[] {
        return state.userDetails.excludedAuditLogs;
    },

    allAuditLogsSelected(state: UserStoreState): boolean {
        return state.userDetails.allAuditLogsSelected;
    },
};
