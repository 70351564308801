export default {
  "page_users_no_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen brukere"])},
  "page_users_users_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukere"])},
  "page_users_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["brukere"])},
  "page_users_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bruker"])},
  "page_users_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-postadresse"])},
  "page_users_recovery_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-post for gjenoppretting"])},
  "page_users_username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukernavn"])},
  "page_users_first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornavn"])},
  "page_users_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etternavn"])},
  "page_users_last_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sist innlogget"])},
  "page_users_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprettet"])},
  "page_users_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppdatert"])},
  "page_users_language_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])},
  "page_users_country_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
  "page_users_applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikasjoner"])},
  "page_users_electronic_id_enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektronisk ID aktivert"])},
  "page_users_2step_enabled_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totrinnskontroll aktivert"])},
  "page_users_search_email_or_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søk etter e-post eller navn"])},
  "page_users_bulk_email_domain_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulk-endringer for e-postdomene"])},
  "page_users_user_export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukereksport"])},
  "page_users_modal_domain_change_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Advarsel - brukerkontoer vil bli endret"])},
  "page_users_modal_domain_change_body_n_users_n_domains": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du er i ferd med å endre ", _interpolate(_named("usersCount")), " brukerkontoer fra ", _interpolate(_named("domainsCount")), " domener"])},
  "page_users_modal_domain_change_body_1_user_1_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du er i ferd med å endre en brukerkonto fra ett domene"])},
  "page_users_modal_domain_change_body_n_users_1_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du er i ferd med å endre ", _interpolate(_named("usersCount")), " brukerkontoer fra ett domene"])},
  "page_users_modal_domain_change_body_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sørg for å fortelle brukerne om endringene"])},
  "page_users_modal_domain_change_source_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kildedomener"])},
  "page_users_modal_domain_change_target_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Måldomene"])},
  "page_users_modal_domain_change_start_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endre domene"])},
  "page_users_modal_domain_change_not_target_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det er ingen tilgjengelige måldomener. Fjern merket for ett av kildedomenene og prøv igjen"])},
  "page_users_modal_domain_change_approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg anerkjenner at denne handlingen vil endre valgte brukerkontoer"])},
  "page_users_modal_domain_change_example_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksempel"])},
  "page_users_modal_domain_change_example_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["john.doe", "@", _interpolate(_named("sourceDomain")), " vil bli endret til john.doe", "@", _interpolate(_named("targetDomain"))])},
  "page_users_modal_export_users_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksporter brukere"])},
  "page_users_modal_export_users_start_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksporter brukere"])},
  "page_users_modal_export_users_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domener"])},
  "page_users_modal_export_users_body_1_user_1_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En bruker fra ett domene vil bli eksportert"])},
  "page_users_modal_export_users_body_n_users_1_domain": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("usersCount")), " brukere fra ett domene vil bli eksportert"])},
  "page_users_modal_export_users_body_n_user_n_domains": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("usersCount")), " brukere fra ", _interpolate(_named("domainsCount")), " domener vil bli eksportert"])},
  "page_users_modal_export_users_select_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg kolonnene med data du vil eksportere"])},
  "page_users_modal_export_users_field_user_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukernr."])},
  "page_users_modal_export_users_field_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-post"])},
  "page_users_modal_export_users_field_first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornavn"])},
  "page_users_modal_export_users_field_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etternavn"])},
  "page_users_modal_export_users_field_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
  "page_users_modal_export_users_field_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])},
  "page_users_modal_export_users_field_applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikasjoner"])},
  "page_users_modal_export_users_field_electronic_id_enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektronisk ID aktivert"])},
  "page_users_modal_export_users_field_2step_enabled_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dato for aktivering av totrinnskontroll"])},
  "page_users_modal_export_users_field_applications_selected_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merk: Du eksporterer programmer for en bruker. Eksporten vil opprette en rad per bruker/program, slik at den samme brukeren gjentas på flere rader hvis han/hun har mer enn 1 applikasjon."])},
  "page_users_clear_selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern utvalg"])},
  "page_users_select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg alle"])},
  "page_users_add_to_selection_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg alle til utvalget"])},
  "page_users_select_all_from_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg alt fra siden"])},
  "page_users_add_to_selection_all_from_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til alt fra siden til utvalget"])},
  "page_users_select_all_from_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg alt fra filter"])},
  "page_users_add_to_selection_all_from_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til alt fra filter til utvalg"])},
  "page_users_selected_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Valgte (", _interpolate(_named("usersCount")), ")"])},
  "page_users_select_usercount_from_filter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Velg ", _interpolate(_named("usersCount")), " brukere fra filteret"])},
  "page_users_select_usercount_from_page": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Velg ", _interpolate(_named("usersCount")), " brukere fra siden"])},
  "page_select_all_usercount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Velg alle ", _interpolate(_named("usersCount")), " brukere"])},
  "page_users_modal_export_users_export_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksport"])},
  "page_users_modal_export_users_column_name_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kolonnenavn"])},
  "page_users_1_selected_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 domene"])},
  "page_users_selected_domains_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("domainsCount")), " domener"])},
  "page_users_all_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle domener"])},
  "page_users_filter_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer domener"])},
  "page_users_clear_filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern filter"])},
  "page_users_add_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til bruker"])},
  "page_users_import_or_update_users_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer brukere"])},
  "page_users_modal_import_users_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer eller oppdater brukere"])},
  "page_users_modal_import_users_upload_file_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg fil"])},
  "page_users_modal_import_users_import_users_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer eller oppdater brukere"])},
  "page_users_modal_import_users_select_csv_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg en CSV-fil"])},
  "page_users_modal_import_users_required_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruker-ID, e-post, fornavn, etternavn, land og språk er obligatoriske felt. Alle mellomrom foran og etter vil bli fjernet"])},
  "page_users_modal_import_users_specify_user_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angi alltid en verdi for bruker-ID hvis du ønsker å oppdatere brukerkontoer, ellers vil en ny bruker bli opprettet"])},
  "page_users_modal_import_users_add_edit_user_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til eller rediger brukerinformasjon i CSV-malen"])},
  "page_users_modal_import_users_download_csv_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last ned CSV-mal"])},
  "page_users_modal_import_users_download_csv_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last ned CSV-mal"])},
  "page_users_modal_import_users_upload_csv_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last opp CSV-fil"])},
  "page_users_modal_import_users_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du må fullføre disse trinnene for å importere eller oppdatere brukere"])},
  "page_users_modal_import_users_mandatory_column_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En eller flere obligatoriske kolonner mangler: "])},
  "page_users_modal_import_users_invalid_column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Én eller flere kolonner er ugyldige:"])},
  "page_users_modal_import_users_invalid_number_of_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugyldig antall kolonner på linjen: "])},
  "page_users_modal_import_users_invalid_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overskriften mangler eller er ugyldig"])},
  "page_users_modal_import_users_invalid_csv_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugyldig innhold i CSV-fil"])},
  "page_users_modal_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
  "page_users_modal_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])},
  "page_users_modal_all_countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle land"])},
  "page_users_modal_all_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle språk"])},
  "page_users_modal_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passord"])},
  "page_users_modal_add_user_invalid_first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugyldig fornavn"])},
  "page_users_modal_add_user_invalid_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugyldig etternavn"])},
  "page_users_modal_add_user_invalid_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugyldig språk"])},
  "page_users_modal_add_user_invalid_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugyldig land"])},
  "page_users_modal_add_user_invalid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugyldig e-post"])},
  "page_users_modal_add_user_email_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posten finnes allerede"])},
  "page_users_modal_add_user_success_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukeren ble lagt til"])},
  "page_users_modal_add_user_success_otp_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukeren ble opprettet og har engangspassordet ovenfor."])},
  "page_users_modal_add_user_success_email_link_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En e-post med påloggingsinstruksjoner ble sendt til brukerens e-postadresse."])},
  "page_users_modal_add_user_auto_generate_otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generer engangspassord automatisk"])},
  "page_users_modal_add_user_send_email_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send e-post for kontoaktivering til brukeren"])},
  "page_users_modal_add_user_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det har oppstått en ukjent feil da brukeren ble lagt til."])},
  "page_users_modal_select_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg domene"])},
  "page_user_details_back_to_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbake til brukere"])},
  "page_user_details_tab_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
  "page_user_details_tab_security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikkerhet"])},
  "page_user_details_tab_devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enheter"])},
  "page_user_details_tab_audit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisjonslogger"])},
  "page_user_details_tab_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupper"])},
  "page_user_details_tab_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Økter"])},
  "page_user_details_tab_email_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-posthistorikk"])},
  "page_user_details_tab_passwordless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uten passord"])},
  "page_user_details_tab_linked_eids": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koblede eID-er"])},
  "page_user_details_tab_applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikasjoner"])},
  "page_user_details_recovery_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-post for gjenoppretting"])},
  "page_user_details_phone_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummere"])},
  "page_user_details_edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger profil"])},
  "page_user_details_update_user_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det har oppstått en ukjent feil under oppdatering av denne brukeren"])},
  "page_user_details_application_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikasjonsnavn"])},
  "page_user_details_authorization_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisasjonsdato"])},
  "page_user_details_login_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innloggingsdato"])},
  "page_user_details_login_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antall innlogginger"])},
  "page_user_details_no_applications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen applikasjoner"])},
  "page_user_details_showing_applications": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Viser ", _interpolate(_named("count")), " av ", _interpolate(_named("totalCount")), " applikasjoner"])},
  "page_user_details_showing_application": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vis ", _interpolate(_named("count")), " av 1 applikasjon"])},
  "page_user_details_show_supporting_applications": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vis ", _interpolate(_named("count")), " applikasjoner som støtter"])},
  "page_user_details_hide_supporting_applications": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Skjul ", _interpolate(_named("count")), " applikasjoner som støtter"])},
  "page_user_details_show_supporting_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis 1applikasjon som støtter"])},
  "page_user_details_hide_supporting_application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skjul 1applikasjon som støtter"])},
  "page_user_details_update_user_profile_image_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilbildefilen er ugyldig eller ødelagt og kan ikke godtas"])},
  "page_user_details_update_user_profile_edit_profile_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger profilbilde"])},
  "page_user_details_update_user_profile_choose_profile_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg bilde ..."])},
  "page_user_details_update_user_profile_image_size_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensjonene til profilbildefilen er for små. Minimum akseptert er 200px bredde og 200px høyde"])},
  "page_user_details_reset_password_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbakestill passord"])},
  "page_user_details_reset_password_modal_body_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker på at du vil sende en e-post for tilbakestilling av passord til brukeren?"])},
  "page_user_details_reset_password_modal_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En lenke for tilbakestilling av passord er sendt til brukerens primære e-postadresse og gjenopprettingsadresse. Passordet tilbakestilles ikke automatisk. Koblingen for tilbakestilling av passord vil utløpe 15 minutter etter at den er sendt."])},
  "page_user_details_one_time_password_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engangspassord"])},
  "page_user_details_one_time_password_modal_body_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engangspassordet er:"])},
  "page_user_details_one_time_password_modal_body_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker på at du vil generere et engangspassord for brukeren?"])},
  "page_user_details_one_time_password_modal_body_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukeren vil bli bedt om å angi et nytt passord så snart de logger inn."])},
  "page_users_modal_generate_emergency_code_totp_disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukeren har ikke TOTP (tidsbegrensede engangskode) aktivert."])},
  "page_users_modal_generate_emergency_code_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engangskode"])},
  "page_user_details_generate_emergency_code_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker på at du vil generere en engangsberedskapskode for brukeren?"])},
  "page_users_modal_generate_emergency_code_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engangskoden er:"])},
  "page_users_modal_generate_emergency_code_how_to_generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg hvordan du vil generere"])},
  "page_users_modal_generate_emergency_code_to_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generer en ny engangskode for å kopiere"])},
  "page_users_modal_generate_emergency_code_by_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send engangskoden til brukerens innboks"])},
  "page_users_modal_generate_emergency_code_by_email_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En e-post med engangskoden ble sendt til brukerens innboks."])},
  "page_users_modal_generate_emergency_code_generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generer"])},
  "page_users_modal_generate_emergency_code_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "page_user_details_more_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flere handlinger"])},
  "page_user_details_send_reset_password_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send e-post for tilbakestilling av passord"])},
  "page_user_details_generate_otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generer engangspassord"])},
  "page_user_details_generate_one_time_emergency_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generer engangskode"])},
  "page_user_details_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passord"])},
  "page_user_details_last_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sist brukt"])},
  "page_user_details_never_used": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aldri brukt"])},
  "page_user_details_last_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sist endret"])},
  "page_user_details_password_not_set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passord er ikke angitt"])},
  "page_user_reset_password_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siste forespørsel om tilbakestilling av passord"])},
  "page_user_not_requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke forespurt"])},
  "page_user_password_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passord låst"])},
  "page_user_not_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke låst"])},
  "page_user_2_fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totrinnskontroll"])},
  "page_user_enforced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Håndhevet"])},
  "page_users_enabled_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivert"])},
  "page_users_not_enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke aktivert"])},
  "page_users_disabled_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktivert"])},
  "page_users_last_generated_emergency_code_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siste genererte engangsberedskapskode"])},
  "page_users_visma_connect_idp_disabled_notification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logg inn med Visma er deaktivert."])},
  "page_users_visma_connect_idp_disabled_adjust_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juster policyen din hvis du vil aktivere dette."])},
  "page_user_details_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innlogging"])},
  "page_user_details_last_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siste innlogging"])},
  "page_user_details_never_logged_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aldri logget inn"])},
  "page_user_details_passkeys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilgangskode"])},
  "page_user_details_passkeys_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antall tilgangskoder"])},
  "page_user_details_no_passkeys_registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen tilgangskoder registrert"])},
  "page_users_modal_unlock_password_account_not_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukerkontoen er ikke låst med passord."])},
  "page_users_modal_unlock_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lås opp passord"])},
  "page_user_details_unlock_password_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lås opp passord"])},
  "page_user_details_unlock_password_modal_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passordet ble låst opp."])},
  "page_users_details_auto_unlocked_minutes": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Låses opp automatisk om ", _interpolate(_named("count")), " minutter"])},
  "page_users_details_auto_unlocked_1minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Låses opp automatisk om 1 minutt"])},
  "page_user_details_unlock_password_modal_body_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker på at du vil låse opp passordet for brukeren?"])},
  "page_user_details_suspend_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utesteng konto"])},
  "page_user_details_resume_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjenoppta konto"])},
  "page_user_details_suspend_user_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utesteng konto"])},
  "page_user_details_suspend_user_modal_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årsak til utestengelse:"])},
  "page_user_details_suspend_user_modal_body_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker på at du vil utestenge kontoen?"])},
  "page_users_modal_suspend_user_account_already_suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukerkontoen er allerede utestengt."])},
  "page_users_modal_suspend_user_account_not_suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brukerkontoen er ikke utestengt."])},
  "page_users_modal_suspend_user_account_invalid_suspend_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugyldig årsak til utestengelse"])},
  "page_user_details_account_suspended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoen er utestengt"])},
  "page_user_details_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Årsak"])},
  "page_user_details_resume_user_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjenoppta konto"])},
  "page_user_details_resume_user_modal_body_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker på at du vil gjenoppta kontoen?"])},
  "page_user_details_ip_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-adresse"])},
  "page_user_details_device": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enhet"])},
  "page_user_details_browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettleser"])},
  "page_user_details_not_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen økter"])},
  "page_user_details_session_logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logg ut"])},
  "page_user_details_session_logout_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logg ut fra økten"])},
  "page_user_details_session_logout_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker på at du vil logge av den valgte økten?"])},
  "page_users_modal_delete_session_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunne ikke logge ut bruker fra valgt økt"])},
  "page_user_details_current_session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjeldende økt"])},
  "page_user_details_view_applications": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vis alle ", _interpolate(_named("applicationsCount")), " appene"])},
  "page_user_details_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hendelse"])},
  "page_user_details_timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidsangivelse"])},
  "page_user_details_payload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyttelast"])},
  "page_users_no_audit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen revisjon"])},
  "page_users_audit_log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["revisjonslogg"])},
  "page_users_audit_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["revisjonslogger"])},
  "page_users_selected_auditcount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Valgte (", _interpolate(_named("auditCount")), ")"])},
  "page_users_select_auditcount_from_page": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Velg ", _interpolate(_named("auditCount")), " brukere fra siden"])},
  "page_select_all_auditcount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Velg alle ", _interpolate(_named("auditCount")), " revisjonslogger"])},
  "page_users_audit_export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksport"])},
  "page_users_audit_event_login_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innlogging feilet"])},
  "page_users_audit_event_login_succeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innlogging lyktes"])},
  "page_users_audit_event_client_application_authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikasjonen er godkjent"])},
  "page_users_audit_event_logout_succeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du er nå logget ut"])},
  "page_users_1_selected_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 hendelse"])},
  "page_users_selected_events_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " hendelser"])},
  "page_users_all_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle hendelser"])},
  "page_users_filter_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer hendelser"])},
  "page_users_modal_export_audit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksporter revisjonslogger"])},
  "page_users_modal_export_audit_body_1_audit_log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 revisjonslogg vil bli eksportert"])},
  "page_users_modal_export_users_body_n_audit_logs": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " revisjonslogger vil bli eksportert"])},
  "page_users_modal_export_user_audit_logs_field_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revisjons-ID"])},
  "page_users_modal_export_user_audit_logs_field_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hendelse"])},
  "page_users_modal_export_user_audit_logs_field_timestamp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidsangivelse"])},
  "page_users_modal_export_user_audit_logs_field_ipv4_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-adresse"])},
  "page_users_modal_export_user_audit_logs_field_application_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikasjonsnr"])},
  "page_users_modal_export_user_audit_logs_field_application_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikasjonsnavn"])},
  "page_users_modal_export_user_audit_logs_field_payload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyttelast"])},
  "page_users_modal_export_audit_logs_start_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksporter revisjonslogger"])},
  "page_users_audit_search_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det oppsto en ukjent feil ved søk etter revisjonslogger"])},
  "page_users_modal_export_audit_logs_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det oppsto en ukjent feil ved eksport av revisjonslogger"])},
  "page_user_details_add_recovery_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til en e-postadresse for gjenoppretting"])},
  "page_user_details_add_another_recovery_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til en annen e-postadresse for gjenoppretting"])},
  "page_user_details_update_user_invalid_recovery_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugyldig e-postadresse for gjenoppretting"])},
  "page_user_details_update_user_recovery_email_already_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-post for gjenoppretting er duplisert"])},
  "page_user_details_update_user_recovery_email_disposable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-post for gjenoppretting bruker et engangsdomene"])}
}