export default {
  "page_groups_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group name"])},
  "page_groups_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["member"])},
  "page_groups_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Members"])},
  "page_groups_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "page_groups_table_no_groups_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No groups added"])},
  "page_groups_table_no_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No groups"])},
  "page_groups_table_no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No groups found matching search criteria"])},
  "page_groups_btn_add_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Group"])},
  "page_groups_btn_save_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Group"])},
  "page_groups_btn_edit_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit group"])},
  "page_groups_users_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
  "page_groups_users_fist_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
  "page_groups_users_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
  "page_groups_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created (UTC)"])},
  "page_groups_membership_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membership updated (UTC)"])},
  "page_groups_delete_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete group"])},
  "page_groups_delete_group_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete the group?"])},
  "page_groups_remove_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove member"])},
  "page_groups_remove_member_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to remove the member"])},
  "page_groups_remove_member_group_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to remove the member ", _interpolate(_named("member")), " from group ", _interpolate(_named("group")), "?"])},
  "page_groups_no_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No users added"])},
  "page_groups_no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No users found matching search criteria"])},
  "page_groups_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< Back to Groups"])},
  "page_groups_add_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add member to group"])},
  "page_groups_add_users_all_are_already_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All users are already added to this group"])},
  "page_groups_add_users_all_no_users_matching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No users matching search criteria"])},
  "page_groups_member_since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member since"])},
  "page_groups_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
  "page_groups_group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group members:"])},
  "page_groups_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group ID:"])},
  "page_groups_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source"])},
  "page_groups_err_group_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unknown error has occurred while adding this group."])},
  "page_groups_err_group_invalid_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The group name is invalid. Maximum allowed length of 255 characters"])},
  "page_groups_err_group_invalid_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The group description is invalid. Maximum allowed length of 1024 characters"])},
  "page_groups_err_group_already_exits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The group you were trying to add already exists."])},
  "page_groups_err_group_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unknown error has occurred while updating this group."])},
  "page_groups_search_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search group name"])},
  "page_groups_search_group_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search by email"])},
  "page_groups_search_group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search members"])},
  "page_groups_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["groups"])},
  "page_groups_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["group"])},
  "page_groups_filter_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter members"])},
  "page_groups_member_filter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Member: ", _interpolate(_named("name"))])}
}