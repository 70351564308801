import util from '@/util';
import { Guid } from 'guid-typescript';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('ScimRequestLogs')
export default class ScimRequestLogs {
    constructor() {
        this.id = Guid.create().toString();
        this.requestPath = '';
        this.requesstMethod = '';
        this.requestQueryString = void 0;
        this.requestBody = void 0;
        this.responseStatusCode = 0;
        this.date = '';
    }

    public id: string;

    @JsonProperty('request_path', String)
    public requestPath: string;

    @JsonProperty('request_method', String)
    public requesstMethod: string;

    @JsonProperty('request_query_string', String, true)
    public requestQueryString?: string;

    @JsonProperty('request_json_payload', String, true)
    public requestBody?: string;

    @JsonProperty('response_status_code', Number)
    public responseStatusCode: number;

    @JsonProperty('date', String)
    public date: string;

    public get displayDate(): string {
        return util.formatDateTimeUtc(this.date);
    }

    public get statusCodeCssClass(): string {
        if (this.responseStatusCode >= 200 && this.responseStatusCode < 300) {
            return 'response-status-success';
        } else if (this.responseStatusCode >= 400) {
            return 'response-status-error';
        } else {
            return 'response-status-info';
        }
    }
}
