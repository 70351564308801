export const PWD_MAX_FAILURE_MIN_VALUE = 0;

export const PWD_MAX_FAILURE_MAX_VALUE = 10;

export const PWD_MAX_FAILURE_DEFAULT_VALUE = 8;

export const PWD_FAILURE_COUNT_INTERVAL_MINUTES_MIN_VALUE = 1;

export const PWD_FAILURE_COUNT_INTERVAL_MINUTES_MAX_VALUE = 60;

export const PWD_FAILURE_COUNT_INTERVAL_MINUTES_DEFAULT_VALUE = 10;

export const PWD_IN_HISTORY_MIN_VALUE = 0;

export const PWD_IN_HISTORY_MAX_VALUE = 10;

export const PWD_IN_HISTORY_INITIAL_VALUE = 5;

export const PWD_LOCKOUT_DURATION_MINUTES_MIN_VALUE = 1;

export const PWD_LOCKOUT_DURATION_MINUTES_MAX_VALUE = 60;

export const PWD_LOCKOUT_DURATION_MINUTES_DEFAULT_VALUE = 30;

export const PWD_MIN_LENGTH_MIN_VALUE = 4;

export const PWD_MIN_LENGTH_MAX_VALUE = 30;

export const PWD_QUALITY_MIN_CHAR_MIN_VALUE = 0;

export const PWD_QUALITY_MIN_CHAR_MAX_VALUE = 10;

export const PWD_MAX_AGE_DAYS_MIN_VALUE = 0;

export const PWD_MAX_AGE_DAYS_INITIAL_VALUE = 30;

export const PWD_MAX_AGE_DAYS_MAX_VALUE = 365;

export const PWD_EXPIRE_WARNING_MINUTES_MIN_VALUE = 0;

export const PWD_EXPIRE_WARNING_MINUTES_MAX_VALUE = 43200;

export const SESSION_MAX_CONCURRENT_USER_SESSIONS = 10;

export const SESSION_LOCK_TO_ORIGIN_IP = false;
