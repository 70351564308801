import ApiClientError from '@/api/error';
import groupApiClient from '@/api/group-api-client';
import GroupModel from '@/api/group-api-client/models/GroupModel';
import GroupUserSearchInputModel from '@/api/group-api-client/models/GroupUserSearchInputModel';
import userApiClient from '@/api/user-api-client';
import UserNotMemberOfGroupSearchInputModel from '@/api/user-api-client/models/UserNotMemberOfGroupSearchInputModel';
import util from '@/util';
import { ActionContext } from 'vuex';
import { BasicStoreAccessors, RootState } from '../types';
import mutations from './mutations';
import { GroupStoreState } from './state';

let storeApi: BasicStoreAccessors<GroupStoreState>;

export function initActions(api: BasicStoreAccessors<GroupStoreState>): void {
    storeApi = api;
}

// helper functions
async function searchNonMembersUsersAsync(groupId: string, searchTerm: string): Promise<void> {
    storeApi.commit(mutations.setStoreProcessingState, true);
    storeApi.commit(mutations.setUserAddSearchResults, []);

    const model = new UserNotMemberOfGroupSearchInputModel();
    model.groupId = groupId;
    model.searchTerm = searchTerm;

    const users = await userApiClient.getListOfNonGroupMembersAsync(model);

    storeApi.commit(mutations.setUserAddSearchResults, users);
}

const debouncedSearchNonMembersUsersAsync = util.debounceAsync(async (groupId: string, searchTerm: string) => {
    await searchNonMembersUsersAsync(groupId, searchTerm);
}, 500);

// main export
export default {
    async fetchAllGroupsAsync(context: ActionContext<GroupStoreState, RootState>): Promise<void> {
        try {
            storeApi.commit(mutations.setStoreProcessingState, true);

            const groupsModel = await groupApiClient.searchGroupsAsync(context.state.groupSearchInputModel);
            if (groupsModel) {
                storeApi.commit(mutations.setGroupsList, groupsModel);
            }
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewGroups);
            }
        } finally {
            storeApi.commit(mutations.setStoreProcessingState, false);
            storeApi.commit(mutations.setDataLoaded, true);
        }
    },

    async debouncedFetchAllGroupsAsync(context: ActionContext<GroupStoreState, RootState>): Promise<void> {
        try {
            storeApi.commit(mutations.setStoreProcessingState, true);

            await debouncedApiFetchAllGroupsAsync(context);
        } catch (err) {
            const apiError = err as ApiClientError;
            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewGroups);
            }
        } finally {
            storeApi.commit(mutations.setStoreProcessingState, false);
            storeApi.commit(mutations.setDataLoaded, true);
        }
    },

    async updateGroupAsync(context: ActionContext<GroupStoreState, RootState>, { id, name, description }: { id: string; name: string; description: string }): Promise<void> {
        try {
            storeApi.commit(mutations.setStoreProcessingState, true);

            const model = new GroupModel();
            model.id = id;
            model.groupName = name;
            model.groupDescription = description;

            const updatedGroup = await groupApiClient.updateAsync(model);

            if (updatedGroup) {
                storeApi.commit(mutations.updateGroup, updatedGroup);
            }
        } finally {
            storeApi.commit(mutations.setStoreProcessingState, false);
        }
    },

    async getGroupUsersAsync(context: ActionContext<GroupStoreState, RootState>, { groupId, model }: { groupId: string; model: GroupUserSearchInputModel }): Promise<void> {
        try {
            storeApi.commit(mutations.setStoreProcessingState, true);

            const userTotals = await groupApiClient.getGroupUsersAsync(groupId, model);

            if (userTotals && userTotals.users.length > 0) {
                storeApi.commit(mutations.setGroupUsers, { groupId: groupId, users: userTotals.users, totalPages: userTotals.totalPages, totalUsers: userTotals.totalUsers });
            } else {
                storeApi.commit(mutations.setGroupUsers, { groupId: groupId, users: [], totalPages: 0, totalUsers: 0 });
            }
        } finally {
            storeApi.commit(mutations.setStoreProcessingState, false);
        }
    },

    async addGroupAsync(context: ActionContext<GroupStoreState, RootState>, { name, description }: { name: string; description: string }): Promise<GroupModel> {
        try {
            storeApi.commit(mutations.setStoreProcessingState, true);

            const group = await groupApiClient.addGroupAsync(name, description);

            storeApi.commit(mutations.addGroup, group);

            return group!;
        } finally {
            storeApi.commit(mutations.setStoreProcessingState, false);
        }
    },

    async deleteGroupAsync(context: ActionContext<GroupStoreState, RootState>, groupId: string): Promise<void> {
        try {
            storeApi.commit(mutations.setStoreProcessingState, true);

            await groupApiClient.deleteGroupAsync(groupId);

            storeApi.commit(mutations.removeGroup, groupId);
        } finally {
            storeApi.commit(mutations.setStoreProcessingState, false);
        }
    },

    async deleteUserAsync(context: ActionContext<GroupStoreState, RootState>, { groupId, userId }: { groupId: string; userId: string }): Promise<void> {
        try {
            storeApi.commit(mutations.setStoreProcessingState, true);

            await groupApiClient.deleteUserAsync(groupId, userId);

            storeApi.commit(mutations.removeUser, { groupId, userId });
        } finally {
            storeApi.commit(mutations.setStoreProcessingState, false);
        }
    },

    async searchNonMembersUsersAsync(context: ActionContext<GroupStoreState, RootState>, { groupId, searchTerm }: { groupId: string; searchTerm?: string }): Promise<void> {
        try {
            if (util.isNullOrEmpty(searchTerm)) {
                await searchNonMembersUsersAsync(groupId, '%');
            } else {
                await debouncedSearchNonMembersUsersAsync(groupId, `%${searchTerm}%`);
            }
        } finally {
            storeApi.commit(mutations.setStoreProcessingState, false);
        }
    },

    async addUserToGroupAsync(context: ActionContext<GroupStoreState, RootState>, { groupId, userId }: { groupId: string; userId: string }): Promise<void> {
        try {
            storeApi.commit(mutations.setStoreProcessingState, true);

            await groupApiClient.addUserAsync(groupId, userId);

            storeApi.commit(mutations.increaseMembersCount, groupId);
        } finally {
            storeApi.commit(mutations.setStoreProcessingState, false);
        }
    },
};

const debouncedApiFetchAllGroupsAsync = util.debounceAsync(async (context: ActionContext<GroupStoreState, RootState>) => {
    const groupsModel = await groupApiClient.searchGroupsAsync(context.state.groupSearchInputModel);
    storeApi.commit(mutations.setGroupsList, groupsModel);
}, 500);
