export default {
  "common_learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiterlesen"])},
  "common_ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "common_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
  "common_keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behalten"])},
  "common_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieren"])},
  "common_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiert"])},
  "common_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herunterladen"])},
  "common_downloaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heruntergeladen"])},
  "common_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochladen"])},
  "common_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
  "common_verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen"])},
  "common_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geprüft"])},
  "common_not_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht geprüft"])},
  "common_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolg"])},
  "common_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlgeschlagen"])},
  "common_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insgesamt"])},
  "common_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
  "common_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
  "common_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "common_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
  "common_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
  "common_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "common_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt Datum"])},
  "common_completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossen datum"])},
  "common_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
  "notif_error_server_unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Webserver konnte nicht kontaktiert werden. Bitte versuchen Sie es in ein paar Minuten erneut."])},
  "notif_warn_unauthorized_to_perform_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind nicht berechtigt, diese Aktion durchzuführen."])},
  "notif_warn_unauthorized_to_view_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind nicht berechtigt, diese Daten einzusehen."])},
  "notif_modal_session_expired_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session abgelaufen"])},
  "notif_modal_session_expired_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre aktuelle Session ist abgelaufen. Aus Sicherheitsgründen müssen Sie sich erneut anmelden."])},
  "alert_no_verified_domains_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen mindestens eine Domäne hinzufügen und verifizieren, um fortzufahren."])},
  "pagination_first_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehe zur ersten Seite"])},
  "pagination_last_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehe zur letzten Seite"])},
  "pagination_previous_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorherige Seite"])},
  "pagination_next_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächste Seite"])},
  "pagination_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["von"])},
  "common_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "common_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
  "common_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
  "common_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
  "common_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt"])},
  "common_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossen"])},
  "common_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
  "common_sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortieren"])},
  "common_apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwenden"])},
  "common_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
  "common_yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
  "common_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
  "common_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
  "leave_page_confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben Ihre Änderungen noch nicht gespeichert"])},
  "leave_page_confirmation_modal_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie diese Seite verlassen möchten? Alle nicht gespeicherten Änderungen gehen verloren."])},
  "common_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeigen"])},
  "common_show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeigen"])},
  "common_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])}
}