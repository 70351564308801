import util from '@/util';
import { JsonObject, JsonProperty } from 'json2typescript';
import { Saml2ProviderType } from '../../enums/Saml2ProviderType';
import Saml2ProviderTypeConverter from '../../json-converters/Saml2ProviderTypeConverter';

@JsonObject('Saml2MetadataModel')
export default class Saml2MetadataModel {
    public constructor() {
        this.entityId = '';
        this.ssoEndpoint = '';
        this.sloEndpoint = void 0;
        this.ssoBindingType = '';
        this.sloBindingType = '';
        this.signAuthnRequests = false;
        this.firstNameClaim = '';
        this.lastNameClaim = '';
        this.languageCodeClaim = void 0;
        this.countryCodeClaim = void 0;
        this.groupClaim = void 0;
        this.providerType = Saml2ProviderType.Other;
    }

    @JsonProperty('entity_id', String)
    public entityId: string;

    @JsonProperty('single_sign_on_endpoint', String)
    public ssoEndpoint: string;

    @JsonProperty('single_logout_endpoint', String, true)
    public sloEndpoint?: string;

    @JsonProperty('sign_on_method', String)
    public ssoBindingType: string;

    @JsonProperty('logout_method', String)
    public sloBindingType: string;

    @JsonProperty('sign_authn_requests', Boolean)
    public signAuthnRequests: boolean;

    @JsonProperty('claim_mapping_first_name', String)
    public firstNameClaim: string;

    @JsonProperty('claim_mapping_last_name', String)
    public lastNameClaim: string;

    @JsonProperty('claim_mapping_language_code', String, true)
    public languageCodeClaim?: string;

    @JsonProperty('claim_mapping_country_code', String, true)
    public countryCodeClaim?: string;

    @JsonProperty('claim_mapping_group', String, true)
    public groupClaim?: string;

    @JsonProperty('provider_type', Saml2ProviderTypeConverter)
    public providerType: Saml2ProviderType;

    public isValid(providerType: Saml2ProviderType): boolean {
        const isInvalid =
            util.isNullOrEmpty(this.entityId) ||
            util.isNullOrEmpty(this.ssoEndpoint) ||
            util.isNullOrEmpty(this.ssoBindingType) ||
            util.isNullOrEmpty(this.sloBindingType) ||
            util.isNullOrEmpty(this.firstNameClaim) ||
            util.isNullOrEmpty(this.lastNameClaim) ||
            this.providerType !== providerType;

        return !isInvalid;
    }
}
