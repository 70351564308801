import UserTableSettingsModel from '@/api/ui-interaction-api-client/models/UserTableSettingsModel';
import util from '@/util';
import { UIInteractionStoreState } from './state';

export default {
    showAddDomainOnTabLoad(state: UIInteractionStoreState): boolean {
        return state.showAddDomainOnTabLoad;
    },

    showJobsList(state: UIInteractionStoreState): boolean {
        return state.showJobsList;
    },

    isStateChanged(state: UIInteractionStoreState): boolean {
        return state.isStateChanged;
    },

    showLeavePageConfirmationModal(state: UIInteractionStoreState): boolean {
        return !util.isNullOrEmpty(state.confirmPageLeaveCallback);
    },

    userTableSettings(state: UIInteractionStoreState): UserTableSettingsModel {
        return state.userTableSettings;
    },
};
