import NotificationStoreModel from './models/NotificationStoreModel';

export class NotificationStoreState {
    public constructor() {
        this.notifications = [];
    }

    public notifications: NotificationStoreModel[];
}

export default new NotificationStoreState();
