import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "notifications-overlay"
}
const _hoisted_2 = { id: "notifications-container" }
const _hoisted_3 = {
  key: 0,
  class: "wrap-long-word"
}
const _hoisted_4 = {
  key: 1,
  class: "wrap-long-word"
}
const _hoisted_5 = {
  key: 2,
  class: "wrap-long-word"
}
const _hoisted_6 = { key: 3 }
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.notifications.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (notification) => {
            return (_openBlock(), _createElementBlock("div", {
              key: notification.id,
              role: "alert",
              class: _normalizeClass(["toast", [notification.type !== 'error' ? 'toast-' + notification.type : 'toast-danger', 'toast-index-' + _ctx.getIndex(notification.id)]])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(notification.messageParts, (messagePart) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: messagePart,
                  class: "notification-content d-inline-flex"
                }, [
                  (messagePart.type === 'normal')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t(messagePart.text)), 1))
                    : (messagePart.type === 'bold')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                          _createElementVNode("strong", null, _toDisplayString(_ctx.$t(messagePart.text)), 1)
                        ]))
                      : (messagePart.type === 'italic')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                            _createElementVNode("i", null, _toDisplayString(_ctx.$t(messagePart.text)), 1)
                          ]))
                        : (messagePart.type === 'space')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_6, " "))
                          : _createCommentVNode("", true)
                ]))
              }), 128)),
              _createElementVNode("button", {
                type: "button",
                class: "close",
                "aria-label": "Close",
                onClick: ($event: any) => (_ctx.closeNotification(notification.id))
              }, null, 8, _hoisted_7)
            ], 2))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}