import UserSearchInputModel from '@/api/user-api-client/models/UserSearchInputModel';
import UsersModel from '@/api/user-api-client/models/UsersModel';
import StoreEntity from '@/store/models';
import SelectedUsersModel from '@/api/user-api-client/models/SelectedUsers/SelectedUsersModel';
import UserDetailsStoreModel from './models/UserDetailsStoreModel';

export class UserStoreState extends StoreEntity {
    constructor() {
        super();

        this.userList = new UsersModel();
        this.userSearchInput = new UserSearchInputModel();
        this.selectedUsers = new SelectedUsersModel();
        this.isUnauthorizedToViewUsers = false;
        this.isDataLoaded = false;
        this.userDetails = new UserDetailsStoreModel();
    }

    public userList: UsersModel;

    public userSearchInput: UserSearchInputModel;

    public isUnauthorizedToViewUsers: boolean;

    public isDataLoaded: boolean;

    public selectedUsers: SelectedUsersModel;

    public userDetails: UserDetailsStoreModel;
}

export default new UserStoreState();
