export default {
  "page_provisioning_no_sso_setup_alert_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single Sign-On opsætning"])},
  "page_provisioning_no_sso_setup_alert_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du skal aktivere Single Sign-On for at fortsætte med din SCIM-provisioneringsopsætning."])},
  "page_provisioning_no_sso_setup_alert_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå til Single Sign-On"])},
  "page_provisioning_scim_not_supported_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM understøttes ikke"])},
  "page_provisioning_scim_not_supported_alert_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["System for Cross-domain Identity Management (SCIM) for ", _interpolate(_named("idpName")), " er ikke understøttet."])},
  "page_provisioning_scim_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Med provisionering kan du automatisere almindelige administrative opgaver. Ved at aktivere System for Cross-domain Identity Management (SCIM) kan du forbinde Visma med ", _interpolate(_named("idpName")), ", så du kan:"])},
  "page_provisioning_scim_info_create_users_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret brugere og grupper"])},
  "page_provisioning_scim_info_grant_revoke_access_to_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giv og tilbagekald adgang til grupper"])},
  "page_provisioning_scim_info_edit_attributes_users_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger attributter for brugere og grupper"])},
  "page_provisioning_scim_info_suspend_deprovisioned_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspendér deprovisionerede brugere"])},
  "page_provisioning_scim_cache_refresh_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemærk, at hver konfigurationsændring kan tage op til 2 minutter at træde i kraft på SCIM-serveren."])},
  "page_provisioning_scim_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM 2.0-provisionering"])},
  "page_provisioning_scim_last_sync_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seneste synkroniseringsaktivitet"])},
  "page_provisioning_scim_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen"])},
  "page_provisioning_scim_endpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM-endepunkt"])},
  "page_provisioning_scim_bearer_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM bearer token"])},
  "page_provisioning_scim_bearer_token_generated_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Genereret på ", _interpolate(_named("date"))])},
  "page_provisioning_scim_bearer_token_expires_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Udløber ", _interpolate(_named("date"))])},
  "page_provisioning_scim_bearer_token_btn_generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generer SCIM-token"])},
  "page_provisioning_scim_modal_disable_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktiver SCIM 2.0-provisionering"])},
  "page_provisioning_scim_modal_disable_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker på, at du vil deaktivere SCIM?"])},
  "page_provisioning_scim_modal_disable_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktivér"])},
  "page_provisioning_scim_refresh_token_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdater SCIM-token"])},
  "page_provisioning_scim_modal_refresh_token_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker på, at du vil opdatere SCIM-tokenet?"])},
  "page_provisioning_scim_modal_refresh_token_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdater"])},
  "page_provisioning_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provisioneringshandlinger"])},
  "page_provisioning_action_user_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret brugere"])},
  "page_provisioning_action_user_read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Læs brugere"])},
  "page_provisioning_action_user_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdatér brugere"])},
  "page_provisioning_action_user_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet brugere"])},
  "page_provisioning_action_group_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opret grupper"])},
  "page_provisioning_action_group_read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Læs grupper"])},
  "page_provisioning_action_group_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdatér grupper"])},
  "page_provisioning_action_group_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet grupper"])},
  "page_provisioning_action_user_update_triggers": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Når brugere deaktiveres fra ", _interpolate(_named("idpName")), ", skal du udføre denne handling i ", _interpolate(_named("tenantOwnerName"))])},
  "page_provisioning_action_user_update_trigger_suspend_useraccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspendér bruger"])},
  "page_provisioning_action_user_update_trigger_delete_from_tenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern bruger"])},
  "page_provisioning_action_user_update_trigger_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slet bruger"])},
  "page_provisioning_action_user_delete_triggers": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Når brugere slettes i ", _interpolate(_named("idpName")), ", skal du udføre denne handling i ", _interpolate(_named("tenantOwnerName"))])},
  "page_provisioning_action_user_delete_trigger_suspend_useraccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspendér bruger"])},
  "page_provisioning_action_user_delete_trigger_delete_from_tenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern bruger"])},
  "page_provisioning_scim_groups_alert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("tenantOwnerName")), " understøtter ikke Opret/Læs/Opdater/Slet af grupper. Du kan dog tildele grupper til Policyer."])},
  "page_provisioning_scim_unsupported_actions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("tenantOwnerName")), " understøtter ikke SCIM-synkronisering til ", _interpolate(_named("unsupportedActions")), "."])},
  "page_provisioning_scim_unsupported_actions_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ændringer vil kun blive afspejlet i Vismas identitetssystem."])},
  "page_provisioning_token_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM-token"])},
  "page_provisioning_token_modal_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiér dette hemmelige token til din SCIM-udbyder."])},
  "page_provisioning_token_modal_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokenet vil kun blive vist én gang og udløber om 1 år."])},
  "page_provisioning_action_trigger_no_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen handling"])},
  "page_provisioning_btn_view_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis provisionslogs"])},
  "page_provisioning_logs_btn_view_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis indstillinger"])},
  "page_provisioning_logs_btn_refresh_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opdatér logs"])},
  "page_provisioning_logs_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provisionslogs"])},
  "page_provisioning_logs_no_logs_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der er ikke foretaget nogen SCIM-forespørgsler på denne opsætning endnu."])},
  "page_provisioning_logs_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der opstod en ukendt fejl under hentning af SCIM-logfiler."])},
  "page_provisioning_logs_item_request_query_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Query string"])},
  "page_provisioning_logs_item_request_json_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request body"])},
  "page_provisioning_logs_item_response_status_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respons status kode"])},
  "page_provisioning_logs_item_view_json": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis JSON"])},
  "page_provisioning_logs_item_no_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<no input>"])},
  "page_provisioning_logs_item_no_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<missing info>"])}
}