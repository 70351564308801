export default {
  "common_learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])},
  "common_ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "common_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])},
  "common_keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep"])},
  "common_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
  "common_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiato"])},
  "common_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
  "common_downloaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Downloaded"])},
  "common_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
  "common_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "common_verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify"])},
  "common_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verified"])},
  "common_not_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not verified"])},
  "common_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
  "common_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed"])},
  "common_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "common_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il prossimo"])},
  "common_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "common_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "common_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "common_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "common_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "common_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created date"])},
  "common_completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed date"])},
  "common_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "notif_error_server_unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Could not contact the web server. Please try again in a few moments."])},
  "notif_warn_unauthorized_to_perform_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not authorized to perform this action."])},
  "notif_warn_unauthorized_to_view_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not authorized to view this data."])},
  "notif_modal_session_expired_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session expired"])},
  "notif_modal_session_expired_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your current session has expired. For security reasons, you need to sign in again."])},
  "alert_no_verified_domains_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need to add and verify at least one domain in order to continue."])},
  "pagination_first_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to first page"])},
  "pagination_last_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to last page"])},
  "pagination_previous_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous page"])},
  "pagination_next_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next page"])},
  "pagination_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
  "common_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
  "common_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
  "common_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "common_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
  "common_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
  "common_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
  "common_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])},
  "common_sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort"])},
  "common_apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
  "common_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "common_yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sì"])},
  "common_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "common_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])},
  "leave_page_confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have unsaved changes"])},
  "leave_page_confirmation_modal_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to leave this page? Any unsaved changes will be lost."])},
  "common_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizzazione"])},
  "common_show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
  "common_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])}
}