import { SortColumn } from '../enums/SortColumn';
import { SortOrder } from '../enums/SortOrder';
import util from '@/util';

export default class UserSearchInputModel {
    public constructor() {
        this.page = 1;
        this.sortColumn = SortColumn.Email;
        this.sortOrder = SortOrder.Ascending;
        this.perPage = 50;
        this.domainNames = [];
    }

    public page: number;

    public sortColumn: SortColumn;

    public sortOrder: SortOrder;

    public email?: string;

    public firstName?: string;

    public lastName?: string;

    public emailOrName?: string;

    public perPage: number;

    public domainNames: string[];

    public get isStateChanged() {
        return (
            this.page !== 1 ||
            this.sortColumn !== SortColumn.Email ||
            this.sortOrder !== SortOrder.Ascending ||
            !util.isNullOrEmpty(this.email) ||
            !util.isNullOrEmpty(this.firstName) ||
            !util.isNullOrEmpty(this.lastName) ||
            !util.isNullOrEmpty(this.emailOrName)
        );
    }
}
