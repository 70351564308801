import util from '@/util';
import { JsonObject, JsonProperty } from 'json2typescript';
import Saml2IdentityProviderModel from './base/Saml2IdentityProviderModel';

@JsonObject('Saml2IdentityProviderOutputModel')
export default class Saml2IdentityProviderOutputModel extends Saml2IdentityProviderModel {
    constructor() {
        super();

        this.displayName = '';
        this.createdDate = '';
        this.certificateThumbprint = '';
        this.certificateExpirationTime = '';
    }

    @JsonProperty('display_name', String)
    public displayName: string;

    @JsonProperty('created_date', String)
    public createdDate: string;

    @JsonProperty('certificate_thumbprint', String)
    public certificateThumbprint: string;

    @JsonProperty('certificate_expiration_time', String)
    public certificateExpirationTime: string;

    public get displayCreatedDate(): string {
        return util.formatDate(this.createdDate);
    }

    public get displayCertificateExpirationTime(): string {
        return util.formatDate(this.certificateExpirationTime);
    }

    public get certificateExpirationDateTime(): Date {
        return new Date(this.certificateExpirationTime);
    }
}
