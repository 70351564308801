export const ERROR_INVALID_DOMAIN_NAME = 'ERROR_INVALID_DOMAIN_NAME';

export const ERROR_DOMAIN_NOT_EXISTS = 'ERROR_DOMAIN_NOT_EXIST';

export const ERROR_DOMAIN_ALREADY_ADDED = 'ERROR_DOMAIN_ALREADY_ADDED';

export const ERROR_DOMAIN_ALREADY_VERIFIED = 'ERROR_DOMAIN_ALREADY_VERIFIED';

export const ERROR_INVALID_DOMAIN_SETUP = 'ERROR_INVALID_DOMAIN_SETUP';

export const ERROR_SSO_EXIST = 'ERROR_SSO_EXIST';

export const ERROR_GROUP_MEMBERS_EXIST = 'ERROR_GROUP_MEMBER_EXIST';
