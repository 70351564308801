import mutations from './mutations';
import { BasicStoreAccessors, RootState } from '../types';
import { JobStoreState } from './state';
import jobsApiClient from '@/api/job-api-client';
import ApiClientError from '@/api/error';
import { ActionContext } from 'vuex';
import DomainChangeInputModel from '@/api/job-api-client/models/DomainChangeInputModel';
import UsersExportInputModel from '@/api/job-api-client/models/UserExportInputModel';
import AuditExportInputModel from '@/api/job-api-client/models/AuditExportInputModel';

let storeApi: BasicStoreAccessors<JobStoreState>;

export function initActions(api: BasicStoreAccessors<JobStoreState>): void {
    storeApi = api;
}

export default {
    async getJobsAsync(): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            const result = await jobsApiClient.getJobsListAsync();

            if (result) {
                storeApi.commit(mutations.setJobs, result);
            }
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewJobs);
                return;
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
            storeApi.commit(mutations.setDataLoaded, true);
        }
    },

    async changeDomainAsync(context: ActionContext<JobStoreState, RootState>, inputModel: DomainChangeInputModel): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            await jobsApiClient.createDomainChangeJobAsync(inputModel);
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewJobs);
                return;
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
            storeApi.commit(mutations.setDataLoaded, true);
        }
    },

    async exportUsersAsync(context: ActionContext<JobStoreState, RootState>, inputModel: UsersExportInputModel): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            await jobsApiClient.createUsersExportJobAsync(inputModel);
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewJobs);
                return;
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
            storeApi.commit(mutations.setDataLoaded, true);
        }
    },

    async importUsersAsync(context: ActionContext<JobStoreState, RootState>, file: File): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            await jobsApiClient.createUsersImportJobAsync(file);
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewJobs);
                return;
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
            storeApi.commit(mutations.setDataLoaded, true);
        }
    },

    async exportUserAuditLogsAsync(context: ActionContext<JobStoreState, RootState>, model: AuditExportInputModel): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            await jobsApiClient.createUserAuditLogsExportJobAsync(model);
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewJobs);
                return;
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
            storeApi.commit(mutations.setDataLoaded, true);
        }
    },

    async getUserExportFieldsAsync(): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            const result = await jobsApiClient.getUserExportFieldsAsync();

            if (result) {
                storeApi.commit(mutations.setUserExportFields, result);
            }
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewJobs);
                return;
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
            storeApi.commit(mutations.setDataLoaded, true);
        }
    },

    async getUserAuditLogsExportFieldsAsync(): Promise<void> {
        try {
            const result = await jobsApiClient.getUserAuditLogsExportFieldsAsync();

            if (result) {
                storeApi.commit(mutations.setUserAuditLogsExportFields, result);
            }
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewJobs);
                return;
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
            storeApi.commit(mutations.setDataLoaded, true);
        }
    },
};
