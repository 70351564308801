import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('OidcIdentityProviderClientSecretInputModel')
export default class OidcIdentityProviderClientSecretInputModel {
    constructor() {
        this.clientSecret = '';
    }

    @JsonProperty('client_secret', String, true)
    public clientSecret: string;
}
