import AuthenticationPolicyBodyModel from '@/api/authenticationpolicy-api-client/models/AuthenticationPolicyBodyModel';
import AuthenticationPolicyModel from '@/api/authenticationpolicy-api-client/models/AuthenticationPolicyModel';
import StoreEntity from '@/store/models';
import util from '@/util';

export default class AuthenticationPolicyStoreModel extends StoreEntity {
    public constructor(model?: AuthenticationPolicyModel) {
        super();

        if (model) {
            this.id = model.id;
            this.name = model.name;
            this.policy = model.policy;
            this.priority = model.priority;
            this.createdDate = model.createdDate;
            this.updatedDate = model.createdDate;
        }
    }

    public id!: string;

    public name!: string;

    public policy!: AuthenticationPolicyBodyModel;

    public priority!: number;

    public createdDate!: string;

    public updatedDate!: string;

    public get displayCreatedDate(): string {
        return util.formatDate(this.createdDate);
    }

    public get displayUpdatedDate(): string {
        return util.formatDate(this.updatedDate);
    }
}
