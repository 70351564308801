export default {
  "page_policies_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
  "page_policies_default_policy_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne politik gælder for alle brugere"])},
  "page_policies_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigér"])},
  "page_policies_reset_to_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nulstil til standard"])},
  "page_policies_2_step_verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totrinsbekræftelse"])},
  "page_policies_2_step_verification_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kræv 2-trinsbekræftelse under login"])},
  "page_policies_2_step_verification_on_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PÅ"])},
  "page_policies_2_step_verification_off_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AF"])},
  "page_policies_identity_providers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identitetsudbydere"])},
  "page_policies_networks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netværk"])},
  "page_policies_ip_ranges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-intervaller"])},
  "page_policies_ips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP'er"])},
  "page_policies_subnet_masks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undernetmasker"])},
  "page_policies_first_ip_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Første IP i området er ugyldig"])},
  "page_policies_second_ip_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anden IP i området er ugyldig"])},
  "page_policies_ip_range_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-intervallet er ugyldigt"])},
  "page_policies_ip_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP er ugyldig"])},
  "page_policies_subnet_mask_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undernetmasken er ugyldig"])},
  "page_policies_add_ip_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj IP-interval"])},
  "page_policies_add_ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj IP"])},
  "page_policies_add_subnet_mask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilføj undernetmaske"])},
  "page_policies_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< Tilbage til Policyer"])},
  "page_policies_notif_update_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der opstod en ukendt fejl under opdatering af policyen."])},
  "page_policies_ip_range_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-intervallet er duplikeret"])},
  "page_policies_ip_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP er duplikeret"])},
  "page_policies_subnet_mask_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undernetmasken er duplikeret"])},
  "page_policies_network_your_ip_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din IP-adresse er"])},
  "page_policies_network_ips_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angiv netværk ved hjælp af IP-intervaller, IP-adresser eller CIDR'er. Disse skal være offentlige IP-adresser og ikke lokale eller private IP-adresser."])},
  "page_policies_network_auth_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkendelsen vil passere, hvis der er et match på nogen af de tilladte IP'er. Hvis netværkene ikke inkluderer din IP-adresse, låser du dig selv ude."])},
  "page_policies_network_all_networks_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis intet er konfigureret, vil alle netværk være tilladt."])},
  "page_policies_countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lande"])},
  "page_policies_countries_no_suggestions_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen forslag fundet"])},
  "page_policies_countries_your_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit IP-adresseland er"])},
  "page_policies_countries_auth_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godkendelsen vil passere, hvis der er et match i et af de tilladte lande. Hvis landene ikke inkluderer dit IP-adresse-løst land, vil du låse dig selv ude."])},
  "page_policies_countries_select_countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vælg lande"])},
  "page_policies_countries_all_countries_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis intet er valgt, vil alle lande være tilladt."])},
  "page_policies_all_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle tilladte"])},
  "page_policies_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adgangskode"])},
  "page_policies_password_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adgangskodeindhold"])},
  "page_policies_password_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adgangskode låst"])},
  "page_policies_password_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adgangskode udløb"])},
  "page_policies_password_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adgangskodeændring"])},
  "page_policies_password_minimum_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimumslængde for adgangskode"])},
  "page_policies_password_minimum_required_digit_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum påkrævet ciffer (0-9)"])},
  "page_policies_password_minimum_required_uppercase_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum påkrævet store bogstaver"])},
  "page_policies_password_minimum_required_lowercase_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum påkrævede små bogstaver"])},
  "page_policies_password_minimum_required_special_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum påkrævede specialtegn"])},
  "page_policies_password_accounts_locked_login_failures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konti kan blive spærret på grund af loginfejler"])},
  "page_policies_password_locked_account_after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lås konto efter"])},
  "page_policies_password_login_failures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["loginfejl(er)"])},
  "page_policies_password_reset_failure_count_after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nulstil fejltælling efter"])},
  "page_policies_password_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutt(er)"])},
  "page_policies_password_lockout_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varighed af lockout"])},
  "page_policies_password_never_expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adgangskode udløber aldrig"])},
  "page_policies_password_expires_after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adgangskoder udløber efter"])},
  "page_policies_password_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dag(e)"])},
  "page_policies_password_send_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis advarsel efter login"])},
  "page_policies_password_minutes_before_password_expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minut(er) før adgangskoden udløber"])},
  "page_policies_password_keep_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem adgangskodehistorikken"])},
  "page_policies_password_remember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Husk"])},
  "page_policies_password_passwords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adgangskode(r)"])},
  "page_policies_password_invalid_value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Værdien skal være mellem ", _interpolate(_named("value1")), " og ", _interpolate(_named("value2"))])},
  "page_policies_password_pwd_expire_warning_minutes_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["og mindre end adgangskodens udløb"])},
  "page_policies_password_save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem ændringer"])},
  "page_policies_password_update_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker på, at du vil opdatere adgangskodepolitikken? Brugerne vil blive påvirket, og hvis deres nuværende adgangskode ikke overholder de nye indstillinger, vil de blive tvunget til at ændre det."])},
  "page_policies_password_reset_to_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nulstil til Visma-standard"])},
  "page_policies_password_reset_to_default_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker på, at du vil nulstille adgangskodepolitikken til Visma-standarden?"])},
  "page_policies_password_minimmum_length": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Minimum længde: ", _interpolate(_named("value"))])},
  "page_policies_password_require_digit_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kræv 1 ciffer"])},
  "page_policies_password_require_digit_characters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kræv ", _interpolate(_named("value")), " ciffer"])},
  "page_policies_password_require_uppercase_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kræv 1 stort tegn"])},
  "page_policies_password_require_uppercase_characters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kræv ", _interpolate(_named("value")), " stort tegn"])},
  "page_policies_password_require_lowercase_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kræv 1 små bogstav"])},
  "page_policies_password_require_lowercase_characters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kræv ", _interpolate(_named("value")), " små bogstaver"])},
  "page_policies_password_require_special_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kræver 1 specialtegn"])},
  "page_policies_password_require_special_characters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kræver ", _interpolate(_named("value")), " specialtegn"])},
  "page_policies_password_accounts_locked_after_one_login_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konti er låst efter 1 loginfejl"])},
  "page_policies_password_accounts_locked_after_login_failures": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Konti er låst efter ", _interpolate(_named("value")), " loginfejler"])},
  "page_policies_password_expires_after_one_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adgangskoder udløber efter 1 dag"])},
  "page_policies_password_expires_after_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Adgangskoder udløber efter ", _interpolate(_named("value")), " dage"])},
  "page_policies_password_keep_one_in_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem 1 adgangskode i historien"])},
  "page_policies_password_keep_in_history": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gem ", _interpolate(_named("value")), " adgangskoder i historien"])},
  "page_policies_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessioner"])},
  "page_policies_sessions_max_concurrent_sessions_per_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samlet antal samtidige sessioner pr. bruger"])},
  "page_policies_sessions_max_concurrent_sessions_per_users_value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Samlet antal samtidige sessioner pr. bruger: ", _interpolate(_named("value"))])},
  "page_policies_sessions_lock_to_origin_ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log ud bruger efter ændring af IP-adresse"])},
  "page_policies_sessions_lock_to_origin_ip_value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Log ud bruger efter ændring af IP-adresse: ", _interpolate(_named("value"))])}
}