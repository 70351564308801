import LanguageModel from '@/api/language-api-client/models/LanguageModel';
import { LanguageStoreState } from './state';

export default {
    setLanguages(state: LanguageStoreState, model: LanguageModel[]): void {
        state.languages = model;
    },

    setProcessingState(state: LanguageStoreState, isProcessing: boolean): void {
        state.isProcessing = isProcessing;
    },

    setUnauthorizedToViewLanguages(state: LanguageStoreState): void {
        state.isUnauthorizedToViewLanguages = true;
    },

    setDataLoaded(state: LanguageStoreState, isDataLoaded: boolean): void {
        state.isDataLoaded = isDataLoaded;
    },
};
