import mutations from './mutations';
import { BasicStoreAccessors } from '../types';
import { CountryStoreState } from './state';
import ApiClientError from '@/api/error';
import countryApiClient from '@/api/country-api-client';

let storeApi: BasicStoreAccessors<CountryStoreState>;

export function initActions(api: BasicStoreAccessors<CountryStoreState>): void {
    storeApi = api;
}

export default {
    async getCountriesAsync(): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            const result = await countryApiClient.getListAsync();

            if (result) {
                storeApi.commit(mutations.setCountries, result);
            }
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewCountries);
                return;
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
            storeApi.commit(mutations.setDataLoaded, true);
        }
    },

    async getUserCountryAsync(): Promise<void> {
        try {
            const userCountry = await countryApiClient.getUserCountryAsync();
            storeApi.commit(mutations.setUserCountry, userCountry);
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewCountries);
            }

            throw err;
        }
    },
};
