import IdentityProviderModel from '@/api/authenticationpolicy-api-client/models/IdentityProviderModel';
import { IdentityProviderType } from '@/api/authenticationpolicy-api-client/models/IdentityProviderType';
import StoreEntity from '@/store/models';

export default class IdentityProviderStoreModel extends StoreEntity {
    public constructor(model?: IdentityProviderModel) {
        super();

        if (model) {
            this.name = model.name;
            this.type = IdentityProviderType[model.type as keyof typeof IdentityProviderType];
            this.isReadonly = model.isReadonly;
        }
    }

    public name!: string;

    public type!: IdentityProviderType;

    public isReadonly!: boolean;
}
