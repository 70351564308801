export default {
  "common_learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprenda más"])},
  "common_ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "common_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
  "common_keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mantener"])},
  "common_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar"])},
  "common_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiado"])},
  "common_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar"])},
  "common_downloaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargado"])},
  "common_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subir"])},
  "common_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suprimir"])},
  "common_verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificar"])},
  "common_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificado"])},
  "common_not_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin verificar"])},
  "common_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exitoso"])},
  "common_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fallido"])},
  "common_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "common_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente"])},
  "common_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar"])},
  "common_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar"])},
  "common_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
  "common_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
  "common_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
  "common_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de creación"])},
  "common_completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha completada"])},
  "common_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar"])},
  "notif_error_server_unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se puede contactar el servidor web. Por favor inténtelo nuevamente en unos minutos."])},
  "notif_warn_unauthorized_to_perform_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usted no está autorizado para ejecutar esta acción."])},
  "notif_warn_unauthorized_to_view_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usted no está autorizado para ver estos datos."])},
  "notif_modal_session_expired_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La sesión ha caducado"])},
  "notif_modal_session_expired_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su sesión actual ha caducado. Por razones de seguridad, usted deberá iniciar sesión nuevamente."])},
  "alert_no_verified_domains_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debe agregar y verificar al menos un dominio para continuar."])},
  "pagination_first_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir a la primera página"])},
  "pagination_last_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir a la última página"])},
  "pagination_previous_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página anterior"])},
  "pagination_next_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente página"])},
  "pagination_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
  "common_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
  "common_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles"])},
  "common_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
  "common_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
  "common_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creado"])},
  "common_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completado"])},
  "common_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrás"])},
  "common_sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clasificar"])},
  "common_apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicar"])},
  "common_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
  "common_yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí"])},
  "common_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "common_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reiniciar"])},
  "leave_page_confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have unsaved changes"])},
  "leave_page_confirmation_modal_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to leave this page? Any unsaved changes will be lost."])},
  "common_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
  "common_show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
  "common_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])}
}