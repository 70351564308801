import { JsonObject, JsonProperty } from 'json2typescript';
import { SESSION_LOCK_TO_ORIGIN_IP, SESSION_MAX_CONCURRENT_USER_SESSIONS } from '../constants';

@JsonObject('AuthenticationPolicySessionRestrictionsModel')
export default class AuthenticationPolicySessionRestrictionsModel {
    public constructor(sessions?: number) {
        this.maxConcurrentUserSessions = sessions || SESSION_MAX_CONCURRENT_USER_SESSIONS;
        this.lockToOriginIpAddress = SESSION_LOCK_TO_ORIGIN_IP;
    }

    @JsonProperty('max_concurrent_user_sessions', Number, true)
    public maxConcurrentUserSessions: number | undefined;

    @JsonProperty('lock_to_originating_ip', Boolean, true)
    public lockToOriginIpAddress: boolean;
}
