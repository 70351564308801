import './registerServiceWorker';

import { createApp } from 'vue';
import App from './App.vue';
import initApp from './config/init';
import router from './router';
import { vuexStore } from './store';
import startBackgroundWorkers from './worker';
import i18n from './i18n';
import VueClipboard from 'vue3-clipboard';
import LoadingSpinner from './components/common/LoadingSpinner.vue';
import ClickOutside from './directives/click-outside';

// import { UI, components } from '@vismaux/vud-vue/src';
// import '@vismaux/vud-vue/dist/vud.css';

// Initialize the application
initApp();

// Create the Vue instance
const app = createApp(App);

// Register dependencies
app.use(router).use(vuexStore).use(i18n.vueModule).use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: false,
});
// .use(UI, { components });

app.use(ClickOutside);

// Register global components
app.component('loading-spinner', LoadingSpinner);

// Register global functions
/// append is needed to be used with the Vue Router in templates
app.config.globalProperties.append = (path: string, pathToAppend: string) => path + (path.endsWith('/') ? '' : '/') + pathToAppend;

// Mount the app root
app.mount('#app');

// Perform startup logic
startBackgroundWorkers();
