export default {
  "page_sso_alert_no_verified_domains_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domēna uzstādīšana"])},
  "page_sso_alert_no_verified_domains_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lai turpinātu Single Sign-On, jāpievieno un jāapstiprina vismaz viens domēns."])},
  "page_sso_alert_no_verified_domains_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doties uz Domēniem"])},
  "page_sso_alert_no_added_mydomain_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domēna uzstādīšana"])},
  "page_sso_alert_no_added_mydomain_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lai turpinātu vienreizējās pieslēgšanās (Single Sign-On) uzstādīšanu, jāpievieno un jāapstiprina vismaz viens domēns."])},
  "page_sso_alert_no_added_mydomain_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doties uz Domēniem"])},
  "page_sso_alert_idp_already_added_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identitātes nodrošinātāja iestatīšana"])},
  "page_sso_alert_idp_already_added_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jums jau ir iestatīts identitātes nodrošinātājs. Jūsu uzņēmumam var konfigurēt tikai vienu ārējo SSO identitātes nodrošinātāju."])},
  "page_sso_alert_idp_already_added_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doties uz vienreizējo pieslēgšanos (Single Sign-On)"])},
  "page_sso_mydomain_info_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MyDomain ir jūsu personīgā Visma pierakstīšanās lapa, kurā ir arī jūsu unikālie SAML2 vai OpenID Connect galapunkti."])},
  "page_sso_mydomain_info_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lietotājiem atpazīstams URL palīdzēs būt drošiem, ka lapa pieder Jūsu organizācijai."])},
  "page_sso_mydomain_info_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūs varat dzēst vai pārdēvēt savu MyDomain tikai tad, ja jums nav iestatīts neviens identitātes nodrošinātājs."])},
  "page_sso_mydomain_modal_add_or_update_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kādu MyDomain vēlaties pievienot?"])},
  "page_sso_mydomain_modal_add_or_update_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["piemērs"])},
  "page_sso_mydomain_modal_add_or_update_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ievadiet lietotājiem zināmu nosaukumu, kas satur tikai mazos burtus, ciparus un domuzīmes (-) (nosaukums nedrīkst sākties vai beigties ar domuzīmi). Maksimālais nosaukuma garums ir 40 rakstzīmes."])},
  "page_sso_mydomain_modal_add_or_update_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radās kļūda, uzstādot šo MyDomain."])},
  "page_sso_mydomain_modal_add_or_update_error_invalid_mydomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MyDomain nav pareizā formātā."])},
  "page_sso_mydomain_modal_add_or_update_error_already_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šis MyDomain jau tiek izmantots."])},
  "page_sso_mydomain_modal_add_or_update_error_sso_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MyDomain nav iespējams mainīt. Tiek izmantoti Identity Providers."])},
  "page_sso_mydomain_modal_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dzēst MyDomain"])},
  "page_sso_mydomain_modal_delete_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vai tiešām vēlies dzēst savu MyDomain: ", _interpolate(_named("myDomainUri")), "?"])},
  "page_sso_mydomain_notif_add_mydomain_success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("myDomainUri")), " veiksmīgi pievienots kā MyDomain."])},
  "page_sso_mydomain_notif_update_mydomain_success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["MyDomain veiksmīgi atjaunots uz: ", _interpolate(_named("myDomainUri")), "."])},
  "page_sso_mydomain_notif_delete_mydomain_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MyDomain ir izdzēsts."])},
  "page_sso_idp_notif_add_or_update_success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vienotā pierakstīšanās ar ", _interpolate(_named("providerDisplayName")), " ir iestatīta veiksmīgi."])},
  "page_sso_idp_selection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties identitātes nodrošinātāju"])},
  "page_sso_idp_selection_title_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atlasiet identitātes nodrošinātāju, kuru vēlaties izmantot vienreizējai pierakstīšanai (Single Sign-On)."])},
  "page_sso_idp_selection_list_title_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cits"])},
  "page_sso_idp_selection_back_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< Atpakaļ uz vienreizējo pieslēgšanos (Single Sign-On)"])},
  "page_sso_idp_selection_list_body_coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drīzumā. Sekojiet līdzi!"])},
  "page_sso_idp_selection_list_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Integrējot Visma ar ", _interpolate(_named("providerDisplayName")), ", varat iespējot lietotājiem automātisku pierakstīšanos Visma, izmantojot viņu ", _interpolate(_named("providerDisplayName")), "  kontu."])},
  "page_sso_idp_notif_add_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pievienojot jūsu identitātes nodrošinātāju, radās nezināma kļūda."])},
  "page_sso_idp_notif_update_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atjauninot jūsu identitātes nodrošinātāju, radās nezināma kļūda."])},
  "page_sso_idp_notif_update_idp_no_longer_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šis identitātes nodrošinātājs jūsu uzņēmumam vairs nepastāv."])},
  "page_sso_idp_notif_delete_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dzēšot jūsu identitātes nodrošinātāju, radās nezināma kļūda."])},
  "page_sso_idp_notif_delete_success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Jūsu ", _interpolate(_named("providerName")), " identitātes nodrošinātājs ir veiksmīgi izdzēsts."])},
  "page_sso_idp_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identitātes nodrošinātājs"])},
  "page_sso_idp_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pievienojiet identitātes nodrošinātāju, kuru vēlaties izmantot vienreizējai pierakstīšanai (Single Sign-On)."])},
  "page_sso_idp_no_idp_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nav pievienots neviens identitātes nodrošinātājs."])},
  "page_sso_idp_setup_back_to_selection_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< Atpakaļ uz izvēles lapu"])},
  "page_sso_idp_setup_step_advanced_config_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papildu konfigurācija"])},
  "page_sso_idp_setup_step_advanced_config_jit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just-in-Time (JIT) lietotāju nodrošināšana"])},
  "page_sso_idp_setup_step_advanced_config_jit_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja tas ir iespējots, automātiski tiks izveidots jebkurš lietotājs, kas neeksistē Visma sistēmā (bez lietojumprogrammas piekļuves tiesībām, izņemot pierakstīšanos). Ja tas ir atspējots, pirms vienreizējās pierakstīšanās ir jāizveido visi lietotāji Visma lietojumprogrammas administrēšanas panelī. Jebkurā gadījumā jums ir jāpiešķir lietojumprogrammu piekļuves tiesības Visma administrēšanas paneļos."])},
  "page_sso_idp_setup_help_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pārbaudiet soli pa solim sniegtos norādījumus, lai Visma konfigurētu ", _interpolate(_named("providerDisplayName")), " kā ", _interpolate(_named("idpType")), " identitātes nodrošinātāju."])},
  "page_sso_idp_modal_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dzēst identitātes nodrošinātāju"])},
  "page_sso_idp_modal_delete_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vai tiešām vēlaties dzēst identitātes nodrošinātāju ", _interpolate(_named("idpName")), "?"])},
  "page_sso_idp_modal_delete_scim_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM 2.0 nodrošināšana pašlaik ir iespējota. Ja turpināsiet, tā tiks atspējota un konfigurācija tiks zaudēta."])},
  "page_sso_saml2idp_notif_invalid_metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML2 metadati nav derīgi."])},
  "page_sso_saml2idp_notif_invalid_metadata_certificate_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsu SAML2 metadatu sertifikātam ir beidzies derīguma termiņš."])},
  "page_sso_saml2idp_notif_invalid_metadata_certificate_still_expired": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sertifikāta derīguma termiņš joprojām ir beidzies. Lūdzu, dodieties uz ", _interpolate(_named("providerDisplayName")), ", lai izveidotu jaunu sertifikātu. Pēc tam atgriezieties šeit un noklikšķiniet uz pogas 'Atsvaidzināt sertifikātu'."])},
  "page_sso_saml2idp_btn_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pievienojiet SAML2 identitātes nodrošinātāju"])},
  "page_sso_saml2idp_certificate_expires_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sertifikāta derīguma termiņš beidzas ", _interpolate(_named("expirationTime"))])},
  "page_sso_saml2idp_certificate_expired_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Derīguma termiņš beidzās ", _interpolate(_named("expirationTime")), ". Lūdzu, dodieties uz ", _interpolate(_named("saml2Idp")), " , lai izveidotu jaunu sertifikātu. Pēc tam atgriezieties šeit, rediģējiet šo pakalpojumu sniedzēju, rediģēšanas lapā noklikšķiniet uz pogas 'Atsvaidzināt sertifikātu', pārbaudiet jaunā sertifikāta informāciju un noklikšķiniet uz Saglabāt, lai to atjauninātu."])},
  "page_sso_saml2idp_certificate_expired_on_mobile": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sertifikāta derīguma termiņš beidzās ", _interpolate(_named("expirationTime")), ". Lūdzu, dodieties uz ", _interpolate(_named("saml2Idp")), " , lai izveidotu jaunu sertifikātu. Pēc tam atgriezieties šeit, rediģējiet šo pakalpojumu sniedzēju, rediģēšanas lapā noklikšķiniet uz pogas 'Atsvaidzināt sertifikātu', pārbaudiet jaunā sertifikāta informāciju un noklikšķiniet uz 'Saglabāt', lai to atjauninātu."])},
  "page_sso_saml2idp_details_header_certificate_expire_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sertifikāta derīguma termiņš"])},
  "page_sso_saml2idp_setup_step_visma_metadata_title_upload": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Augšupielādējiet Visma metadatu failu ", _interpolate(_named("providerDisplayName"))])},
  "page_sso_saml2idp_setup_step_visma_metadata_title_copy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kopējiet Visma metadatu informāciju ", _interpolate(_named("providerDisplayName"))])},
  "page_sso_saml2idp_setup_step_visma_metadata_title_copy_with_public_certificate_download": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Konfigurējiet metadatu informāciju ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_visma_metadata_title_upload_or_copy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Konfigurējiet ", _interpolate(_named("providerDisplayName")), " ar Visma metadatiem no faila vai atsevišķiem laukiem"])},
  "page_sso_saml2idp_setup_step_visma_metadata_info_upload": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Augšupielādējiet ", _interpolate(_named("providerDisplayName")), " Visma metadatu failu, kuru lejupielādējāt tālāk."])},
  "page_sso_saml2idp_setup_step_visma_metadata_info_copy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kopējiet katru tālāk esošo metadatu lauku un ielīmējiet to attiecīgajā konfigurācijā ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_visma_metadata_info_copy_with_public_certificate_download": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kopējiet tālāk norādītās saites savā ", _interpolate(_named("providerDisplayName")), " konfigurācijā. Lai iespējotu vienreizējās izrakstīšanās funkciju, lejupielādējiet sertifikātu no apakšas un augšupielādējiet to ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_visma_metadata_info_upload_or_copy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Augšupielādējiet Visma metadatu failu, ko lejupielādējāt zemāk, vai kopējiet katru tālāk norādīto metadatu lauku un ielīmējiet to attiecīgajā konfigurācijā ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_visma_metadata_info_copy_metadata_url_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tālāk nokopējiet metadatu URL un ielīmējiet to attiecīgajā konfigurācijā mapē ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_visma_metadata_metadata_file_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadatu fails"])},
  "page_sso_saml2idp_setup_step_visma_metadata_or_divider_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAI"])},
  "page_sso_saml2idp_setup_step_claims_config_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Konfigurējiet prasības ", _interpolate(_named("providerDisplayName"))])},
  "page_sso_saml2idp_setup_step_claims_config_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nosūtot SAML atbildes uz Visma, pārliecinieties, vai ", _interpolate(_named("providerDisplayName")), " ir konfigurēts, lai izmantotu tālāk norādītos prasību nosaukumus."])},
  "page_sso_saml2idp_setup_step_provider_metadata_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ielīmējiet ", _interpolate(_named("providerDisplayName")), " metadatu URL Visma"])},
  "page_sso_saml2idp_setup_step_provider_metadata_title_upload": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Augšupielādējiet ", _interpolate(_named("providerDisplayName")), " metadatus Visma"])},
  "page_sso_saml2idp_setup_step_provider_metadata_info_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kopējiet metadatu URL no ", _interpolate(_named("providerDisplayName")), " un ielīmējiet to tālāk."])},
  "page_sso_saml2idp_setup_step_provider_metadata_info_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tas ir pieejams no jūsu ", _interpolate(_named("providerDisplayName")), " un satur publisko sertifikātu, prasības un SAML galapunktus, kas Visma ir nepieciešami savai iestatīšanai."])},
  "page_sso_saml2idp_setup_step_provider_metadata_info_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lietotāja identitātei jānosūta derīgs e-pasts kā NameID, kā arī vārds un uzvārds."])},
  "page_sso_saml2idp_setup_step_provider_metadata_info_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lejupielādējiet metadatu failu no ", _interpolate(_named("providerDisplayName")), " un augšupielādējiet to zemāk."])},
  "page_sso_saml2idp_setup_step_provider_metadata_url_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ielīmējiet metadatu URL"])},
  "page_sso_saml2idp_setup_step_provider_metadata_preview_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priekšskatīt datus"])},
  "page_sso_saml2idp_setup_step_provider_metadata_empty_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lauks nevar būt tukšs."])},
  "page_sso_saml2idp_setup_step_provider_metadata_max_length_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksimālais nosaukuma garums ir 100 rakstzīmes."])},
  "page_sso_saml2idp_setup_step_provider_metadata_upload_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Augšupielādējiet metadatu failu"])},
  "page_sso_saml2idp_setup_step_provider_metadata_upload_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atlasiet failu, lai priekšskatītu datus"])},
  "page_sso_saml2idp_setup_step_provider_metadata_upload_refresh_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atlasiet failu, lai atjaunotu datus"])},
  "page_sso_saml2idp_setup_step_provider_metadata_refresh_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atjaunot datus"])},
  "page_sso_saml2idp_setup_step_provider_metadata_preview_from_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadatu priekšskatījums no URL"])},
  "page_sso_saml2idp_setup_step_provider_metadata_preview_from_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadatu priekšskatījums no faila"])},
  "page_sso_saml2idp_setup_step_provider_metadata_error_metadata_empty": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Šis lauks ir obligāts. Lūdzu, kopējiet to no ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_provider_metadata_error_metadata_url_invalid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nederīgs metadatu URL. Lūdzu, kopējiet to no ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_provider_metadata_error_metadata_file_invalid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nederīgs metadatu fails. Lūdzu, lejupielādējiet to no ", _interpolate(_named("providerDisplayName")), " un augšupielādējiet to šeit."])},
  "page_sso_saml2idp_setup_step_provider_metadata_certificate_expires_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Derīguma termiņš beidzas ", _interpolate(_named("expirationTime"))])},
  "page_sso_saml2idp_setup_step_provider_metadata_certificate_expired_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Derīguma termiņš beidzās ", _interpolate(_named("expirationTime")), ". Lūdzu, dodieties uz ", _interpolate(_named("providerDisplayName")), " , lai izveidotu jaunu sertifikātu. Pēc tam atgriezieties šeit, rediģēšanas lapā noklikšķiniet uz pogas 'Atsvaidzināt sertifikātu', pārbaudiet jaunā sertifikāta informāciju un noklikšķiniet uz 'Saglabāt', lai to atjauninātu."])},
  "page_sso_saml2idp_setup_step_advanced_config_slo_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vienreizējā izrakstīšanās (Single Sign-Out)"])},
  "page_sso_saml2idp_setup_step_advanced_config_slo_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vai vēlaties, lai Visma izsauktu jūsu ", _interpolate(_named("providerDisplayName")), " vienas atteikšanās galapunktu, kad lietotājs izrakstās no Visma lietotnēm? Atbilde uz šo ir \"NĒ\", ja vēlaties saglabāt savu ", _interpolate(_named("providerDisplayName")), " sesiju aktīvu, līdz tiek sākta izrakstīšanās no ", _interpolate(_named("providerDisplayName")), " uz Visma SAML vienas atteikšanās galapunktu."])},
  "page_sso_saml2idp_setup_step_advanced_config_updateclaims_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atjauniniet profilu katru reizi, kad lietotājs pierakstās"])},
  "page_sso_saml2idp_setup_step_advanced_config_updateclaims_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ja tas ir iespējots, pieejamie lietotāja profila atribūti tiks sinhronizēti no ", _interpolate(_named("providerDisplayName")), " katru reizi, kad lietotājs pierakstīsies."])},
  "page_sso_saml2idp_setup_step_advanced_config_updategroups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atjauniniet dalību grupā katru reizi, kad lietotājs pierakstās"])},
  "page_sso_saml2idp_setup_step_advanced_config_updategroups_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ja tas ir iespējots, lietotāja dalība grupā tiks sinhronizēta no ", _interpolate(_named("providerDisplayName")), " katru reizi, kad lietotājs pierakstīsies."])},
  "page_sso_saml2idp_setup_step_metadata_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata URL"])},
  "page_sso_saml2idp_setup_step_entity_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entity ID"])},
  "page_sso_saml2idp_setup_step_saml_logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML Logout"])},
  "page_sso_saml2idp_setup_step_sign_on_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign on URL"])},
  "page_sso_saml2idp_setup_step_logout_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout URL"])},
  "page_sso_saml2idp_setup_step_certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sertifikāts"])},
  "page_sso_saml2idp_setup_step_thumbprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Īkšķa nospiedums"])},
  "page_sso_saml2idp_setup_step_sign_authn_requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parakstiet autentifikācijas pieprasījumus"])},
  "page_sso_saml2idp_setup_step_saml_assertion_consumer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML Assertion Consumer"])},
  "page_sso_saml2sp_setup_step_visma_public_certificate_file_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publisks sertifikāts"])},
  "page_sso_oidcidp_btn_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pievienojiet OIDC identitātes nodrošinātāju"])},
  "page_sso_oidcidp_configure_uris_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Konfigurējiet URI mapē ", _interpolate(_named("providerDisplayName"))])},
  "page_sso_oidcidp_signin_redirect_uri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirect URI"])},
  "page_sso_oidcidp_signout_redirect_uri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign-out redirect URI"])},
  "page_sso_oidcidp_setup_step_visma_uris": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kopējiet tālāk norādītās saites savā ", _interpolate(_named("providerDisplayName")), " konfigurācijā."])},
  "page_sso_oidcidp_invalid_authority_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederīga autoritāte."])},
  "page_sso_oidcidp_invalid_authority_for_provider_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nederīga iestāde ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_oidcidp_configure_oidc_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurējiet OpenID Connect Client"])},
  "page_sso_oidcidp_authority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoritāte"])},
  "page_sso_oidcidp_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client ID"])},
  "page_sso_oidcidp_client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klienta noslēpums"])},
  "page_sso_oidcidp_update_client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atjauniniet klienta noslēpumu"])},
  "page_sso_oidcidp_authentication_flow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentifikācijas plūsma"])},
  "page_sso_oidcidp_authentication_web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web"])},
  "page_sso_oidcidp_authentication_pkce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PKCE"])},
  "page_sso_oidcidp_authentication_web_and_pkce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web + PKCE"])},
  "page_sso_surfconext_setup_home_realm_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SURFconext mājas sfēra"])},
  "page_sso_surfconext_setup_home_realm_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iestatiet mājas jomu, ko esat iestatījis programmā SURFconext"])},
  "page_sso_surfconext_setup_home_realm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mājas valstība"])},
  "page_sso_surfconext_select_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvēlieties domēnu"])},
  "page_sso_surfconext_notif_already_added_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jums jau ir iestatīts SURFconext identitātes nodrošinātājs."])}
}