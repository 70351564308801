
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'LoadingSpinnerComponent',

    props: {
        show: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: 'sm',
        },
    },

    setup() {
        return {};
    },
});
