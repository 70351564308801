export default {
  "page_domains_modal_add_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Hinzufügen dieser Domäne ist ein unbekannter Fehler aufgetreten."])},
  "page_domains_modal_add_error_already_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Domäne wurde bereits hinzugefügt."])},
  "page_domains_modal_add_error_invalid_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Domäne hat nicht das richtige Format."])},
  "page_domains_modal_add_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beispiel.com"])},
  "page_domains_modal_add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welchen Domainname würden Sie gerne hinzufügen?"])},
  "page_domains_modal_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domäne löschen"])},
  "page_domains_modal_delete_info_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie die Domäne löschen wollen"])},
  "page_domains_modal_delete_info_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachdem Sie diese Domäne gelöscht haben, müssen Sie sie erneut hinzufügen und prüfen, um sie als Eigentümer zu beanspruchen. Benutzer werden nicht gelöscht und kehren zu den Standard-Authentifizierungseinstellungen zurück."])},
  "page_domains_modal_delete_btn_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
  "page_domains_modal_delete_error_unkown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Löschen dieser Domäne ist ein unbekannter Fehler aufgetreten."])},
  "page_domains_modal_verify_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen durch"])},
  "page_domains_modal_verify_instructions_dns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melden Sie sich bei Ihrem DNS-Anbieter an und erstellen Sie einen DNS-TXT-Eintrag, damit wir Ihre Domain überprüfen können"])},
  "page_domains_modal_verify_instructions_dns_step_1_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieren Sie alle Informationen aus der nachstehenden Tabelle und fügen Sie sie in Ihre"])},
  "page_domains_modal_verify_instructions_dns_step_1_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DNS-Host."])},
  "page_domains_modal_verify_instructions_dns_step_1_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
  "page_domains_modal_verify_instructions_dns_step_1_txt_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TXT-Name"])},
  "page_domains_modal_verify_instructions_dns_step_1_txt_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DNS Text Eintragswert"])},
  "page_domains_modal_verify_instructions_dns_step_1_txt_name_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oder überspringen Sie es, wenn es vom Anbieter nicht unterstützt wird"])},
  "page_domains_modal_verify_instructions_dns_step_2_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kehren Sie zu dieser Seite zurück und klicken Sie auf"])},
  "page_domains_modal_verify_instructions_dns_step_2_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es dauert 5 Minuten (TTL ist auf 300s eingestellt), bis sich die DNS-Änderungen verbreitet haben."])},
  "page_domains_modal_verify_instructions_https_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevor wir überprüfen können"])},
  "page_domains_modal_verify_instructions_https_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["müssen Sie diese Überprüfungsschritte durchführen."])},
  "page_domains_modal_verify_instructions_https_step_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["die Verifizierungsdatei unten."])},
  "page_domains_modal_verify_instructions_https_step_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["die Datei in den Root-Ordner der Website Ihrer Domain."])},
  "page_domains_modal_verify_instructions_https_step_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kehren Sie zu dieser Seite zurück und klicken Sie auf"])},
  "page_domains_modal_verify_error_domain_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Domain, die Sie zu überprüfen versuchen, wurde von Ihnen oder einer anderen Person gelöscht."])},
  "page_domains_modal_verify_error_domain_invalid_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Domain konnte nicht verifiziert werden. Bitte überprüfen Sie Ihre Domainkonfiguration entsprechend der gewählten Verifizierungsmethode."])},
  "page_domains_modal_verify_error_domain_already_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Domain, die Sie zu verifizieren versuchen, wurde bereits von Ihnen oder einer anderen Person verifiziert."])},
  "page_domains_notif_common_the_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Domäne"])},
  "page_domains_notif_verified_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erfolgreich überprüft."])},
  "page_domains_notif_deleted_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wurde erfolgreich entfernt."])},
  "page_domains_table_header_domain_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain Name"])},
  "page_domains_table_header_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "page_domains_table_no_domains_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Domänen hinzugefügt."])},
  "page_domains_btn_add_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domäne hinzufügen"])},
  "page_domains_modal_delete_error_sso_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Domäne kann nicht gelöscht werden. Bitte entfernen Sie die Single Sign-On-Einrichtung, wenn Sie die Domäne löschen möchten."])},
  "page_domains_modal_delete_error_group_member_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Domäne kann nicht gelöscht werden. Es gibt Gruppen, denen noch Mitglieder zugeordnet sind."])},
  "page_domains_modal_delete_cannot_delete_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domäne kann nicht gelöscht werden: "])},
  "page_domains_modal_delete_cannot_delete_domain_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um die Domäne zu löschen, müssen Sie entweder die Single-Sign-On-Einrichtung entfernen oder eine neue verifizierte Domäne hinzufügen."])},
  "page_domains_modal_delete_cannot_delete_domain_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Domäne kann nicht gelöscht werden: ", _interpolate(_named("domainDisplayName"))])}
}