export default {
  "page_domains_modal_add_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ett okänt fel uppstod när domänen skulle läggas till."])},
  "page_domains_modal_add_error_already_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den här domänen har redan lagts till."])},
  "page_domains_modal_add_error_invalid_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domänen har fel format."])},
  "page_domains_modal_add_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exempel.com"])},
  "page_domains_modal_add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vilken domän vill du lägga till?"])},
  "page_domains_modal_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort domän"])},
  "page_domains_modal_delete_info_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Är du säker på att du vill ta bort domänen?"])},
  "page_domains_modal_delete_info_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efter att du har tagit bort domänen, måste du lägga till och verifiera den på nytt om du vill ta tillbaka ägarskapet. Användarna raderas inte och återfår standardinställningarna för autentisering."])},
  "page_domains_modal_delete_btn_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bort"])},
  "page_domains_modal_delete_error_unkown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ett okänt fel uppstod när domänen skulle tas bort."])},
  "page_domains_modal_verify_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifiera med"])},
  "page_domains_modal_verify_instructions_dns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga in hos din DNS-leverantör och skapa en DNS TXT-post för att hjälpa oss att verifiera din domän"])},
  "page_domains_modal_verify_instructions_dns_step_1_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiera alla uppgifter i tabellen nedan och lägg till dem i din"])},
  "page_domains_modal_verify_instructions_dns_step_1_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DNS-värd."])},
  "page_domains_modal_verify_instructions_dns_step_1_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
  "page_domains_modal_verify_instructions_dns_step_1_txt_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TXT-namn"])},
  "page_domains_modal_verify_instructions_dns_step_1_txt_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DNS TXT-postens värde"])},
  "page_domains_modal_verify_instructions_dns_step_1_txt_name_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eller hoppa över om detta inte stöds av leverantören"])},
  "page_domains_modal_verify_instructions_dns_step_2_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återgå till den här sidan och klicka på"])},
  "page_domains_modal_verify_instructions_dns_step_2_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det tar 5 minuter (TTL är satt till 300s) innan DNS-ändringarna är distribuerade."])},
  "page_domains_modal_verify_instructions_https_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innan vi kan verifiera"])},
  "page_domains_modal_verify_instructions_https_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["måste du slutföra dessa verifieringssteg."])},
  "page_domains_modal_verify_instructions_https_step_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verifieringsfilen nedan."])},
  "page_domains_modal_verify_instructions_https_step_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filen till root-mappen för din domäns webbplats."])},
  "page_domains_modal_verify_instructions_https_step_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Återgå till den här sidan och klicka på"])},
  "page_domains_modal_verify_error_domain_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domänen du försöker verifiera har tagits bort av dig eller någon annan."])},
  "page_domains_modal_verify_error_domain_invalid_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det gick inte att verifiera din domän. Kontrollera att din domänkonfiguration stämmer med den valda verifieringsmetoden."])},
  "page_domains_modal_verify_error_domain_already_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domänen du försöker verifiera har redan verifierats av dig eller någon annan."])},
  "page_domains_notif_common_the_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domänen"])},
  "page_domains_notif_verified_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["har verifierats."])},
  "page_domains_notif_deleted_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["har tagits bort."])},
  "page_domains_table_header_domain_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domännamn"])},
  "page_domains_table_header_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "page_domains_table_no_domains_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inga domäner har lagts till."])},
  "page_domains_btn_add_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till domän"])},
  "page_domains_modal_delete_error_sso_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det gick inte att ta bort domänen. Ta bort konfigurationen för Single Sign-on om du vill ta bort domänen."])},
  "page_domains_modal_delete_error_group_member_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det gick inte att ta bort domänen. Det finns grupper som fortfarande har medlemmar tilldelade dem."])},
  "page_domains_modal_delete_cannot_delete_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det går inte att ta bort domän: "])},
  "page_domains_modal_delete_cannot_delete_domain_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["För att ta bort domänen måste du antingen ta bort konfigurationen för Single Sign-on eller lägga till en ny verifierad domän."])},
  "page_domains_modal_delete_cannot_delete_domain_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kan inte ta bort domän: ", _interpolate(_named("domainDisplayName"))])}
}