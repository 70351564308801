export enum NotificationMessagePartStyle {
    Nomal = 'normal',
    Bold = 'bold',
    Italic = 'italic',
    BlankSpace = 'space',
}

export default class NotificationMessagePart {
    public constructor(text: string, type: NotificationMessagePartStyle = NotificationMessagePartStyle.Nomal) {
        this.text = text;
        this.type = type;
    }

    public text: string;

    public type: NotificationMessagePartStyle;
}
