import MyDomainModel from '@/api/mydomain-api-client/models/MyDomainModel';
import { MyDomainStoreState } from './state';
import TenantClientSignInUriModel from '@/api/mydomain-api-client/models/TenantClientSignInUriModel';

export default {
    setMyDomain(state: MyDomainStoreState, model: MyDomainModel): void {
        state.myDomain = model.myDomain;
        state.myDomainUri = model.myDomainUri;
        state.isModifiable = model.isModifiable;
    },

    setVismaMetadataXml(state: MyDomainStoreState, vismaMetadataXml: string) {
        state.vismaMetadataXml = vismaMetadataXml;
    },

    setVismaMetadataXmlUrl(state: MyDomainStoreState, vismaMetadataXmlUrl: string) {
        state.vismaMetadataXmlUrl = vismaMetadataXmlUrl;
    },

    deleteMyDomain(state: MyDomainStoreState): void {
        state.myDomain = void 0;
        state.myDomainUri = void 0;
    },

    setIsModifiable(state: MyDomainStoreState, isModifiable: boolean) {
        state.isModifiable = isModifiable;
    },

    setTenantClientsWithSignInUri(state: MyDomainStoreState, tenantClients: TenantClientSignInUriModel[] | undefined) {
        state.tenantClientsWithSignInUri = tenantClients;
    },

    setAssignedTenantClientWithSignInUri(state: MyDomainStoreState, tenantClient: TenantClientSignInUriModel | undefined) {
        state.assignedTenantClientWithSignInUri = tenantClient;
    },

    setVismaHomeSignInUri(state: MyDomainStoreState, vismaHomeSignInUri: string | undefined) {
        state.vismaHomeSignInUri = vismaHomeSignInUri;
        state.isVismaHomeSignInUriLoaded = true;
    },

    setProcessingState(state: MyDomainStoreState, isProcessing: boolean): void {
        state.isProcessing = isProcessing;
    },

    setUnauthorizedToViewMyDomain(state: MyDomainStoreState): void {
        state.isUnauthorizedToViewMyDomain = true;
    },

    setDataLoaded(state: MyDomainStoreState): void {
        state.isDataLoaded = true;
    },
};
