import { StatusCodes } from 'http-status-codes';

export default class ApiClientError {
    public constructor(statusCode: number, errorCode?: string, details?: string) {
        this.statusCode = statusCode;
        this.errorCode = errorCode;
        this.details = details;
    }

    public statusCode: number;

    public errorCode: string | undefined;

    public details: string | undefined;

    public get isSessionExpired(): boolean {
        return this.statusCode === StatusCodes.UNAUTHORIZED;
    }

    public get isAccessRestrictedStatus(): boolean {
        return this.statusCode === StatusCodes.FORBIDDEN;
    }
}
