import util from '@/util';
import { JsonObject, JsonProperty } from 'json2typescript';
import { Saml2ProviderType } from '../enums/Saml2ProviderType';
import Saml2MetadataModel from './base/Saml2MetadataModel';

@JsonObject('Saml2MetadataParseResultModel')
export default class Saml2MetadataParseResultModel extends Saml2MetadataModel {
    public constructor() {
        super();

        this.certificateRawData = '';
        this.certificateExpirationTime = '';
        this.certificateThumbprint = '';
    }

    @JsonProperty('certificate', String)
    public certificateRawData: string;

    @JsonProperty('certificate_thumbprint', String)
    public certificateThumbprint: string;

    @JsonProperty('certificate_expiration_time', String)
    public certificateExpirationTime: string;

    public get displayCertificateExpirationTime(): string {
        return util.formatDateTimeUtc(this.certificateExpirationTime);
    }

    public get certificateExpirationDateTime(): Date {
        return new Date(this.certificateExpirationTime);
    }

    public isValid(providerType: Saml2ProviderType): boolean {
        if (!super.isValid(providerType)) {
            return false;
        }

        const isInvalid = util.isNullOrEmpty(this.certificateRawData) || util.isNullOrEmpty(this.certificateThumbprint) || util.isNullOrEmpty(this.certificateExpirationTime);

        return !isInvalid;
    }
}
