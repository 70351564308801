import { notificationStore } from '@/store';
import NotificationMessagePart, { NotificationMessagePartStyle } from '@/store/notification-store/models/NotificationMessagePart';
import NotificationStoreModel, { NotificationType } from '@/store/notification-store/models/NotificationStoreModel';

export default {
    info(message: string): void {
        this.multiPartInfo([new NotificationMessagePart(message, NotificationMessagePartStyle.Nomal)]);
    },

    success(message: string): void {
        this.multiPartSuccess([new NotificationMessagePart(message, NotificationMessagePartStyle.Nomal)]);
    },

    warning(message: string): void {
        this.multiPartWarning([new NotificationMessagePart(message, NotificationMessagePartStyle.Nomal)]);
    },

    error(message: string): void {
        this.multiPartError([new NotificationMessagePart(message, NotificationMessagePartStyle.Nomal)]);
    },

    multiPartInfo(messageParts: NotificationMessagePart[]): void {
        notificationStore.storeApi.dispatch(notificationStore.actions.addNotification, new NotificationStoreModel(messageParts, NotificationType.Info));
    },

    multiPartSuccess(messageParts: NotificationMessagePart[]): void {
        notificationStore.storeApi.dispatch(notificationStore.actions.addNotification, new NotificationStoreModel(messageParts, NotificationType.Success));
    },

    multiPartWarning(messageParts: NotificationMessagePart[]): void {
        notificationStore.storeApi.dispatch(notificationStore.actions.addNotification, new NotificationStoreModel(messageParts, NotificationType.Warning));
    },

    multiPartError(messageParts: NotificationMessagePart[]): void {
        notificationStore.storeApi.dispatch(notificationStore.actions.addNotification, new NotificationStoreModel(messageParts, NotificationType.Error));
    },
};
