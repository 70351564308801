/* eslint-disable no-console */

import { register } from 'register-service-worker';
import config from './config';
import logger from './util/logger';

if (config().isProdEnv) {
    register(`${config().frontendUrl}/service-worker.js`, {
        ready() {
            logger.log('[Service worker] App is being served from cache. For more details, visit https://goo.gl/AFskqB.');
        },
        registered() {
            logger.log('[Service worker] Registration successful.');
        },
        updatefound() {
            logger.log('[Service worker] New content found. Purging cache and downloading new data.');

            caches.keys().then(function (names) {
                if (!names || names.length === 0) {
                    console.log('[Service worker] No cache to clean...');
                    return;
                }

                for (const name of names) {
                    caches.delete(name);
                }

                console.log('[Service worker] The cache has been purged.');
            });
        },
        updated() {
            logger.log('[Service worker] New content will be available after a page refresh. Triggering page refresh now...');
            window.location.reload();
        },
        cached() {
            logger.log('[Service worker] New content has been cached for offline use.');
        },
        offline() {
            logger.log('[Service worker] No internet connection found. App is running in offline mode.');
        },
        error(error) {
            logger.log(`[Service worker] Error during service worker registration: ${error}.`);
        },
    });
}
