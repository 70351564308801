import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('MyDomainInputModel')
export default class MyDomainInputModel {
    public constructor(myDomain?: string) {
        if (myDomain) {
            this.myDomain = myDomain;
        } else {
            this.myDomain = '';
        }
    }

    @JsonProperty('my_domain', String)
    public myDomain: string;
}
