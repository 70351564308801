import util from '@/util';
import { JsonObject, JsonProperty } from 'json2typescript';
import SurfConextInputModel from './SurfConextInputModel';

@JsonObject('SurfConextOutputModel')
export default class SurfConextOutputModel extends SurfConextInputModel {
    constructor() {
        super();

        this.createdDate = '';
    }

    @JsonProperty('created_date', String)
    public createdDate: string;

    public get displayCreatedDate(): string {
        return util.formatDate(this.createdDate);
    }
}
