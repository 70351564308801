export default {
  "page_provisioning_no_sso_setup_alert_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurering for Single Sign-on"])},
  "page_provisioning_no_sso_setup_alert_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du må aktivere Single Sign-on for å fortsette med konfigurering av SCIM-klargjøring."])},
  "page_provisioning_no_sso_setup_alert_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå til Single Sign-on"])},
  "page_provisioning_scim_not_supported_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM støttes ikke"])},
  "page_provisioning_scim_not_supported_alert_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["System for Cross-domain Identity Management (SCIM) for ", _interpolate(_named("idpName")), " støttes ikke."])},
  "page_provisioning_scim_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Med provisjonering kan du automatisere vanlige administrative oppgaver. Ved å aktivere systemet for Cross-domain Identity Management (SCIM) kan du koble Visma til ", _interpolate(_named("idpName")), ", slik at du kan:"])},
  "page_provisioning_scim_info_create_users_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett brukere og grupper"])},
  "page_provisioning_scim_info_grant_revoke_access_to_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gi og sperr tilgang til grupper"])},
  "page_provisioning_scim_info_edit_attributes_users_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger attributter til brukere og grupper"])},
  "page_provisioning_scim_info_suspend_deprovisioned_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utesteng avprovisjonerte brukere"])},
  "page_provisioning_scim_cache_refresh_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merk at det kan ta opptil to minutter før hver konfigurasjonsendring trer i kraft på SCIM-serveren."])},
  "page_provisioning_scim_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM 2.0 provisjonering"])},
  "page_provisioning_scim_last_sync_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siste synkroniseringsaktivitet"])},
  "page_provisioning_scim_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen"])},
  "page_provisioning_scim_endpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM-endepunkt"])},
  "page_provisioning_scim_bearer_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM eiertoken"])},
  "page_provisioning_scim_bearer_token_generated_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Generert ", _interpolate(_named("date"))])},
  "page_provisioning_scim_bearer_token_expires_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Utløper ", _interpolate(_named("date"))])},
  "page_provisioning_scim_bearer_token_btn_generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generer SCIM-token"])},
  "page_provisioning_scim_modal_disable_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktiver provisjonering av SCIM 2.0"])},
  "page_provisioning_scim_modal_disable_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker på at du vil deaktivere SCIM?"])},
  "page_provisioning_scim_modal_disable_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktiver"])},
  "page_provisioning_scim_refresh_token_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppdater SCIM-token"])},
  "page_provisioning_scim_modal_refresh_token_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker på at du vil oppdatere SCIM tokenet?"])},
  "page_provisioning_scim_modal_refresh_token_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppdater"])},
  "page_provisioning_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provisjoneringshandlinger"])},
  "page_provisioning_action_user_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett brukere"])},
  "page_provisioning_action_user_read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les brukere"])},
  "page_provisioning_action_user_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppdater brukere"])},
  "page_provisioning_action_user_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett brukere"])},
  "page_provisioning_action_group_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprett grupper"])},
  "page_provisioning_action_group_read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les grupper"])},
  "page_provisioning_action_group_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppdater grupper"])},
  "page_provisioning_action_group_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett grupper"])},
  "page_provisioning_action_user_update_triggers": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Når brukere er deaktivert fra ", _interpolate(_named("idpName")), ", utfør denne handlingen i ", _interpolate(_named("tenantOwnerName"))])},
  "page_provisioning_action_user_update_trigger_suspend_useraccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utesteng bruker"])},
  "page_provisioning_action_user_update_trigger_delete_from_tenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern bruker"])},
  "page_provisioning_action_user_update_trigger_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett bruker"])},
  "page_provisioning_action_user_delete_triggers": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Når brukere er slettet i ", _interpolate(_named("idpName")), ", utfør denne handlingen i ", _interpolate(_named("tenantOwnerName"))])},
  "page_provisioning_action_user_delete_trigger_suspend_useraccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utesteng bruker"])},
  "page_provisioning_action_user_delete_trigger_delete_from_tenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fjern bruker"])},
  "page_provisioning_scim_groups_alert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("tenantOwnerName")), " støtter ikke Opprett/Les/Oppdater/Sletting av grupper. Du kan imidlertid tilordne grupper til policies."])},
  "page_provisioning_scim_unsupported_actions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("tenantOwnerName")), " støtter ikke SCIM-synkronisering til ", _interpolate(_named("unsupportedActions")), "."])},
  "page_provisioning_scim_unsupported_actions_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endringer vil kun gjenspeiles i Visma identitetssystem."])},
  "page_provisioning_token_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM-token"])},
  "page_provisioning_token_modal_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopier dette hemmelige tokenet til SCIM-leverandøren din."])},
  "page_provisioning_token_modal_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tokenet vil bare vises en gang og utløper om 1 år."])},
  "page_provisioning_action_trigger_no_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen handling"])},
  "page_provisioning_btn_view_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis forelesningslogger"])},
  "page_provisioning_logs_btn_view_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis innstillinger"])},
  "page_provisioning_logs_btn_refresh_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppdater logger"])},
  "page_provisioning_logs_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provisjoneringslogger"])},
  "page_provisioning_logs_no_logs_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen SCIM-forespørsler er gjort i dette oppsettet enda."])},
  "page_provisioning_logs_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det oppstod en ukjent feil under henting av SCIM logger."])},
  "page_provisioning_logs_item_request_query_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Query string"])},
  "page_provisioning_logs_item_request_json_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request body"])},
  "page_provisioning_logs_item_response_status_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respons statuskode"])},
  "page_provisioning_logs_item_view_json": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se JSON"])},
  "page_provisioning_logs_item_no_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<no input>"])},
  "page_provisioning_logs_item_no_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<missing info>"])}
}