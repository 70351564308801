import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-title row" }
const _hoisted_2 = ["title"]
const _hoisted_3 = { class: "wrap-long-word" }
const _hoisted_4 = { class: "d-flex justify-content-start action-btn-group" }
const _hoisted_5 = { class: "row w-100 m-0 p-0 text-center action-btn-group-mobile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_dialog = _resolveComponent("modal-dialog")!

  return (_openBlock(), _createBlock(_component_modal_dialog, {
    id: "modal-leave-page-confirmation",
    icon: _ctx.isScreenMaxSm ? 'none' : 'warning',
    show: _ctx.show,
    title: _ctx.$t('leave_page_confirmation_modal_title'),
    onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.cancel()))
  }, _createSlots({
    body: _withCtx(() => [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('leave_page_confirmation_modal_content')), 1)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          class: "btn btn-primary btn-disable",
          type: "button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.confirm()))
        }, _toDisplayString(_ctx.$t('common_yes')), 1),
        _createElementVNode("button", {
          class: "btn btn-seconday btn-cancel",
          type: "button",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.cancel()))
        }, _toDisplayString(_ctx.$t('common_cancel')), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          class: "btn btn-primary btn-disable font-weight-bold col rounded-0",
          type: "button",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.confirm()))
        }, _toDisplayString(_ctx.$t('common_yes')), 1),
        _createElementVNode("button", {
          class: "btn btn-seconday btn-cancel font-weight-bold col rounded-0",
          type: "button",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.cancel()))
        }, _toDisplayString(_ctx.$t('common_cancel')), 1)
      ])
    ]),
    _: 2
  }, [
    (_ctx.isScreenMaxSm)
      ? {
          name: "title",
          fn: _withCtx(() => [
            _createElementVNode("h4", _hoisted_1, [
              _cache[5] || (_cache[5] = _createElementVNode("span", { class: "col-2 vismaicon vismaicon-filled vismaicon-warning" }, null, -1)),
              _createElementVNode("span", {
                class: "col-auto modal-title-content",
                title: _ctx.$t('leave_page_confirmation_modal_title')
              }, _toDisplayString(_ctx.$t('leave_page_confirmation_modal_title')), 9, _hoisted_2)
            ])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["icon", "show", "title"]))
}