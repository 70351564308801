import DomainStoreModel from './models/DomainStoreModel';
import StoreEntity from '../models';

export class DomainStoreState extends StoreEntity {
    public constructor() {
        super();

        this.domains = [];
        this.isProcessing = false;
        this.isUnauthorizedToViewDomains = false;
    }

    public domains: DomainStoreModel[];

    public isUnauthorizedToViewDomains: boolean;
}

export default new DomainStoreState();
