import { BasicStoreAccessors } from '../types';
import { Saml2SpProviderStoreState } from './state';
import saml2SpClient from '@/api/saml2-sp-client';
import mutations from './mutations';
let storeApi: BasicStoreAccessors<Saml2SpProviderStoreState>;

export function initActions(api: BasicStoreAccessors<Saml2SpProviderStoreState>): void {
    storeApi = api;
}

export default {
    async fetchVismaPublicCertificateAsync() {
        try {
            const publicCertificate = await saml2SpClient.fetchVismaPublicCertificateAsync();

            if (publicCertificate) {
                storeApi.commit(mutations.setPublicCertificate, publicCertificate);
            }
        } finally {
            // nothing to do here
        }
    },
};
