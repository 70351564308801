export default {
  "page_domains_modal_add_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En ukjent feil oppsto når domenet ble lagt til."])},
  "page_domains_modal_add_error_already_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette domenet er allerede lagt til."])},
  "page_domains_modal_add_error_invalid_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domenet har feil format."])},
  "page_domains_modal_add_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eksempel.com"])},
  "page_domains_modal_add_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvilket domene vil du legge til?"])},
  "page_domains_modal_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett domene"])},
  "page_domains_modal_delete_info_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vil du slette dette domenet"])},
  "page_domains_modal_delete_info_explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etter at du har slettet dette domenet må du legge det til og bekrefte det på nytt for å kreve eierskap. Brukere blir ikke slettet og vil få tilbake standard autentiseringsinnstillinger."])},
  "page_domains_modal_delete_btn_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett"])},
  "page_domains_modal_delete_error_unkown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En ukjent feil oppsto under sletting av domenet."])},
  "page_domains_modal_verify_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekreft med"])},
  "page_domains_modal_verify_instructions_dns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logg inn på din DNS-leverandør og opprett en DNS TXT-post for å hjelpe oss med å bekrefte ditt domene"])},
  "page_domains_modal_verify_instructions_dns_step_1_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopier all informasjon fra tabellen under og legg det til i din"])},
  "page_domains_modal_verify_instructions_dns_step_1_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DNS-vert."])},
  "page_domains_modal_verify_instructions_dns_step_1_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
  "page_domains_modal_verify_instructions_dns_step_1_txt_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TXT-navn"])},
  "page_domains_modal_verify_instructions_dns_step_1_txt_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DNS TXT-postens verdi"])},
  "page_domains_modal_verify_instructions_dns_step_1_txt_name_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eller utelate hvis det ikke støttes av leverandøren"])},
  "page_domains_modal_verify_instructions_dns_step_2_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå tilbake til denne siden og klikk"])},
  "page_domains_modal_verify_instructions_dns_step_2_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det tar 5 minutter (TLL er stilt inn på 300s) før DNS-endringene overføres."])},
  "page_domains_modal_verify_instructions_https_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Før vi kan bekrefte"])},
  "page_domains_modal_verify_instructions_https_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["du må fullføre disse trinnene for å bekrefte."])},
  "page_domains_modal_verify_instructions_https_step_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bekreftelsesfilen under."])},
  "page_domains_modal_verify_instructions_https_step_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filen til rotmappen for nettsted til ditt domene."])},
  "page_domains_modal_verify_instructions_https_step_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå tilbake til denne siden og klikk"])},
  "page_domains_modal_verify_error_domain_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domenet du prøver å bekrefte har blitt slettet av deg eller noen andre."])},
  "page_domains_modal_verify_error_domain_invalid_setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domenet ditt kan ikke bekreftes. Kontroller at domenets innstillinger er i henhold til den valgte verifikasjonsmetoden."])},
  "page_domains_modal_verify_error_domain_already_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domenet du prøver å bekrefte er allerede bekreftet."])},
  "page_domains_notif_common_the_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domenet"])},
  "page_domains_notif_verified_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er bekreftet."])},
  "page_domains_notif_deleted_successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["er fjernet."])},
  "page_domains_table_header_domain_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domenenavn"])},
  "page_domains_table_header_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "page_domains_table_no_domains_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen domener er lagt til."])},
  "page_domains_btn_add_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til domene"])},
  "page_domains_modal_delete_error_sso_exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan ikke slette domenet. Fjern innstillingen for Single Sign-on først hvis du ønsker å slette domenet."])},
  "page_domains_modal_delete_error_group_member_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan ikke slette domenet. Det er grupper som fortsatt har tildelte medlemmer."])},
  "page_domains_modal_delete_cannot_delete_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan ikke slette domene: "])},
  "page_domains_modal_delete_cannot_delete_domain_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For å slette domenet må du enten fjerne oppsettet for Single Sign-on eller legge til et nytt verifisert domene."])},
  "page_domains_modal_delete_cannot_delete_domain_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kan ikke slette domene: ", _interpolate(_named("domainDisplayName"))])}
}