export default {
  "page_policies_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standaard"])},
  "page_policies_default_policy_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit beleid is van toepassing op alle gebruikers"])},
  "page_policies_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerken"])},
  "page_policies_reset_to_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standaard herstellen"])},
  "page_policies_2_step_verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tweestapsverificatie"])},
  "page_policies_2_step_verification_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tweestapsverificatie vereist voor inloggen"])},
  "page_policies_2_step_verification_on_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aan"])},
  "page_policies_2_step_verification_off_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uit"])},
  "page_policies_identity_providers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identiteitsproviders"])},
  "page_policies_networks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netwerken"])},
  "page_policies_ip_ranges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-bereiken"])},
  "page_policies_ips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP's"])},
  "page_policies_subnet_masks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subnetmaskers"])},
  "page_policies_first_ip_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eerste IP in het bereik is ongeldig"])},
  "page_policies_second_ip_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tweede IP in het bereik is ongeldig"])},
  "page_policies_ip_range_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-bereik is ongeldig"])},
  "page_policies_ip_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP is ongeldig"])},
  "page_policies_subnet_mask_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subnetmasker is ongeldig"])},
  "page_policies_add_ip_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-bereik toevoegen"])},
  "page_policies_add_ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP toevoegen"])},
  "page_policies_add_subnet_mask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subnetmasker toevoegen"])},
  "page_policies_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< Terug naar Beleid"])},
  "page_policies_notif_update_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een onbekende fout opgetreden tijdens het bijwerken van het beleid."])},
  "page_policies_ip_range_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-bereik is gedupliceerd"])},
  "page_policies_ip_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP is gedupliceerd"])},
  "page_policies_subnet_mask_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subnetmasker is gedupliceerd"])},
  "page_policies_network_your_ip_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw IP-adres is"])},
  "page_policies_network_ips_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef netwerken op met behulp van IP-bereiken, IP-adressen of CIDR's. Dit moeten openbare IP-adressen zijn en geen lokale of privé-IP-adressen."])},
  "page_policies_network_auth_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De authenticatie lukt als er een match is met een van de toegestane IP's. Als uw IP-adres niet in de netwerken aanwezig is, dan wordt uw account geblokkeerd."])},
  "page_policies_network_all_networks_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u niets configureert, dan zijn alle netwerken toegestaan."])},
  "page_policies_countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landen"])},
  "page_policies_countries_no_suggestions_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen suggesties gevonden"])},
  "page_policies_countries_your_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het IP-adres is omgezet naar dit land"])},
  "page_policies_countries_auth_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De authenticatie lukt als er een match is met een van de toegestane landen. Als het land waarnaar uw IP-adres is omgezet, niet in de lijst met toegestane landen staat, dan wordt uw account geblokkeerd."])},
  "page_policies_countries_select_countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landen selecteren"])},
  "page_policies_countries_all_countries_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u niets selecteert, dan zijn alle landen toegestaan."])},
  "page_policies_all_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle toegestaan"])},
  "page_policies_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])},
  "page_policies_password_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inhoud wachtwoord"])},
  "page_policies_password_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord geblokkeerd"])},
  "page_policies_password_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlooptijd wachtwoord"])},
  "page_policies_password_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijziging wachtwoord"])},
  "page_policies_password_minimum_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimale lengte wachtwoord"])},
  "page_policies_password_minimum_required_digit_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimaal aantal vereiste cijfers (0-9)"])},
  "page_policies_password_minimum_required_uppercase_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimaal aantal vereiste hoofdletters"])},
  "page_policies_password_minimum_required_lowercase_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimaal aantal vereiste kleine letters"])},
  "page_policies_password_minimum_required_special_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimaal aantal vereiste speciale tekens"])},
  "page_policies_password_accounts_locked_login_failures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts kunnen worden geblokkeerd vanwege mislukte inlogpogingen"])},
  "page_policies_password_locked_account_after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account blokkeren na"])},
  "page_policies_password_login_failures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inlogpoging(en)"])},
  "page_policies_password_reset_failure_count_after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal mislukkingen herstellen na"])},
  "page_policies_password_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minuten"])},
  "page_policies_password_lockout_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blokkadeduur"])},
  "page_policies_password_never_expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het wachtwoord verloopt nooit"])},
  "page_policies_password_expires_after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het wachtwoord verloopt na"])},
  "page_policies_password_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dagen"])},
  "page_policies_password_send_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waarschuwing tonen na inloggen"])},
  "page_policies_password_minutes_before_password_expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minuten voordat wachtwoord verloopt"])},
  "page_policies_password_keep_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoordhistorie bewaren"])},
  "page_policies_password_remember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onthouden"])},
  "page_policies_password_passwords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wachtwoord(en)"])},
  "page_policies_password_invalid_value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["De waarde moet tussen ", _interpolate(_named("value1")), " en ", _interpolate(_named("value2")), " liggen"])},
  "page_policies_password_pwd_expire_warning_minutes_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en kleiner dan de verlooptijd van het wachtwoord"])},
  "page_policies_password_save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wijzigingen opslaan"])},
  "page_policies_password_update_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u het wachtwoordbeleid wilt bijwerken? Dit heeft gevolgen voor de gebruikers; als hun huidige wachtwoord niet overeenkomt met de nieuwe instellingen, worden ze gedwongen dit te wijzigen."])},
  "page_policies_password_reset_to_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standaard van Visma herstellen"])},
  "page_policies_password_reset_to_default_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u het wachtwoordbeleid wilt herstellen in de Visma-standaard?"])},
  "page_policies_password_minimmum_length": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Minimale lengte: ", _interpolate(_named("value"))])},
  "page_policies_password_require_digit_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 cijfer verplicht stellen"])},
  "page_policies_password_require_digit_characters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " cijfers verplicht stellen"])},
  "page_policies_password_require_uppercase_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 hoofdletter verplicht stellen"])},
  "page_policies_password_require_uppercase_characters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " hoofdletters verplicht stellen"])},
  "page_policies_password_require_lowercase_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 kleine letter verplicht stellen"])},
  "page_policies_password_require_lowercase_characters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " kleine letters verplicht stellen"])},
  "page_policies_password_require_special_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 speciaal teken verplicht stellen"])},
  "page_policies_password_require_special_characters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " speciale tekens verplicht stellen"])},
  "page_policies_password_accounts_locked_after_one_login_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts worden geblokkeerd na 1 mislukte inlogpoging"])},
  "page_policies_password_accounts_locked_after_login_failures": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Accounts worden geblokkeerd na ", _interpolate(_named("value")), " mislukte inlogpogingen"])},
  "page_policies_password_expires_after_one_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoorden verlopen na 1 dag"])},
  "page_policies_password_expires_after_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wachtwoorden verlopen na ", _interpolate(_named("value")), " dagen"])},
  "page_policies_password_keep_one_in_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 wachtwoord in de historie bewaren"])},
  "page_policies_password_keep_in_history": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " wachtwoorden in de historie bewaren"])},
  "page_policies_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessies"])},
  "page_policies_sessions_max_concurrent_sessions_per_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal aantal gelijktijdige sessies per gebruiker"])},
  "page_policies_sessions_max_concurrent_sessions_per_users_value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Totaal aantal gelijktijdige sessies per gebruiker: ", _interpolate(_named("value"))])},
  "page_policies_sessions_lock_to_origin_ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker uitloggen na wijziging van IP-adres"])},
  "page_policies_sessions_lock_to_origin_ip_value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gebruiker uitloggen na wijziging van IP-adres: ", _interpolate(_named("value"))])}
}