
import { defineComponent } from 'vue';
import ModalDialog from '@/components/common/ModalDialog.vue';
import locator from '@/util/locator';
import { windowSizeModule } from '@/modules';

export default defineComponent({
    name: 'SessionExpiredModalComponent',

    components: {
        ModalDialog,
    },

    setup() {
        // functions
        function confirm() {
            locator.redirectToOidcAuthentication();
        }

        return {
            // functions
            confirm,

            // modules
            ...windowSizeModule(),
        };
    },
});
