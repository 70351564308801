import { JsonObject, JsonProperty } from 'json2typescript';
import AuditModel from './AuditModel';

@JsonObject('AuditLogsModel')
export default class AuditLogsModel {
    public constructor() {
        this.totalAuditLogs = 0;
        this.totalPages = 0;
    }

    @JsonProperty('total_audit_logs', Number)
    public totalAuditLogs: number;

    @JsonProperty('total_pages', Number)
    public totalPages: number;

    @JsonProperty('audit_logs', [AuditModel])
    public auditLogs: AuditModel[] = [];
}
