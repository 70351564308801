export default {
  "common_learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lue lisää"])},
  "common_ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "common_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peruuta"])},
  "common_keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Säilytä"])},
  "common_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopioi"])},
  "common_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopioitu"])},
  "common_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lataa"])},
  "common_downloaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladattu"])},
  "common_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siirrä"])},
  "common_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poista"])},
  "common_verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vahvista"])},
  "common_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vahvistettu"])},
  "common_not_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vahvistamaton"])},
  "common_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toiminto onnistui"])},
  "common_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toiminto epäonnistui"])},
  "common_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yhteensä"])},
  "common_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seuraava"])},
  "common_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää"])},
  "common_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Päivitä"])},
  "common_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toiminnot"])},
  "common_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tallenna"])},
  "common_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nimi"])},
  "common_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luontipäivä"])},
  "common_completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmistumispvm"])},
  "common_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sulje"])},
  "notif_error_server_unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkkopalvelimeen ei saatu yhteyttä. Yritä hetken kuluttua uudelleen."])},
  "notif_warn_unauthorized_to_perform_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinulla ei ole oikeutta suorittaa tätä toimenpidettä."])},
  "notif_warn_unauthorized_to_view_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinulla ei ole oikeutta tarkastella näitä tietoja."])},
  "notif_modal_session_expired_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istunto on vanhentunut"])},
  "notif_modal_session_expired_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nykyinen istuntosi on vanhentunut. Kirjaudu järjestelmään turvallisuussyistä uudelleen sisään."])},
  "alert_no_verified_domains_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisää ja vahvista ainakin yksi verkkotunnus jatkaaksesi."])},
  "pagination_first_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siirry ensimmäiselle sivulle"])},
  "pagination_last_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siirry viimeiselle sivulle"])},
  "pagination_previous_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edellinen sivu"])},
  "pagination_next_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seuraava sivu"])},
  "pagination_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/"])},
  "common_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tila"])},
  "common_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiedot"])},
  "common_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuvaus"])},
  "common_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunnus"])},
  "common_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muodostettu"])},
  "common_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valmis"])},
  "common_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takaisin"])},
  "common_sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lajittele"])},
  "common_apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käytä"])},
  "common_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa"])},
  "common_yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyllä"])},
  "common_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ei"])},
  "common_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nollaa"])},
  "leave_page_confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinulla on tallentamattomia muutoksia"])},
  "leave_page_confirmation_modal_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haluatko varmasti poistua tältä sivulta? Kaikki tallentamattomat muutokset menetetään."])},
  "common_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä"])},
  "common_show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Näytä"])},
  "common_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tyyppi"])}
}