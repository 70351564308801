import LanguageModel from '@/api/language-api-client/models/LanguageModel';
import StoreEntity from '@/store/models';

export class LanguageStoreState extends StoreEntity {
    constructor() {
        super();

        this.languages = new Array<LanguageModel>();
        this.isDataLoaded = false;
        this.isUnauthorizedToViewLanguages = false;
    }

    public languages: LanguageModel[];

    public isDataLoaded: boolean;

    public isUnauthorizedToViewLanguages: boolean;
}

export default new LanguageStoreState();
