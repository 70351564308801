import GroupUserModel from '@/api/group-api-client/models/GroupUserModel';

export default class GroupUsersTotalsModel {
    public constructor() {
        this.groupUsers = [];
        this.groupUserCount = 0;
        this.groupUserPages = 0;
    }

    public groupUsers: GroupUserModel[];

    public groupUserCount: number;

    public groupUserPages: number;
}
