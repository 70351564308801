import util from '@/util';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('UserApplicationModel')
export default class UserApplicationModel {
    public constructor() {
        this.clientId = '';
        this.clientName = '';
        this.logoUri = '';
        this.loginCount = 0;
        this.isFirstParty = false;
        this.requireUnlinkToDelete = false;
    }

    @JsonProperty('application_id', String)
    public clientId: string;

    @JsonProperty('application_name', String)
    public clientName: string;

    @JsonProperty('created_date', String, true)
    public createdDate?: string;

    @JsonProperty('login_date', String, true)
    public loginDate?: string;

    @JsonProperty('last_login_date', String, true)
    public lastLoginDate?: string;

    @JsonProperty('logo_uri', String)
    public logoUri: string;

    @JsonProperty('login_count', Number)
    public loginCount: number;

    @JsonProperty('is_first_party', Boolean)
    public isFirstParty: boolean;

    @JsonProperty('identity_provider_last_used', String, true)
    public identityProviderLastUsed?: string;

    @JsonProperty('require_unlink_to_delete', Boolean, true)
    public requireUnlinkToDelete: boolean;

    public get displayCreatedDate(): string {
        if (!this.createdDate) {
            return '';
        }

        return util.formatDate(this.createdDate);
    }

    public get displayLoginDate(): string {
        if (!this.loginDate) {
            return '';
        }

        return util.formatDateTime(this.loginDate);
    }

    public get displayLastLoginDate(): string {
        if (!this.lastLoginDate) {
            return '';
        }

        return util.formatDate(this.lastLoginDate);
    }
}
