export default {
  "common_learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer"])},
  "common_ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "common_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuleren"])},
  "common_keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behouden"])},
  "common_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiëren"])},
  "common_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gekopieerd"])},
  "common_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
  "common_downloaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gedownload"])},
  "common_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
  "common_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderen"])},
  "common_verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifiëren"])},
  "common_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geverifieerd"])},
  "common_not_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet geverifieerd"])},
  "common_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelukt"])},
  "common_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet gelukt"])},
  "common_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal"])},
  "common_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgende"])},
  "common_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toevoegen"])},
  "common_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijwerken"])},
  "common_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acties"])},
  "common_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan"])},
  "common_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
  "common_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangemaakt op"])},
  "common_completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum voltooid"])},
  "common_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluiten"])},
  "notif_error_server_unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan geen verbinding maken met de webserver. Probeer het later nog eens."])},
  "notif_warn_unauthorized_to_perform_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U hebt geen toestemming om deze actie uit te voeren."])},
  "notif_warn_unauthorized_to_view_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U hebt geen toestemming om deze gegevens te bekijken."])},
  "notif_modal_session_expired_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessie is verlopen"])},
  "notif_modal_session_expired_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw huidige sessie is verlopen. Om veiligheidsredenen moet u opnieuw inloggen."])},
  "alert_no_verified_domains_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U moet minstens één domein toevoegen en controleren om verder te kunnen gaan."])},
  "pagination_first_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naar eerste pagina"])},
  "pagination_last_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naar laatste pagina"])},
  "pagination_previous_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorige pagina"])},
  "pagination_next_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volgende pagina"])},
  "pagination_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["van"])},
  "common_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "common_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegevens"])},
  "common_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omschrijving"])},
  "common_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "common_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangemaakt"])},
  "common_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltooid"])},
  "common_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug"])},
  "common_sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorteren"])},
  "common_apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toepassen"])},
  "common_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerken"])},
  "common_yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
  "common_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nee"])},
  "common_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herstellen"])},
  "leave_page_confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De wijzigingen zijn niet opgeslagen"])},
  "leave_page_confirmation_modal_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u deze pagina wilt verlaten? Alle niet-opgeslagen wijzigingen gaan verloren."])},
  "common_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weergeven"])},
  "common_show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tonen"])},
  "common_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])}
}