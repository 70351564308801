/* eslint-disable */
import Environment from '../config/environment';

export default {
    log(message: string | any, error?: any): void {
        if (Environment.isDev()) {
            console.log(message);

            if (error) {
                console.log(error);
            }
        }
    },
};
