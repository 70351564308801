export default {
  "page_sso_alert_no_verified_domains_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain Einrichtung"])},
  "page_sso_alert_no_verified_domains_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen mindestens eine Domäne hinzufügen und verifizieren, um mit der Einrichtung Ihres Single Sign-On fortzufahren."])},
  "page_sso_alert_no_verified_domains_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu Domänen gehen"])},
  "page_sso_alert_no_added_mydomain_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MyDomain-Einrichtung"])},
  "page_sso_alert_no_added_mydomain_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen eine MyDomain hinzufügen, um mit der Einrichtung Ihres Single Sign-On fortfahren zu können."])},
  "page_sso_alert_no_added_mydomain_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu MyDomains gehen"])},
  "page_sso_alert_idp_already_added_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identitätsanbieter einrichten"])},
  "page_sso_alert_idp_already_added_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben bereits einen Identity Provider eingerichtet. Nur ein externer SSO Identity Provider kann für Ihr Unternehmen konfiguriert werden."])},
  "page_sso_alert_idp_already_added_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu Single Sign-On gehen"])},
  "page_sso_mydomain_info_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die MyDomain ist Ihre persönliche Anmeldeseite bei Visma, die auch Ihre einzigartigen SAML2 oder OpenID Connect Endpunkte enthält."])},
  "page_sso_mydomain_info_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anhand einer vertrauten URL können Ihre Benutzer erkennen, dass die Website zu Ihrer Organisation gehört."])},
  "page_sso_mydomain_info_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können Ihre MyDomain nur löschen oder umbenennen, wenn Sie keinen Identitätsanbieter eingerichtet haben."])},
  "page_sso_mydomain_modal_add_or_update_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welche MyDomain möchten Sie hinzufügen?"])},
  "page_sso_mydomain_modal_add_or_update_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beispiel"])},
  "page_sso_mydomain_modal_add_or_update_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie einen vertrauten Namen für Ihre Benutzer ein, der nur Kleinbuchstaben, Zahlen und Bindestriche (-) enthalten darf, aber nicht mit einem Bindestrich beginnen oder enden darf. Die maximale Länge beträgt 40 Zeichen."])},
  "page_sso_mydomain_modal_add_or_update_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Einstellen dieser MyDomain ist ein unbekannter Fehler aufgetreten."])},
  "page_sso_mydomain_modal_add_or_update_error_invalid_mydomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die MyDomain hat nicht das richtige Format."])},
  "page_sso_mydomain_modal_add_or_update_error_already_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese MyDomain ist bereits vergeben."])},
  "page_sso_mydomain_modal_add_or_update_error_sso_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können Ihre MyDomain nicht ändern. Sie haben Identitätsprovider im Einsatz."])},
  "page_sso_mydomain_modal_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mydomain löschen"])},
  "page_sso_mydomain_modal_delete_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sind Sie sicher, dass Sie Ihre MyDomain löschen möchten: ", _interpolate(_named("myDomainUri")), "?"])},
  "page_sso_mydomain_notif_add_mydomain_success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Erfolgreich ", _interpolate(_named("myDomainUri")), " als Ihre MyDomain hinzugefügt."])},
  "page_sso_mydomain_notif_update_mydomain_success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ihre MyDomain wurde erfolgreich aktualisiert auf: ", _interpolate(_named("myDomainUri")), "."])},
  "page_sso_mydomain_notif_delete_mydomain_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben Ihre MyDomain erfolgreich gelöscht."])},
  "page_sso_idp_notif_add_or_update_success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Single Sign-On mit ", _interpolate(_named("providerDisplayName")), " wurde erfolgreich eingerichtet."])},
  "page_sso_idp_selection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identitätsanbieter wählen"])},
  "page_sso_idp_selection_title_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie einen Identitätsanbieter aus, den Sie für Single Sign-On verwenden möchten."])},
  "page_sso_idp_selection_list_title_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstiges"])},
  "page_sso_idp_selection_back_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< Zurück zu Single Sign-On"])},
  "page_sso_idp_selection_list_body_coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wird bald kommen. Bleiben Sie dabei!"])},
  "page_sso_idp_selection_list_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wenn Sie Visma mit ", _interpolate(_named("providerDisplayName")), " integrieren, können Sie Ihren Benutzern ermöglichen, sich automatisch mit ihrem ", _interpolate(_named("providerDisplayName")), " Konto bei Visma anzumelden."])},
  "page_sso_idp_notif_add_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannter Fehler beim Hinzufügen Ihres Identity Providers."])},
  "page_sso_idp_notif_update_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannter Fehler beim Aktualisieren Ihres Identity Providers."])},
  "page_sso_idp_notif_update_idp_no_longer_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Identitätsanbieter existiert für Ihr Unternehmen nicht mehr."])},
  "page_sso_idp_notif_delete_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannter Fehler beim Löschen Ihres Identity Providers."])},
  "page_sso_idp_notif_delete_success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie haben Ihren ", _interpolate(_named("providerName")), " Identitätsanbieter erfolgreich gelöscht."])},
  "page_sso_idp_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identitätsanbieter"])},
  "page_sso_idp_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fügen Sie den Identitätsanbieter hinzu, den Sie für Single Sign-On verwenden möchten."])},
  "page_sso_idp_no_idp_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Identitätsanbieter hinzugefügt."])},
  "page_sso_idp_setup_back_to_selection_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< Zurück zur Auswahlseite"])},
  "page_sso_idp_setup_step_advanced_config_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erweiterte Konfiguration"])},
  "page_sso_idp_setup_step_advanced_config_jit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just-in-Time (JIT) Benutzerbereitstellung"])},
  "page_sso_idp_setup_step_advanced_config_jit_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn diese Option aktiviert ist, wird jeder Benutzer, der nicht im Visma-System existiert, automatisch erstellt (mit Ausnahme der Anmeldung keine Zugriffsrechte für die Anwendung). Wenn es deaktiviert ist, müssen Sie alle Benutzer im Admin-Bereich Ihrer Visma-Anwendung anlegen, bevor eine einzelne Anmeldung möglich ist. In jeder Hinsicht müssen Sie die Zugriffsrechte in den Visma-Admin-Panels gewähren."])},
  "page_sso_idp_setup_help_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Überprüfen Sie die Schritt-für-Schritt-Anleitung, um ", _interpolate(_named("providerDisplayName")), " als ", _interpolate(_named("idpType")), " Identity Provider innerhalb von Visma zu konfigurieren."])},
  "page_sso_idp_modal_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identitätsanbieter löschen"])},
  "page_sso_idp_modal_delete_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sind Sie sicher, dass Sie den Identitätsanbieter ", _interpolate(_named("idpName")), " löschen möchten?"])},
  "page_sso_idp_modal_delete_scim_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM 2.0 Provisioning ist derzeit aktiviert, wenn Sie fortfahren, wird es deaktiviert und die Konfiguration geht verloren."])},
  "page_sso_saml2idp_notif_invalid_metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die SAML2 Metadaten sind nicht gültig."])},
  "page_sso_saml2idp_notif_invalid_metadata_certificate_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Zertifikat aus Ihren SAML2 Metadaten ist abgelaufen."])},
  "page_sso_saml2idp_notif_invalid_metadata_certificate_still_expired": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das Zertifikat ist immer noch abgelaufen. Bitte gehen Sie zu ", _interpolate(_named("providerDisplayName")), ", um ein neues Zertifikat zu erstellen. Kommen Sie dann hierher zurück und klicken Sie auf die Schaltfläche \"Zertifikat aktualisieren\"."])},
  "page_sso_saml2idp_btn_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML2 Identity Provider hinzufügen"])},
  "page_sso_saml2idp_certificate_expires_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zertifikat läuft ab am ", _interpolate(_named("expirationTime"))])},
  "page_sso_saml2idp_certificate_expired_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Abgelaufen am ", _interpolate(_named("expirationTime")), ". Bitte gehen Sie zu ", _interpolate(_named("saml2Idp")), ", um ein neues Zertifikat zu erstellen. Kehren Sie dann hierher zurück, bearbeiten Sie diesen Anbieter, klicken Sie auf die Schaltfläche \"Zertifikat aktualisieren\" auf der Bearbeitungsseite, überprüfen Sie die neuen Zertifikatsdetails und klicken Sie auf \"Speichern\", um es zu aktualisieren."])},
  "page_sso_saml2idp_certificate_expired_on_mobile": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das Zertifikat ist am ", _interpolate(_named("expirationTime")), " abgelaufen. Bitte gehen Sie zu ", _interpolate(_named("saml2Idp")), ", um ein neues Zertifikat zu erstellen. Kehren Sie dann hierher zurück, bearbeiten Sie diesen Anbieter, klicken Sie auf die Schaltfläche \"Zertifikat aktualisieren\" auf der Bearbeitungsseite, überprüfen Sie die neuen Zertifikatsdetails und klicken Sie auf \"Speichern\", um es zu aktualisieren."])},
  "page_sso_saml2idp_details_header_certificate_expire_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablaufdatum des Zertifikats"])},
  "page_sso_saml2idp_setup_step_visma_metadata_title_upload": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hochladen der Visma-Metadaten-Datei in ", _interpolate(_named("providerDisplayName"))])},
  "page_sso_saml2idp_setup_step_visma_metadata_title_copy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kopieren Sie die Visma-Metadaten-Details in ", _interpolate(_named("providerDisplayName"))])},
  "page_sso_saml2idp_setup_step_visma_metadata_title_copy_with_public_certificate_download": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Konfigurieren Sie die Details der Metadaten in ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_visma_metadata_title_upload_or_copy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Konfigurieren Sie ", _interpolate(_named("providerDisplayName")), " mit den Visma-Metadaten aus der Datei oder einzelnen Feldern"])},
  "page_sso_saml2idp_setup_step_visma_metadata_info_upload": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Laden Sie in ", _interpolate(_named("providerDisplayName")), " die Visma-Metadaten-Datei hoch, die Sie unten heruntergeladen haben."])},
  "page_sso_saml2idp_setup_step_visma_metadata_info_copy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kopieren Sie jedes der nachstehenden Metadatenfelder und fügen Sie es in die entsprechende Konfiguration in ", _interpolate(_named("providerDisplayName")), " ein."])},
  "page_sso_saml2idp_setup_step_visma_metadata_info_copy_with_public_certificate_download": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kopieren Sie die unten aufgeführten Links in Ihre ", _interpolate(_named("providerDisplayName")), " Konfiguration. Um die Single Sign-Out-Funktion zu aktivieren, laden Sie das Zertifikat von unten herunter und laden Sie es in ", _interpolate(_named("providerDisplayName")), " hoch."])},
  "page_sso_saml2idp_setup_step_visma_metadata_info_upload_or_copy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Laden Sie die Visma-Metadaten-Datei hoch, die Sie unten heruntergeladen haben, oder kopieren Sie die einzelnen Metadatenfelder und fügen Sie sie in die entsprechende Konfiguration in ", _interpolate(_named("providerDisplayName")), " ein."])},
  "page_sso_saml2idp_setup_step_visma_metadata_info_copy_metadata_url_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kopieren Sie jedes der nachstehenden Metadatenfelder und fügen Sie es in die entsprechende Konfiguration in ", _interpolate(_named("providerDisplayName")), " ein."])},
  "page_sso_saml2idp_setup_step_visma_metadata_metadata_file_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadaten-Datei"])},
  "page_sso_saml2idp_setup_step_visma_metadata_or_divider_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ODER"])},
  "page_sso_saml2idp_setup_step_claims_config_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Claims in ", _interpolate(_named("providerDisplayName")), " konfigurieren"])},
  "page_sso_saml2idp_setup_step_claims_config_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Stellen Sie sicher, dass ", _interpolate(_named("providerDisplayName")), " so konfiguriert ist, dass beim Senden von SAML-Antworten an Visma die folgenden Claim-Namen verwendet werden."])},
  "page_sso_saml2idp_setup_step_provider_metadata_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Einfügen der Metadaten-URL ", _interpolate(_named("providerDisplayName")), " in Visma"])},
  "page_sso_saml2idp_setup_step_provider_metadata_title_upload": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hochladen von ", _interpolate(_named("providerDisplayName")), " Metadaten in Visma"])},
  "page_sso_saml2idp_setup_step_provider_metadata_info_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kopieren Sie die Metadaten-URL von ", _interpolate(_named("providerDisplayName")), " und fügen Sie sie unten ein."])},
  "page_sso_saml2idp_setup_step_provider_metadata_info_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Diese ist über Ihren ", _interpolate(_named("providerDisplayName")), " verfügbar und enthält das öffentliche Zertifikat, die Ansprüche und SAML-Endpunkte, die Visma für seine eigene Einrichtung benötigt."])},
  "page_sso_saml2idp_setup_step_provider_metadata_info_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für die Benutzeridentität müssen Sie eine gültige E-Mail als NameID sowie den Vor- und Nachnamen angeben."])},
  "page_sso_saml2idp_setup_step_provider_metadata_info_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Laden Sie die Metadaten-Datei von ", _interpolate(_named("providerDisplayName")), " herunter und laden Sie sie unten hoch."])},
  "page_sso_saml2idp_setup_step_provider_metadata_url_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadaten-URL einfügen"])},
  "page_sso_saml2idp_setup_step_provider_metadata_preview_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschaudaten"])},
  "page_sso_saml2idp_setup_step_provider_metadata_empty_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Feld darf nicht leer sein."])},
  "page_sso_saml2idp_setup_step_provider_metadata_max_length_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die maximale Länge beträgt 100 Zeichen."])},
  "page_sso_saml2idp_setup_step_provider_metadata_upload_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadaten-Datei hochladen"])},
  "page_sso_saml2idp_setup_step_provider_metadata_upload_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei auswählen, um Daten in der Vorschau anzuzeigen"])},
  "page_sso_saml2idp_setup_step_provider_metadata_upload_refresh_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei zum Aktualisieren der Daten auswählen"])},
  "page_sso_saml2idp_setup_step_provider_metadata_refresh_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten aktualisieren"])},
  "page_sso_saml2idp_setup_step_provider_metadata_preview_from_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadaten-Vorschau von URL"])},
  "page_sso_saml2idp_setup_step_provider_metadata_preview_from_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadaten-Vorschau aus Datei"])},
  "page_sso_saml2idp_setup_step_provider_metadata_error_metadata_empty": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dieses Feld ist obligatorisch. Bitte kopieren Sie es von ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_provider_metadata_error_metadata_url_invalid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ungültige Metadaten-URL. Bitte kopieren Sie sie von ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_provider_metadata_error_metadata_file_invalid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ungültige Metadaten-Datei. Bitte laden Sie sie von ", _interpolate(_named("providerDisplayName")), " herunter und laden Sie sie hier hoch."])},
  "page_sso_saml2idp_setup_step_provider_metadata_certificate_expires_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Läuft ab am ", _interpolate(_named("expirationTime"))])},
  "page_sso_saml2idp_setup_step_provider_metadata_certificate_expired_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Abgelaufen am ", _interpolate(_named("expirationTime")), ". Bitte gehen Sie zu ", _interpolate(_named("providerDisplayName")), ", um ein neues Zertifikat zu erstellen. Kehren Sie dann hierher zurück, klicken Sie auf der Bearbeitungsseite auf die Schaltfläche \"Zertifikat aktualisieren\", überprüfen Sie die neuen Zertifikatsdetails und klicken Sie auf \"Speichern\", um es zu aktualisieren."])},
  "page_sso_saml2idp_setup_step_advanced_config_slo_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single Sign-Out"])},
  "page_sso_saml2idp_setup_step_advanced_config_slo_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie, dass Visma Ihren ", _interpolate(_named("providerDisplayName")), " Single Logout-Endpunkt aufruft, wenn sich der Benutzer von Visma-Anwendungen abmeldet? Die Antwort darauf ist \"NEIN\", wenn Sie Ihre eigene ", _interpolate(_named("providerDisplayName")), "-Sitzung aktiv halten möchten, bis die Abmeldung von ", _interpolate(_named("providerDisplayName")), " zum Visma SAML Single Logout Endpunkt initiiert wird."])},
  "page_sso_saml2idp_setup_step_advanced_config_updateclaims_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren Sie das Profil jedes Mal, wenn sich ein Benutzer anmeldet"])},
  "page_sso_saml2idp_setup_step_advanced_config_updateclaims_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wenn diese Option aktiviert ist, werden die verfügbaren Benutzerprofilattribute bei jeder Anmeldung eines Benutzers mit ", _interpolate(_named("providerDisplayName")), " synchronisiert."])},
  "page_sso_saml2idp_setup_step_advanced_config_updategroups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisierung der Gruppenmitgliedschaften bei jeder Anmeldung eines Benutzers"])},
  "page_sso_saml2idp_setup_step_advanced_config_updategroups_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wenn sie aktiviert ist, werden die Gruppenmitgliedschaften des Benutzers bei jeder Anmeldung von ", _interpolate(_named("providerDisplayName")), " synchronisiert."])},
  "page_sso_saml2idp_setup_step_metadata_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata URL"])},
  "page_sso_saml2idp_setup_step_entity_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entity ID"])},
  "page_sso_saml2idp_setup_step_saml_logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML Logout"])},
  "page_sso_saml2idp_setup_step_sign_on_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign on URL"])},
  "page_sso_saml2idp_setup_step_logout_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout URL"])},
  "page_sso_saml2idp_setup_step_certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zertifikat"])},
  "page_sso_saml2idp_setup_step_thumbprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daumenabdruck"])},
  "page_sso_saml2idp_setup_step_sign_authn_requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungsanfragen signieren"])},
  "page_sso_saml2idp_setup_step_saml_assertion_consumer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML Assertion Konsument"])},
  "page_sso_saml2sp_setup_step_visma_public_certificate_file_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffentliches Zertifikat"])},
  "page_sso_oidcidp_btn_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OIDC Identity Provider hinzufügen"])},
  "page_sso_oidcidp_configure_uris_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Konfigurieren Sie URIs in ", _interpolate(_named("providerDisplayName"))])},
  "page_sso_oidcidp_signin_redirect_uri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirect URI"])},
  "page_sso_oidcidp_signout_redirect_uri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign-out redirect URI"])},
  "page_sso_oidcidp_setup_step_visma_uris": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kopieren Sie die unten aufgeführten Links in Ihre ", _interpolate(_named("providerDisplayName")), " Konfiguration."])},
  "page_sso_oidcidp_invalid_authority_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige Autorität."])},
  "page_sso_oidcidp_invalid_authority_for_provider_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ungültige Autorität für ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_oidcidp_configure_oidc_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OpenID Connect Client konfigurieren"])},
  "page_sso_oidcidp_authority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorität"])},
  "page_sso_oidcidp_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client ID"])},
  "page_sso_oidcidp_client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clientgeheimnis"])},
  "page_sso_oidcidp_update_client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clientgeheimnis aktualisieren"])},
  "page_sso_oidcidp_authentication_flow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication flow"])},
  "page_sso_oidcidp_authentication_web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web"])},
  "page_sso_oidcidp_authentication_pkce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PKCE"])},
  "page_sso_oidcidp_authentication_web_and_pkce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web + PKCE"])},
  "page_sso_surfconext_setup_home_realm_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SURFconext-Heimbereich"])},
  "page_sso_surfconext_setup_home_realm_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legen Sie den Home-Bereich fest, den Sie in SURFconext eingerichtet haben"])},
  "page_sso_surfconext_setup_home_realm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heimbereich"])},
  "page_sso_surfconext_select_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domäne auswählen"])},
  "page_sso_surfconext_notif_already_added_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben den SURFconext-Identitätsanbieter bereits eingerichtet."])}
}