
import { defineComponent } from 'vue';
import ModalDialog from '@/components/common/ModalDialog.vue';
import { windowSizeModule } from '@/modules';
import { uiInteractionStore } from '@/store';

export default defineComponent({
    name: 'LeavePageConfirmationModalComponent',

    components: { ModalDialog },

    props: {
        show: Boolean,
    },

    setup() {
        // functions
        function confirm() {
            uiInteractionStore.storeApi.dispatch(uiInteractionStore.actions.confirmPageLeave);
        }

        function cancel() {
            uiInteractionStore.storeApi.commit(uiInteractionStore.mutations.setConfirmPageLeaveCallback, void 0);
        }

        return {
            // functions
            confirm,
            cancel,

            // modules
            ...windowSizeModule(),
        };
    },
});
