export default {
  "page_policies_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default"])},
  "page_policies_default_policy_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This policy applies to all users"])},
  "page_policies_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Labot"])},
  "page_policies_reset_to_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset to default"])},
  "page_policies_2_step_verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2-Step Verification"])},
  "page_policies_2_step_verification_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Require 2-Step Verification during sign in"])},
  "page_policies_2_step_verification_on_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ON"])},
  "page_policies_2_step_verification_off_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OFF"])},
  "page_policies_identity_providers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity Providers"])},
  "page_policies_networks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Networks"])},
  "page_policies_ip_ranges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP ranges"])},
  "page_policies_ips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IPs"])},
  "page_policies_subnet_masks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subnet masks"])},
  "page_policies_first_ip_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First IP in the range is invalid"])},
  "page_policies_second_ip_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Second IP in the range is invalid"])},
  "page_policies_ip_range_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP Range is invalid"])},
  "page_policies_ip_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP is invalid"])},
  "page_policies_subnet_mask_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subnet mask is invalid"])},
  "page_policies_add_ip_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add IP range"])},
  "page_policies_add_ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add IP"])},
  "page_policies_add_subnet_mask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add subnet mask"])},
  "page_policies_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< Back to Policies"])},
  "page_policies_notif_update_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unknown error has occurred while updating the policy."])},
  "page_policies_ip_range_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP Range is duplicated"])},
  "page_policies_ip_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP is duplicated"])},
  "page_policies_subnet_mask_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subnet mask is duplicated"])},
  "page_policies_network_your_ip_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your IP address is"])},
  "page_policies_network_ips_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specify networks using IP ranges, IP addresses or CIDRs. These must be public IP addresses, and not local or private IP addresses."])},
  "page_policies_network_auth_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The authentication will pass if there is a match on any of the allowed IPs. If the networks do not include your IP address, you will lock yourself out."])},
  "page_policies_network_all_networks_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If nothing is configured then all networks will be allowed."])},
  "page_policies_countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Countries"])},
  "page_policies_countries_no_suggestions_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No suggestions found"])},
  "page_policies_countries_your_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your IP address resolved country is"])},
  "page_policies_countries_auth_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The authentication will pass if there is a match on any of the allowed countries. If the countries do not include your IP address resolved country, you will lock yourself out."])},
  "page_policies_countries_select_countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select countries"])},
  "page_policies_countries_all_countries_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If nothing is selected then all countries will be allowed."])},
  "page_policies_all_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viss atļauts"])},
  "page_policies_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parole"])},
  "page_policies_password_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paroles saturs"])},
  "page_policies_password_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parole bloķēta"])},
  "page_policies_password_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paroles derīguma termiņš"])},
  "page_policies_password_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paroles maiņa"])},
  "page_policies_password_minimum_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paroles minimālais garums"])},
  "page_policies_password_minimum_required_digit_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimālais nepieciešamais cipars (0–9) rakstzīmes"])},
  "page_policies_password_minimum_required_uppercase_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepieciešamais minimālais lielo burtu skaits"])},
  "page_policies_password_minimum_required_lowercase_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obligātie mazie burti"])},
  "page_policies_password_minimum_required_special_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimālais nepieciešamo speciālo rakstzīmju skaits"])},
  "page_policies_password_accounts_locked_login_failures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konti var tikt bloķēti pieteikšanās kļūmju dēļ"])},
  "page_policies_password_locked_account_after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloķēt kontu pēc"])},
  "page_policies_password_login_failures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pieteikšanās kļūme(-es)"])},
  "page_policies_password_reset_failure_count_after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atiestatīt kļūdu skaitu pēc"])},
  "page_policies_password_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minūte(s)"])},
  "page_policies_password_lockout_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokauta ilgums"])},
  "page_policies_password_never_expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paroles nekad nebeidzas"])},
  "page_policies_password_expires_after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paroļu derīguma termiņš beidzas pēc"])},
  "page_policies_password_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["diena(s)"])},
  "page_policies_password_send_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rādīt brīdinājumu pēc pieteikšanās"])},
  "page_policies_password_minutes_before_password_expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minūti(-es) pirms paroles derīguma termiņa beigām"])},
  "page_policies_password_keep_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saglabājiet paroļu vēsturi"])},
  "page_policies_password_remember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atcerieties"])},
  "page_policies_password_passwords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parole(-es)"])},
  "page_policies_password_invalid_value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vērtībai ir jābūt no ", _interpolate(_named("value1")), " līdz ", _interpolate(_named("value2"))])},
  "page_policies_password_pwd_expire_warning_minutes_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un mazāks par paroles derīguma termiņu"])},
  "page_policies_password_save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saglabāt izmaiņas"])},
  "page_policies_password_update_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai tiešām vēlaties atjaunināt paroles politiku? Lietotāji tiks ietekmēti, un, ja viņu pašreizējā parole neatbilst jaunajiem iestatījumiem, viņi būs spiesti to mainīt."])},
  "page_policies_password_reset_to_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atiestatīt uz Visma noklusējuma iestatījumu"])},
  "page_policies_password_reset_to_default_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai tiešām vēlaties atiestatīt paroles politiku uz Visma noklusējuma iestatījumu?"])},
  "page_policies_password_minimmum_length": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Minimālais garums: ", _interpolate(_named("value"))])},
  "page_policies_password_require_digit_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepieciešama 1 cipara rakstzīme"])},
  "page_policies_password_require_digit_characters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nepieciešamas ", _interpolate(_named("value")), " ciparu rakstzīmes"])},
  "page_policies_password_require_uppercase_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepieciešams 1 lielais burts"])},
  "page_policies_password_require_uppercase_characters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nepieciešamas ", _interpolate(_named("value")), " lielie burti"])},
  "page_policies_password_require_lowercase_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepieciešams 1 mazais burts"])},
  "page_policies_password_require_lowercase_characters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nepieciešamas ", _interpolate(_named("value")), " mazās rakstzīmes"])},
  "page_policies_password_require_special_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepieciešama 1 īpašā rakstzīme"])},
  "page_policies_password_require_special_characters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nepieciešamas ", _interpolate(_named("value")), " īpašās rakstzīmes"])},
  "page_policies_password_accounts_locked_after_one_login_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konti tiek bloķēti pēc 1 pieteikšanās kļūmes"])},
  "page_policies_password_accounts_locked_after_login_failures": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Konti tiek bloķēti pēc ", _interpolate(_named("value")), " pieteikšanās kļūmēm"])},
  "page_policies_password_expires_after_one_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paroļu derīguma termiņš beidzas pēc 1 dienas"])},
  "page_policies_password_expires_after_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Paroļu derīguma termiņš beidzas pēc ", _interpolate(_named("value")), " dienām"])},
  "page_policies_password_keep_one_in_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saglabājiet 1 paroli vēsturē"])},
  "page_policies_password_keep_in_history": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Saglabājiet ", _interpolate(_named("value")), " paroles vēsturē"])},
  "page_policies_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesijas"])},
  "page_policies_sessions_max_concurrent_sessions_per_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopējais vienlaicīgu sesiju skaits vienam lietotājam"])},
  "page_policies_sessions_max_concurrent_sessions_per_users_value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kopējais vienlaicīgo sesiju skaits vienam lietotājam: ", _interpolate(_named("value"))])},
  "page_policies_sessions_lock_to_origin_ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izrakstīties no lietotāja pēc IP adreses maiņas"])},
  "page_policies_sessions_lock_to_origin_ip_value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lietotājs izrakstās pēc IP adreses maiņas: ", _interpolate(_named("value"))])}
}