export default {
  "page_groups_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepsnaam"])},
  "page_groups_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lid"])},
  "page_groups_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leden"])},
  "page_groups_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omschrijving"])},
  "page_groups_table_no_groups_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen groepen toegevoegd"])},
  "page_groups_table_no_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen groepen"])},
  "page_groups_table_no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen groepen gevonden die aan de zoekcriteria voldoen"])},
  "page_groups_btn_add_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groep toevoegen"])},
  "page_groups_btn_save_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groep opslaan"])},
  "page_groups_btn_edit_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groep bewerken"])},
  "page_groups_users_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mailadres"])},
  "page_groups_users_fist_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
  "page_groups_users_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
  "page_groups_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aangemaakt (UTC)"])},
  "page_groups_membership_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lidmaatschap bijgewerkt (UTC)"])},
  "page_groups_delete_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groep verwijderen"])},
  "page_groups_delete_group_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u de groep wilt verwijderen?"])},
  "page_groups_remove_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lid verwijderen"])},
  "page_groups_remove_member_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weet u zeker dat u het lid wilt verwijderen"])},
  "page_groups_remove_member_group_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Weet u zeker dat u het lid ", _interpolate(_named("member")), " uit de groep ", _interpolate(_named("group")), " wilt verwijderen?"])},
  "page_groups_no_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gebruikers toegevoegd"])},
  "page_groups_no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gebruikers gevonden die aan de zoekcriteria voldoen"])},
  "page_groups_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< Terug naar Groepen"])},
  "page_groups_add_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lid aan groep toevoegen"])},
  "page_groups_add_users_all_are_already_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle gebruikers zijn al aan deze groep toegevoegd"])},
  "page_groups_add_users_all_no_users_matching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen gebruikers die aan de zoekcriteria voldoen"])},
  "page_groups_member_since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lid sinds"])},
  "page_groups_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijderen"])},
  "page_groups_group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepsleden:"])},
  "page_groups_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepsnr.:"])},
  "page_groups_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bron"])},
  "page_groups_err_group_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een onbekende fout opgetreden bij het toevoegen van deze groep."])},
  "page_groups_err_group_invalid_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De groepsnaam is ongeldig. Deze mag maximaal 255 tekens lang zijn."])},
  "page_groups_err_group_invalid_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De groepsomschrijving is ongeldig. Deze mag maximaal 1024 tekens lang zijn."])},
  "page_groups_err_group_already_exits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De groep die u probeert toe te voegen, bestaat al."])},
  "page_groups_err_group_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een onbekende fout opgetreden bij het bijwerken van deze groep."])},
  "page_groups_search_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groepsnaam zoeken"])},
  "page_groups_search_group_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoek op e-mailadres"])},
  "page_groups_search_group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leden zoeken"])},
  "page_groups_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["groepen"])},
  "page_groups_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["groep"])},
  "page_groups_filter_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leden filteren"])},
  "page_groups_member_filter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lid: ", _interpolate(_named("name"))])}
}