import AuthenticationPolicyStoreModel from './models/AuthenticationPolicyStoreModel';
import StoreEntity from '../models';
import IdentityProviderStoreModel from './models/IdentityProviderStoreModel';
import AuthenticationPolicyPasswordPolicyModel from '@/api/authenticationpolicy-api-client/models/AuthenticationPolicyPasswordPolicyModel';
import PasswordPolicyValidationStoreModel from './models/PasswordPolicyValidationStoreModel';
import AuthorizedNetworksStoreModel from './models/AuthorizedNetworksStoreModel';
import AuthenticationPolicyCountryStoreModel from './models/AuthenticationPolicyCountryStoreModel';
import AuthenticationPolicySessionRestrictionsModel from '@/api/authenticationpolicy-api-client/models/AuthenticationPolicySessionRestrictionsModel';
import SessionRestrictionsSettingsModel from '@/api/authenticationpolicy-api-client/models/SessionRestrictionsSettingsModel';

export class AuthenticationPolicyStoreState extends StoreEntity {
    public constructor() {
        super();

        this.authenticationPolicies = [];
        this.allIdentityProviders = [];
        this.isProcessing = false;
        this.isUnauthorizedToViewAuthenticationPolicies = false;
        this.isDataLoaded = false;
        this.editedAuthorizedNetworks = new AuthorizedNetworksStoreModel();
        this.editedCountries = [];
        this.editedPasswordPolicy = new AuthenticationPolicyPasswordPolicyModel();
        this.editedPasswordPolicyValidation = new PasswordPolicyValidationStoreModel();
        this.editedSessionRestrictions = new AuthenticationPolicySessionRestrictionsModel();
        this.sessionRestrictionsSettings = new SessionRestrictionsSettingsModel();
    }

    public authenticationPolicies: AuthenticationPolicyStoreModel[];

    public allIdentityProviders: IdentityProviderStoreModel[];

    public isUnauthorizedToViewAuthenticationPolicies: boolean;

    public isDataLoaded: boolean;

    public editedAuthorizedNetworks: AuthorizedNetworksStoreModel;

    public editedCountries: AuthenticationPolicyCountryStoreModel[];

    public editedPasswordPolicy: AuthenticationPolicyPasswordPolicyModel;

    public editedPasswordPolicyValidation: PasswordPolicyValidationStoreModel;

    public editedSessionRestrictions: AuthenticationPolicySessionRestrictionsModel;

    public sessionRestrictionsSettings: SessionRestrictionsSettingsModel;
}

export default new AuthenticationPolicyStoreState();
