import logger from '@/util/logger';
import { authStore } from '@/store';
import { StatusCodes } from 'http-status-codes';
import ApiResponseInterceptor from '../models/ApiResponseInterceptor';
import ApiResponse from '../models/ApiResponse';

export default class UnauthorizedInterceptor implements ApiResponseInterceptor {
    name(): string {
        return 'UnauthorizedInterceptor';
    }

    async intercept(response: ApiResponse): Promise<boolean> {
        // Ignore all OIDC paths
        if (response.request.url && response.request.url.startsWith('/oidc')) {
            return true;
        }

        // Validate the response code
        if (response.statusCode === StatusCodes.UNAUTHORIZED) {
            logger.log(`Received 401 Unauthorized from backend. Response ${response.statusCode} ${JSON.stringify(response.rawData)}`);
            authStore.storeApi.commit(authStore.mutations.setAuthenticationState, false);
            return false;
        }

        return true;
    }
}
