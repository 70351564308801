import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('AuthenticationPolicyPasswordPolicyModel')
export default class AuthenticationPolicyPasswordPolicyModel {
    public constructor() {
        this.pwdMaxFailure = 0;
        this.pwdFailureCountIntervalMinutes = 0;
        this.pwdInHistory = 0;
        this.pwdLockoutDurationMinutes = 0;
        this.pwdMinLength = 0;
        this.pwdQualityMinDigitChar = 0;
        this.pwdQualityMinUppercaseChar = 0;
        this.pwdQualityMinLowercaseChar = 0;
        this.pwdQualityMinSpecialChar = 0;
        this.pwdMaxAgeDays = 0;
        this.pwdExpireWarningMinutes = 0;
    }

    @JsonProperty('pwd_max_failure', Number, true)
    public pwdMaxFailure: number | undefined;

    @JsonProperty('pwd_failure_count_interval_minutes', Number, true)
    public pwdFailureCountIntervalMinutes: number | undefined;

    @JsonProperty('pwd_in_history', Number, true)
    public pwdInHistory: number | undefined;

    @JsonProperty('pwd_lockout_duration_minutes', Number, true)
    public pwdLockoutDurationMinutes: number | undefined;

    @JsonProperty('pwd_min_length', Number, true)
    public pwdMinLength: number | undefined;

    @JsonProperty('pwd_quality_min_digit_char', Number, true)
    public pwdQualityMinDigitChar: number | undefined;

    @JsonProperty('pwd_quality_min_uppercase_char', Number, true)
    public pwdQualityMinUppercaseChar: number | undefined;

    @JsonProperty('pwd_quality_min_lowercase_char', Number, true)
    public pwdQualityMinLowercaseChar: number | undefined;

    @JsonProperty('pwd_quality_min_special_char', Number, true)
    public pwdQualityMinSpecialChar: number | undefined;

    @JsonProperty('pwd_max_age_days', Number, true)
    public pwdMaxAgeDays: number | undefined;

    @JsonProperty('pwd_expire_warning_minutes', Number, true)
    public pwdExpireWarningMinutes: number | undefined;
}
