import { SortOrder } from '@/api/user-api-client/enums/SortOrder';
import { UserApplicationSortColumn } from '@/api/user-api-client/enums/UserApplicationSortColumn';
import UserApplicationModel from '@/api/user-api-client/models/UserApplicationModel';

export default class UserApplicationsModel {
    public constructor(list: UserApplicationModel[]) {
        this.list = list;
        this.sortColumn = UserApplicationSortColumn.ClientName;
        this.sortOrder = SortOrder.Ascending;
        this.showSupportingApplications = false;
    }

    public list: UserApplicationModel[];

    public sortColumn: UserApplicationSortColumn;

    public sortOrder: SortOrder;

    // supporting applications are the ones with requireUnlinkToDelete = false
    public showSupportingApplications: boolean;
}
