import util from '@/util';
import { JsonObject, JsonProperty } from 'json2typescript';
import ProvisioningConfigurationModel from './ProvisioningConfigurationModel';

@JsonObject('ProvisioningModel')
export default class ProvisioningModel {
    public constructor() {
        this.createdDate = '';
        this.updatedDate = '';
        this.tokenCreatedDate = void 0;
        this.tokenExpirationDate = void 0;
        this.configuration = new ProvisioningConfigurationModel();
        this.tenantOwnerClientId = '';
        this.tenantOwnerClientName = '';
        this.tenantOwnerProvisioningActions = [];
    }

    @JsonProperty('created_date', String)
    public createdDate: string;

    @JsonProperty('updated_date', String)
    public updatedDate: string;

    @JsonProperty('last_sync_activity_date', String, true)
    public lastSyncActivityDate?: string;

    @JsonProperty('token_created_date', String, true)
    public tokenCreatedDate?: string;

    @JsonProperty('token_expiry_date', String, true)
    public tokenExpirationDate?: string;

    @JsonProperty('configuration', ProvisioningConfigurationModel)
    public configuration: ProvisioningConfigurationModel;

    @JsonProperty('tenant_owner_application_id', String)
    public tenantOwnerClientId: string;

    @JsonProperty('tenant_owner_application_name', String)
    public tenantOwnerClientName: string;

    @JsonProperty('tenant_owner_provisioning_actions_support', [String])
    public tenantOwnerProvisioningActions: string[];

    public get displayCreatedDate(): string {
        return util.formatDate(this.createdDate);
    }

    public get displayUpdatedDate(): string {
        return util.formatDate(this.updatedDate);
    }

    public get displayLastSyncActivityDate(): string | undefined {
        if (!this.lastSyncActivityDate) {
            return void 0;
        }

        return util.formatDateTimeCustom(this.lastSyncActivityDate, 'YYYY-MM-DD HH:mm UTC');
    }

    public get displayTokenCreatedDate(): string | undefined {
        if (!this.tokenCreatedDate) {
            return void 0;
        }

        return util.formatDate(this.tokenCreatedDate);
    }

    public get displayTokenExpirationDate(): string | undefined {
        if (!this.tokenExpirationDate) {
            return void 0;
        }

        return util.formatDate(this.tokenExpirationDate);
    }
}
