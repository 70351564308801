
import { defineComponent } from 'vue';
import { setupNotificationsOverlay } from './module';

export default defineComponent({
    name: 'NotificationsOverlayComponent',

    setup() {
        return {
            ...setupNotificationsOverlay(),
        };
    },
});
