import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('AuthenticationTenantModel')
export default class AuthenticationTenantModel {
    public constructor() {
        this.tenantId = '';
        this.organizationName = '';
        this.organizationNumber = '';
        this.countryCode = '';
    }

    @JsonProperty('tenant_id', String)
    public tenantId: string;

    @JsonProperty('organization_name', String)
    public organizationName: string;

    @JsonProperty('organization_number', String)
    public organizationNumber: string;

    @JsonProperty('country_code', String)
    public countryCode: string;
}
