import CountryModel from '@/api/country-api-client/models/CountryModel';
import StoreEntity from '@/store/models';

export class CountryStoreState extends StoreEntity {
    constructor() {
        super();

        this.countries = new Array<CountryModel>();
        this.isDataLoaded = false;
        this.isUnauthorizedToViewCountries = false;
    }

    public countries: CountryModel[];

    public userCountry?: CountryModel;

    public isDataLoaded: boolean;

    public isUnauthorizedToViewCountries: boolean;
}

export default new CountryStoreState();
