const devEnv = 'development';
const prodEnv = 'production';

export default {
    isDev(): boolean {
        return process.env.NODE_ENV === devEnv;
    },

    isProd(): boolean {
        return process.env.NODE_ENV === prodEnv;
    },
};
