export default class AuditEventModel {
    public constructor(name: string) {
        this.name = name;
        this.selected = false;
    }

    public name: string;

    public selected: boolean;
}
