import DomainModel from '@/api/domain-api-client/models/DomainModel';
import StoreEntity from '@/store/models';
import util from '@/util';

export default class DomainStoreModel extends StoreEntity {
    public constructor(model?: DomainModel) {
        super();

        this.verificationRecord = '';

        if (model) {
            this.name = model.name;
            this.isVerified = model.isVerified;
            this.createdDate = model.createdDate;
            this.userCount = model.userCount;
        }
    }

    public name!: string;

    public isVerified!: boolean;

    public createdDate!: string;

    public verificationRecord!: string;

    public userCount!: number;

    public get displayCreatedDate(): string {
        return util.formatDate(this.createdDate);
    }
}
