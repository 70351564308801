import AuthenticationModel from '@/api/auth-api-client/models/AuthenticationModel';
import TenantStoreModel from './TenantStoreModel';

export default class TenantContextStoreModel {
    public constructor(model?: AuthenticationModel) {
        if (model) {
            this.tenantId = model.tenantId;
            this.tenants = model.tenants.map((tenant) => {
                return new TenantStoreModel(tenant);
            });
        }
    }

    public tenantId!: string;

    public tenants!: TenantStoreModel[];
}
