import { vuexStore, authStore, surfConextStore, oidcIdpStore } from '.';
import DefaultStoreAccessors from './store';
import { AuthStoreState } from './auth-store/state';
import domainsStore from './domain-store';
import { DomainStoreState } from './domain-store/state';
import notificationStore from './notification-store';
import { NotificationStoreState } from './notification-store/state';
import uiInteractionStore from './ui-interaction-store';
import { UIInteractionStoreState } from './ui-interaction-store/state';
import mydomainStore from './mydomain-store';
import { MyDomainStoreState } from './mydomain-store/state';
import saml2IdpStore from './saml2-idp-store';
import { Saml2IdentityProviderStoreState } from './saml2-idp-store/state';
import userStore from './user-store';
import { UserStoreState } from './user-store/state';
import saml2SpStore from './saml2-sp-store';
import { Saml2SpProviderStoreState } from './saml2-sp-store/state';
import provisioningStore from './provisioning-store';
import { ProvisioningStoreState } from './provisioning-store/state';
import groupStore from './group-store';
import { GroupStoreState } from './group-store/state';
import jobStore from './job-store';
import { JobStoreState } from './job-store/state';
import countryStore from './country-store';
import { CountryStoreState } from './country-store/state';
import languageStore from './language-store';
import { LanguageStoreState } from './language-store/state';
import authenticationPolicyStore from './authenticationpolicy-store';
import { AuthenticationPolicyStoreState } from './authenticationpolicy-store/state';
import { SurfConextStoreState } from './surfconext-store/state';
import { OidcIdentityProviderStoreState } from './oidc-idp-store/state';

export default function initVuexStore(): void {
    // features
    authStore.init(new DefaultStoreAccessors<AuthStoreState>(vuexStore, 'auth'));
    domainsStore.init(new DefaultStoreAccessors<DomainStoreState>(vuexStore, 'domains'));
    mydomainStore.init(new DefaultStoreAccessors<MyDomainStoreState>(vuexStore, 'mydomain'));
    saml2IdpStore.init(new DefaultStoreAccessors<Saml2IdentityProviderStoreState>(vuexStore, 'saml2Idp'));
    saml2SpStore.init(new DefaultStoreAccessors<Saml2SpProviderStoreState>(vuexStore, 'saml2Sp'));
    userStore.init(new DefaultStoreAccessors<UserStoreState>(vuexStore, 'users'));
    provisioningStore.init(new DefaultStoreAccessors<ProvisioningStoreState>(vuexStore, 'provisioning'));
    jobStore.init(new DefaultStoreAccessors<JobStoreState>(vuexStore, 'jobs'));
    groupStore.init(new DefaultStoreAccessors<GroupStoreState>(vuexStore, 'groups'));
    countryStore.init(new DefaultStoreAccessors<CountryStoreState>(vuexStore, 'countries'));
    languageStore.init(new DefaultStoreAccessors<LanguageStoreState>(vuexStore, 'languages'));
    authenticationPolicyStore.init(new DefaultStoreAccessors<AuthenticationPolicyStoreState>(vuexStore, 'authenticationPolicies'));
    surfConextStore.init(new DefaultStoreAccessors<SurfConextStoreState>(vuexStore, 'surfConext'));
    oidcIdpStore.init(new DefaultStoreAccessors<OidcIdentityProviderStoreState>(vuexStore, 'oidcIdp'));

    // generic
    notificationStore.init(new DefaultStoreAccessors<NotificationStoreState>(vuexStore, 'notifications'));
    uiInteractionStore.init(new DefaultStoreAccessors<UIInteractionStoreState>(vuexStore, 'uiInteraction'));
}
