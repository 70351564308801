export default class SelectedDomainModel {
    constructor(domainName: string, bulkMode: boolean) {
        this.domainName = domainName.toLowerCase();
        this.includedUsers = [];
        this.excludedUsers = [];
        this.bulkMode = bulkMode;
    }
    domainName: string;
    includedUsers: string[];
    excludedUsers: string[];
    bulkMode: boolean;
}
