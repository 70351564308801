import { SortColumn } from '../enums/SortColumn';
import { SortOrder } from '../enums/SortOrder';

export default class GroupUserSearchInputModel {
    public constructor() {
        this.page = 1;
        this.sortColumn = SortColumn.Email;
        this.sortOrder = SortOrder.Ascending;
    }

    public page: number;
    public sortColumn: SortColumn;
    public sortOrder: SortOrder;
    public member?: string;
}
