import { JsonObject, JsonProperty } from 'json2typescript';
import GroupUserModel from './GroupUserModel';

@JsonObject('GroupUsersTotalsModel')
export default class GroupUsersTotalsOutputModel {

    public constructor(){
        this.totalUsers = 0;
        this.totalPages = 0;
        this.users = [];
    }

    @JsonProperty('total_users', Number)
    public totalUsers: number;

    @JsonProperty('total_pages', Number)
    public totalPages: number;

    @JsonProperty('users', [GroupUserModel])
    public users: GroupUserModel[];
}