import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('SuspendUserInputModel')
export default class SuspendUserInputModel {
    constructor() {
        this.suspendedReason = '';
    }

    @JsonProperty('suspended_reason', String)
    public suspendedReason: string;
}
