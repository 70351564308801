import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('UserOneTimeEmergencyCodeOutputModel')
export default class UserOneTimeEmergencyCodeOutputModel {
    constructor() {
        this.emergencyCode = '';
    }
    
    @JsonProperty('emergency_code', String)
    public emergencyCode: string;
}
