import AuthorizedNetworkBaseModel from './AuthorizedNetworkBaseModel';
import { authenticationPolicyStore } from '@/store';

export default class IpRangeDisplayModel extends AuthorizedNetworkBaseModel {
    public constructor(firstIp: string, secondIp: string) {
        super(firstIp, /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/);
        this.secondValue = secondIp;
        this.isFirstValueValid = true;
        this.isSecondValueValid = true;
    }

    public secondValue: string;

    public isFirstValueValid: boolean;

    public isSecondValueValid: boolean;

    public validate(): void {
        const isFirstValueValid = this.regex.test(this.value);
        const isSecondValueValid = this.regex.test(this.secondValue);

        // if one of the IPs in the range is invalid then we don't continue to the range validation
        if (!isFirstValueValid || !isSecondValueValid) {
            authenticationPolicyStore.storeApi.commit(authenticationPolicyStore.mutations.updateEditedAuthorizedNetworksIpRangeValidation, {
                id: this.id,
                isFirstValueValid: isFirstValueValid,
                isSecondValueValid: isSecondValueValid,
            });
            return;
        }

        const firstValueNumber = this.ipToInt(this.value);
        const secondValueNumber = this.ipToInt(this.secondValue);

        const isValid = firstValueNumber < secondValueNumber;
        if (!isValid) {
            authenticationPolicyStore.storeApi.commit(authenticationPolicyStore.mutations.updateEditedAuthorizedNetworksIpRangeValidation, {
                id: this.id,
                isValid: isValid,
                errorMessageId: 'page_policies_ip_range_invalid',
            });
        }
    }

    private ipToInt(ip: string): number {
        const a = ip.split('.');
        const buffer = new ArrayBuffer(4);
        const dv = new DataView(buffer);
        for (let i = 0; i < 4; i++) {
            dv.setUint8(i, parseInt(a[i]));
        }
        return dv.getUint32(0);
    }
}
