import DomainStoreModel from './models/DomainStoreModel';
import { DomainStoreState } from './state';

export default {
    setDomains(state: DomainStoreState, domains: DomainStoreModel[]): void {
        if (state.domains.length > 0) {
            state.domains.splice(0, state.domains.length);
        }

        if (domains && domains.length > 0) {
            state.domains.push(...domains);
        }
    },

    addDomain(state: DomainStoreState, domain: DomainStoreModel): void {
        state.domains.push(domain);
    },

    removeDomain(state: DomainStoreState, domainName: string): void {
        const index = state.domains.findIndex((domain) => domain.name === domainName);
        if (index >= 0) {
            state.domains.splice(index, 1);
        }
    },

    setVerificationRecord(state: DomainStoreState, { domainName, verificationRecord }: { domainName: string; verificationRecord: string }): void {
        const domain = state.domains.find((d) => d.name === domainName);

        if (domain) {
            domain.verificationRecord = verificationRecord;
        }
    },

    verifyDomain(state: DomainStoreState, domainName: string): void {
        const domain = state.domains.find((d) => d.name === domainName);

        if (domain) {
            domain.isVerified = true;
            domain.verificationRecord = '';
        }
    },

    setStoreProcessingState(state: DomainStoreState, isProcessing: boolean): void {
        state.isProcessing = isProcessing;
    },

    setDomainProcessingState(state: DomainStoreState, { domainName, isProcessing }: { domainName: string; isProcessing: boolean }): void {
        const domain = state.domains.find((d) => d.name === domainName);

        if (domain) {
            domain.isProcessing = isProcessing;
        }
    },

    setUnauthorizedToViewDomains(state: DomainStoreState): void {
        state.isUnauthorizedToViewDomains = true;
    },
};
