// eslint-disable-next-line max-classes-per-file
import { AxiosResponse, AxiosRequestConfig } from 'axios';
import { JsonConvert } from 'json2typescript';
import logger from '@/util/logger';
import util from '@/util';
import ApiError from './ApiError';

export default class ApiResponse {
    private readonly restResponse: AxiosResponse<Record<string, unknown>>;

    private readonly jsonConverter: JsonConvert;

    constructor(restResponse: AxiosResponse<Record<string, unknown>>, jsonConverter: JsonConvert) {
        this.restResponse = restResponse;
        this.jsonConverter = jsonConverter;
    }

    public get request(): AxiosRequestConfig {
        return this.restResponse.config;
    }

    public get statusCode(): number {
        return this.restResponse.status;
    }

    public get rawData(): Record<string, unknown> | null {
        return this.restResponse.data;
    }

    public get isSuccess(): boolean {
        return this.statusCode >= 200 && this.statusCode <= 299;
    }

    public get errorCode(): string | undefined {
        if (this.isSuccess) {
            return void 0;
        }

        return this.data(ApiError)?.code;
    }

    public get errorDetails(): string | undefined {
        if (this.isSuccess) {
            return void 0;
        }

        return this.data(ApiError)?.details;
    }

    public data<T extends object>(classReference: { new (): T }): T | undefined {
        if (util.isNullOrEmpty(this.rawData)) {
            return void 0;
        }

        try {
            return this.jsonConverter.deserializeObject(this.rawData, classReference);
        } catch (err) {
            logger.log('Error deserializing JSON object', err);
            return void 0;
        }
    }

    public dataCollection<T extends object>(classReference: { new (): T }): T[] {
        if (util.isNullOrEmpty(this.rawData)) {
            return [];
        }

        try {
            return this.jsonConverter.deserializeArray(Object.values(this.rawData), classReference);
        } catch (err) {
            logger.log('Error deserializing JSON object', err);
            return [];
        }
    }
}
