import StoreEntity from '@/store/models';

export default class PasswordPolicyValidationStoreModel extends StoreEntity {
    public constructor() {
        super();

        this.pwdMaxFailureInvalid = false;
        this.pwdFailureCountIntervalMinutesInvalid = false;
        this.pwdInHistoryInvalid = false;
        this.pwdLockoutDurationMinutesInvalid = false;
        this.pwdMinLengthInvalid = false;
        this.pwdQualityMinDigitCharInvalid = false;
        this.pwdQualityMinUppercaseCharInvalid = false;
        this.pwdQualityMinLowercaseCharInvalid = false;
        this.pwdQualityMinSpecialCharInvalid = false;
        this.pwdMaxAgeDaysInvalid = false;
        this.pwdExpireWarningMinutesInvalid = false;
    }

    public pwdMaxFailureInvalid: boolean;
    public pwdFailureCountIntervalMinutesInvalid: boolean;
    public pwdInHistoryInvalid: boolean;
    public pwdLockoutDurationMinutesInvalid: boolean;
    public pwdMinLengthInvalid: boolean;
    public pwdQualityMinDigitCharInvalid: boolean;
    public pwdQualityMinUppercaseCharInvalid: boolean;
    public pwdQualityMinLowercaseCharInvalid: boolean;
    public pwdQualityMinSpecialCharInvalid: boolean;
    public pwdMaxAgeDaysInvalid: boolean;
    public pwdExpireWarningMinutesInvalid: boolean;
}
