import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('UserApplicationTruncatedModel')
export default class UserApplicationTruncatedModel {
    public constructor() {
        this.name = '';
        this.twoStepRequired = false;
        this.loginCount = 0;
    }

    @JsonProperty('application_name', String)
    public name: string;

    @JsonProperty('2_step_required', Boolean, true)
    public twoStepRequired: boolean;

    @JsonProperty('authorization_date', String, true)
    public authorizationDate?: string;

    @JsonProperty('login_date', String, true)
    public loginDate?: string;

    @JsonProperty('login_count', Number)
    public loginCount: number;
}
