import { createStore } from 'vuex';
import authStore from './auth-store';
import domainStore from './domain-store';
import mydomainStore from './mydomain-store';
import notificationStore from './notification-store';
import saml2IdpStore from './saml2-idp-store';
import saml2SpStore from './saml2-sp-store';
import userStore from './user-store';
import uiInteractionStore from './ui-interaction-store';
import provisioningStore from './provisioning-store';
import groupStore from './group-store';
import jobStore from './job-store';
import countryStore from './country-store';
import languageStore from './language-store';
import authenticationPolicyStore from './authenticationpolicy-store';
import surfConextStore from './surfconext-store';
import oidcIdpStore from './oidc-idp-store';

// export the raw Vuex store
export const vuexStore = createStore({
    modules: {
        // features
        auth: authStore,
        domains: domainStore,
        mydomain: mydomainStore,
        saml2Idp: saml2IdpStore,
        saml2Sp: saml2SpStore,
        users: userStore,
        provisioning: provisioningStore,
        groups: groupStore,
        jobs: jobStore,
        countries: countryStore,
        languages: languageStore,
        authenticationPolicies: authenticationPolicyStore,
        surfConext: surfConextStore,
        oidcIdp: oidcIdpStore,

        // generic
        notifications: notificationStore,
        uiInteraction: uiInteractionStore,
    },
});

// Export the modules as separate stores
export {
    // features
    authStore,
    domainStore,
    mydomainStore,
    saml2IdpStore,
    userStore,
    groupStore,
    jobStore,
    countryStore,
    languageStore,
    authenticationPolicyStore,
    surfConextStore,
    oidcIdpStore,

    // generic
    notificationStore,
    uiInteractionStore,
};
