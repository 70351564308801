
import { defineComponent, computed } from 'vue';
import { authStore, domainStore } from '@/store';
import uiInteractionStore from '@/store/ui-interaction-store';
import router from '@/router';
import routes from '@/router/routes';
import config from '@/config';
import knowledgeBaseService, { KnowledgeBaseContexts } from '@/services/knowledge-base-service';

export default defineComponent({
    name: 'WelcomePaneComponent',

    setup() {
        // computed
        const userFirstName = computed(() => {
            const user = authStore.storeApi.get(authStore.getters.authenticatedUser);
            return user.firstName;
        });

        const hasDomains = computed(() => {
            const domains = domainStore.storeApi.get(domainStore.getters.domains);
            return domains && domains.length > 0;
        });

        const hasVerifiedDomains = computed(() => {
            const domains = domainStore.storeApi.get(domainStore.getters.verifiedDomains);

            return domains && domains.length > 0;
        });

        const pendingDomainsCount = computed(() => {
            const domains = domainStore.storeApi.get(domainStore.getters.pendingDomains);

            if (domains) {
                return domains.length;
            }

            return 0;
        });

        const areDomainsLoading = computed(() => {
            return domainStore.storeApi.get(domainStore.getters.isProcessing);
        });

        // functions
        function performDomainAction() {
            if (hasDomains.value) {
                if (!hasVerifiedDomains.value) {
                    router.push(routes.domains.path);
                }
            } else {
                if (config().isMobile) {
                    router.push(routes.addDomain.path);
                } else {
                    uiInteractionStore.storeApi.commit(uiInteractionStore.mutations.setShowAddDomainOnTabLoad, true);
                    router.push(routes.domains.path);
                }
            }
        }

        // functions
        function openHelpPage() {
            knowledgeBaseService.open(KnowledgeBaseContexts.DOMAINS);
        }

        return {
            // computed
            userFirstName,
            hasDomains,
            hasVerifiedDomains,
            pendingDomainsCount,
            areDomainsLoading,

            // functions
            performDomainAction,
            openHelpPage,
        };
    },
});
