import { JsonObject, JsonProperty } from 'json2typescript';
import AuthenticationPolicyBodyModel from './AuthenticationPolicyBodyModel';

@JsonObject('AuthenticationPolicyInputModel')
export default class AuthenticationPolicyInputModel {
    public constructor() {
        this.name = '';
        this.policy = new AuthenticationPolicyBodyModel();
        this.priority = 0;
    }

    @JsonProperty('name', String)
    public name: string;

    @JsonProperty('policy', AuthenticationPolicyBodyModel)
    public policy: AuthenticationPolicyBodyModel;

    @JsonProperty('priority', Number)
    public priority: number;
}
