export default {
  "page_sso_alert_no_verified_domains_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domäninställningar"])},
  "page_sso_alert_no_verified_domains_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du måste lägga till och verifiera minst en domän innan du kan fortsätta med att konfigurera Single Sign-on."])},
  "page_sso_alert_no_verified_domains_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå till Domäner"])},
  "page_sso_alert_no_added_mydomain_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MyDomain-inställningar"])},
  "page_sso_alert_no_added_mydomain_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du måste lägga till en MyDomain innan du kan fortsätta med att konfigurera Single Sign-on."])},
  "page_sso_alert_no_added_mydomain_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå till MyDomain"])},
  "page_sso_alert_idp_already_added_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurering av identitetsleverantör"])},
  "page_sso_alert_idp_already_added_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har redan konfigurerat en identitetsleverantör. Endast en extern SSO-identitetsleverantör kan konfigureras för ditt företag."])},
  "page_sso_alert_idp_already_added_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå till Single Sign-on"])},
  "page_sso_mydomain_info_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MyDomain är din personliga inloggningssida hos Visma som även innehåller dina unika SAML2- eller OpenID Connect-slutpunkter."])},
  "page_sso_mydomain_info_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En igenkännbar webbadress hjälper dina användare att förstå att webbplatsen tillhör din organisation."])},
  "page_sso_mydomain_info_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan bara ta bort eller byta namn på din MyDomain om du inte har någon identitetsleverantör inställning."])},
  "page_sso_mydomain_modal_add_or_update_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vilken MyDomain vill du lägga till?"])},
  "page_sso_mydomain_modal_add_or_update_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exempel"])},
  "page_sso_mydomain_modal_add_or_update_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange ett namn som dina användare känner igen. Tänk på att det bara får innehålla små bokstäver, siffror och bindestreck (-), men det kan inte börja eller sluta med ett bindestreck. Maxlängden är 40 tecken."])},
  "page_sso_mydomain_modal_add_or_update_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ett okänt fel uppstod när MyDomain skulle sättas upp."])},
  "page_sso_mydomain_modal_add_or_update_error_invalid_mydomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MyDomain har felaktigt format."])},
  "page_sso_mydomain_modal_add_or_update_error_already_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denna MyDomain är upptagen."])},
  "page_sso_mydomain_modal_add_or_update_error_sso_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan inte ändra din MyDomain. Du använder identitetsleverantörer."])},
  "page_sso_mydomain_modal_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radera Mydomain"])},
  "page_sso_mydomain_modal_delete_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Är du säker på att du vill radera din MyDomain: ", _interpolate(_named("myDomainUri")), "?"])},
  "page_sso_mydomain_notif_add_mydomain_success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("myDomainUri")), " har nu lagts till som din MyDomain."])},
  "page_sso_mydomain_notif_update_mydomain_success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Din MyDomain har nu uppdaterats till: ", _interpolate(_named("myDomainUri")), "."])},
  "page_sso_mydomain_notif_delete_mydomain_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din MyDomain har nu raderats."])},
  "page_sso_idp_notif_add_or_update_success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Single Sign-On med ", _interpolate(_named("providerDisplayName")), " är inställt med framgång."])},
  "page_sso_idp_selection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj identitetsleverantör"])},
  "page_sso_idp_selection_title_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj en identitetsleverantör som du vill använda för Single Sign-on."])},
  "page_sso_idp_selection_list_title_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annan"])},
  "page_sso_idp_selection_back_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< Tillbaka till Single Sign-on"])},
  "page_sso_idp_selection_list_body_coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommer snart! Håll utkik!"])},
  "page_sso_idp_selection_list_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Integrationen av Visma med ", _interpolate(_named("providerDisplayName")), " gör det möjligt för dina användare att automatiskt logga in på Visma med sina ", _interpolate(_named("providerDisplayName")), " -konton."])},
  "page_sso_idp_notif_add_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ett okänt fel uppstod när din identitetsleverantör lades till."])},
  "page_sso_idp_notif_update_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ett okänt fel uppstod när din identitetsleverantör uppdaterades."])},
  "page_sso_idp_notif_update_idp_no_longer_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denna identitetsleverantör finns inte längre för ditt företag."])},
  "page_sso_idp_notif_delete_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okänt fel uppstod vid borttagning av din identitetsleverantör."])},
  "page_sso_idp_notif_delete_success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Din ", _interpolate(_named("providerName")), "-identitetsleverantör har raderats."])},
  "page_sso_idp_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identitetsleverantör"])},
  "page_sso_idp_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till identitetsleverantören som du vill använda för Single Sign-on."])},
  "page_sso_idp_no_idp_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen identitetsleverantör har lagts till."])},
  "page_sso_idp_setup_back_to_selection_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< Tillbaka till urvalssidan"])},
  "page_sso_idp_setup_step_advanced_config_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avancerad konfiguration"])},
  "page_sso_idp_setup_step_advanced_config_jit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just-in-Time (JIT)-användarprovisionering"])},
  "page_sso_idp_setup_step_advanced_config_jit_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Om detta är aktiverat, kommer icke-existerande användare i Visma-systemet skapas automatiskt (utan behörighet till applikationer förutom inloggning). När detta är inaktiverat, måste du skapa alla användare i Admin-panelen i din Visma-applikation innan inloggning är möjligt. Du måste oavsett ge behörighet till applikationen i Vismas admin-paneler."])},
  "page_sso_idp_setup_help_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kontrollera steg för steg guiden för att konfigurera ", _interpolate(_named("providerDisplayName")), " som en ", _interpolate(_named("idpType")), " identitetsleverantör inom Visma."])},
  "page_sso_idp_modal_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radera identitetsleverantör"])},
  "page_sso_idp_modal_delete_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Är du säker på att du vill radera identitetsleverantören ", _interpolate(_named("idpName")), "?"])},
  "page_sso_idp_modal_delete_scim_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM 2.0-provisionering är för närvarande aktiverad. Om du fortsätter, kommer den att inaktiveras och konfigurationen kommer att gå förlorad."])},
  "page_sso_saml2idp_notif_invalid_metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML2-metadata är ogiltiga."])},
  "page_sso_saml2idp_notif_invalid_metadata_certificate_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certifikatet från dina SAML2-metadata har upphört att gälla."])},
  "page_sso_saml2idp_notif_invalid_metadata_certificate_still_expired": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Certifikatet har upphört att gälla. Gå till ", _interpolate(_named("providerDisplayName")), " för att skapa ett nytt certifikat. Gå sedan tillbaka hit och klicka på 'Uppdatera certifikat'."])},
  "page_sso_saml2idp_btn_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till SAML2-identitetsleverantör"])},
  "page_sso_saml2idp_certificate_expires_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Certifikatet upphör att gälla ", _interpolate(_named("expirationTime"))])},
  "page_sso_saml2idp_certificate_expired_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Upphörde att gälla ", _interpolate(_named("expirationTime")), ". Gå till ", _interpolate(_named("saml2Idp")), " för att skapa ett nytt certifikat. Kom sedan tillbaka hit, redigera denna leverantör, och klicka på 'Uppdatera certifikat'. Verifiera den nya certifikatinformationen och klicka på 'Spara' för att uppdatera det."])},
  "page_sso_saml2idp_certificate_expired_on_mobile": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Certifikatet upphörde att gälla ", _interpolate(_named("expirationTime")), ". Gå till ", _interpolate(_named("saml2Idp")), " för att skapa ett nytt certifikat. Kom sedan tillbaka hit, redigera denna leverantör, och klicka på 'Uppdatera certifikat'. Verifiera den nya certifikatinformationen och klicka på 'Spara' för att uppdatera certifikatet."])},
  "page_sso_saml2idp_details_header_certificate_expire_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certifikatets utgångsdatum"])},
  "page_sso_saml2idp_setup_step_visma_metadata_title_upload": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ladda upp metadatafilen från Visma till ", _interpolate(_named("providerDisplayName"))])},
  "page_sso_saml2idp_setup_step_visma_metadata_title_copy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kopiera Visma-metadata till ", _interpolate(_named("providerDisplayName"))])},
  "page_sso_saml2idp_setup_step_visma_metadata_title_copy_with_public_certificate_download": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Konfigurera metadata i ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_visma_metadata_title_upload_or_copy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Konfigurera ", _interpolate(_named("providerDisplayName")), " med Visma-metadata från filen eller enskilda fält"])},
  "page_sso_saml2idp_setup_step_visma_metadata_info_upload": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["I ", _interpolate(_named("providerDisplayName")), " laddar du upp metadatafilen från Visma som du hämtade nedan."])},
  "page_sso_saml2idp_setup_step_visma_metadata_info_copy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kopiera varje metadatafält nedan och klistra in det i motsvarande konfiguration i ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_visma_metadata_info_copy_with_public_certificate_download": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kopiera länkarna nedan till din ", _interpolate(_named("providerDisplayName")), "-konfiguration. För att aktivera Single Sign-out-funktionen, ladda ner certifikatet nedan och ladda upp det till ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_visma_metadata_info_upload_or_copy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ladda upp metadatafilen från Visma som du laddade ner nedan, eller kopiera varje metadatafält nedan och klistra in det i motsvarande konfiguration i ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_visma_metadata_info_copy_metadata_url_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kopiera metadata-URL:en nedan och klistra in den i motsvarande konfiguration i ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_visma_metadata_metadata_file_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadatafil"])},
  "page_sso_saml2idp_setup_step_visma_metadata_or_divider_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ELLER"])},
  "page_sso_saml2idp_setup_step_claims_config_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Konfigurera anspråk i ", _interpolate(_named("providerDisplayName"))])},
  "page_sso_saml2idp_setup_step_claims_config_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kontrollera att ", _interpolate(_named("providerDisplayName")), " har konfigurerats för att använda följande anspråksnamn när du skickar SAML-svar till Visma."])},
  "page_sso_saml2idp_setup_step_provider_metadata_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Klistra in metadata-URL:en från ", _interpolate(_named("providerDisplayName")), " i Visma"])},
  "page_sso_saml2idp_setup_step_provider_metadata_title_upload": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ladda upp metadata från ", _interpolate(_named("providerDisplayName")), " till Visma"])},
  "page_sso_saml2idp_setup_step_provider_metadata_info_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kopiera metadata-URL: en från ", _interpolate(_named("providerDisplayName")), " och klistra in den nedan."])},
  "page_sso_saml2idp_setup_step_provider_metadata_info_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Detta är tillgängligt från din ", _interpolate(_named("providerDisplayName")), " och innehåller det offentliga certifikatet, anspråk och SAML-slutpunkter som Visma behöver till sin egen konfiguration."])},
  "page_sso_saml2idp_setup_step_provider_metadata_info_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["För användaridentiteten måste du skicka en giltig e-postadress som NameID och även förnamn och efternamn."])},
  "page_sso_saml2idp_setup_step_provider_metadata_info_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ladda ner metadatafilen från ", _interpolate(_named("providerDisplayName")), " och ladda upp den nedan."])},
  "page_sso_saml2idp_setup_step_provider_metadata_url_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klistra in metadata-URL"])},
  "page_sso_saml2idp_setup_step_provider_metadata_preview_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förhandsgranska data"])},
  "page_sso_saml2idp_setup_step_provider_metadata_empty_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fältet får inte vara tomt."])},
  "page_sso_saml2idp_setup_step_provider_metadata_max_length_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maxlängden är 100 tecken."])},
  "page_sso_saml2idp_setup_step_provider_metadata_upload_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladda upp metadatafil"])},
  "page_sso_saml2idp_setup_step_provider_metadata_upload_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj fil för att förhandsgranska data"])},
  "page_sso_saml2idp_setup_step_provider_metadata_upload_refresh_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj fil för att uppdatera data"])},
  "page_sso_saml2idp_setup_step_provider_metadata_refresh_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdatera data"])},
  "page_sso_saml2idp_setup_step_provider_metadata_preview_from_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förhandsgranskning metadata-URL"])},
  "page_sso_saml2idp_setup_step_provider_metadata_preview_from_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Förhandsgranskning metadatafil"])},
  "page_sso_saml2idp_setup_step_provider_metadata_error_metadata_empty": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Fältet är obligatoriskt. Kopiera det från ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_provider_metadata_error_metadata_url_invalid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ogiltig metadata-URL. Kopiera URL:en från ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_provider_metadata_error_metadata_file_invalid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ogiltig metadatafil. Ladda ner filen från ", _interpolate(_named("providerDisplayName")), " och ladda upp den här."])},
  "page_sso_saml2idp_setup_step_provider_metadata_certificate_expires_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Upphör ", _interpolate(_named("expirationTime"))])},
  "page_sso_saml2idp_setup_step_provider_metadata_certificate_expired_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Upphörde att gälla ", _interpolate(_named("expirationTime")), ". Gå till ", _interpolate(_named("providerDisplayName")), " för att skapa ett nytt certifikat. Kom sedan tillbaka hit och klicka på 'Uppdatera certifikat'. Verifiera den nya certifikatinformationen och klicka på 'Spara' för att uppdatera det."])},
  "page_sso_saml2idp_setup_step_advanced_config_slo_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single Sign-out"])},
  "page_sso_saml2idp_setup_step_advanced_config_slo_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vill du att Visma anropar ", _interpolate(_named("providerDisplayName")), "-slutpunkten för single log-out när användaren loggar ut från en Visma-app? Svara 'NEJ' om du vill hålla din egen ", _interpolate(_named("providerDisplayName")), "-session aktiv tills utloggning påbörjas från ", _interpolate(_named("providerDisplayName")), " till Vismas SAML-slutpunkt för single log-out."])},
  "page_sso_saml2idp_setup_step_advanced_config_updateclaims_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdatera profil varje gång en användare loggar in"])},
  "page_sso_saml2idp_setup_step_advanced_config_updateclaims_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Om detta är aktiverat, kommer tillgängliga användarprofilattribut att synkroniseras från ", _interpolate(_named("providerDisplayName")), " varje gång en användare loggar in."])},
  "page_sso_saml2idp_setup_step_advanced_config_updategroups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdatera gruppmedlemskap varje gång en användare loggar in"])},
  "page_sso_saml2idp_setup_step_advanced_config_updategroups_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Om detta är aktiverat, kommer användarens gruppmedlemskap att synkroniseras från ", _interpolate(_named("providerDisplayName")), " varje gång användaren loggar in."])},
  "page_sso_saml2idp_setup_step_metadata_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata-URL"])},
  "page_sso_saml2idp_setup_step_entity_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entity ID"])},
  "page_sso_saml2idp_setup_step_saml_logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML Logout"])},
  "page_sso_saml2idp_setup_step_sign_on_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign on URL"])},
  "page_sso_saml2idp_setup_step_logout_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout URL"])},
  "page_sso_saml2idp_setup_step_certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate"])},
  "page_sso_saml2idp_setup_step_thumbprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbprint"])},
  "page_sso_saml2idp_setup_step_sign_authn_requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Authn Requests"])},
  "page_sso_saml2idp_setup_step_saml_assertion_consumer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML Assertion Consumer"])},
  "page_sso_saml2sp_setup_step_visma_public_certificate_file_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offentligt certifikat"])},
  "page_sso_oidcidp_btn_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lägg till OIDC-identitetsleverantör"])},
  "page_sso_oidcidp_configure_uris_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Konfigurera URI: er i ", _interpolate(_named("providerDisplayName"))])},
  "page_sso_oidcidp_signin_redirect_uri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirect URI"])},
  "page_sso_oidcidp_signout_redirect_uri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign-out redirect URI"])},
  "page_sso_oidcidp_setup_step_visma_uris": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kopiera länkarna nedan till din ", _interpolate(_named("providerDisplayName")), " -konfiguration."])},
  "page_sso_oidcidp_invalid_authority_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogiltig authority."])},
  "page_sso_oidcidp_invalid_authority_for_provider_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ogiltig authority för ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_oidcidp_configure_oidc_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurera OpenID Connect Client"])},
  "page_sso_oidcidp_authority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authority"])},
  "page_sso_oidcidp_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client ID"])},
  "page_sso_oidcidp_client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client secret"])},
  "page_sso_oidcidp_update_client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uppdatera client secret"])},
  "page_sso_oidcidp_authentication_flow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentisering flöde"])},
  "page_sso_oidcidp_authentication_web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web"])},
  "page_sso_oidcidp_authentication_pkce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PKCE"])},
  "page_sso_oidcidp_authentication_web_and_pkce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web + PKCE"])},
  "page_sso_surfconext_setup_home_realm_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SURFconext home realm"])},
  "page_sso_surfconext_setup_home_realm_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ange hemsfären du har konfigurerat i SURFcontext"])},
  "page_sso_surfconext_setup_home_realm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hemsfär"])},
  "page_sso_surfconext_select_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Välj domän"])},
  "page_sso_surfconext_notif_already_added_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har redan konfigurerat SURFconext-identitetsleverantören."])}
}