export default {
  "page_provisioning_no_sso_setup_alert_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single Sign-On setup"])},
  "page_provisioning_no_sso_setup_alert_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez activer Single Sign-On pour continuer la configuration de votre provisioning SCIM."])},
  "page_provisioning_no_sso_setup_alert_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller à Single Sign-On"])},
  "page_provisioning_scim_not_supported_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM non pris en charge"])},
  "page_provisioning_scim_not_supported_alert_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le système de gestion des identités inter-domaines (SCIM) pour ", _interpolate(_named("idpName")), " n'est pas pris en charge."])},
  "page_provisioning_scim_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Avec Provisioning vous pouvez automatiser des tâches administratives communes. En activant le système de gestion des identités inter-domaines (SCIM), vous pouvez connecter Visma à ", _interpolate(_named("idpName")), ", ainsi vous pouvez:"])},
  "page_provisioning_scim_info_create_users_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer des utilisateurs et des groupes"])},
  "page_provisioning_scim_info_grant_revoke_access_to_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accorder et révoquer l'accès aux groupes"])},
  "page_provisioning_scim_info_edit_attributes_users_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éditer les attributs des utilisateurs et des groupes"])},
  "page_provisioning_scim_info_suspend_deprovisioned_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspendre les utilisateurs déprovisionnés"])},
  "page_provisioning_scim_cache_refresh_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notez que chaque changement de configuration peut prendre jusqu'à 2 minutes pour prendre effet sur le serveur SCIM."])},
  "page_provisioning_scim_toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM 2.0 Provisioning"])},
  "page_provisioning_scim_last_sync_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière activité de synchronisation"])},
  "page_provisioning_scim_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun"])},
  "page_provisioning_scim_endpoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM Endpoint"])},
  "page_provisioning_scim_bearer_token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM Bearer Token"])},
  "page_provisioning_scim_bearer_token_generated_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Généré le ", _interpolate(_named("date"))])},
  "page_provisioning_scim_bearer_token_expires_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Expire le ", _interpolate(_named("date"))])},
  "page_provisioning_scim_bearer_token_btn_generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Générer SCIM token"])},
  "page_provisioning_scim_modal_disable_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver SCIM 2.0 Provisioning"])},
  "page_provisioning_scim_modal_disable_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir désactiver SCIM?"])},
  "page_provisioning_scim_modal_disable_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactivé"])},
  "page_provisioning_scim_refresh_token_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rafraîchir SCIM token"])},
  "page_provisioning_scim_modal_refresh_token_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir actualiser SCIM token ?"])},
  "page_provisioning_scim_modal_refresh_token_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rafraîchir"])},
  "page_provisioning_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions de provisionnement"])},
  "page_provisioning_action_user_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer des utilisateurs"])},
  "page_provisioning_action_user_read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lire les utilisateurs"])},
  "page_provisioning_action_user_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualiser les utilisateurs"])},
  "page_provisioning_action_user_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer utilisateurs"])},
  "page_provisioning_action_group_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez des groupes"])},
  "page_provisioning_action_group_read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes lus"])},
  "page_provisioning_action_group_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualiser les groupes"])},
  "page_provisioning_action_group_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer des groupes"])},
  "page_provisioning_action_user_update_triggers": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lorsque les utilisateurs sont désactivés de ", _interpolate(_named("idpName")), ", effectuer cette action dans ", _interpolate(_named("tenantOwnerName"))])},
  "page_provisioning_action_user_update_trigger_suspend_useraccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspendre utilisateur"])},
  "page_provisioning_action_user_update_trigger_delete_from_tenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l’utilisateur"])},
  "page_provisioning_action_user_update_trigger_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer l'utilisateur"])},
  "page_provisioning_action_user_delete_triggers": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lorsque les utilisateurs sont supprimés dans ", _interpolate(_named("idpName")), ", effectuer cette action dans ", _interpolate(_named("tenantOwnerName"))])},
  "page_provisioning_action_user_delete_trigger_suspend_useraccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspendre utilisateur"])},
  "page_provisioning_action_user_delete_trigger_delete_from_tenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l’utilisateur"])},
  "page_provisioning_scim_groups_alert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("tenantOwnerName")), " ne prend pas en charge la création/lecture/mise à jour/suppression des groupes. Cependant, vous pouvez assigner des groupes à des politiques."])},
  "page_provisioning_scim_unsupported_actions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("tenantOwnerName")), " ne supporte pas la synchronisation SCIM vers ", _interpolate(_named("unsupportedActions")), "."])},
  "page_provisioning_scim_unsupported_actions_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les changements ne se refléteront que dans le système d'identité Visma."])},
  "page_provisioning_token_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM Token"])},
  "page_provisioning_token_modal_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiez ce secret token sur votre fournisseur SCIM."])},
  "page_provisioning_token_modal_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token ne sera affiché qu'une seule fois et expirera dans 1 an."])},
  "page_provisioning_action_trigger_no_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune action"])},
  "page_provisioning_btn_view_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les journaux de provisioning"])},
  "page_provisioning_logs_btn_view_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les paramètres"])},
  "page_provisioning_logs_btn_refresh_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rafraîchir les logs"])},
  "page_provisioning_logs_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journaux de provisionnement"])},
  "page_provisioning_logs_no_logs_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune requête SCIM n'a encore été faite sur cette configuration."])},
  "page_provisioning_logs_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur inconnue s'est produite lors de la récupération des SCIM logs."])},
  "page_provisioning_logs_item_request_query_string": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Query string"])},
  "page_provisioning_logs_item_request_json_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request body"])},
  "page_provisioning_logs_item_response_status_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code d'état de la réponse"])},
  "page_provisioning_logs_item_view_json": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher JSON"])},
  "page_provisioning_logs_item_no_input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<no input>"])},
  "page_provisioning_logs_item_no_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<missing info>"])}
}