import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('UserInputModel')
export default class UserInputModel {
    public constructor() {
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.countryCode = '';
        this.languageCode = '';
        this.generateOtp = false;
    }

    @JsonProperty('first_name', String)
    public firstName: string;

    @JsonProperty('last_name', String)
    public lastName: string;

    @JsonProperty('email', String)
    public email: string;

    @JsonProperty('country_code', String, true)
    public countryCode?: string;

    @JsonProperty('preferred_language', String)
    public languageCode: string;

    @JsonProperty('generate_otp', Boolean)
    public generateOtp: boolean;
}
