import { JsonObject, JsonProperty } from 'json2typescript';
import GroupModel from './GroupModel';

@JsonObject('GroupsModel')
export default class GroupsModel {
    public constructor() {
        this.totalGroups = 0;
        this.totalPages = 0;
    }

    @JsonProperty('total_items', Number)
    public totalGroups: number;

    @JsonProperty('pages', Number)
    public totalPages: number;

    @JsonProperty('items', [GroupModel])
    public groups: GroupModel[] = [];
}
