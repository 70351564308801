import UserTableSettingsModel from '@/api/ui-interaction-api-client/models/UserTableSettingsModel';
import StoreEntity from '../models';

export class UIInteractionStoreState extends StoreEntity {
    public constructor() {
        super();

        this.showAddDomainOnTabLoad = false;
        this.showJobsList = false;
        this.isStateChanged = false;
        this.userTableSettings = new UserTableSettingsModel();
    }

    public showAddDomainOnTabLoad: boolean;

    public showJobsList: boolean;

    public isStateChanged: boolean;

    public confirmPageLeaveCallback?: () => void;

    public userTableSettings: UserTableSettingsModel;
}

export default new UIInteractionStoreState();
