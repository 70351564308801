export default {
  "common_learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mer"])},
  "common_ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
  "common_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avbryt"])},
  "common_keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behold"])},
  "common_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopier"])},
  "common_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopiert"])},
  "common_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last ned"])},
  "common_downloaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastet ned"])},
  "common_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last opp"])},
  "common_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett"])},
  "common_verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekreft"])},
  "common_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekreftet"])},
  "common_not_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikke bekreftet"])},
  "common_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vellykket"])},
  "common_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mislyktes"])},
  "common_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
  "common_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neste"])},
  "common_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til"])},
  "common_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppdater"])},
  "common_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handlinger"])},
  "common_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagre"])},
  "common_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navn"])},
  "common_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprettet dato"])},
  "common_completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fullført dato"])},
  "common_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lukk"])},
  "notif_error_server_unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fikke ikke kontakt med nettserveren. Prøv igjen om en stund."])},
  "notif_warn_unauthorized_to_perform_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du er ikke autorisert til å utføre denne handlingen."])},
  "notif_warn_unauthorized_to_view_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du er ikke autorisert til å se denne informasjonen."])},
  "notif_modal_session_expired_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Økten er utløpt"])},
  "notif_modal_session_expired_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Økten din er utløpt. Av sikkerhetshensyn må du logge inn igjen."])},
  "alert_no_verified_domains_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du må legge til og bekrefte hvertfall ett domene for å få fortsette."])},
  "pagination_first_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå til første side"])},
  "pagination_last_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå til siste side"])},
  "pagination_previous_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forrige side"])},
  "pagination_next_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neste side"])},
  "pagination_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["av"])},
  "common_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "common_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detaljer"])},
  "common_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beskrivelse"])},
  "common_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
  "common_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opprettet"])},
  "common_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fullført"])},
  "common_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbake"])},
  "common_sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorter"])},
  "common_apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruk"])},
  "common_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger"])},
  "common_yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
  "common_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nei"])},
  "common_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbakestill"])},
  "leave_page_confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har endringer som ikke er lagret"])},
  "leave_page_confirmation_modal_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker på at du vil forlate denne siden? Ikke lagrede endringer vil gå tapt."])},
  "common_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis"])},
  "common_show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis"])},
  "common_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])}
}