import { authenticationPolicyStore } from '@/store';
import AuthorizedNetworkBaseModel from './AuthorizedNetworkBaseModel';

export default class SubnetMaskDisplayModel extends AuthorizedNetworkBaseModel {
    public constructor(subnetMask: string) {
        super(subnetMask, /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}(\/([0-9]|[1-2][0-9]|3[0-2]))$/);
    }

    public validate(): void {
        const isValid = this.regex.test(this.value);

        authenticationPolicyStore.storeApi.commit(authenticationPolicyStore.mutations.updateEditedAuthorizedNetworksSubnetMaskValidation, {
            id: this.id,
            isValid: isValid,
            errorMessageId: isValid ? void 0 : 'page_policies_subnet_mask_invalid',
        });
    }
}
