import SurfConextOutputModel from '@/api/surfconext-api-client/models/SurfConextOutputModel';
import StoreEntity from '../models';

export class SurfConextStoreState extends StoreEntity {
    constructor() {
        super();

        this.isUnauthorizedToViewSurfConext = false;
    }

    public surfConextIdentityProvider: SurfConextOutputModel | undefined;

    public isUnauthorizedToViewSurfConext: boolean;
}

export default new SurfConextStoreState();
