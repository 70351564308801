import { JsonObject } from 'json2typescript';

@JsonObject('UserNotMemberOfGroupSearchInputModel')
export default class UserNotMemberOfGroupSearchInputModel {
    public constructor() {
        this.groupId = '';
        this.searchTerm = '%';
        this.page = 1;
        this.perPage = 10;
    }

    public groupId: string;

    public searchTerm: string;

    public page: number;

    public perPage: number;
}
