import TenantClientSignInUriModel from '@/api/mydomain-api-client/models/TenantClientSignInUriModel';
import { MyDomainStoreState } from './state';

export default {
    myDomain(state: MyDomainStoreState): string | undefined {
        return state.myDomain;
    },

    myDomainUri(state: MyDomainStoreState): string | undefined {
        return state.myDomainUri;
    },

    vismaMetadataXml(state: MyDomainStoreState): string | undefined {
        return state.vismaMetadataXml;
    },

    vismaMetadataXmlUrl(state: MyDomainStoreState): string | undefined {
        return state.vismaMetadataXmlUrl;
    },

    isModifiable(state: MyDomainStoreState): boolean {
        return !state.myDomain || state.isModifiable;
    },

    tenantClientsWithSignInUri(state: MyDomainStoreState): TenantClientSignInUriModel[] | undefined {
        return state.tenantClientsWithSignInUri;
    },

    assignedTenantClientWithSignInUri(state: MyDomainStoreState): TenantClientSignInUriModel | undefined {
        return state.assignedTenantClientWithSignInUri;
    },

    vismaHomeSignInUri(state: MyDomainStoreState): string | undefined {
        return state.vismaHomeSignInUri;
    },

    isVismaHomeSignInUriLoaded(state: MyDomainStoreState): boolean {
        return state.isVismaHomeSignInUriLoaded;
    },

    isProcessing(state: MyDomainStoreState): boolean {
        return state.isProcessing;
    },

    isUnauthorizedToViewMyDomain(state: MyDomainStoreState): boolean {
        return state.isUnauthorizedToViewMyDomain;
    },

    isDataLoaded(state: MyDomainStoreState): boolean {
        return state.isDataLoaded;
    },
};
