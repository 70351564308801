import ApiClientError from '@/api/error';
import { ERROR_MYDOMAIN_NOT_EXISTS } from '@/api/mydomain-api-client/error-codes';
import surfConextApiClient from '@/api/surfconext-api-client';
import { ERROR_SURFCONEXT_NOT_EXIST } from '@/api/surfconext-api-client/error-codes';
import SurfConextInputModel from '@/api/surfconext-api-client/models/SurfConextInputModel';
import SurfConextOutputModel from '@/api/surfconext-api-client/models/SurfConextOutputModel';
import { StatusCodes } from 'http-status-codes';
import { ActionContext } from 'vuex';
import { BasicStoreAccessors, RootState } from '../types';
import mutations from './mutations';
import { SurfConextStoreState } from './state';

let storeApi: BasicStoreAccessors<SurfConextStoreState>;

export function initActions(api: BasicStoreAccessors<SurfConextStoreState>): void {
    storeApi = api;
}

export default {
    async getSurfConextIdentityProviderAsync(): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            const surfConextConfiguration = await surfConextApiClient.getAsync();

            if (surfConextConfiguration) {
                storeApi.commit(mutations.setSurfConextIdentityProvider, surfConextConfiguration);
            }
        } catch (err) {
            const apiError = err as ApiClientError;

            // Ignore the MyDomain does not exist error and simply let the SURFconext IDP be null
            if (apiError.statusCode === StatusCodes.NOT_FOUND && apiError.errorCode === ERROR_MYDOMAIN_NOT_EXISTS) {
                return;
            }

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewSurfConext);
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
        }
    },

    async addSurfConextIdentityProviderAsync(context: ActionContext<SurfConextStoreState, RootState>, model: SurfConextInputModel): Promise<SurfConextOutputModel> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            const surfConextConfiguration = await surfConextApiClient.addAsync(model);

            storeApi.commit(mutations.setSurfConextIdentityProvider, surfConextConfiguration);

            return surfConextConfiguration;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
        }
    },

    async updateSurfConextIdentityProviderAsync(context: ActionContext<SurfConextStoreState, RootState>, model: SurfConextInputModel): Promise<SurfConextOutputModel> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            const surfConextConfiguration = await surfConextApiClient.updateAsync(model);

            storeApi.commit(mutations.setSurfConextIdentityProvider, surfConextConfiguration);

            return surfConextConfiguration;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
        }
    },

    async deleteSurfConextIdentityProviderAsync(): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            await surfConextApiClient.deleteAsync();

            storeApi.commit(mutations.setSurfConextIdentityProvider, void 0);
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.statusCode === StatusCodes.NOT_FOUND && apiError.errorCode === ERROR_SURFCONEXT_NOT_EXIST) {
                storeApi.commit(mutations.setSurfConextIdentityProvider, void 0);
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
        }
    },
};
