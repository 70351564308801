import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('ExportFieldModel')
export default class ExportFieldModel {
    public constructor() {
        this.fieldName = '';
        this.isSelected = false;
    }

    @JsonProperty('field_name', String)
    public fieldName: string;

    @JsonProperty('is_selected', Boolean, true)
    public isSelected: boolean;
}
