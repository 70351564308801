import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('AuditExportInputModel')
export default class AuditExportInputModel {
    public constructor() {
        this.userId = '';
        this.fieldsToExport = new Array<string>();
    }

    @JsonProperty('user_id', String)
    public userId: string;

    @JsonProperty('events', [String], true)
    public events: string[] | undefined;

    @JsonProperty('audit_ids', [String], true)
    public auditIds: string[] | undefined;

    @JsonProperty('excluded_audit_ids', [String], true)
    public excludedAuditIds: string[] | undefined;

    @JsonProperty('fields_to_export', [String])
    public fieldsToExport: string[];
}
