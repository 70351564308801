import httpClient from '@/http-client';
import ApiRequestPayload from '@/http-client/models/ApiRequestPayload';
import { StatusCodes } from 'http-status-codes';
import ApiClientError from '../error';
import UserTableSettingsModel from './models/UserTableSettingsModel';

const settingsRouteRoot = '/v1.0/settings';

export default {
    async getUserTableSettings(): Promise<UserTableSettingsModel> {
        const requestUri = `${settingsRouteRoot}/usertable`;
        const response = await httpClient.getAsync(requestUri);

        if (response.statusCode === StatusCodes.OK) {
            return response.data(UserTableSettingsModel)!;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async setUserTableSettings(model: UserTableSettingsModel): Promise<void> {
        const requestUri = `${settingsRouteRoot}/usertable`;
        const response = await httpClient.putAsync<UserTableSettingsModel>(requestUri, new ApiRequestPayload(model, UserTableSettingsModel));

        if (response.statusCode === StatusCodes.ACCEPTED) {
            return;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },
};
