import SelectedDomainModel from './SelectedDomainModel';
import SelectDomainsModel from './SelectDomainsModel';
import UserModel from '@/api/user-api-client/models/UserModel';

export default class SelectedUsersModel {
    constructor() {
        this.domains = new Map<string, SelectedDomainModel>();
        this.usersCount = 0;
    }
    domains: Map<string, SelectedDomainModel>;
    usersCount: number;

    selectDomains(input: SelectDomainsModel): void {
        if (!input.preserveSelection) {
            this.domains.clear();
        }

        for (const domain of input.domains) {
            this.domains.set(domain.toLowerCase(), new SelectedDomainModel(domain, true));
        }
    }

    clearSelection(): void {
        this.domains.clear();
        this.usersCount = 0;
    }

    updateUserCount(userCount: number) {
        this.usersCount = userCount;
    }

    selectUsers(users: UserModel[]): void {
        for (const user of users) {
            const domain = this.domains.get(user.domain.toLowerCase());
            if (domain) {
                if (domain.bulkMode) {
                    if (domain.excludedUsers.indexOf(user.id) >= 0) {
                        domain.excludedUsers.splice(domain.excludedUsers.indexOf(user.id), 1);
                    }
                } else {
                    if (domain.includedUsers.indexOf(user.id) === -1) {
                        domain.includedUsers.push(user.id);
                    }
                }
            } else {
                const domainSelectionModel = new SelectedDomainModel(user.domain, false);
                domainSelectionModel.includedUsers.push(user.id);
                this.domains.set(user.domain, domainSelectionModel);
            }
        }
    }

    deselectUser(user: UserModel) {
        const domain = this.domains.get(user.domain.toLowerCase());
        if (domain) {
            if (domain.bulkMode) {
                if (domain.excludedUsers.indexOf(user.id) === -1) {
                    domain.excludedUsers.push(user.id);
                }
            } else {
                if (domain.includedUsers.indexOf(user.id) >= 0) {
                    domain.includedUsers.splice(domain.includedUsers.indexOf(user.id), 1);
                }
                if (domain.includedUsers.length === 0) {
                    this.domains.delete(user.domain.toLowerCase());
                }
            }
        }
    }

    isUserSelected(user: UserModel) {
        const domain = this.domains.get(user.domain.toLowerCase());
        if (domain) {
            if (domain.bulkMode) {
                return domain.excludedUsers.indexOf(user.id) === -1;
            } else {
                return domain.includedUsers.indexOf(user.id) >= 0;
            }
        }
        return false;
    }

    get totalUsersCount(): number {
        let count = this.usersCount;
        for (const [, value] of this.domains) {
            if (value.bulkMode) {
                count -= value.excludedUsers.length;
            } else {
                count += value.includedUsers.length;
            }
        }
        return count;
    }

    get bulkSelectDomains(): string[] {
        const domainNames = [];
        for (const [domainName, value] of this.domains) {
            if (value.bulkMode) {
                domainNames.push(domainName);
            }
        }
        return domainNames;
    }
}
