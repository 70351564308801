import ProvisioningActionModel from '@/api/provisioning-api-client/models/ProvisioningActionModel';
import ProvisioningModel from '@/api/provisioning-api-client/models/ProvisioningModel';
import { ProvisioningStoreState } from './state';
import IdentityProviderScimConfigModel from '@/api/provisioning-api-client/models/IdentityProviderScimConfigModel';

export default {
    setProvisioningData(state: ProvisioningStoreState, provisioningData: ProvisioningModel): void {
        state.provisioningData = provisioningData;
    },

    disableProvisioning(state: ProvisioningStoreState) {
        state.provisioningData = void 0;
    },

    setTokenData(state: ProvisioningStoreState, { tokenGeneratedTime, tokenExpirationTime }: { tokenGeneratedTime: string; tokenExpirationTime: string }): void {
        if (!state.provisioningData) {
            return;
        }

        state.provisioningData.tokenCreatedDate = tokenGeneratedTime;
        state.provisioningData.tokenExpirationDate = tokenExpirationTime;
    },

    addAction(state: ProvisioningStoreState, actionName: string): void {
        if (!state.provisioningData) {
            return;
        }

        if (state.provisioningData.configuration.actions.findIndex((action) => action.name === actionName) === -1) {
            state.provisioningData.configuration.actions.push({ name: actionName } as ProvisioningActionModel);
        }
    },

    removeAction(state: ProvisioningStoreState, actionName: string) {
        if (!state.provisioningData) {
            return;
        }

        const actionIndex = state.provisioningData.configuration.actions.findIndex((action) => action.name === actionName);

        if (actionIndex >= 0) {
            state.provisioningData.configuration.actions.splice(actionIndex, 1);
        }
    },

    addTrigger(state: ProvisioningStoreState, { actionName, triggerName }: { actionName: string; triggerName: string }): void {
        if (!state.provisioningData) {
            return;
        }

        const action = state.provisioningData.configuration.actions.find((a) => a.name === actionName);
        if (!action) {
            return;
        }

        // we only allow one trigger at a time, so the whole list is replaced
        action.triggers = [triggerName];
    },

    removeTrigger(state: ProvisioningStoreState, actionName: string): void {
        if (!state.provisioningData) {
            return;
        }

        const action = state.provisioningData.configuration.actions.find((a) => a.name === actionName);
        if (!action) {
            return;
        }

        // we only allow one trigger at a time, so the whole list is deleted
        action.triggers = void 0;
    },

    removeAllTriggers(state: ProvisioningStoreState, actionName: string): void {
        if (!state.provisioningData) {
            return;
        }

        const action = state.provisioningData.configuration.actions.find((action) => action.name === actionName);
        if (!action) {
            return;
        }

        action.triggers = void 0;
    },

    setUnauthorizedToViewProvisioning(state: ProvisioningStoreState): void {
        state.isUnauthorizedToViewProvisioning = true;
    },

    setProcessingState(state: ProvisioningStoreState, isProcessing: boolean): void {
        state.isProcessing = isProcessing;
    },

    setIdentityProvidersScimConfig(state: ProvisioningStoreState, identityProvidersScimConfig: IdentityProviderScimConfigModel[]): void {
        state.identityProvidersScimConfig = identityProvidersScimConfig;
    },

    setIsIdentityProvidersScimConfigLoaded(state: ProvisioningStoreState): void {
        state.isIdentityProvidersScimConfigLoaded = true;
    },
};
