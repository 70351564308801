import { ActionContext } from 'vuex';
import { BasicStoreAccessors, RootState } from '../types';
import mutations from './mutations';
import { UIInteractionStoreState } from './state';
import uiInteractionApiClient from '@/api/ui-interaction-api-client';
import uiInteractionStore from '.';

let storeApi: BasicStoreAccessors<UIInteractionStoreState>;

export function initActions(api: BasicStoreAccessors<UIInteractionStoreState>): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    storeApi = api;
}

export default {
    confirmPageLeave(context: ActionContext<UIInteractionStoreState, RootState>): void {
        if (!context.state.confirmPageLeaveCallback) {
            return;
        }

        context.state.confirmPageLeaveCallback();
        storeApi.commit(mutations.setConfirmPageLeaveCallback, void 0);
        storeApi.commit(mutations.setIsStateChanged, false);
    },

    async getUserTableSettingsAsync(): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            const result = await uiInteractionApiClient.getUserTableSettings();

            storeApi.commit(mutations.setUserTableSettings, result);
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
        }
    },

    async setUserTableColumnsSettingsAsync(context: ActionContext<UIInteractionStoreState, RootState>, columns: Array<string>): Promise<void> {
        try {
            storeApi.commit(mutations.setProcessingState, true);

            const userTableSettings = uiInteractionStore.state.userTableSettings;
            userTableSettings.columns = columns;

            await uiInteractionApiClient.setUserTableSettings(userTableSettings);

            storeApi.commit(mutations.setUserTableSettings, userTableSettings);
        } finally {
            storeApi.commit(mutations.setProcessingState, false);
        }
    },
};
