import screenBreakpoints from '@/util/screen-breakpoints';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';

export default function () {
    // state
    const windowWidth = ref(window.innerWidth);

    // computed
    const isScreenMaxXs = computed(() => windowWidth.value < screenBreakpoints.XS);
    const isScreenMaxSm = computed(() => windowWidth.value < screenBreakpoints.SM);
    const isScreenMaxMd = computed(() => windowWidth.value <= screenBreakpoints.MD);
    const isScreenMaxLg = computed(() => windowWidth.value <= screenBreakpoints.LG);
    const isScreenMaxXL = computed(() => windowWidth.value <= screenBreakpoints.XL);
    const isScreenMaxXXL = computed(() => windowWidth.value <= screenBreakpoints.XXL);

    // functions
    function onWindowResized(): void {
        windowWidth.value = window.innerWidth;
    }

    // lifecycle
    onMounted(() => {
        window.addEventListener('resize', onWindowResized);
    });

    onBeforeUnmount(() => {
        window.removeEventListener('resize', onWindowResized);
    });

    return {
        // computed
        isScreenMaxXs: isScreenMaxXs,
        isScreenMaxSm: isScreenMaxSm,
        isScreenMaxMd: isScreenMaxMd,
        isScreenMaxLg: isScreenMaxLg,
        isScreenMaxXL: isScreenMaxXL,
        isScreenMaxXXL: isScreenMaxXXL,
    };
}
