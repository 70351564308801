import StoreEntity from '@/store/models';

export default class AuthenticationPolicyCountryStoreModel extends StoreEntity {
    public constructor(name: string, iso2Code: string, selected: boolean) {
        super();

        this.name = name;
        this.iso2Code = iso2Code;
        this.selected = selected;
    }

    public name: string;

    public iso2Code: string;

    public selected: boolean;
}
