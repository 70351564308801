import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('AddRecoveryEmailInputModel')
export default class AddRecoveryEmailsInputModel {
    constructor(emails?: string[]) {
        this.emails = emails || [];
    }

    @JsonProperty('emails', [String])
    public emails: string[];
}
