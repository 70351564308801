import { Guid } from 'guid-typescript';

export default abstract class AuthorizedNetworkBaseModel {
    public constructor(ip: string, regex: RegExp) {
        this.id = Guid.create();
        this.value = ip;
        this.isValid = true;
        this.regex = regex;
    }

    public id: Guid; // used to uniquely identify each network on the front-end

    public value: string;

    public isValid: boolean;

    public errorMessageId?: string;

    protected regex: RegExp;

    public abstract validate(): void;
}
