export default {
  "page_policies_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard"])},
  "page_policies_default_policy_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne policyen gjelder for alle brukere"])},
  "page_policies_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger"])},
  "page_policies_reset_to_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbakestill til standard"])},
  "page_policies_2_step_verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totrinnskontroll"])},
  "page_policies_2_step_verification_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krever totrinnskontroll ved innlogging"])},
  "page_policies_2_step_verification_on_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["På"])},
  "page_policies_2_step_verification_off_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Av"])},
  "page_policies_identity_providers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identitetsleverandører"])},
  "page_policies_networks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettverk"])},
  "page_policies_ip_ranges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-intervall"])},
  "page_policies_ips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP'er"])},
  "page_policies_subnet_masks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettverksmaske"])},
  "page_policies_first_ip_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Første IP i intervallet er ugyldig"])},
  "page_policies_second_ip_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andre IP i intervallet er ugyldig"])},
  "page_policies_ip_range_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-intervallet er ugyldig"])},
  "page_policies_ip_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP er ugyldig"])},
  "page_policies_subnet_mask_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugyldig nettverksmaske"])},
  "page_policies_add_ip_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til IP-intervall"])},
  "page_policies_add_ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til IP"])},
  "page_policies_add_subnet_mask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til nettverksmaske"])},
  "page_policies_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< Tilbake til Policyer"])},
  "page_policies_notif_update_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det har oppstått en ukjent feil under oppdatering av denne policien."])},
  "page_policies_ip_range_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-området er duplisert"])},
  "page_policies_ip_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP er duplisert"])},
  "page_policies_subnet_mask_duplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettverksmaske er duplisert"])},
  "page_policies_network_your_ip_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din IP-adresse er"])},
  "page_policies_network_ips_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angi nettverk som bruker IP-intervaller, IP-adresser eller CIDR'er. Disse må være offentlige IP-adresser, og ikke lokale eller private IP-adresser."])},
  "page_policies_network_auth_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentiseringen vil bli godkjent hvis det er samsvar med noen av de tillatte IP-ene. Hvis nettverkene ikke inkluderer din IP-adresse, vil du låse deg selv ute."])},
  "page_policies_network_all_networks_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis ingenting er konfigurert, vil alle nettverk være tillatt."])},
  "page_policies_countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
  "page_policies_countries_no_suggestions_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen forslag funnet"])},
  "page_policies_countries_your_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landet for IP-adressen din er"])},
  "page_policies_countries_auth_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentiseringen vil bli godkjent hvis det er samsvar med noen av de tillatte landene. Hvis landene ikke inkluderer din IP-adresse, vil du låse deg selv ute."])},
  "page_policies_countries_select_countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg land"])},
  "page_policies_countries_all_countries_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis ingenting er valgt, vil alle land være tillatt."])},
  "page_policies_all_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle tillatt"])},
  "page_policies_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passord"])},
  "page_policies_password_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passordinnhold"])},
  "page_policies_password_locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passord låst"])},
  "page_policies_password_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passord utløper"])},
  "page_policies_password_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passordsendring"])},
  "page_policies_password_minimum_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimumslengde for passord"])},
  "page_policies_password_minimum_required_digit_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum påkrevde siffer (0-9)"])},
  "page_policies_password_minimum_required_uppercase_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum antall store bokstaver"])},
  "page_policies_password_minimum_required_lowercase_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum antall små bokstaver"])},
  "page_policies_password_minimum_required_special_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum antall spesialtegn"])},
  "page_policies_password_accounts_locked_login_failures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoer kan bli låst ute på grunn av påloggingsfeil"])},
  "page_policies_password_locked_account_after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lås konto etter"])},
  "page_policies_password_login_failures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["feil ved pålogging(er)"])},
  "page_policies_password_reset_failure_count_after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbakestill feiltellingen etter"])},
  "page_policies_password_minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutt(er)"])},
  "page_policies_password_lockout_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varighet for låsing"])},
  "page_policies_password_never_expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passordene utgår aldri"])},
  "page_policies_password_expires_after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passord utgår etter"])},
  "page_policies_password_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dag(er)"])},
  "page_policies_password_send_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis advarsel etter pålogging"])},
  "page_policies_password_minutes_before_password_expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutt(er) før passordet utløper"])},
  "page_policies_password_keep_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behold passordhistorikk"])},
  "page_policies_password_remember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Husk"])},
  "page_policies_password_passwords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["passord(ene)"])},
  "page_policies_password_invalid_value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Verdien må være mellom ", _interpolate(_named("value1")), " og ", _interpolate(_named("value2"))])},
  "page_policies_password_pwd_expire_warning_minutes_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["og mindre enn passordets utløpstid"])},
  "page_policies_password_save_changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagre endringer"])},
  "page_policies_password_update_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker på at du vil oppdatere passordpolicyen? Brukerne vil bli berørt, og hvis deres nåværende passord ikke samsvarer med de nye innstillingene, vil de bli tvunget til å endre det."])},
  "page_policies_password_reset_to_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbakestill til Visma-standard"])},
  "page_policies_password_reset_to_default_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er du sikker på at du vil tilbakestille passordpolicyen til Visma-standarden?"])},
  "page_policies_password_minimmum_length": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Minimum lengde: ", _interpolate(_named("value"))])},
  "page_policies_password_require_digit_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krever 1 siffer"])},
  "page_policies_password_require_digit_characters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Krever ", _interpolate(_named("value")), " siffer"])},
  "page_policies_password_require_uppercase_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krever 1 tegn, stor bokstav"])},
  "page_policies_password_require_uppercase_characters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Krever ", _interpolate(_named("value")), " store bokstaver"])},
  "page_policies_password_require_lowercase_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krever 1 tegn, liten bokstav"])},
  "page_policies_password_require_lowercase_characters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Krever ", _interpolate(_named("value")), " små bokstaver"])},
  "page_policies_password_require_special_character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krevet 1 spesialtegn"])},
  "page_policies_password_require_special_characters": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Krever ", _interpolate(_named("value")), " spesialtegn"])},
  "page_policies_password_accounts_locked_after_one_login_failure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoer blir låst etter 1 påloggingsfeil"])},
  "page_policies_password_accounts_locked_after_login_failures": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kontoer blir låst etter ", _interpolate(_named("value")), " påloggingsfeil"])},
  "page_policies_password_expires_after_one_day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passord utløper etter 1 dag"])},
  "page_policies_password_expires_after_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Passord utløper etter ", _interpolate(_named("value")), " dager"])},
  "page_policies_password_keep_one_in_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behold 1 passord i historikken"])},
  "page_policies_password_keep_in_history": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Behold ", _interpolate(_named("value")), " passord i historikken"])},
  "page_policies_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Økter"])},
  "page_policies_sessions_max_concurrent_sessions_per_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totalt antall samtidige økter per bruker"])},
  "page_policies_sessions_max_concurrent_sessions_per_users_value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Totalt antall samtidige økter per bruker: ", _interpolate(_named("value"))])},
  "page_policies_sessions_lock_to_origin_ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logg ut bruker etter endring av IP-adresse"])},
  "page_policies_sessions_lock_to_origin_ip_value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Logg ut bruker etter endring av IP-adresse: ", _interpolate(_named("value"))])}
}