import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "modal-content" }
const _hoisted_2 = { class: "modal-header" }
const _hoisted_3 = {
  key: 1,
  class: "alert alert-danger alert-dismissible",
  role: "alert"
}
const _hoisted_4 = ["title"]
const _hoisted_5 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_spinner = _resolveComponent("loading-spinner")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["modal", [_ctx.icon ? 'modal-' + _ctx.icon : '', !_ctx.show ? 'modal-hidden' : '']]),
    disabled: ""
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["modal-dialog", 'modal-' + _ctx.size])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.showCloseBtn)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                type: "button",
                class: "close",
                "aria-label": "Close",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close()))
              }))
            : _createCommentVNode("", true),
          (_ctx.errorMessage)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("button", {
                  type: "button",
                  class: "close",
                  "data-dismiss": "alert",
                  "aria-label": "Close",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clearError()))
                }),
                _createElementVNode("span", null, _toDisplayString(_ctx.$t(_ctx.errorMessage)), 1)
              ]))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "title", {}, () => [
            _createElementVNode("h4", {
              class: "modal-title wrap-long-word",
              title: _ctx.title
            }, _toDisplayString(_ctx.title), 9, _hoisted_4),
            _createVNode(_component_loading_spinner, { show: _ctx.showLoadingSpinner }, null, 8, ["show"])
          ], true)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["modal-body", _ctx.bodyOverflow ? 'overflow-' + _ctx.bodyOverflow : ''])
        }, [
          _renderSlot(_ctx.$slots, "body", {}, undefined, true)
        ], 2),
        _createElementVNode("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
        ])
      ])
    ], 2)
  ], 2))
}