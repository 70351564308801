export default {
  "nav_menu_logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión"])},
  "nav_menu_account_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración de Cuenta"])},
  "nav_menu_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayuda"])},
  "nav_menu_change_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar la organización"])},
  "nav_menu_tenant_org_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la organización"])},
  "nav_menu_tenant_org_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de organización"])},
  "nav_menu_route_name_dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tablero"])},
  "nav_menu_route_name_domains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dominios"])},
  "nav_menu_route_name_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuarios"])},
  "nav_menu_route_name_provisioning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprovisionamiento"])},
  "nav_menu_route_name_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos"])},
  "nav_menu_route_name_policies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Políticas"])},
  "nav_menu_tenant_search_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar por nombre de organización"])},
  "nav_menu_tenant_search_no_items_found_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron organizaciones"])},
  "nav_menu_profile_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])},
  "nav_menu_jobs_change_domain_job_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change email domain"])},
  "nav_menu_jobs_users_export_job_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportación de usuarios"])},
  "nav_menu_jobs_users_import_job_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importación de usuarios"])},
  "nav_menu_jobs_user_audit_export_job_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User audit logs export"])},
  "nav_menu_jobs_status_queued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queued"])},
  "nav_menu_jobs_status_processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesando"])},
  "nav_menu_jobs_status_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completado"])},
  "nav_menu_jobs_status_completed_with_errors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completado con errores"])},
  "nav_menu_jobs_status_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fallido"])},
  "nav_menu_jobs_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
  "nav_menu_jobs_started_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Started on"])},
  "nav_menu_jobs_completed_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed on"])},
  "nav_menu_jobs_download_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar informe"])},
  "nav_menu_jobs_no_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay actividad"])},
  "nav_menu_jobs_activities_cleared": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las actividades de más de 15 días se borran de esta lista"])},
  "nav_menu_jobs_created_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Created by ", _interpolate(_named("email"))])},
  "nav_menu_jobs_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Background jobs"])}
}