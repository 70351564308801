export default {
  "page_groups_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppenname"])},
  "page_groups_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mitglied"])},
  "page_groups_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglieder"])},
  "page_groups_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
  "page_groups_table_no_groups_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Gruppen hinzugefügt"])},
  "page_groups_table_no_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Gruppen"])},
  "page_groups_table_no_groups_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Gruppen mit Suchkriterien gefunden"])},
  "page_groups_btn_add_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe hinzufügen"])},
  "page_groups_btn_save_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe speichern"])},
  "page_groups_btn_edit_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe bearbeiten"])},
  "page_groups_users_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])},
  "page_groups_users_fist_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
  "page_groups_users_last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
  "page_groups_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt (UTC)"])},
  "page_groups_membership_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitgliedschaft aktualisiert (UTC)"])},
  "page_groups_delete_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe löschen"])},
  "page_groups_delete_group_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie die Gruppe löschen möchten?"])},
  "page_groups_remove_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglied entfernen"])},
  "page_groups_remove_member_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie das Mitglied entfernen möchten"])},
  "page_groups_remove_member_group_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sind Sie sicher, dass Sie das Mitglied ", _interpolate(_named("member")), " aus der Gruppe ", _interpolate(_named("group")), " entfernen möchten?"])},
  "page_groups_no_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Benutzer hinzugefügt"])},
  "page_groups_no_users_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden keine Benutzer gefunden, die den Suchkriterien entsprechen"])},
  "page_groups_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< Zurück zu Gruppen"])},
  "page_groups_add_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglied zur Gruppe hinzufügen"])},
  "page_groups_add_users_all_are_already_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Benutzer sind dieser Gruppe bereits hinzugefügt"])},
  "page_groups_add_users_all_no_users_matching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Benutzer, die den Suchkriterien entsprechen"])},
  "page_groups_member_since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglied seit"])},
  "page_groups_remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernen"])},
  "page_groups_group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppenmitglieder:"])},
  "page_groups_external_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen-ID:"])},
  "page_groups_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelle"])},
  "page_groups_err_group_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Hinzufügen dieser Gruppe ist ein unbekannter Fehler aufgetreten."])},
  "page_groups_err_group_invalid_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Gruppenname ist ungültig. Maximal zulässige Länge von 255 Zeichen"])},
  "page_groups_err_group_invalid_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Gruppenbeschreibung ist ungültig. Maximal zulässige Länge von 1024 Zeichen"])},
  "page_groups_err_group_already_exits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Gruppe, die Sie hinzufügen wollten, existiert bereits."])},
  "page_groups_err_group_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Aktualisieren dieser Gruppe ist ein unbekannter Fehler aufgetreten."])},
  "page_groups_search_group_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppenname suchen"])},
  "page_groups_search_group_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche per E-Mail"])},
  "page_groups_search_group_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglieder suchen"])},
  "page_groups_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen"])},
  "page_groups_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe"])},
  "page_groups_filter_members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglieder filtern"])},
  "page_groups_member_filter": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mitglied: ", _interpolate(_named("name"))])}
}