import state, { GroupStoreState } from './state';
import { BasicStoreAccessors } from '../types';
import actions, { initActions } from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
    namespaced: true,
    storeApi: {} as BasicStoreAccessors<GroupStoreState>,

    state,
    getters,
    mutations,
    actions,

    init(storeApi: BasicStoreAccessors<GroupStoreState>): void {
        this.storeApi = storeApi;
        initActions(storeApi);
    }
};