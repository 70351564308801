/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import i18n from '@/i18n';
import moment from 'moment';
import screenBreakpoints from './screen-breakpoints';

// Standalone functions
const isNullOrEmpty = <T>(obj: T | null | undefined): obj is null | undefined => {
    return obj === undefined || typeof obj === 'undefined' || obj === null || (typeof obj === 'string' && obj === '');
};

const formatString = (value: string, ...args: string[]) => {
    return value.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] !== 'undefined' ? args[number] : match;
    });
};

const formatStringWithTranslation = (value: string, ...args: string[]) => {
    return value.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] !== 'undefined' ? i18n.t(args[number]).toString() : i18n.t(match).toString();
    });
};

const debounce = <F extends (...args: unknown[]) => any>(func: F, waitFor: number) => {
    let timeout: NodeJS.Timeout;

    const debounced = (...args: any[]) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), waitFor);
    };

    return debounced as unknown as (...args: Parameters<F>) => ReturnType<F>;
};

const debounceAsync = <F extends (...args: any[]) => any>(func: F, waitFor: number) => {
    let timeout: NodeJS.Timeout;

    return (...args: Parameters<F>): Promise<ReturnType<F>> =>
        new Promise((resolve) => {
            if (timeout) {
                clearTimeout(timeout);
            }

            timeout = setTimeout(() => resolve(func(...args)), waitFor);
        });
};

const formatDateTimeUtc = (date: string): string => {
    return formatDateTimeCustom(date, 'YYYY-MM-DD HH:mm:ss UTC');
};

const formatDateTime = (date: string): string => {
    return formatDateTimeCustom(date, 'YYYY-MM-DD HH:mm:ss');
};

const formatDate = (date: string): string => {
    return formatDateTimeCustom(date, 'YYYY-MM-DD');
};

const formatDateTimeCustom = (date: string, customFormat: string): string => {
    return moment(date).utc().format(customFormat);
};

const sortDates = (a: string, b: string, desc = false): number => {
    const firstTime = Date.parse(a);
    const secondTime = Date.parse(b);

    return desc ? secondTime - firstTime : firstTime - secondTime;
};

const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

const scrollToTop = (): void => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
};

const isValidUrl = (url: string) => {
    const urlPattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
        'i'
    ); // fragment locator

    return !!urlPattern.test(url);
};

export default {
    // export passing
    screenBreakpoints,

    // functions
    isNullOrEmpty,
    formatString,
    formatStringWithTranslation,
    debounce,
    debounceAsync,
    formatDateTimeUtc,
    formatDateTime,
    formatDate,
    formatDateTimeCustom,
    sortDates,
    sleep,
    scrollToTop,
    isValidUrl,
};
