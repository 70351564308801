export default {
  "page_sso_alert_no_verified_domains_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domenekonfigurering"])},
  "page_sso_alert_no_verified_domains_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du må legge til og verifisere minst ett domene for å fortsette med konfigurering av Single Sign-On."])},
  "page_sso_alert_no_verified_domains_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå til Domener"])},
  "page_sso_alert_no_added_mydomain_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MyDomain-konfigurering"])},
  "page_sso_alert_no_added_mydomain_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du må legge til et MyDomain for å fortsette med konfigurering av Single Sign-on."])},
  "page_sso_alert_no_added_mydomain_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå til MyDomain"])},
  "page_sso_alert_idp_already_added_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identitetsleveandør uppsett"])},
  "page_sso_alert_idp_already_added_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har allerede konfigurert en identitetsleverandør. Bare én ekstern SSO-identitetsleverandør kan konfigureres for din bedrift."])},
  "page_sso_alert_idp_already_added_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gå til Single Sign-on"])},
  "page_sso_mydomain_info_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MyDomain er din personlig side for pålogging hos Visma. Denne siden inneholder også dine unike SAML- eller OpenID Connect-endepunkter."])},
  "page_sso_mydomain_info_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En URL som brukere dine kjenner igjen vil hjelpe brukerne å forstå at nettstedet hører til din organisasjon."])},
  "page_sso_mydomain_info_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kan bare slette eller gi nytt navn til MyDomain hvis du ikke har oppsett av identitetsleverandør."])},
  "page_sso_mydomain_modal_add_or_update_title_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvilket MyDomain vil du legge til?"])},
  "page_sso_mydomain_modal_add_or_update_input_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eksempel"])},
  "page_sso_mydomain_modal_add_or_update_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angi et navn som er kjent for dine brukere. Navnet kan bestå av små bokstaver, tall, bindestrek(-), men kan ikke begynne eller slutt med bindestrek. Maksimal antall tegn er 40."])},
  "page_sso_mydomain_modal_add_or_update_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det har oppstått en ukjent feil under innstilling av MyDomain."])},
  "page_sso_mydomain_modal_add_or_update_error_invalid_mydomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MyDomainet har ikke gyldig format."])},
  "page_sso_mydomain_modal_add_or_update_error_already_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette MyDomainet er opptatt."])},
  "page_sso_mydomain_modal_add_or_update_error_sso_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan ikke endre ditt MyDomain. Du har Identitetsleverandører i bruk."])},
  "page_sso_mydomain_modal_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett MyDomain"])},
  "page_sso_mydomain_modal_delete_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Er du sikker på at du vil slette ditt MyDomain: ", _interpolate(_named("myDomainUri")), "?"])},
  "page_sso_mydomain_notif_add_mydomain_success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("myDomainUri")), " er lagt til som ditt MyDomain."])},
  "page_sso_mydomain_notif_update_mydomain_success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ditt MyDomain er oppdatert til ", _interpolate(_named("myDomainUri")), "."])},
  "page_sso_mydomain_notif_delete_mydomain_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditt MyDomain er slettet."])},
  "page_sso_idp_notif_add_or_update_success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Single Sign-On med ", _interpolate(_named("providerDisplayName")), " er satt opp med suksess."])},
  "page_sso_idp_selection_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg identitetsleverandør"])},
  "page_sso_idp_selection_title_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg den identitetsleverandøren du vil bruke til Enkel pålogging."])},
  "page_sso_idp_selection_list_title_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annet"])},
  "page_sso_idp_selection_back_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< Tilbake til Single Sign-on"])},
  "page_sso_idp_selection_list_body_coming_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommer snart. Følg med!"])},
  "page_sso_idp_selection_list_body": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Når du integrerer Visma med ", _interpolate(_named("providerDisplayName")), " kan dine brukere automatisk bli logget inn til Visma når de bruker sine ", _interpolate(_named("providerDisplayName")), "-kontoer."])},
  "page_sso_idp_notif_add_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukjent feil oppstod mens du la til identitetsleverandøren din."])},
  "page_sso_idp_notif_update_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det oppstod en egen feil under oppdateringen av identitetsleverandøren din."])},
  "page_sso_idp_notif_update_idp_no_longer_exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne identitetsleverandøren finnes ikke lenger for ditt firma."])},
  "page_sso_idp_notif_delete_unknown_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Det oppstod en egen feil under sletting av din identitet leverandør."])},
  "page_sso_idp_notif_delete_success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Din ", _interpolate(_named("providerName")), "-identitetsleverandør er slettet."])},
  "page_sso_idp_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identitetsleverandør"])},
  "page_sso_idp_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til den identitetsleverandøren du vil bruke til Single Sign-on."])},
  "page_sso_idp_no_idp_added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen identitetsleverandør er lagt til."])},
  "page_sso_idp_setup_back_to_selection_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< Tilbake til valgside"])},
  "page_sso_idp_setup_step_advanced_config_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avansert konfigurasjon"])},
  "page_sso_idp_setup_step_advanced_config_jit_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just-in-Time (JIT) brukerprovisjonering"])},
  "page_sso_idp_setup_step_advanced_config_jit_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvis det er aktivert i systemets innstillinger, vil alle brukere som ikke finnes i Visma-systemet automatisk bli opprettet (uten tilgangsrettigheter bortsett fra sign in). Hvis funksjonen er deaktivert, må du opprette alle brukere i Admin-panelet for din Visma-applikasjon før pålogging er mulig. Uansett må du gi tilgang til rettigheter for applikasjonen i Vismas admin-paneler."])},
  "page_sso_idp_setup_help_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sjekk trinn-for-trinn-veiledningen for å konfigurere ", _interpolate(_named("providerDisplayName")), " som en ", _interpolate(_named("idpType")), " identitetsleverandør i Visma."])},
  "page_sso_idp_modal_delete_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slett identitetsleverandør"])},
  "page_sso_idp_modal_delete_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vil du slette identitetsleverandør ", _interpolate(_named("idpName")), "?"])},
  "page_sso_idp_modal_delete_scim_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCIM 2.0 provisjonering er for øyeblikket aktivert. Hvis du fortsetter, vil den bli deaktivert og konfigurasjonen vil gå tapt."])},
  "page_sso_saml2idp_notif_invalid_metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML2-metadata er ikke gyldig."])},
  "page_sso_saml2idp_notif_invalid_metadata_certificate_expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sertifikatet for SAML2-metadata er utløpt."])},
  "page_sso_saml2idp_notif_invalid_metadata_certificate_still_expired": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sertifikatet er fortsatt utløpt. Gå til ", _interpolate(_named("providerDisplayName")), " for å opprette et nytt sertifikat. Gå tilbake hit og klikk knappen 'Oppdater sertifikat'."])},
  "page_sso_saml2idp_btn_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til SAML2-identitetsleverandør"])},
  "page_sso_saml2idp_certificate_expires_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sertifikatet utløper ", _interpolate(_named("expirationTime"))])},
  "page_sso_saml2idp_certificate_expired_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Utløpt ", _interpolate(_named("expirationTime")), ". Gå til ", _interpolate(_named("saml2Idp")), " for å opprette et nytt sertifikat. Gå tilbake hit, rediger leverandør, klikk knappen 'Oppdater sertifikat', verifiser opplysningene om sertifikatet og klikk 'Lagre' for å oppdatere."])},
  "page_sso_saml2idp_certificate_expired_on_mobile": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sertifikatet utløper ", _interpolate(_named("expirationTime")), ". Gå til ", _interpolate(_named("saml2Idp")), " for å opprette et nytt sertifikat. Kom tilbake hit, rediger denne leverandøren, klikk 'Oppdater sertifikat'-knappen og rediger siden. Kontroller de nye sertifikatopplysningene og klikk 'Lagre' for å oppdatere dem."])},
  "page_sso_saml2idp_details_header_certificate_expire_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sertifikatets utløpsdato"])},
  "page_sso_saml2idp_setup_step_visma_metadata_title_upload": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Last opp metadata-filen fra Visma til ", _interpolate(_named("providerDisplayName"))])},
  "page_sso_saml2idp_setup_step_visma_metadata_title_copy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kopier informasjonen fra Vismas metadata til ", _interpolate(_named("providerDisplayName"))])},
  "page_sso_saml2idp_setup_step_visma_metadata_title_copy_with_public_certificate_download": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Konfigurer metadata i ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_visma_metadata_title_upload_or_copy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Konfigurer ", _interpolate(_named("providerDisplayName")), " med Vismas metadat fra filen eller fra individuelle felter"])},
  "page_sso_saml2idp_setup_step_visma_metadata_info_upload": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Last opp til ", _interpolate(_named("providerDisplayName")), " metadata-filen fra Visma som du har lastet ned under."])},
  "page_sso_saml2idp_setup_step_visma_metadata_info_copy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kopier hvert metadata-felt under og lim det inn i den korresponderende konfigurasjonen i ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_visma_metadata_info_copy_with_public_certificate_download": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kopier koblingene nedenfor til ", _interpolate(_named("providerDisplayName")), "-konfigurasjonen. For å aktivere Single Sign-on-tjenesten, last ned sertifikatet nedenfor og last det opp til ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_visma_metadata_info_upload_or_copy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Last opp metadata-filen fra Visma som du lastet ned under eller kopier hvert metadata-felt under og lim det inn i den korresponderende konfigurasjonen i ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_visma_metadata_info_copy_metadata_url_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kopier metadata-URL nedenfor og lim den inn i den tilsvarende konfigurasjonen i ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_visma_metadata_metadata_file_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata-fil"])},
  "page_sso_saml2idp_setup_step_visma_metadata_or_divider_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ELLER"])},
  "page_sso_saml2idp_setup_step_claims_config_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Konfigurer krav i ", _interpolate(_named("providerDisplayName"))])},
  "page_sso_saml2idp_setup_step_claims_config_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kontroller at ", _interpolate(_named("providerDisplayName")), " er konfigurert for å bruke følgende kravnnavn når du sender SAML-svar til Visma."])},
  "page_sso_saml2idp_setup_step_provider_metadata_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Lim inn metadata URL for ", _interpolate(_named("providerDisplayName")), " i Visma"])},
  "page_sso_saml2idp_setup_step_provider_metadata_title_upload": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Last opp metadata fra ", _interpolate(_named("providerDisplayName")), " til Visma"])},
  "page_sso_saml2idp_setup_step_provider_metadata_info_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kopier metadata-URL fra ", _interpolate(_named("providerDisplayName")), " og lim den inn under."])},
  "page_sso_saml2idp_setup_step_provider_metadata_info_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dette er tilgjengelig fra din leverandør ", _interpolate(_named("providerDisplayName")), " og inneholder det offentlige sertifikatet, krav og SAML-endepunkter som Visma bruker i sin egen konfigurering."])},
  "page_sso_saml2idp_setup_step_provider_metadata_info_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For din bruker-ID må du sende en gyldig e-postadresse som NameID og i tillegg ditt fornavn og etternavn."])},
  "page_sso_saml2idp_setup_step_provider_metadata_info_4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Last ned filen med metadata fra ", _interpolate(_named("providerDisplayName")), " og last det opp under."])},
  "page_sso_saml2idp_setup_step_provider_metadata_url_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lim inn metadata URL"])},
  "page_sso_saml2idp_setup_step_provider_metadata_preview_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forhåndsvis data"])},
  "page_sso_saml2idp_setup_step_provider_metadata_empty_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feltet kan ikke være tomt."])},
  "page_sso_saml2idp_setup_step_provider_metadata_max_length_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksimalt antall tegn er 100."])},
  "page_sso_saml2idp_setup_step_provider_metadata_upload_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last opp metadata-fil"])},
  "page_sso_saml2idp_setup_step_provider_metadata_upload_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg filen for å forhåndsvise data"])},
  "page_sso_saml2idp_setup_step_provider_metadata_upload_refresh_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg fil for å laste inn data på nytt"])},
  "page_sso_saml2idp_setup_step_provider_metadata_refresh_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last inn data på nytt"])},
  "page_sso_saml2idp_setup_step_provider_metadata_preview_from_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forhåndsvisning av metadata fra URL"])},
  "page_sso_saml2idp_setup_step_provider_metadata_preview_from_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forhåndsvisning av metadata fra fil"])},
  "page_sso_saml2idp_setup_step_provider_metadata_error_metadata_empty": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dette feltet er obligatorisk. Kopier det fra ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_provider_metadata_error_metadata_url_invalid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ugyldig metadata URL. Kopier URL fra ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_saml2idp_setup_step_provider_metadata_error_metadata_file_invalid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ugyldig metadata-fil. Last den ned filen fra ", _interpolate(_named("providerDisplayName")), " og last den opp her."])},
  "page_sso_saml2idp_setup_step_provider_metadata_certificate_expires_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Utløper ", _interpolate(_named("expirationTime"))])},
  "page_sso_saml2idp_setup_step_provider_metadata_certificate_expired_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Utløpt den ", _interpolate(_named("expirationTime")), ". Gå til ", _interpolate(_named("providerDisplayName")), " for å opprette nytt sertifikat. Kom tilbake hit og klikk 'Oppdater sertifikat'-knappen på redigeringssiden. Kontroller de nye sertifikatopplysningene og klikk 'Lagre' for å oppdatere dem."])},
  "page_sso_saml2idp_setup_step_advanced_config_slo_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enkel utlogging"])},
  "page_sso_saml2idp_setup_step_advanced_config_slo_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vil du at Visma anroper ditt ", _interpolate(_named("providerDisplayName")), " single logout-endepunkt når brukeren logger ut fra Vismas apper? Svar \"NEI\" hvis du vil beholde din ", _interpolate(_named("providerDisplayName")), "-økt aktiv inntil sign out er satt i gang fra ", _interpolate(_named("providerDisplayName")), " mot Visma SAML Single Logout-endepunkt."])},
  "page_sso_saml2idp_setup_step_advanced_config_updateclaims_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppdater profilen hver gang en bruker logger inn"])},
  "page_sso_saml2idp_setup_step_advanced_config_updateclaims_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hvis det er aktivert, vil tilgjengelige attributter for brukerprofilen synkroniseres fra ", _interpolate(_named("providerDisplayName")), " hver gang brukeren logger inn."])},
  "page_sso_saml2idp_setup_step_advanced_config_updategroups_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppdater gruppemedlemskap hver gang en bruker logger inn"])},
  "page_sso_saml2idp_setup_step_advanced_config_updategroups_info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hvis det er aktivert, vil gruppemedlemskapene til brukeren synkroniseres fra ", _interpolate(_named("providerDisplayName")), " hver gang brukeren logger inn."])},
  "page_sso_saml2idp_setup_step_metadata_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata-URL"])},
  "page_sso_saml2idp_setup_step_entity_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entity ID"])},
  "page_sso_saml2idp_setup_step_saml_logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML Logout"])},
  "page_sso_saml2idp_setup_step_sign_on_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign on URL"])},
  "page_sso_saml2idp_setup_step_logout_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout URL"])},
  "page_sso_saml2idp_setup_step_certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificate"])},
  "page_sso_saml2idp_setup_step_thumbprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumbprint"])},
  "page_sso_saml2idp_setup_step_sign_authn_requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Authn Requests"])},
  "page_sso_saml2idp_setup_step_saml_assertion_consumer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAML Assertion Consumer"])},
  "page_sso_saml2sp_setup_step_visma_public_certificate_file_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offentlig sertifikat"])},
  "page_sso_oidcidp_btn_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til OIDC-identitetsleverandør"])},
  "page_sso_oidcidp_configure_uris_title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Konfigurer URIer i ", _interpolate(_named("providerDisplayName"))])},
  "page_sso_oidcidp_signin_redirect_uri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirect URI"])},
  "page_sso_oidcidp_signout_redirect_uri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign-out redirect URI"])},
  "page_sso_oidcidp_setup_step_visma_uris": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kopier koblingene nedenfor til din ", _interpolate(_named("providerDisplayName")), " -konfigurasjon."])},
  "page_sso_oidcidp_invalid_authority_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ugyldig authority."])},
  "page_sso_oidcidp_invalid_authority_for_provider_error": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ugyldig authority for ", _interpolate(_named("providerDisplayName")), "."])},
  "page_sso_oidcidp_configure_oidc_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konfigurer OpenID Connect Client"])},
  "page_sso_oidcidp_authority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authority"])},
  "page_sso_oidcidp_client_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client ID"])},
  "page_sso_oidcidp_client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client secret"])},
  "page_sso_oidcidp_update_client_secret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppdater client secret"])},
  "page_sso_oidcidp_authentication_flow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autentiserings flyt"])},
  "page_sso_oidcidp_authentication_web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web"])},
  "page_sso_oidcidp_authentication_pkce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PKCE"])},
  "page_sso_oidcidp_authentication_web_and_pkce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web + PKCE"])},
  "page_sso_surfconext_setup_home_realm_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SURFconext hjemmeområde"])},
  "page_sso_surfconext_setup_home_realm_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angi hjemmeområdet du har konfigurert i SURFconext"])},
  "page_sso_surfconext_setup_home_realm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hjemmeområde"])},
  "page_sso_surfconext_select_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg domene"])},
  "page_sso_surfconext_notif_already_added_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har allerede konfigurert SURFconext-identitetsleverandøren."])}
}