import GroupUserSearchInputModel from '@/api/group-api-client/models/GroupUserSearchInputModel';
import UserNotMemberOfGroupModel from '@/api/user-api-client/models/UserNotMemberOfGroupModel';
import StoreEntity from '../models';
import GroupUsersTotalsModel from './models/GroupUsersTotalsModel';
import GroupsModel from '@/api/group-api-client/models/GroupsModel';
import GroupSearchInputModel from '@/api/group-api-client/models/GroupSearchInputModel';

export class GroupStoreState extends StoreEntity {
    public constructor() {
        super();

        this.groupsList = new GroupsModel();
        this.isProcessing = false;
        this.isUnauthorizedToViewGroups = false;
        this.isEditMode = false;
        this.isDataLoaded = true;
        this.userSearchModel = new GroupUserSearchInputModel();
        this.groupUsersTotalsModel = new GroupUsersTotalsModel();
        this.userAddSearchResult = [];
        this.groupSearchInputModel = new GroupSearchInputModel();
    }

    public groupsList: GroupsModel;

    public groupSearchInputModel: GroupSearchInputModel;

    public isUnauthorizedToViewGroups: boolean;

    public isEditMode: boolean;

    public isDataLoaded: boolean;

    public userSearchModel: GroupUserSearchInputModel;

    public groupUsersTotalsModel: GroupUsersTotalsModel;

    public userAddSearchResult: UserNotMemberOfGroupModel[];
}

export default new GroupStoreState();
