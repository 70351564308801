import { JsonConvert } from 'json2typescript';

export default class ApiRequestPayload<T extends object> {
    public constructor(data: T | T[], type: { new (): T }) {
        this.data = data;
        this.type = type;
    }

    public data: T | T[];

    public type: { new (): T } | undefined;

    public serialize(jsonCovert: JsonConvert) {
        if (Array.isArray(this.data)) {
            return jsonCovert.serializeArray(this.data, this.type);
        }

        return jsonCovert.serializeObject(this.data, this.type);
    }
}
