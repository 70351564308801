import { JsonObject, JsonProperty } from 'json2typescript';
import IdentityProviderScimConfiguration from './IdentityProviderScimConfiguration';

@JsonObject('IdentityProviderScimConfigModel')
export default class IdentityProviderScimConfigModel {
    public constructor() {
        this.name = '';
        this.saml2ScimConfiguration = void 0;
        this.oidcScimConfiguration = void 0;
    }

    @JsonProperty('name', String)
    public name: string;

    @JsonProperty('saml2_scim_configuration', IdentityProviderScimConfiguration, true)
    public saml2ScimConfiguration?: IdentityProviderScimConfiguration;

    @JsonProperty('oidc_scim_configuration', IdentityProviderScimConfiguration, true)
    public oidcScimConfiguration?: IdentityProviderScimConfiguration;
}
