import StoreEntity from '../models';
import OidcIdentityProviderOutputModel from '@/api/oidc-idp-client/models/OidcIdentityProviderOutputModel';

export class OidcIdentityProviderStoreState extends StoreEntity {
    constructor() {
        super();

        this.isUnauthorizedToViewOidcIdp = false;
    }

    public oidcIdentityProvider: OidcIdentityProviderOutputModel | undefined;

    public isUnauthorizedToViewOidcIdp: boolean;
}

export default new OidcIdentityProviderStoreState();
