import util from '@/util';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('UserSessionApplicationModel')
export default class UserSessionApplicationModel {
    public constructor() {
        this.applicationId = '';
        this.applicationName = '';
        this.loginDate = '';
    }

    @JsonProperty('application_id', String)
    public applicationId: string;

    @JsonProperty('application_name', String)
    public applicationName: string;

    @JsonProperty('login_date', String)
    public loginDate: string;

    public get displayLoginDate(): string {
        if (!this.loginDate) {
            return '';
        }

        return util.formatDateTime(this.loginDate);
    }
}
