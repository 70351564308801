import { Store } from 'vuex';
import { StoreAccessors, getStoreAccessors, GetterHandler, MutationHandlerWithPayload, MutationHandlerNoPayload, ActionHandlerWithPayload, ActionHandlerNoPayload } from 'vuex-typescript';
import util from '@/util';
import { BasicStoreAccessors, RootState } from './types';

export default class DefaultStoreAccessors<TState> implements BasicStoreAccessors<TState> {
    private storeApi: StoreAccessors<TState, RootState>;

    public constructor(private readonly store: Store<RootState>, private readonly namespace: string) {
        this.storeApi = getStoreAccessors<TState, RootState>(this.namespace);
    }

    public get<TResult>(handler: GetterHandler<TState, RootState, TResult>): TResult {
        return this.storeApi.read<TResult>(handler)(this.store);
    }

    public commit<TPayload>(handler: MutationHandlerWithPayload<TState, TPayload> | MutationHandlerNoPayload<TState>, payload?: TPayload): void {
        if (util.isNullOrEmpty(payload)) {
            this.storeApi.commit(handler as MutationHandlerNoPayload<TState>)(this.store);
        } else {
            this.storeApi.commit(handler as MutationHandlerWithPayload<TState, TPayload>)(this.store, payload);
        }
    }

    public async dispatch<TPayload, TResult>(
        handler: ActionHandlerWithPayload<TState, RootState, TPayload, TResult> | ActionHandlerNoPayload<TState, RootState, TResult>,
        payload?: TPayload
    ): Promise<TResult> {
        if (util.isNullOrEmpty(payload)) {
            return this.storeApi.dispatch(handler as ActionHandlerNoPayload<TState, RootState, TResult>)(this.store);
        }

        return this.storeApi.dispatch(handler as ActionHandlerWithPayload<TState, RootState, TPayload, TResult>)(this.store, payload);
    }
}
