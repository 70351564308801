import IdentityProviderScimConfigModel from '@/api/provisioning-api-client/models/IdentityProviderScimConfigModel';
import { ProvisioningStoreState } from './state';
import ProvisioningModel from '@/api/provisioning-api-client/models/ProvisioningModel';

export default {
    provisioningData(state: ProvisioningStoreState): ProvisioningModel | undefined {
        return state.provisioningData;
    },

    isProcessing(state: ProvisioningStoreState): boolean {
        return state.isProcessing;
    },

    isUnauthorizedToViewProvisioning(state: ProvisioningStoreState): boolean {
        return state.isUnauthorizedToViewProvisioning;
    },

    identityProvidersScimConfig(state: ProvisioningStoreState): IdentityProviderScimConfigModel[] {
        return state.identityProvidersScimConfig;
    },

    isIdentityProvidersScimConfigLoaded(state: ProvisioningStoreState): boolean {
        return state.isIdentityProvidersScimConfigLoaded;
    },
};
