import { SortColumn } from '@/api/group-api-client/enums/SortColumn';
import { SortOrder } from '@/api/group-api-client/enums/SortOrder';
import GroupUserModel from '@/api/group-api-client/models/GroupUserModel';
import GroupUserSearchInputModel from '@/api/group-api-client/models/GroupUserSearchInputModel';
import UserNotMemberOfGroupModel from '@/api/user-api-client/models/UserNotMemberOfGroupModel';
import { GroupStoreState } from './state';
import GroupsModel from '@/api/group-api-client/models/GroupsModel';
import GroupModel from '@/api/group-api-client/models/GroupModel';
import GroupSearchInputModel from '@/api/group-api-client/models/GroupSearchInputModel';

export default {
    setGroupsList(state: GroupStoreState, model: GroupsModel): void {
        state.groupsList = model;
    },

    addGroup(state: GroupStoreState, group: GroupModel): void {
        state.groupsList.groups.push(group);
        state.groupsList.groups.sort((a, b) => (a.groupName.toLowerCase() < b.groupName.toLowerCase() ? -1 : 1));
    },

    setStoreProcessingState(state: GroupStoreState, isProcessing: boolean): void {
        state.isProcessing = isProcessing;
    },

    setUnauthorizedToViewGroups(state: GroupStoreState): void {
        state.isUnauthorizedToViewGroups = true;
    },

    setIsEditMode(state: GroupStoreState, isEditMode: boolean): void {
        state.isEditMode = isEditMode;
    },

    updateGroup(state: GroupStoreState, model: GroupModel): void {
        const groupIndex = state.groupsList.groups.findIndex((g) => g.id === model.id);

        state.groupsList.groups.splice(groupIndex, 1, model);
        state.groupsList.groups.sort((a, b) => (a.groupName.toLowerCase() < b.groupName.toLowerCase() ? -1 : 1));
    },

    setGroupUsers(state: GroupStoreState, { users, totalPages, totalUsers }: { users: GroupUserModel[]; totalPages: number; totalUsers: number }): void {
        state.groupUsersTotalsModel.groupUsers = users;

        state.groupUsersTotalsModel.groupUserCount = totalUsers;
        state.groupUsersTotalsModel.groupUserPages = totalPages;
    },

    setGroupUserSearchModel(state: GroupStoreState, model: GroupUserSearchInputModel): void {
        state.userSearchModel = model;
    },

    setGroupUserSearchPage(state: GroupStoreState, page: number): void {
        state.userSearchModel.page = page;
    },

    setGroupUserSearchColumnAndOrder(state: GroupStoreState, { column, order }: { column: SortColumn; order: SortOrder }): void {
        state.userSearchModel.sortColumn = column;
        state.userSearchModel.sortOrder = order;
    },

    setGroupUserSearchMember(state: GroupStoreState, member: string): void {
        state.userSearchModel.member = member;
    },

    removeGroup(state: GroupStoreState, groupId: string): void {
        const groupIndex = state.groupsList.groups.findIndex((g) => g.id === groupId);

        if (groupIndex > -1) {
            state.groupsList.groups.splice(groupIndex, 1);
        }
    },

    removeUser(state: GroupStoreState, { groupId, userId }: { groupId: string; userId: string }): void {
        const userIndex = state.groupUsersTotalsModel.groupUsers.findIndex((u) => u.id === userId);
        const groupIndex = state.groupsList.groups.findIndex((g) => g.id === groupId);

        if (userIndex > -1 && groupIndex > -1) {
            state.groupUsersTotalsModel.groupUsers.splice(userIndex, 1);

            const group = state.groupsList.groups[groupIndex];
            group!.members = group!.members - 1;

            state.groupsList.groups.splice(groupIndex, 1, group!);
        }
    },

    increaseMembersCount(state: GroupStoreState, groupId: string): void {
        const groupIndex = state.groupsList.groups.findIndex((g) => g.id === groupId);

        if (groupIndex > -1) {
            const group = state.groupsList.groups[groupIndex];
            group!.members = group!.members + 1;

            state.groupsList.groups.splice(groupIndex, 1, group!);
        }
    },

    setUserAddSearchResults(state: GroupStoreState, users: UserNotMemberOfGroupModel[]): void {
        if (state.userAddSearchResult.length > 0) {
            state.userAddSearchResult.splice(0, state.userAddSearchResult.length);
        }

        if (users.length > 0) {
            state.userAddSearchResult.push(...users);
        }
    },

    setGroupSearchPage(state: GroupStoreState, page: number): void {
        state.groupSearchInputModel.page = page;
    },

    setGroupSearchGroupNameFilter(state: GroupStoreState, filter: string): void {
        state.groupSearchInputModel.groupName = filter;
    },

    setGroupSearchMemberFilter(state: GroupStoreState, filter: string): void {
        state.groupSearchInputModel.member = filter;
    },

    resetGroupSearchInput(state: GroupStoreState): void {
        state.groupSearchInputModel = new GroupSearchInputModel();
    },

    setDataLoaded(state: GroupStoreState, isDataLoaded: boolean): void {
        state.isDataLoaded = isDataLoaded;
    },
};
