import TenantClientSignInUriModel from '@/api/mydomain-api-client/models/TenantClientSignInUriModel';
import StoreEntity from '@/store/models';

export class MyDomainStoreState extends StoreEntity {
    constructor() {
        super();

        this.isModifiable = false;
        this.isUnauthorizedToViewMyDomain = false;
        this.isDataLoaded = false;
        this.isVismaHomeSignInUriLoaded = false;
    }

    public myDomain: string | undefined;

    public myDomainUri: string | undefined;

    public vismaMetadataXml: string | undefined;

    public vismaMetadataXmlUrl: string | undefined;

    public isModifiable: boolean;

    public tenantClientsWithSignInUri: TenantClientSignInUriModel[] | undefined;

    public assignedTenantClientWithSignInUri: TenantClientSignInUriModel | undefined;

    public vismaHomeSignInUri: string | undefined;

    public isVismaHomeSignInUriLoaded: boolean;

    public isUnauthorizedToViewMyDomain: boolean;

    public isDataLoaded: boolean;
}

export default new MyDomainStoreState();
