import logger from '@/util/logger';
import { StatusCodes } from 'http-status-codes';
import notificationService from '@/services/notification-service';
import ApiResponseInterceptor from '../models/ApiResponseInterceptor';
import ApiResponse from '../models/ApiResponse';
import translations from '@/locales/translations';

export default class SystemTemporarilyUnavailableInterceptor implements ApiResponseInterceptor {
    name(): string {
        return 'SystemTemporarilyUnavailableInterceptor';
    }

    intercept(response: ApiResponse): Promise<boolean> {
        // Validate the response code
        if (response.statusCode === 0 || response.statusCode === StatusCodes.BAD_GATEWAY || response.statusCode === StatusCodes.SERVICE_UNAVAILABLE) {
            logger.log(`Received ${response.statusCode} unavailability response from backend. Response ${response.statusCode} ${JSON.stringify(response.rawData)}`);
            notificationService.error(translations.NOTIF_ERROR_SERVER_UNAVAILABLE);
            return Promise.resolve(false);
        }

        return Promise.resolve(true);
    }
}
