import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('DomainModel')
export default class DomainModel {
    public constructor() {
        this.name = '';
        this.isVerified = false;
        this.createdDate = '';
        this.userCount = 0;
    }

    @JsonProperty('domain_name', String)
    public name: string;

    @JsonProperty('is_verified', Boolean)
    public isVerified: boolean;

    @JsonProperty('created_date', String)
    public createdDate: string;

    @JsonProperty('user_count', Number)
    public userCount: number;
}
