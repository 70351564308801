export default {
  "common_learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzzināt vairāk"])},
  "common_ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Labi"])},
  "common_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atcelt"])},
  "common_keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paturēt"])},
  "common_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopēt"])},
  "common_copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nokopēts"])},
  "common_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lejupielādēt"])},
  "common_downloaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lejupielādēts"])},
  "common_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Augšupielādēt"])},
  "common_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dzēst"])},
  "common_verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprināt"])},
  "common_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apstiprināts"])},
  "common_not_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nav apstiprināts"])},
  "common_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veiksmīgi"])},
  "common_failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neizdevās"])},
  "common_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopā"])},
  "common_next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tālāk"])},
  "common_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pievienot"])},
  "common_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atjaunot"])},
  "common_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darbības"])},
  "common_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saglabāt"])},
  "common_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vārds"])},
  "common_created_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izveidošanas datums"])},
  "common_completed_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pabeigšanas datums"])},
  "common_close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aizvērt"])},
  "notif_error_server_unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neizdevās sazināties ar web serveri. Lūdzu mēģiniet atkārtoti pēc pāris sekundēm."])},
  "notif_warn_unauthorized_to_perform_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jums nav tiesību veikt šo darbību."])},
  "notif_warn_unauthorized_to_view_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jums nav tiesību skatīt šos datus."])},
  "notif_modal_session_expired_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sesija beigusies"])},
  "notif_modal_session_expired_body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jūsu sesija ir beigusies. Drošības apsvērumu dēļ lūdzam pieslēgties atkārtoti."])},
  "alert_no_verified_domains_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lai turpinātu, jums ir jāpievieno un jāverificē vismaz viens domēns."])},
  "pagination_first_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iet uz pirmo lapu"])},
  "pagination_last_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iet uz pēdējo lapu"])},
  "pagination_previous_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iepriekšējā lapa"])},
  "pagination_next_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nākamā lapa"])},
  "pagination_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no"])},
  "common_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statuss"])},
  "common_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sīkāka informācija"])},
  "common_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apraksts"])},
  "common_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Id"])},
  "common_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izveidots"])},
  "common_completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pabeigts"])},
  "common_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atpakaļ"])},
  "common_sort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kārtot"])},
  "common_apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pieteikties"])},
  "common_edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Labot"])},
  "common_yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jā"])},
  "common_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nē"])},
  "common_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atiestatīt"])},
  "leave_page_confirmation_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jums ir nesaglabātas izmaiņas"])},
  "leave_page_confirmation_modal_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vai tiešām vēlaties pamest šo lapu? Visas nesaglabātās izmaiņas tiks zaudētas."])},
  "common_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skatīt"])},
  "common_show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rādīt"])},
  "common_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veids"])}
}