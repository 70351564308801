import { JsonObject, JsonProperty } from 'json2typescript';
import BaseJobModel from './BaseJobModel';
import DomainSourceModel from './DomainSourceModel';

@JsonObject('AggregatedJobModel')
export default class AggregatedJobModel extends BaseJobModel {
    public constructor() {
        super();

        this.sourceDomains = new Array<DomainSourceModel>();
        this.targetDomainName = '';
    }

    // Bulk email change job properties
    @JsonProperty('domains', [DomainSourceModel], true)
    public sourceDomains: DomainSourceModel[];

    @JsonProperty('target_domain', String, true)
    public targetDomainName: string;
}
