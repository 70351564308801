export const JOB_TYPE_BULK_DOMAIN_CHANGE = 'bulkdomainchange';
export const JOB_TYPE_USER_EXPORT = 'userexport';
export const JOB_TYPE_USER_IMPORT = 'userimportorupdate';
export const JOB_TYPE_USER_AUDIT_EXPORT = 'userauditlogsexport';

export const JOB_STATE_QUEUED = 'queued';
export const JOB_STATE_PROCESSING = 'processing';
export const JOB_STATE_COMPLETED = 'completed';
export const JOB_STATE_FAILED = 'failed';
export const JOB_STATE_COMPLETED_WITH_ERRORS = 'completed_with_errors';

export const REPORT_STATE_SUCCESS = 'success';
export const REPORT_STATE_FAILURE = 'failure';

export const ERROR_TENANT_NOT_EXIST = 'ERROR_TENANT_NOT_EXIST';
export const ERROR_INVALID_USER_ID = 'ERROR_INVALID_USER_ID';
export const ERROR_USER_NOT_EXIST = 'ERROR_USER_NOT_EXIST';
export const ERROR_INVALID_EMAIL = 'ERROR_INVALID_EMAIL';
export const ERROR_DOMAIN_NOT_EXIST = 'ERROR_DOMAIN_NOT_EXIST';
export const ERROR_EMAIL_EXIST = 'ERROR_EMAIL_EXIST';
export const ERROR_GENERIC_JOBS_WORKER = 'ERROR_GENERIC_JOBS_WORKER';
export const ERROR_JOB_NOT_EXIST = 'ERROR_JOB_NOT_EXIST';
export const ERROR_INVALID_JOB_ID = 'ERROR_INVALID_JOB_ID';

export const DEFAULT_USER_AUDIT_LOGS_EXPORT_FIELD_NAMES = ['event', 'timestamp', 'ipv4_address', 'application_name'];
