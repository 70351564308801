import ApiClientError from '@/api/error';
import mutations from './mutations';
import { BasicStoreAccessors, RootState } from '../types';
import { AuthenticationPolicyStoreState } from './state';
import authenticationpoliciesApiClient from '@/api/authenticationpolicy-api-client';
import AuthenticationPolicyStoreModel from './models/AuthenticationPolicyStoreModel';
import IdentityProviderStoreModel from './models/IdentityProviderStoreModel';
import AuthenticationPolicyInputModel from '@/api/authenticationpolicy-api-client/models/AuthenticationPolicyInputModel';
import { ActionContext } from 'vuex';

let storeApi: BasicStoreAccessors<AuthenticationPolicyStoreState>;

export function initActions(api: BasicStoreAccessors<AuthenticationPolicyStoreState>): void {
    storeApi = api;
}

export default {
    async fetchAllAuthenticationPoliciesAsync(): Promise<void> {
        try {
            const authenticationPolicies = await authenticationpoliciesApiClient.getAllAsync();

            storeApi.commit(
                mutations.setAuthenticationPolicies,
                authenticationPolicies.map((authenticationpolicy) => new AuthenticationPolicyStoreModel(authenticationpolicy))
            );
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewAuthenticationPolicies);
            }

            throw err;
        }
    },

    async fetchAllIdentityProvidersAsync(): Promise<void> {
        try {
            const identityProviders = await authenticationpoliciesApiClient.getAllIdentityProvidersAsync();

            storeApi.commit(
                mutations.setAllIdentityProviders,
                identityProviders.map((identityProvider) => new IdentityProviderStoreModel(identityProvider))
            );
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewAuthenticationPolicies);
            }

            throw err;
        }
    },

    async updateAuthenticationPolicyAsync(context: ActionContext<AuthenticationPolicyStoreState, RootState>, { id, model }: { id: string; model: AuthenticationPolicyInputModel }): Promise<void> {
        try {
            storeApi.commit(mutations.setStoreProcessingState, true);
            const updatedAuthenticationPolicy = await authenticationpoliciesApiClient.updateAsync(id, model);
            storeApi.commit(mutations.updateAuthenticationPolicy, updatedAuthenticationPolicy);
        } catch (err) {
            const apiError = err as ApiClientError;

            if (apiError.isAccessRestrictedStatus) {
                storeApi.commit(mutations.setUnauthorizedToViewAuthenticationPolicies);
            }

            throw err;
        } finally {
            storeApi.commit(mutations.setStoreProcessingState, false);
        }
    },

    async getSessionRestrictionsSettingsAsync(): Promise<void> {
        try {
            storeApi.commit(mutations.setStoreProcessingState, true);

            const result = await authenticationpoliciesApiClient.getSessionRestrictionsSettingsAsync();

            storeApi.commit(mutations.setSessionRestrictionsSettings, result);
        } finally {
            storeApi.commit(mutations.setStoreProcessingState, false);
        }
    },
};
