import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('IdentityProviderModel')
export default class IdentityProviderModel {
    public constructor() {
        this.name = '';
        this.displayName = '';
        this.type = '';
        this.isReadonly = false;
    }

    @JsonProperty('name', String)
    public name: string;

    @JsonProperty('display_name', String)
    public displayName: string;

    @JsonProperty('type', String)
    public type: string;

    @JsonProperty('is_readonly', Boolean)
    public isReadonly: boolean;
}
