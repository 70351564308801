import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('UserPhoneModel')
export default class UserPhoneModel {
    constructor() {
        this.id = '';
        this.phoneNumber = '';
    }

    @JsonProperty('id', String)
    public id: string;

    @JsonProperty('phone_number', String)
    public phoneNumber: string;
}
