import { JsonObject, JsonProperty } from 'json2typescript';
import AuthenticationPolicyBodyTwoStepVerificationModel from './AuthenticationPolicyBodyTwoStepVerificationModel';
import AuthenticationPolicyPasswordPolicyModel from './AuthenticationPolicyPasswordPolicyModel';
import AuthenticationPolicySessionRestrictionsModel from './AuthenticationPolicySessionRestrictionsModel';

@JsonObject('AuthenticationPolicyBodyModel')
export default class AuthenticationPolicyBodyModel {
    @JsonProperty('2_step_verification', AuthenticationPolicyBodyTwoStepVerificationModel, true)
    public twoStepVerification?: AuthenticationPolicyBodyTwoStepVerificationModel;

    @JsonProperty('devices', [String], true)
    public devices?: string[];

    @JsonProperty('browsers', [String], true)
    public browsers?: string[];

    @JsonProperty('identity_providers', [String], true)
    public identityProviders?: string[];

    @JsonProperty('authorized_networks', [String], true)
    public authorizedNetworks?: string[];

    @JsonProperty('geo_ip_locations', [String], true)
    public geoIpLocations?: string[];

    @JsonProperty('password', AuthenticationPolicyPasswordPolicyModel, true)
    public password?: AuthenticationPolicyPasswordPolicyModel;

    @JsonProperty('session_restrictions', AuthenticationPolicySessionRestrictionsModel, true)
    public sessionRestrictions?: AuthenticationPolicySessionRestrictionsModel;
}
