import httpClient from '@/http-client';
import DomainModel from './models/DomainModel';
import ApiClientError from '../error';
import DomainCreatedModel from './models/DomainCreatedModel';
import DomainVerificationRecordModel from './models/DomainVerificationRecordModel';
import AddDomainModel from './models/AddDomainModel';
import { StatusCodes } from 'http-status-codes';
import ApiRequestPayload from '@/http-client/models/ApiRequestPayload';

const routeRoot = '/v1.0/domains';

export default {
    async getAllAsync(): Promise<DomainModel[]> {
        const response = await httpClient.getAsync(routeRoot);

        if (response.statusCode === StatusCodes.OK) {
            return response.dataCollection(DomainModel);
        }

        if (response.statusCode === StatusCodes.NO_CONTENT) {
            return [];
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async addAsync(domainName: string): Promise<DomainCreatedModel> {
        const response = await httpClient.postAsync(`${routeRoot}`, new ApiRequestPayload(new AddDomainModel(domainName), AddDomainModel));

        if (response.statusCode === StatusCodes.CREATED) {
            const domain = response.data(DomainCreatedModel);

            if (!domain) {
                throw new ApiClientError(response.statusCode);
            }

            return domain;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async getVerificationRecordAsync(domainName: string): Promise<string> {
        const response = await httpClient.getAsync(`${routeRoot}/${domainName}/verify`);

        if (response.statusCode === StatusCodes.OK) {
            const verificationRecord = response.data(DomainVerificationRecordModel)?.verificationRecord;

            if (!verificationRecord) {
                throw new ApiClientError(response.statusCode);
            }

            return verificationRecord;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async verifyAsync(domainName: string): Promise<void> {
        const response = await httpClient.patchAsync(`${routeRoot}/${domainName}/verify`);

        if (response.statusCode === StatusCodes.OK) {
            return;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async deleteDomainAsync(domainName: string): Promise<void> {
        const response = await httpClient.deleteAsync(`${routeRoot}/${domainName}`);

        if (response.statusCode === StatusCodes.OK) {
            return;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },
};
