import httpClient from '@/http-client';
import ApiRequestPayload from '@/http-client/models/ApiRequestPayload';
import { StatusCodes } from 'http-status-codes';
import ApiClientError from '../error';
import SurfConextInputModel from './models/SurfConextInputModel';
import SurfConextOutputModel from './models/SurfConextOutputModel';

const routeRoot = '/v1.0/sso/surfconext';

// public functions
export default {
    async getAsync(): Promise<SurfConextOutputModel | undefined> {
        const response = await httpClient.getAsync(routeRoot);

        if (response.statusCode === StatusCodes.OK) {
            return response.data(SurfConextOutputModel);
        }

        if (response.statusCode === StatusCodes.NO_CONTENT) {
            return void 0;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async addAsync(model: SurfConextInputModel): Promise<SurfConextOutputModel> {
        const response = await httpClient.postAsync(`${routeRoot}`, new ApiRequestPayload(model, SurfConextInputModel));

        if (response.statusCode === StatusCodes.CREATED) {
            const surfConextConfiguration = response.data(SurfConextOutputModel);

            if (!surfConextConfiguration) {
                throw new ApiClientError(response.statusCode);
            }

            return surfConextConfiguration;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async updateAsync(model: SurfConextInputModel): Promise<SurfConextOutputModel> {
        const response = await httpClient.putAsync(`${routeRoot}`, new ApiRequestPayload(model, SurfConextInputModel));

        if (response.statusCode === StatusCodes.OK) {
            const surfConextConfiguration = response.data(SurfConextOutputModel);

            if (!surfConextConfiguration) {
                throw new ApiClientError(response.statusCode);
            }

            return surfConextConfiguration;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },

    async deleteAsync(): Promise<void> {
        const response = await httpClient.deleteAsync(`${routeRoot}`);

        if (response.statusCode === StatusCodes.NO_CONTENT) {
            return;
        }

        throw new ApiClientError(response.statusCode, response.errorCode);
    },
};
