import state, { DomainStoreState } from './state';
import getters from './getters';
import mutations from './mutations';
import { BasicStoreAccessors } from '../types';
import actions, { initActions } from './actions';

export default {
    namespaced: true,
    storeApi: {} as BasicStoreAccessors<DomainStoreState>,

    state,
    getters,
    mutations,
    actions,

    init(storeApi: BasicStoreAccessors<DomainStoreState>): void {
        this.storeApi = storeApi;
        initActions(storeApi);
    },
};
